import { Skill } from "./Skill";
import { Company } from "./Company";

export class Persona {
  id: number;
  idParty: number;
  enable: boolean;
  teamName : string;
  personalNumber: string;
  idKeycloak: string;
  firstName: string;
  middleName: string;
  lastName: string;
  publicName: string;
  birthDate: Date;
  nationality: string;
  responsible: boolean;
  gender: string;
  mail: string;
  phone: string;
  address: string;
  manager: string;
  photo: string;
  subscription: string;
  globalRating: number;
  eligibility: string;
  organizationEntities: any [];
  listSkill: PersonaSkills[];
  rgpd: boolean;
  company: any;
  partyRoleID: number;
  listProfil: PartyRoleCatalogue [];
  //partyRoleDtos: any [];
  postPersona: PartyRoleCatalogue;
  user : boolean;
   constructor(persona?: Partial<Persona>) {

    if (persona){

      Object.assign(this, persona)

      this.listSkill = persona.listSkill?.sort((a,b) => a.skill.name.localeCompare(b.skill.name));

      if (persona.birthDate){
        this.birthDate = new Date(persona.birthDate);
      }
      else{
        this.birthDate = null
      }
      if (this.listSkill){
        for (const skill of this.listSkill){
          skill.startDate = new Date(skill.startDate);
          skill.endDate = new Date(skill.endDate);
        }
      }
    }
    else{
      this.enable = true;
      this.personalNumber = "";
      this.firstName= "";
      this.middleName= "";
      this.lastName= "";
      this.publicName = "";
      this.birthDate = null;
      this.nationality = "";
      this.phone = "";
      this.address = "";
      this.manager = "";
      this.mail = "";
      this.photo = "";
      this.gender = "";
      this.subscription = "";
      this.globalRating = null;
      this.eligibility = "";
      this.rgpd=false;
      this.listSkill = [];
      this.listProfil = [];
      this.postPersona = null;
      //this.partyRoleDtos = [];
      this.company= null;

    }

  }

  set setPhoto(newPhoto: string) {

    this.photo = newPhoto;

  }

  public isDefined(){
    return (
      this.firstName ||
      this.middleName ||
      this.lastName ||
      this.publicName ||
      this.birthDate ||
      this.nationality ||
      this.phone ||
      this.address ||
      this.manager ||
      this.mail ||
      this.photo ||
      this.gender ||
      this.subscription ||
      this.globalRating ||
      this.eligibility ||
      this.company
    )
  }


}

export interface PersonaSkills{

  id: number;
  idPersona: number;
  idSkill: number;
  skill: Skill;
  startDate: Date;
  endDate: Date;

}

export interface PartyRoleCatalogue{
  id: number;
  code:string;
  nameFR:string;
  nameEN:string;
  count:number;
  nameOrgaUnit:string;
  characteristics:any ;

}
