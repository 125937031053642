import { OnInit,HostListener } from '@angular/core';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { Technician } from '@data/schema/Technician';
import { PersonaService } from '@data/service/persona.service';
import { Persona } from '@data/schema/Persona';
import { Observable, BehaviorSubject } from 'rxjs';
import { ComponentCanDeactivate } from '@core/guard/component-can-deactivate.guard';
import { OrganizationService } from '@data/service/organization.service';
import { LocalStorageService } from '@shared/service/local-storage.service';
import { PermissionsList } from '@shared/enums/permissionsList';

@Component({
  selector: 'app-new-technician',
  templateUrl: './new-technician.component.html',
})
export class NewTechnicianComponent implements OnInit, ComponentCanDeactivate {

    public submited: BehaviorSubject<boolean>;

    //@HostListener allows us to also guard against browser refresh, close, etc.
    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {

      return (this.submited.value ? true : false)
    }

  listAllPersona: Persona[] = [];
  listAllIntervenant: Technician[] = [];

  listPersonaToChoose: Persona[] = [];
  totalRecords: number = this.listPersonaToChoose.length;

  // listPersonaToChoose: Persona[];

  selectedPersona: Persona = new Persona();
  intervenant: Technician = new Technician();

  display: boolean;
  userPools: number[] = [];


  constructor(
    private messageService: MessageService,
    private personaService: PersonaService,
    private router: Router,
    private localStorageService : LocalStorageService,
    private organizationService: OrganizationService
  ) {
      this.submited = new BehaviorSubject(false)
  }

  ngOnInit() {
    this.getPersonasByPools();
  } 

  private getPersonaList() {
    var listPersonaToChoose = [];
    this.personaService.getMinifiedPersonaList(this.userPools.toString()).subscribe(
      (res) => {
        if (res.status === 200) {
          this.listAllPersona = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {
        this.personaService.getListTechnician().subscribe(
          (res) => {
            if (res.status === 200) {
              this.listAllIntervenant = res.body;
              var isIntervenant: boolean = false;
              var lenghtPersona = this.listAllPersona.length;
              var lenghtIntervenant = this.listAllIntervenant.length;

              for (let i = 0; i < lenghtPersona; i++) {
                isIntervenant = false;
                for (let j = 0; j < lenghtIntervenant; j++) {
                  if (this.listAllPersona[i].id === this.listAllIntervenant[j].idPersona) {
                    isIntervenant = true;
                  }
                }
                if (isIntervenant == false) {
                  listPersonaToChoose.push(this.listAllPersona[i]);
                }
              }
            }
          },

          (error) => {
            if (error) {
              this.messageService.add({
                severity: 'error',
                summary: 'Échec de l’opération : veuillez réessayer',
              });
            }
          },

          () => {
            this.listPersonaToChoose = listPersonaToChoose;
          }
        );
      }
    );
  }

  getPersonasByPools() {
    let hasPermissionToViewAll = this.localStorageService.hasPermission(PermissionsList.Kober_viewAll);
    if(hasPermissionToViewAll){
      this.getPersonaList();
    }else{
      this.getUserPools();
    }
  }

  showDialog(idPersona: number) {
    var lenghtPersona = this.listAllPersona.length;
    this.display = true;
    for (let i = 0; i < lenghtPersona; i++) {
      if (this.listAllPersona[i].id == idPersona) {
        this.selectedPersona = this.listAllPersona[i];
      }
    }
  }
  gotoList() {
    this.router.navigate(['/phoenix/technician']);
  }

  createTechnicien() {
    this.intervenant.idPersona = this.selectedPersona.id;
    this.intervenant.idParty = this.selectedPersona.idParty;
    //set specific fields for role technician
    //TODO: get characteristics for role type technician from settings---- should return {fieldName:address, type:string, validationDate:[DATE]}


    let characteristics = {};
    characteristics["address"] = this.intervenant.address;
    this.intervenant.characteristics = characteristics;


    this.submited.next(true);
    this.personaService.createTechnician(this.intervenant).subscribe(
      (data) => {
        if (data.status === 202) {
          this.messageService.add({
            severity: 'success',
            summary: 'Opération réussie',
          });
          this.gotoList();
        }
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Échec de l’opération : veuillez réessayer',
        });
      },
      () => {}
    );
  }

  getUserPools(){
    let partyId =  JSON.parse(sessionStorage.getItem("persona")).idParty
    this.organizationService.getUserPools(partyId).subscribe(
     (res) =>{
      let pools = res;
      if(pools && pools.length > 0){
        this.userPools = pools.map(pool => pool.id);
        this.getPersonaList();
      }else{
        this.userPools = [0];
      }
     }
   )
   }
}
