export enum WorkOrderCommands {
  ATTRIBUER = "ATTOT",
  DESATTRIBUER = "DESATTOT",
  CLOTURER = "CLOOT",
  VALIDER_CLOTURE = "VALIDCLO",
  REATTRIBUER = "REATTOT",
  VALIDER_OT = "VALIDOT",
  REJETER_CLOTURE = "REJCLO",
  DESAFFECTER_OT = "DESAFFOT",
  DEMARRER_OT = "DEMOT",
  AFFECTER_OT = "AFFOT",
  REAFFECTER_OT = "REAFOT",
  TERMINER_OT = "TERMOT",
  REFUSER_OT = "REFUOT",
  RETENIR_OT = "RETOT",
  REORIENTER_OT = "REOROT",
  AJOURNER_OT = "AJOT",
  ANNULER_OT = "ANNOT",
  RECOMMENCER_OT = "RECOMOT",
  MODIFIER_RDV = "Modifier_date_heure_RDV",
  AJOUTER_SUPP_RDV = "Ajouter_Supprimer_RDV",
  CONFIRMER_CLOTURE = "CONFCLO",
  MODIFIER_OT = "MODIFOT",
  CREER_OT = "CREAOT",
  MODIFIER_POOL = "MODIFPOOL",
  AJOUTER_NOTE = "ADDNOTE",
	MODIFIER_NOTE = "UPDATENOTE",
	SUPPRIMER_NOTE = "DELNOTE",
	REACTIVER_NOTE = "REACTIVNOTE",
  DESACTIVER_NOTE = "DEACTIVNOTE",
}
