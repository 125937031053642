import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFromDateString'
})
export class TimeFromDateStringPipe implements PipeTransform {
  transform(dateString: string): string {
    if (!dateString) {
      return null;
    }
    const date = new Date(dateString);
    const hours = date.getHours()?date.getHours():'00';
    const minutes = date.getMinutes()?date.getMinutes():'00';

    return `${hours}:${minutes}`;
  }

  getDateOnly(dateString: string): Date {
    let date = new Date(dateString);
    date.setHours(0);
    date.setMinutes(0);
    return date;
  }
}
