<!--div class="p-grid">
  <h4 class="p-col-12 p-md-12 p-lg-10">Consommables</h4>
  <app-voucher [editMode]="editMode" [listWorkOrderProjectWork]="listWorkOrderProjectWork" [id]="id" [listWorkMaterialConsumable]="listWorkMaterialConsumable" [attachedCompany]="attachedCompany" style="margin: auto;margin-left: 600px;"></app-voucher>
</div-->

<div class="p-grid">
    <div class="p-col p-text-right p-mr-12">
      <app-voucher [editMode]="editMode" [listWorkOrderProjectWork]="listWorkOrderProjectWork" [id]="id" [listWorkMaterialConsumable]="listWorkMaterialConsumable" [attachedCompany]="attachedCompany" style="margin: auto;margin-left: 600px;"></app-voucher>
    </div>
</div>


<p-table
  #dt1
  (sortFunction)="naturalSortMaterial($event)"
  [customSort]="true"
  sortField="materialDto.label" [sortOrder]='1'
  *ngIf="listProjectWork || (listWorkOrderProjectWork && editMode && !id)"
  [value]="listWorkMaterialConsumable"
  styleClass="p-datatable-sm p-datatable-striped"
  [globalFilterFields]="[
    'materialDto.label',
    'materialDto.description',
    'materialDto.unit.name',
    'quantityMaterial'
  ]"
>
<ng-template pTemplate="caption" >
  <div  class="p-d-flex p-jc-between p-m-3" [style]="{'margin-bottom':'100%'}">
    <div>
      <div><span [style]="{'font-weight': 'bold','margin-left':'-20px'}">Consommables</span></div>
    </div>
    <div>
       <span class="p-input-icon-left p-ml-auto" [style]="{'height':'20px'}">
            <i class="pi pi-search" [style]="{'margin-left':'10%','height': '45px','margin-top':'5px'}"></i>
            <input
            [style]="{'margin-left':'10%','height': '45px'}"
              pInputText
              type="text"
              (input)="dt1.filterGlobal($event.target.value, 'contains')"
              placeholder="Rechercher"
            />
        </span>
    </div>
  </div>
</ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th class="table-5 head"></th>
      <th class="head" pSortableColumn="materialDto.label">
        Nom
        <p-sortIcon field="materialDto.label"></p-sortIcon>
      </th>
      <th class="head" pSortableColumn="materialDto.description">
        Description
        <p-sortIcon field="materialDto.description"></p-sortIcon>
      </th>
      <th class="table-15 head" pSortableColumn="materialDto.unit.name">
        Unité
        <p-sortIcon field="materialDto.unit.name"></p-sortIcon>
      </th>
      <th class="table-15 p-text-right head" pSortableColumn="quantityMaterial">
        Quantité
        <p-sortIcon field="quantityMaterial"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-workMaterialConsumable>
    <tr>
      <td class="table-5"></td>
      <td>{{ workMaterialConsumable.materialDto?.label }}</td>
      <td>{{ workMaterialConsumable.materialDto?.description }}</td>
      <td class="table-10">
        {{ workMaterialConsumable.materialDto?.unit.name }}
      </td>
      <td class="table-10 p-text-right">
        {{ workMaterialConsumable.quantityMaterial | number: "1.0-0":"fr" }}
      </td>
    </tr>
  </ng-template>
</p-table>

<p-table
  #dt2
  (sortFunction)="naturalSortMaterial($event)"
  sortField="materialDto.label" [sortOrder]='1'
  [customSort]="true"
  *ngIf="(listWorkOrderProjectWork && id)"
  [value]="listWorkMaterialConsumable"
  styleClass="p-datatable-sm p-datatable-striped"
  [globalFilterFields]="[
    'materialDto.label',
    'materialDto.unit.name',
    'quantityMaterial',
    'totalIssuedQuantity'
  ]"
>

  <ng-template pTemplate="caption">
    <div class="p-d-flex p-jc-between p-m-3">
      <div><span [style]="{'font-weight': 'bold','margin-left':'-20px'}">Consommables</span></div>
      <div>
        <span class="p-input-icon-left p-ml-auto" [style]="{'height':'20px'}">
          <i class="pi pi-search" [style]="{'margin-left':'10%','height': '45px','margin-top':'5px'}"></i>
          <input
          [style]="{'margin-left':'10%','height': '45px'}"
            pInputText
            type="text"
            (input)="dt2.filterGlobal($event.target.value, 'contains')"
            placeholder="Rechercher"
          />
      </span>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th class="table-5 head"></th>
      <th class="head" pSortableColumn="materialDto.label">
        Nom<p-sortIcon field="materialDto.label" ></p-sortIcon>
      </th>
      <th class="head" pSortableColumn="materialDto.unit.name">
        Unité
        <p-sortIcon field="materialDto.unit.name"></p-sortIcon>
      </th>
      <th class="p-text-right head" pSortableColumn="quantityMaterial">
        Quantité totale<p-sortIcon field="quantityMaterial"></p-sortIcon>
      </th>
      <th class="p-text-right head" pSortableColumn="totalIssuedQuantity">
        Sortie<p-sortIcon field="totalIssuedQuantity"></p-sortIcon>
      </th>
      <th class="p-text-right head" pSortableColumn="totalIssuedQuantity">
        Utilisée<p-sortIcon field="usedQuantity"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-workMaterialConsumable>
    <tr>
      <td class="table-5"></td>
      <td [pTooltip]="workMaterialConsumable.materialDto?.description">{{ workMaterialConsumable.materialDto?.label }}</td>
      <td>{{ workMaterialConsumable.materialDto?.unit.name }}</td>
      <td class="table-10 p-text-right">
        {{ workMaterialConsumable.quantityMaterial | number: "1.0-0":"fr" }}
      </td>
      <td class="table-10 p-text-right">
        {{ workMaterialConsumable.totalIssuedQuantity }}
      </td>
      <td class="table-10 p-text-right">
        {{ workMaterialConsumable.quantityMaterialUsed }}
      </td>
    </tr>
  </ng-template>
</p-table>
<br>


<p-table
  #dt3
  (sortFunction)="naturalSortMaterial($event)"
  [customSort]="true"
  sortField="materialDto.label" [sortOrder]='1'
  [value]="listWorkMaterialTool"
  styleClass="p-datatable-sm p-datatable-striped"
  [globalFilterFields]="[
    'materialDto.label',
    'materialDto.description',
    'materialDto.unit.name',
    'quantityMaterial'
  ]"
>
    <ng-template pTemplate="caption">
    <div class="p-d-flex p-jc-between p-m-3">
      <div><span [style]="{'font-weight': 'bold','margin-left':'-20px'}">Outillages</span></div>
      <div>
        <span class="p-input-icon-left p-ml-auto" [style]="{'height':'20px'}">
          <i class="pi pi-search" [style]="{'margin-left':'10%','height': '45px','margin-top':'5px'}"></i>
          <input
          [style]="{'margin-left':'10%','height': '45px'}"
            pInputText
            type="text"
            (input)="dt1.filterGlobal($event.target.value, 'contains')"
            placeholder="Rechercher"
          />
        </span>
      </div>
    </div>
    </ng-template>

  <ng-template pTemplate="header" [style]="">
    <tr>
      <th class="table-5 head"></th>
      <th class="head" pSortableColumn="materialDto.label">
        Nom
        <p-sortIcon field="materialDto.label"></p-sortIcon>
      </th>
      <th class="head" pSortableColumn="materialDto.description">
        Description
        <p-sortIcon field="materialDto.description"></p-sortIcon>
      </th>
      <th class="table-15 head"><!--Unité --></th>
      <th class="table-15 head"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-workMaterialTool>
    <tr>
      <td class="table-5"></td>
      <td>{{ workMaterialTool.materialDto?.label }}</td>
      <td>{{ workMaterialTool.materialDto?.description }}</td>
      <td class="table-15">
        <!--{{workMaterialTool.materialDto?.unit.name}}-->
      </td>
      <td class="table-15"></td>
    </tr>
  </ng-template>
</p-table>
