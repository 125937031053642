import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isStatusChecked',
})
export class IsStatusChecked implements PipeTransform {

  transform(array: any[], currentValue?: any, maxValue?:any) {

      return (array?.includes(currentValue) || array?.includes(maxValue));

  }
}
