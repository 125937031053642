import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Work } from '@data/schema/Work';
import { ConfirmationService, MessageService } from 'primeng/api';
import { WorkService } from '@data/service/work.service';

@Component({
  selector: 'app-work-detail-general',
  templateUrl: './work-detail-general.component.html',
  styleUrls: ['./work-detail-general.component.css']
})
export class WorkDetailGeneralComponent implements OnInit {
  @Input() work: Work;
  @Output() workChange = new EventEmitter();
  change() {
    this.workChange.emit(this.work);
  }

  errorNameRequired : boolean = false;
  listWorks: Work[] = [];
  outputName: string[]= []

  constructor(
    public messageService : MessageService,
    public workService : WorkService,
  ) { }

  ngOnInit(): void {

    this.workService.getListWork().subscribe(
      (res) => {
        if (res.status === 200) {
          this.listWorks = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {}
    );
  }

  filterName(event) {
    this.outputName = this.listWorks
      .filter((c) =>
        c.name.toLowerCase().startsWith(event.query.toLowerCase())
      )
      .map((work) => work.name);
  }

  nameFieldValidator(){
    if (!this.work.name.trim()){
      this.errorNameRequired = true;
    }
    else {
      this.errorNameRequired = false;
    }

    return this.errorNameRequired;
  }

}
