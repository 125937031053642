import { Pipe, PipeTransform } from '@angular/core';
import { Field } from '@data/schema/intervention/Form';

@Pipe({
  name: 'jSONParseAndInitQuantity'
})
export class JSONParseAndInitQuantityPipe implements PipeTransform {

  transform(field: Field): any[] {
    let parsedDataSource = JSON.parse(field.datasource.dataJson);
    if(field.objectType.controlsType.code === 'selectWithBoxAndQuantity'){
      parsedDataSource.forEach(dataSource =>{
          dataSource.quantity = 1;
      })
  }
  return parsedDataSource;
  }

}
