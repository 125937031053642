import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Activity } from '@data/schema/Activity';
import { CreateOrganizationDto } from '@data/schema/Create-organization-dto';
import { Pool } from '@data/schema/Pool';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {EnvService} from "@core/service/env.service";
import { Store } from "@data/schema/Store";

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  private apiURL = this.environment?.readConfig()?.urlApiGateway + 'organization/';

  constructor(private http: HttpClient, private environment: EnvService) {}

  public getAllEntity(): Observable<any> {
    return this.http.get(this.apiURL + 'entity/all').pipe(
      map((res) => {
        return res;
      })
    );
  }

  public create(createOrganizationDto: CreateOrganizationDto): Observable<any> {
    return this.http.post(this.apiURL + 'create', createOrganizationDto).pipe(
      map((res) => {
        return res;
      })
    );
  }

  public getAllOrganization(): Observable<any> {
    return this.http.get(this.apiURL + 'all').pipe(
      map((res) => {
        return res;
      })
    );
  }

  public checkStoreName(name: any): Observable<any> {
    return this.http.get(this.apiURL + 'store/existsByName/' + name).pipe(
      map((res) => {
        return res;
      })
    );
  }

  public getOrganization(id: number): Observable<any> {
    return this.http.get(this.apiURL + id).pipe(
      map((res) => {
        return res;
      })
    );
  }

  public getAllOrganizationByStatus(status: boolean): Observable<any> {
    return this.http
      .get(this.apiURL + `all/` + status, { observe: 'response' as 'body' })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  public getAllOrganizationWithPersona(): Observable<any> {
    return this.http
      .get(this.apiURL + `persona` , { observe: 'response' as 'body' })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  public getAllProfilesPersona(): Observable<any> {
    return this.http.get(this.apiURL + 'getAllProfilesPersona').pipe(
      map((res) => {
        return res;
      })
    );
  }

  public update(updateOrganizationDto: CreateOrganizationDto): Observable<any> {
    return this.http.put(this.apiURL + 'update', updateOrganizationDto).pipe(
      map((res) => {
        return res;
      })
    );
  }

  public deleteOrganization(id: number): Observable<any> {
    return this.http.delete(this.apiURL + `deleteOrgaById/` + id, {
      observe: 'response' as 'body',
      responseType: 'text',
    });
  }
  // GET LIST OF ALL STORES in a given organization
  public getStoreNamesInOrganization(id: number): Observable<any> {
    return this.http.get(this.apiURL + `storeNames/` + id, {
      observe: 'response' as 'body',
      responseType: 'text',
    });
  }

  /**
   *
   Get eligible persona to asssociate
   */
  public getEligiblePersona(companyId: number): Observable<any> {
    return this.http
      .get(this.apiURL + companyId + `/eligible-persona`, {
        observe: 'response' as 'body',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  /**
   *
   Associate users to organization
   */
  public associateUsers(associationObject: any): Observable<any> {
    return this.http
      .post(this.apiURL + `associate-users`, associationObject, {
        observe: 'response' as 'body',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  public isAnyPostOccupiedInOrganization(id: number): Observable<any> {
    return this.http
      .get(this.apiURL + 'any-post-occupied/' + id, {
        observe: 'response' as 'body',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  public getAllActivityNames(): Observable<any> {
    return this.http.get(this.apiURL + 'activity/names', {
      observe: 'response' as 'body',
    });
  }
  /**
   * Get organization unit with its personas
   */
  public getOrganizationUnitWithAssociatedPersonas(
    id: number
  ): Observable<any> {
    return this.http
      .get(this.apiURL + 'unit/' + id, { observe: 'response' as 'body' })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  public createActivity(activity: Activity) {
    return this.http.post(this.apiURL + 'activity/create', activity, {
      observe: 'response' as 'body',
    });
  }
  public updateActivity(activity: Activity) {
    return this.http.put(this.apiURL + 'activity/update', activity, {
      observe: 'response' as 'body',
    });
  }

  public getAllSpecialities(): Observable<any> {
    return this.http.get(this.apiURL + 'getAllSpecialities').pipe(
      map((res) => {
        return res;
      })
    );
  }

  public getAllAreas(): Observable<any> {
    return this.http.get(this.apiURL + 'getAllAreas').pipe(
      map((res) => {
        return res;
      })
    );
  }

  public getPostsList(): Observable<any> {
    return this.http.get(this.apiURL + 'posts').pipe(
      map((res) => {
        return res;
      })
    );
  }

  public getActivities(): Observable<any> {
    return this.http.get(this.apiURL + 'activity/all').pipe(
      map((res) => {
        return res;
      })
    );
  }

  public getActivityById(id: number): Observable<any> {
    return this.http.get(this.apiURL + 'activity/' + id).pipe(
      map((res) => {
        return res;
      })
    );
  }
  public organizationCanBeDeletedInActivity(
    idOrganization: number,
    idActivity
  ): Observable<any> {
    return this.http
      .get(
        this.apiURL + idOrganization + '/can-be-deleted/activity/' + idActivity
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  public getPoolPersonas(activityID: number): Observable<any> {
    return this.http.get(this.apiURL + 'pool/' + activityID + '/eligible-persona').pipe(
      map((res) => {
        return res;
      })
    );
  }

  public getPoolById(id: number): Observable<any> {
    return this.http.get(this.apiURL + 'pool/' + id).pipe(
      map((res) => {
        return res;
      })
    );
  }

  public getPoolPersonaList(id: number): Observable<any> {
    return this.http.get(this.apiURL + 'pool/' + id + '/persona').pipe(
      map((res) => {
        return res;
      })
    );
  }
  // public getPoolByIdCardView(id: number): Observable<any> {
  //   return this.http.get(this.apiURL + 'pool/card-view/' + id).pipe(
  //     map((res) => {
  //       return res;
  //     })
  //   );
  // }

  public getAllSpecialitiesByActivity(id: number): Observable<any> {
    return this.http.get(this.apiURL + 'specialitiesByActivityId/' + id).pipe(
      map((res) => {
        return res;
      })
    );
  }

  public getPools(): Observable<any> {
    return this.http.get(this.apiURL + 'pool/list').pipe(
      map((res) => {
        return res;
      })
    );
  }
  public isPersonaPartOfTeamInOrganization(
    listIdsParty: number[],
    organisationID
  ): Observable<any> {
    return this.http
      .post(
        this.apiURL + organisationID + '/technician/' + 'is-part-of-team',
        listIdsParty
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  public getPoolNames(): Observable<any> {
    return this.http.get(this.apiURL + 'pool/lighweight-list').pipe(
      map((res) => {
        return res;
      })
    );
  }
  public createPool(pool: any): Observable<any> {
    return this.http.post(this.apiURL + 'pool/create', pool).pipe(
      map((res) => {
        return res;
      })
    );
  }

  public createPoolWithAllAreas(pool: any): Observable<any> {
    return this.http.post(this.apiURL + 'pool/create/areas', pool).pipe(
      map((res) => {
        return res;
      })
    );
  }

  public updatePool(pool: any): Observable<any> {
    return this.http.put(this.apiURL + 'pool/update', pool).pipe(
      map((res) => {
        return res;
      })
    );
  }

  public poolCanBeDeleted(id: number): Observable<any> {
    return this.http
      .get(this.apiURL + 'pool/' + id + '/can-be-deleted', {
        observe: 'response' as 'body',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  public isPersonaPartOfTeamInPool(
    poolId: number,
    listOfPersona: any[]
  ): Observable<any> {
    return this.http
      .post(this.apiURL + 'pool/' + poolId + '/techs-in-team', listOfPersona, {
        observe: 'response' as 'body',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  public deletePool(id: number): Observable<any> {
    return this.http.delete(this.apiURL + `pool/delete/` + id, {
      observe: 'response' as 'body',
      responseType: 'text',
    });
  }

  public deleteActivity(activityId: number): Observable<any> {
    return this.http.delete(this.apiURL + `activity/delete/` + activityId, {
      observe: 'response' as 'body',
    });
  }
  public canActivityBeDeleted(activityId: number): Observable<any> {
    return this.http
      .get(this.apiURL + `activity/` + activityId + `/can-be-deleted`, {
        observe: 'response' as 'body',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  public canSpecialityFromActivityBeDeleted(
    activityId: number,
    spacialityId: number
  ): Observable<any> {
    return this.http
      .get(
        this.apiURL +
          `activity/` +
          activityId +
          `/speciality/` +
          spacialityId +
          `/can-be-deleted`,
        { observe: 'response' as 'body' }
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  public canAllSpecialitiesInActivityBeDeleted(
    activityId: number
  ): Observable<any> {
    return this.http
      .get(
        this.apiURL +
          `activity/` +
          activityId +
          `/can-specialities-be-deleted`,
        { observe: 'response' as 'body' }
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  public canAllAreasInActivityBeDeleted(
    activityId: number
  ): Observable<any> {
    return this.http
      .get(
        this.apiURL +
          `activity/` +
          activityId +
          `/can-areas-be-deleted`,
        { observe: 'response' as 'body' }
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  public getUserPools(idParty: number): Observable<any> {
    return this.http.get(this.apiURL + 'pool/getPoolsByUser/' + idParty).pipe(
      map((res) => {
        return res;
      })
    );
  }

  public getProviderCompanies(showAll: boolean, pools: any, companyId: number): Observable<any> {
    const params = new HttpParams()
      .set('pools', pools)
      .set('company', companyId);
    return this.http
      .get(this.apiURL + 'company/sub-contractors/' + showAll, {
        params: params,
        observe: 'response' as 'body',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  public getWorkOrderPools(areaId: any, specialityId: number): Observable<any> {
    const params = new HttpParams()
      .set('areaId', areaId)
      .set('specialityId', specialityId);
    return this.http
      .get(this.apiURL + 'pool/workOrderPools', {
        params: params,
        observe: 'response' as 'body',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  public isCompanyProvider(partyId: number ): Observable<any> {
    return this.http
      .get(this.apiURL + 'company/'+partyId+'/is-provider-company', { observe: 'response' as 'body', })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  public getStoreByUser(): Observable<any> {
    return this.http
      .get(this.apiURL + 'user/entity/store', { observe: 'response' as 'body' })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  public getStoreByUserId(userId: string): Observable<string[]> {
    return this.http.get<string[]>(this.apiURL + 'user/' + userId + '/entity/store');
  }


  public getCompanyOrganizationNames(companyId: number): Observable<any> {
    return this.http
      .get(this.apiURL + 'company/'+ companyId +'/organizations'  ,
      {observe: 'response' as 'body' })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
}
