import {Component, EventEmitter, Input, OnInit, Optional, Output, ViewChild} from '@angular/core';
import { Project, ProjectWork } from '@data/schema/Project';
import { WorkOrder, WorkOrderProjectWork } from '@data/schema/WorkOrder';
import { ConfirmationService,PrimeNGConfig, MessageService, SortEvent } from 'primeng/api';
import {CustomSortService} from "@shared/service/custom-sort.service";


@Component({
  selector: 'app-work-order-form-list-works',
  templateUrl: './work-order-form-list-works.component.html',
  styleUrls: ['./work-order-form-list-works.component.css']
})
export class WorkOrderFormListWorksComponent implements OnInit {

  //recupérer l'objet parent
  @Input() workOrder: WorkOrder;

  @Output() isChangeListWork = new EventEmitter();

  changeListWork() {
    this.isChangeListWork.emit(this.workOrder.listWorkOrderProjectWork);
    console.log(this.workOrder.listWorkOrderProjectWork )
  }

  //initialiser la prestation à ajouter dans l'ot
  addedProjectWork: WorkOrderProjectWork = new WorkOrderProjectWork();

  //variable qui me permet d'afficher le modal pour  valider l'ajout de la prestation
  displayModal: boolean = false;

  //variable tableau qui permet de sélectionner les prestations d'unn projet
  selectedProjectWorks: ProjectWork[] = [];

  @ViewChild("input") elm;

  constructor(
    private customSortService: CustomSortService,
    private confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {
    //console.log(this.workOrder.project.projectWorks)
    this.workOrder.project.projectWorks = this.workOrder.project.projectWorks.filter(x => x.quantityFree > 0)
    //console.log(this.workOrder.project.projectWorks)

  }



  //methode pour ouvrir le modal
  addProjectWork(projectWork: ProjectWork) {

    if(this.workOrder.status==="DOING"){

      this.confirmationService.confirm({
        header: 'Confirmation',
        message:
          'La modification des prestations impactera le budget de l\'OT.' +
          '<br>' +
          'Voulez vous continuer ?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Confirmer',
        rejectLabel: 'Annuler',
        accept: () => {
          this.displayModal = true;
          this.addedProjectWork = new WorkOrderProjectWork();
          this.addedProjectWork.projectWork = projectWork;
          this.addedProjectWork.quantityTotal = undefined;
          this.addedProjectWork.projectWork.created = null;

        },
      });

  }
  else{

      this.displayModal = true;
      this.addedProjectWork = new WorkOrderProjectWork();
      this.addedProjectWork.projectWork = projectWork;
      this.addedProjectWork.quantityTotal = undefined;
      this.addedProjectWork.projectWork.created = null;

  }


  }


  validProjectWorks(){

      for (let projectWork of this.selectedProjectWorks){

        this.addedProjectWork = new WorkOrderProjectWork();
        this.addedProjectWork.projectWork = projectWork;
        this.addedProjectWork.quantityTotal = projectWork.quantityFree;
        this.addedProjectWork.projectWork.created = null;
        //console.log("valider le project work",this.addedProjectWork)
        if (this.addedProjectWork.quantityTotal <= this.addedProjectWork.projectWork?.quantityFree && this.addedProjectWork.quantityTotal > 0){
          // is already selected
        if (!this.projectWorkAlreadySelected()){
          this.workOrder.listWorkOrderProjectWork.push(this.addedProjectWork);

          this.workOrder.listWorkOrderProjectWork = this.workOrder.listWorkOrderProjectWork.slice()
          this.changeListWork();
        }

        this.decreaseAvailableQuantity();

       }

      }
      this.selectedProjectWorks = [];


  }


  //valider l'ajout de la prestation dans l'objet projet
  //ferme la modal
  validProjectWork() {

    if (this.addedProjectWork.quantityTotal <= this.addedProjectWork.projectWork?.quantityFree && this.addedProjectWork.quantityTotal > 0){
      // is already selected
      if (!this.projectWorkAlreadySelected()){
        this.workOrder.listWorkOrderProjectWork.push(this.addedProjectWork);

        this.workOrder.listWorkOrderProjectWork = this.workOrder.listWorkOrderProjectWork.slice()
        this.changeListWork();
      }

      this.decreaseAvailableQuantity();

      this.displayModal = false;
    }



  }

  projectWorkAlreadySelected(){
    for (const workOrderProjectWork of this.workOrder.listWorkOrderProjectWork){
      if (workOrderProjectWork.projectWork.id === this.addedProjectWork.projectWork.id){
        workOrderProjectWork.quantityTotal += this.addedProjectWork.quantityTotal;
        return true
      }
    }

    return false
  }

  decreaseAvailableQuantity(){
    for (const projectWork of this.workOrder.project.projectWorks){
      if (projectWork.id === this.addedProjectWork.projectWork.id){
        //console.log(projectWork.quantityFree)
        projectWork.quantityFree -= this.addedProjectWork.quantityTotal
        //console.log(projectWork.quantityFree,this.addedProjectWork.quantityTotal)
      }
    }
  }

  checkToShow(){
    this.workOrder.project.projectWorks = this.workOrder.project.projectWorks.filter(x => x.quantityFree > 0)
    return (this.workOrder.project.projectWorks.length != 0)
  }

  cancel() {
    this.displayModal = false;
  }

  setFocus() {
    this.elm.input.nativeElement.focus();
  }

  naturalSortWorkOrder(event: SortEvent) {
    this.customSortService.naturalSort(event);
  }

}
