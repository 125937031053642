import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-search-ot',
  templateUrl: './dialog-search-ot.component.html',
  styleUrls: ['./dialog-search-ot.component.css']
})
export class DialogSearchOtComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogSearchOtComponent>,
  ) { }

  ngOnInit(): void {
  }
}
