import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormGroup} from "@angular/forms";
import {ConfirmationService, MessageService,SortEvent} from 'primeng/api';
import {MaterialWork} from '@data/schema/Work';
import {CustomSortService} from "@shared/service/custom-sort.service";

@Component({
  selector: 'app-work-new-voucher-list-materials',
  templateUrl: './work-new-voucher-list-materials.component.html',
  styleUrls: ['./work-new-voucher-list-materials.component.css']
})
export class WorkNewVoucherListMaterialsComponent implements OnInit {

  @Input() formG: FormGroup;
  submitted: boolean = false;
  @Input() listWorkMaterialConsumable: MaterialWork[] = []
  @Input()newItem: any;
  listItem: any[] = [];
  @Output() getMaterial = new EventEmitter();
  @Output() getAllMaterial = new EventEmitter();
  @Output() listMaterial = new EventEmitter();
  @Input() edit: Boolean = false;
  @Input() selectedMateriel: any;
  riDelete: any;
  productDialog: boolean;
  listConsumable: MaterialWork;
  selectedProducts: MaterialWork[];


  constructor(private confirmationService: ConfirmationService, private messageService: MessageService,private customSortService : CustomSortService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (Object.keys(this.newItem).length !== 0){
      if (this.newItem.editMode === true){
        for (let i=0; i<this.listItem.length; i++){
          if (this.listItem[i].cons.id === this.newItem.cons.id){
            this.listItem[i] = this.newItem;
            this.listItem = this.listItem.slice();
          }
        }
      } else {
        this.listItem.push(this.newItem);
        this.listItem = this.listItem.slice();
      }
      this.getAllMaterial.emit({
          listItem: this.listItem
      })
    }
  }

  editArticle(voucher: any) {
    if (voucher === null) {
      this.submitted = true;
    } else {
      this.formG.patchValue({conso: voucher.cons});
      this.voucherControls.at(0).patchValue({quantity: voucher.formG[0].quantity});
      this.voucherControls.at(0).patchValue({quantityTotal: voucher.cons.quantityMaterial});
      this.voucherControls.at(0).get('materialWork').patchValue({id: voucher.formG[0].materialWork.id});
      this.selectedMateriel = voucher.cons;
      //this.listWorkMaterialConsumable.push(this.newItem[ri].cons);
      //console.log(this.listWorkMaterialConsumable);
      this.getMaterial.emit({
        cons: this.selectedMateriel,
        formG: this.formG.value.voucherMaterialWorks,
        edit: true,
        listItem: this.listItem,
        //maxInput: this.selectedMateriel.quantityMaterial
      });
    }
  }

  get voucherFormControls() {
    return this.formG.controls;
  }

  get voucherControls() {
    return this.voucherFormControls.voucherMaterialWorks as FormArray;
  }

  editProduct(rowI: number, listConsommable: MaterialWork) {
    this.listConsumable = {...listConsommable};
    this.productDialog = true;
  }

  deleteProduct(voucher: any) {
    let lastMatDelete: boolean = false;
    this.confirmationService.confirm({
      message: 'Les données seront supprimées définitivement.\n' +
        'Voulez vous continuer ?',
      header: 'Confirmation',
      acceptButtonStyleClass: 'p-button-outlined',
      acceptIcon: 'null',
      rejectIcon: 'null',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Supprimer',
      rejectLabel: 'Annuler',
      accept: () => {
        if (this.listItem.length === 1){
          lastMatDelete = true;
        }
        this.messageService.add({severity: 'success', summary: 'Opération réussi'});
        this.getMaterial.emit({
          delete: true,
          cons: voucher.cons,
          lastMat: lastMatDelete,
          formG: this.formG.value.voucherMaterialWorks,
        })
        let indexOfVoucher = this.listItem.indexOf(voucher);
        this.listItem.splice(indexOfVoucher,1);
        // MERCI PRIMENG DE NE JAMAIS METTRE A JOUR LE TABLEAU AUTOMATIQUEMENT ...
        this.listItem = this.listItem.slice();
      }
    });
  }

  naturalSort(event:SortEvent) {
    
    this.customSortService.naturalSort(event);

  }
}
