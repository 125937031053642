import { Component, OnInit } from '@angular/core';
import {Materiel} from "@data/schema/Materiel";

@Component({
  selector: 'app-upload-file-material',
  templateUrl: './upload-file-material.component.html',
  styleUrls: ['./upload-file-material.component.css']
})
export class UploadFileMaterialComponent implements OnInit {

  materiels: Materiel[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
