import { Component, Input, OnInit, ViewChild, HostListener } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { Project, ProjectCompaniesListWorkOrder, ProjectWorkOrder } from '@data/schema/Project';
import { ProjectService } from '@data/service/project.service';
import { ProjectFormGeneralComponent } from '../../components/project/project-form-general/project-form-general.component';
import { WorkOrderService } from '@data/service/work-order.service';
import { BlService} from "@data/service/bl.service";
import { Observable, BehaviorSubject} from 'rxjs';


@Component({
  selector: 'app-show-project',
  templateUrl: './show-project.component.html',
  styleUrls: ['./show-project.component.css'],
})
export class ShowProjectComponent implements OnInit {
  public submited: BehaviorSubject<boolean>;

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return (!this.editMode ||this.submited.value ? true : false)
  }
  project: Project = new Project();
  editMode: boolean = false;
  sub: any;
  id: number;
  list: ProjectWorkOrder[] = [];
  items: any = {};
  disabled: boolean = false;

  isRedirection : boolean;

  projectCompaniesListWorkOrder: ProjectCompaniesListWorkOrder[]

  @ViewChild(ProjectFormGeneralComponent)
  private projectGeneral!: ProjectFormGeneralComponent;

  constructor(
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private projectService: ProjectService,
    private workOrderService: WorkOrderService,
    private blService: BlService
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params.editMode) {
        this.editMode = true;
      }
    });
     this.submited = new BehaviorSubject(false)
  }

  ngOnInit(): void {
    this.sub = this.activatedRoute.params.subscribe((params) => {
      this.id = +params['id'];
    });


    this.projectService.getProjectById(this.id).subscribe(
      (res) => {
        if (res.status === 200) {
          this.project = res.body;
         this.getWoWithCompany(this.project.projectWorkOrders)

        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {
        this.generateBreadCrumb();
        this.projectService.getAllWorkOrdersAndCompanyByIdProject(this.project.id).subscribe(
          (res) => {
            if (res.status === 200) {
              console.log(res.body)
              this.disabled = true;
              let doublon = []
              this.projectCompaniesListWorkOrder = res.body.map((value: ProjectCompaniesListWorkOrder) => {
                //console.log(value)
                if (value && value.company && !doublon.includes(value.company.companyId)){
                  doublon.push(value.company.companyId)
                  return new ProjectCompaniesListWorkOrder(value)
                }
              })
              //console.log(this.projectCompaniesListWorkOrder)
            }
          },
          (error) => {
            if (error) {
              this.messageService.add({
                severity: 'error',
                summary: 'Échec de l’opération : veuillez réessayer',
              });
            }
          },
          () => {
            this.project.projectCompaniesListWorkOrder = this.projectCompaniesListWorkOrder
            //this.getWorksByCompany();
          }
        );
      }
    );
  }

  generateBreadCrumb(){
    this.items.update = [
      {label:'Projets', routerLink:'/phoenix/project'},
      {label:"Modification du projet "+ this.project.name, styleClass:'ariane-current'}
    ];
    this.items.show = [
      {label:'Projets', routerLink:'/phoenix/project'},
      {label:"Projet "+ this.project.name, styleClass:'ariane-current'}
    ];
  }

  update() {

    if ( this.project.status=="DONE"){

      this.confirmationService.confirm({
        header: 'Modification impossible',
        message: "Ce projet est terminé.",
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        rejectIcon: 'null',
        rejectLabel: "J’ai compris",
        acceptVisible: false,
      });

    }
    else {

      this.editMode = true;

    }
  }
 confirm() {
   this.confirmationService.confirm({
     header: 'Confirmation',
     message:
       'Vous allez quitter la page, les données seront perdues.' +
       '<br>' +
       'Voulez vous continuer ?',
     icon: 'pi pi-exclamation-triangle',
     acceptButtonStyleClass: 'p-button-outlined',
     acceptIcon: 'null',
     rejectIcon: 'null',
     acceptLabel: 'Quitter',
     rejectLabel: 'Annuler',
     accept: () => {
      this.submited.next(true)
        this.router.navigate(['/phoenix/project']);
       },
     });
   }


  submit() {
    let validForm = true;
    if (this.projectGeneral.nameFieldValidator()) {
      validForm = false;
    }
    if (!validForm) {
      this.messageService.add({
        severity: 'error',
        summary: 'Échec de l’opération : veuillez remplir tous les champs',
      });
    } else {
      this.updateProjet();
    }
  }
  updateProjet() {
    this.projectService.updateProject(this.project).subscribe(
      (res) => {
        if (res.status === 200) {
          this.project = res.body;
          //console.log(this.project);
          this.messageService.add({
            severity: 'success',
            summary: 'Opération réussie',
          });
          this.gotoList();
        }
      },
      (error) => {
        if (error) {
          if (error.status === 500) {
           this.messageService.add({severity: 'error', summary: 'Échec de l’opération : veuillez réessayer'});
         }
          if (error.status === 409) {
            this.messageService.add({severity: 'error', summary: 'Cet élément existe déjà'});
         }
      }
      },
      () => {

      }
    );
  }

  back() {

    this.confirmationService.confirm({
      header: 'Confirmation',
      message:
        'Vous allez quitter la page, les données seront perdues.' +
        '<br>' +
        'Voulez vous continuer ?',
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button-outlined',
      acceptIcon: 'null',
      rejectIcon: 'null',
      acceptLabel: 'Quitter',
      rejectLabel: 'Annuler',
      accept: () => {
        this.submited.next(true)
        this.router.navigate(['/phoenix/project']);
      },
    });

  }


  returnList() {
    this.submited.next(true)
    this.router.navigate(['/phoenix/project']);
  }

  delete() {

    if ( this.project.status=="DOING"){
      this.confirmationService.confirm({
        header: 'Suppression impossible',
        message: "Ce projet est déjà démarré.",
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        rejectIcon: 'null',
        rejectLabel: "J’ai compris",
        acceptVisible: false,
      });
    }
    else if ( this.project.status=="DONE"){
      this.confirmationService.confirm({
        header: 'Suppression impossible',
        message: "Ce projet est terminé.",
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        rejectIcon: 'null',
        rejectLabel: "J’ai compris",
        acceptVisible: false,
      });
    }
    else{
      this.confirmationService.confirm({
        header: 'Confirmation',
        message:
          'Les données seront supprimées définitivement.' +
          '<br>' +
          'Voulez vous continuer ?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Supprimer',
        rejectLabel: 'Annuler',
        accept: () => {
          this.deleteProject();
        },
      });
    }

  }

  deleteProject() {
    this.projectService.deleteProject(this.project.id).subscribe(
      (res) => {
        if (res.status === 200) {
          //console.log(res);
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Opération réussie',
        });
        this.gotoList();
      }
    );
  }

  // redirect to list page
  gotoList() {
    this.redirectTo('/phoenix/project');
  }
  redirectTo(uri: string) {
    this.submited.next(true)
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }

 async getWoWithCompany(ot : ProjectWorkOrder[]){
   for(let wo of ot){
      this.workOrderService.getWorkOrderById(wo.workOrder.id).subscribe(
      (res) => {
        if (res.status === 200) {
          let workOrder = res.body;

          wo.company = workOrder.company

        }
      });
    }
  }


}
