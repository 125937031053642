import {Injectable} from '@angular/core';
import {Work} from '@data/schema/Work';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {EnvService} from "@core/service/env.service";

@Injectable({
  providedIn: 'root',
})
export class WorkService {
  private apiURL = this.environment?.readConfig()?.urlApiGateway + 'bl/';

  constructor(private http: HttpClient, private environment: EnvService) {
  }

  // GET LIST OF ALL Works
  public getListWork(): Observable<any> {
    return this.http
      .get(this.apiURL + 'listWork', {observe: 'response' as 'body'})
      .pipe(
        map((res) => {
          return res;
        })
      );
  }


  public getWorkByIdWork(idWork: number): Observable<any> {
    return this.http
      .get(this.apiURL + 'getWorkByIdWork/' + idWork, {observe: 'response' as 'body'})
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

// Export Work Data

  public getExcelData() {
    return this.http.get<any>(`${this.apiURL}exportExcel`, {responseType: 'arraybuffer' as 'json'});
  }

  public getPdfData() {
    return this.http.get<any>(`${this.apiURL}exportPdf`, {responseType: 'arraybuffer' as 'json'});
  }

  public getCsvData() {
    return this.http.get<any>(`${this.apiURL}exportCsv`, {responseType: 'arraybuffer' as 'json'});
  }


  // Create Work
  public createWork(work: Work): Observable<any> {
    return this.http.post(this.apiURL + `createWork`, work, {
      observe: 'response' as 'body',
    });
  }

// Create Works of skills and materials
  public createWorks(works: Work[]): Observable<any> {
    return this.http.post(this.apiURL + `createWorks`, works, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    );
  }

  // UPDATE A Work WITH NEW VALUES
  public updateWork(work: Work): Observable<any> {
    return this.http.put(this.apiURL + `updateWork`, work, {
      observe: 'response' as 'body',
    });
  }

  // DELETE A work
  public deleteWork(id: number): Observable<any> {
    return this.http.delete(this.apiURL + `deleteWork/` + id, {observe: 'response' as 'body'});
  }


  isMaterialUsedInWork(id: number): Observable<any> {
    return this.http.get(this.apiURL + "work/contains-material/" + id, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }


  isWorkAlreadyInUse(id: number): Observable<any> {
    return this.http.get(this.apiURL + "work/is-used/" + id, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }


}
