<app-kober-menu module="kober-services"></app-kober-menu>
<div class="p-grid p-d-flex p-ml-2 p-pl-2 main-page">
  <div class="p-mt-3 p-pt-3 p-ml-3 p-pl-3">
    <p-breadcrumb *ngIf="editMode" [model]="items.update"></p-breadcrumb>
    <p-breadcrumb *ngIf="!editMode" [model]="items.show"></p-breadcrumb>
  </div>
</div>
<div class="p-grid p-d-flex p-jc-center p-m-2 p-p-2 main-page margin-b-20">
  <div class="p-grid p-col-12 p-md-11 p-m-3 p-p-3 main-content p-shadow-2">
        <app-work-show-general *ngIf="!editMode" [(work)]="work" class="p-col-8"></app-work-show-general>
        <app-work-detail-general *ngIf="editMode" [(work)]="work" class="p-col-8"></app-work-detail-general>
      <div class=" p-d-flex p-jc-end">
        <qrcode *ngIf="qrData" [qrdata]="qrData" [width]="150" [errorCorrectionLevel]="'M'"></qrcode>
      </div>
  </div>
  <div class="p-grid p-col-12 p-md-11 p-m-3 p-p-3 main-content p-shadow-2">
    <app-work-detail-consumable *ngIf="editMode" [listConsumable]="listConsumable" [(listConsumableSelected)]="listConsumableSelected" class="p-col-12"></app-work-detail-consumable>
    <app-work-show-consumable *ngIf="!editMode" [listConsumable]="listConsumable" [(listConsumableSelected)]="listConsumableSelected" class="p-col-12"></app-work-show-consumable>

  </div>
  <div class="p-grid p-col-12 p-md-11 p-m-3 p-p-3 main-content p-shadow-2">
    <app-work-detail-tools *ngIf="editMode" [listTools]="listTools" [(listToolsSelected)]="listToolsSelected" class="p-col-12"></app-work-detail-tools>
    <app-work-show-tools *ngIf="!editMode" [listTools]="listTools" [(listToolsSelected)]="listToolsSelected" class="p-col-12"></app-work-show-tools>

  </div>
  <div class="p-grid p-col-12 p-md-11 p-m-3 p-p-3 main-content p-shadow-2">
    <app-work-detail-skill *ngIf="editMode" [(work)]="work" class="p-col-12"></app-work-detail-skill>
    <app-work-show-skill *ngIf="!editMode" [(work)]="work" class="p-col-12"></app-work-show-skill>
  </div>
  <div class="p-grid p-col-12 p-md-11 p-m-3 p-p-3 main-content p-shadow-2">
    <app-work-detail-cost *ngIf="editMode" [(work)]="work" class="p-col-12"></app-work-detail-cost>
    <app-work-show-cost *ngIf="!editMode" [(work)]="work" class="p-col-12"></app-work-show-cost>
  </div>
  <div [style]="{ height: '13vh' }"></div>
</div>

<p-confirmDialog baseZIndex=5 styleClass="main-page" [style]="{width: '60%'}"></p-confirmDialog>
<p-confirmDialog baseZIndex=5 key="confirmDelete" styleClass="main-page" [style]="{width: '60%'}"></p-confirmDialog>
<p-confirmDialog baseZIndex=5 key="isInUseWhenDelete" styleClass="main-page" appendTo="body" [style]="{width: '60%'}"></p-confirmDialog>
<p-confirmDialog baseZIndex=5 key="isInUseWhenUpdate" styleClass="main-page" appendTo="body" [style]="{width: '60%'}"></p-confirmDialog>
<p-confirmDialog baseZIndex=5 key="back" styleClass="main-page" [style]="{width: '60%'}"></p-confirmDialog>
<p-sidebar
  class="p-text-right"
  [style]="{ height: '13vh' }"
  [visible]="true"
  [blockScroll]="true"
  [dismissible]="false"
  [showCloseIcon]="false"
  [modal]="false"
  position="bottom"
>
  <div>
    <!--RETOUR A LA LISTE-->
    <p-button *ngIf="editMode" styleClass="p-button-outlined p-mr-2" (click)="back()" label="{{'formulaire_RETOUR'|translate}}"></p-button>
    <p-button *ngIf="!editMode" styleClass="p-button-outlined p-mr-2" routerLink="/phoenix/work" label="{{'formulaire_RETOUR'|translate}}"></p-button>

    <p-button *ifRoles='["administrateur","admin_metier","admin_responsable_DPO", "manager_operationnel" , "manager_operationnel_prestataire"]' styleClass="p-mr-2" (click)="isWorkInUseBeforeDelete()" label="{{'SUPPRIMER'|translate}}"></p-button>

    <p-button
      *ifRoles='["administrateur",  "admin_metier","admin_responsable_DPO", "manager_operationnel" , "manager_operationnel_prestataire"]'
      class="p-mr-2 p-mb-2"
      (click)="isWorkInUseBeforeUpdate()"
      label="{{ label }}"
    ></p-button>
  </div>
</p-sidebar>



