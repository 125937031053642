<app-kober-menu module="kober-services"></app-kober-menu>
<div class="p-grid p-d-flex p-ml-2 p-pl-2 main-page">
  <div class="p-mt-3 p-pt-3 p-ml-3 p-pl-3">
    <p-breadcrumb [model]="items"></p-breadcrumb>
  </div>
</div>
<div class="p-grid p-d-flex p-jc-center p-m-2 p-p-2 main-page margin-b-20">
  <div class="p-grid p-col-12 p-md-11 p-m-3 p-p-3 main-content p-shadow-2">
    <h3>Projet</h3>
    <app-project-form-general  [(project)]="project"  class="p-col-12" ></app-project-form-general>
  </div>
  <div class="p-grid p-col-12 p-md-11 p-m-3 p-p-3 main-content p-shadow-2">
    <h3>Informations</h3>
    <app-project-form-detail [(project)]="project" [editMode]="true" class="p-col-12"></app-project-form-detail>
  </div>
  <p-confirmDialog    baseZIndex="5"    styleClass="main-page"    [style]="{ width: '60%' }"  ></p-confirmDialog>
</div>
<p-sidebar
  class="p-text-right"
  [style]="{ height: '13vh' }"
  [visible]="true"
  [blockScroll]="true"
  [dismissible]="false"
  [showCloseIcon]="false"
  [modal]="false"
  position="bottom"
>
  <div>
    <!--RETOUR A LA LISTE-->
    <p-button
      styleClass="p-button-outlined p-mr-2"
      (click)="back()"
      label="Annuler"
    ></p-button>

    <p-button styleClass="p-mr-2" (click)="submit()" label="Valider"></p-button>
  </div>
</p-sidebar>
