<mat-card
  class="card"
  [ngClass]="{ 'yellow-border': persona.idParty === responsibleTechnician }"
>
  <mat-card-header>
    <div class="icon-container" mat-card-avatar>
      <img *ngIf="persona.photo" [src]="persona.photo" />
      <img *ngIf="!persona.photo" src="assets/img/technician-default.png" />
      <div *ngIf="persona.user" class="status-circle"></div>
    </div>
    <mat-card-title class="card-title"
      >{{ persona.firstName }} {{ persona.middleName }} {{ persona.lastName }}
    </mat-card-title>
    <mat-card-subtitle class="card-subtitle">{{
      persona.publicName
    }}</mat-card-subtitle>

    <mat-card-title class="card-title-company">
      <span class="company-label">{{ persona.company }}</span>
    </mat-card-title>
    <div *ngIf="teamScope">
      <mat-icon
        matTooltip="{{ 'creation_equipe_RESPONSABLE' | translate }}"
        matTooltipClass="tooltip"
        matTooltipPosition="above"
        class="card-icons"
        svgIcon="teamLeaderIcon"
        *ngIf="persona.idParty === responsibleTechnician"
      ></mat-icon>
      <mat-icon
        class="card-icons"
        svgIcon="deleteFillIcon"
        *ngIf="persona.idParty !== responsibleTechnician && !displayTeams"
        (click)="childClick()"
      ></mat-icon>
    </div>
  </mat-card-header>
</mat-card>
