<app-kober-menu module="kober-skill"></app-kober-menu>
<div class="p-d-flex p-jc-center p-m-2 p-p-2 main-page">
  <div class="p-grid p-ai-center vertical-container p-jc-center">
    <div class="p-col-12 p-md-10 p-jc-center p-m-3 p-p-3 main-content p-shadow-4">
      <h2>Créer un intervenant</h2>
      <p-table #dt1 [value]="listPersonaToChoose"
               class ="table"
               [scrollable]="true"
               scrollHeight="40vh"
               [paginator]="true"
               [rows]="10"
               styleClass="p-datatable-sm p-datatable-striped"
               [showCurrentPageReport]="true"
               currentPageReportTemplate="{{'PAGINATION'|translate}}"
               [rowsPerPageOptions]="[10, 25, 50]"
               [globalFilterFields]="[
              'firstName',
              'lastName',
              'publicName',
              'mail',
              'personalNumber'
              ]">
        <ng-template pTemplate="caption">
          <div class="p-d-flex">
            Sélectionner un persona
            <span class="p-input-icon-left p-ml-auto">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                     placeholder="Rechercher" />
            </span>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th id="lastName" pSortableColumn="lastName">
              {{ 'NOM_FAMILLE' | translate }}
              <p-sortIcon field="lastName"></p-sortIcon>
            </th>
            <th id="firstName" pSortableColumn="firstName">
              {{ 'PRENOM' | translate }}
              <p-sortIcon field="firstName"></p-sortIcon>
            </th>
            <th id="publicName" pSortableColumn="publicName">
              {{ 'NOM_PUBLIC' | translate }}
              <p-sortIcon field="publicName"></p-sortIcon>
            </th>
            <th id="mail" pSortableColumn="mail">
              Email
              <p-sortIcon field="mail"></p-sortIcon>
            </th>
            <th id="personalNumber" pSortableColumn="personalNumber">
                {{ 'NUM_PERSONEL'| translate }}
              <p-sortIcon field="personalNumber"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-listPersonaToChoose>
          <tr style="cursor:pointer;" (click)="showDialog(listPersonaToChoose?.id)">
            <td>{{listPersonaToChoose?.lastName}}</td>
            <td>{{listPersonaToChoose?.firstName}}</td>
            <td>{{listPersonaToChoose?.publicName}}</td>
            <td>{{listPersonaToChoose?.mail}}</td>
            <td>{{listPersonaToChoose?.personalNumber}}</td>
          </tr>
        </ng-template>
      </p-table>

      <p-dialog header="Intervenant" styleClass="main-page"  [draggable]="false" [closable]="true" position="center"
       [(visible)]="display">
        <div class="p-grid p-mb-2">
          <div class="p-col-6">
            <h3 class="p-d-inline">{{ 'NOM_FAMILLE' | translate }} : </h3>
            {{ selectedPersona?.lastName }}
          </div>
          <div class="p-col-6">
            <h3 class="p-d-inline">{{ 'NOM_PUBLIC' | translate }} :</h3>
            {{ selectedPersona?.publicName}}
          </div>
        </div>
        <div class="p-grid p-mb-2">
          <div class="p-col-6">
            <h3 class="p-d-inline">{{ "PRENOM" | translate }} :</h3>
            {{ selectedPersona?.firstName }}
          </div>
          <div class="p-col-6">
            <h3 class="p-d-inline">2e nom :</h3>
            {{ selectedPersona?.middleName }}
          </div>
        </div>
        <div class="p-grid p-mb-2">
          <div class="p-col-12">
            <h3 class="p-d-inline">Email :</h3>
            {{ selectedPersona?.mail }}
          </div>
        </div>
        <div class="p-grid p-mb-2">
          <div class="p-col-12">
            <h3 class="p-d-inline">Numéro personnel :</h3>
            {{ selectedPersona?.personalNumber }}
          </div>
        </div>
        <div class="p-grid p-mb-2">
          <div class="p-col-12">
            <h3 class="p-d-inline">Adresse de départ :</h3>
          </div>
          <div class="p-col-12">
            <input
            type="text"
            name="adress"
            class="form-control"
            pInputText
            id="adress"
            [(ngModel)]="intervenant.address"
            name="adress"
            />
          </div>
        </div>

        <!-- spacing pour button -->

        <button pButton type="button" (click)="display=false" label="Annuler" class="p-button-outlined p-mr-2"></button>
        <p-button (onClick)="createTechnicien()" styleClass="p-mr-2" label="Valider"></p-button>
      </p-dialog>
    </div>
  </div>
</div>
<p-sidebar class="p-text-right" [style]="{ height: '13vh' }" [visible]="true" [blockScroll]="true" [dismissible]="false"
           [showCloseIcon]="false" [modal]="false" position="bottom">
  <div>
    <!--RETOUR A LA LISTE-->
    <p-button styleClass="p-button-outlined p-mr-2" routerLink="/phoenix/technician" label="Retour"></p-button>
  </div>
</p-sidebar>
<p-confirmDialog baseZIndex=5 styleClass="main-page" [style]="{width: '60%'}"></p-confirmDialog>
