import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn} from "@angular/forms";

@Directive({
  selector: '[appNumberService]',
  providers: [{provide: NG_VALIDATORS, useExisting: RegexDirective, multi: true}]
})
export class RegexDirective implements Validator {
  @Input('appErrorRegex') errorRegex = ' +';

  validate(control: AbstractControl): ValidationErrors | null {
    return this.errorRegex ? serialNumberValidator(new RegExp(this.errorRegex, 'i'))(control)
      : null;
  }
}
export function serialNumberValidator(nameRe: RegExp): ValidatorFn {
  var regex: RegExp = new RegExp(' +');
  return (control: AbstractControl): ValidationErrors | null => {
    const errorRegex = regex.test(control.value);
    return errorRegex ? {errorRegex: {value: control.value}} : null;
  };
}
