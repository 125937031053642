import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Persona } from '@data/schema/Persona';
@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() persona : Persona;
  @Input() responsibleTechnician : number;
  @Input() teamScope : boolean;
  @Input() displayTeams : boolean;
  profiles: boolean = false;

  @Output() deleteTechnician = new EventEmitter();

  constructor() { }

  childClick() {
    this.deleteTechnician.emit();
  }

  ngOnInit(): void {
  }

}
