import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ConfirmationService,PrimeNGConfig, MessageService, MenuItem, SortEvent } from 'primeng/api';
import { ProjectWorkOrder } from '@data/schema/Project';
import { WorkOrder } from '@data/schema/WorkOrder';
import { WorkOrderService } from '@data/service/work-order.service';
import {TokenStorageService} from "@core/service/token-storage.service";
import {PersonaService} from "@data/service/persona.service";
import {Persona} from "@data/schema/Persona";
import { TranslateService } from '@ngx-translate/core';
import {SearchItemService} from "@data/service/search-item.service";
import {CustomSortService} from "@shared/service/custom-sort.service";

@Component({
  selector: 'app-work-order',
  templateUrl: './work-order.component.html',
  styleUrls: ['./work-order.component.css']
})
export class WorkOrderComponent implements OnInit {

  listProjectWorkOrder: ProjectWorkOrder[]= [];
  listProjectWorkOrderToDo: ProjectWorkOrder[]= [];
  listProjectWorkOrderDoing: ProjectWorkOrder[]= [];
  listProjectWorkOrderDone: ProjectWorkOrder[]= [];

  workOrderOnToggle: WorkOrder;
  private role: string;
  idKeycloak: string;
  persona: Persona;
  workOrderRecherche: string;

  items: MenuItem[];

  constructor(
    private messageService: MessageService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private workOrderService: WorkOrderService,
    private tokenStorageService: TokenStorageService,
    private personaService: PersonaService,
    private config: PrimeNGConfig,
    private translateService: TranslateService,
    private searchItemService: SearchItemService,
    private customSortService: CustomSortService,
  ) { }

  ngOnInit(): void {

    this.items = [
      {label:'Ordre de travail'},
    ];

    // this.translateService.setDefaultLang('fr');
    this.config.setTranslation({
      startsWith: "Commence par",
      addRule: "Ajouter une règle",
      matchAll: "Tous les filtres correspondent",
      matchAny: "Au moins un filtre correspond",
      contains: "Contient",
      notContains: "Ne contient pas",
      endsWith: "Termine par",
      equals: "Equivalent",
      notEquals: "Pas équivalent",
      noFilter: "Pas de filtre",
      lt: "Plus petit que",
      lte: "Plus petit ou égal",
      gt: "Plus grand",
      gte: "Plus grand ou égal",
      is: "Est",
      isNot: "N'est pas",
      before: "Avant",
      after: "Après",
      clear: "Effacer",
      apply: "Appliquer",
      removeRule: "Enlever la règle",
    });
    if (!this.persona) {
      let token: any;
      token = this.tokenStorageService.decodeToken();
      this.idKeycloak = token.sub;
      //console.log(this.idKeycloak);

    }
    this.role = this.tokenStorageService.decodeToken().realm_access.roles[0];
    if (this.hasAuthorization([
      'administrateur',
      'admin_metier',
      'admin_responsable_DPO',
      'magasinier',
      'manager_operationnel',
    ])) {

      this.workOrderService.getAllWorkOrders().subscribe(
        (res) => {
          //console.log(res);
          if (res.status === 200) {
            this.listProjectWorkOrder = res.body;
          }
        },
        (error) => {
          if (error) {
            this.messageService.add({
              severity: 'error',
              summary: 'Erreur',
              detail: 'Échec de l’opération : veuillez réessayer',
            });
          }
        },
        () => {
          this.listProjectWorkOrderToDo = this.listProjectWorkOrder.filter(pwo => pwo.workOrder.status === "TODO");
          this.listProjectWorkOrderDoing = this.listProjectWorkOrder.filter(pwo => pwo.workOrder.status === "DOING");
          this.listProjectWorkOrderDone = this.listProjectWorkOrder.filter(pwo => pwo.workOrder.status === "DONE");
        }
      );
    } else if (this.hasAuthorization([
      'ca_prestataire','manager_operationnel_prestataire',
      'manager_CA', 'charge_de_pilotage'])) {

      this.personaService.getPersonaByKeycloak(this.idKeycloak).subscribe(
        (res) => {
          if (res.status === 200) {
            this.persona = res.body;
            console.log(this.persona.id);

            this.workOrderService.getAllWorkOrdersById(this.persona.id).subscribe(
              (res) => {
                //console.log(res);
                if (res.status === 200) {
                  this.listProjectWorkOrder = res.body;
                }
              },
              (error) => {
                if (error) {
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Erreur',
                    detail: 'Échec de l’opération : veuillez réessayer',
                  });
                }
              },
              () => {
              }
            );
          }
        },
        (error) => {
          if (error) {
            console.log('didnt work');
          }
        },
        () => {

        }
      );

    }

  }

  getRowProjects(workOrder: WorkOrder) {
    this.workOrderOnToggle = workOrder;
  }

  update(){

    if ( this.workOrderOnToggle.status=="DONE"){
      this.confirmationService.confirm({
        header: 'Modification impossible',
        message:
          'Cet OT est terminé.',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: "J’ai compris",
        rejectVisible: false,

      });
    }
    else{
      let navigationExtras: NavigationExtras = {
        queryParams: {
          editMode: true
        }
      }
      this.router.navigate(['phoenix/show-work-order/', this.workOrderOnToggle.id], navigationExtras);
    }
  }

  redirectionWorkOrder(){

    localStorage.isRedirect = "false"
    this.router.navigateByUrl("/phoenix/new-work-order")

  }


  delete(){

    if ( this.workOrderOnToggle.status=="DOING"){
      this.confirmationService.confirm({
        header: 'Suppression impossible',
        message:
          'Cet OT est déjà démarré.',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: "J’ai compris",
        rejectVisible: false,

      });
    }
    else if ( this.workOrderOnToggle.status=="DONE"){
      this.confirmationService.confirm({
        header: 'Suppression impossible',
        message:
          'Cet OT est terminé.',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: "J’ai compris",
        rejectVisible: false,

      });
    }
    else{
      this.confirmationService.confirm({
        header: 'Confirmation',
        message:
          'Les données seront supprimées définitivement.' +
          '<br>' +
          'Voulez vous continuer ?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Supprimer',
        rejectLabel: 'Annuler',
        accept: () => {
          this.deleteWorkOrder();

        },
      });
    }

  }

  deleteWorkOrder(){
    this.workOrderService.deleteWorkOrder(this.workOrderOnToggle.id).subscribe(
      (res) => {
        if (res.status === 200) {
          this.messageService.add({
            severity: 'success',
            summary: 'Opération réussie',
          });
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => { this.gotoList() }
    );

  }

  // redirect to list page
  gotoList() {
    this.redirectTo('/phoenix/work-order');
  }

  redirectTo(uri:string){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate([uri]));
  }

  hasAuthorization(authorized: String[]) {
    if (authorized.includes(this.role)) {
      return true;
    } else {
      return false;
    }
  }

  getProgress(workOrder: WorkOrder){
    let done = 0;
    let total = 0;
    for (const wopw of workOrder.listWorkOrderProjectWork){
      done += wopw.quantityDone;
      total += wopw.quantityTotal;
    }
    return Math.trunc((done / total) * 100);
  }

  filterTableInputChangeTabWorkOrder(event, str, dt1,dt2,dt3,dt4) {
    this.workOrderRecherche = event;
    this.searchItemService.filterTableInputChange(this.workOrderRecherche, str, dt1,dt2,dt3,dt4);
  }

  handleChangeTabWorkOrder(event,str, dt1,dt2,dt3,dt4){
    this.searchItemService.handleChange(event,this.workOrderRecherche, dt1,dt2,dt3,dt4);
  }

  naturalSortWorkOrder(event: SortEvent) {
    this.customSortService.naturalSort(event);
  }

}
