import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jSONParse'
})
export class JSONParsePipe implements PipeTransform {

   transform(dataSource: any): any[] {
    return JSON.parse(dataSource);
  }

}
