import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { WorkOrder, WorkOrderHistoryProgress } from '@data/schema/WorkOrder';
import { WorkOrderService } from '@data/service/work-order.service';

@Component({
  selector: 'app-work-order-form-report-advance',
  templateUrl: './work-order-form-report-advance.component.html',
  styleUrls: ['./work-order-form-report-advance.component.css']
})
export class WorkOrderFormReportAdvanceComponent implements OnInit {

  //recupérer l'objet parent
  @Input() workOrder: WorkOrder;

  displayModal: boolean = false;

  referenceNumber: string;


  date: Date = new Date(Date.now());

  listWorkOrderAdvance: WorkOrderHistoryProgress[] = [];

  constructor(
    public workOrderService: WorkOrderService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {


   // this.referenceNumber = "Av-"+this.date.getFullYear()+this.addLeadingZeros(this.date.getMonth() + 1)+this.addLeadingZeros(this.date.getDate())+this.addLeadingZeros(this.date.getHours())+this.addLeadingZeros(this.date.getMinutes())+this.addLeadingZeros(this.date.getSeconds())

  }
  
  //  addLeadingZeros(n){
  //  if (n <= 9) {
   //   return "0" + n;
  //  }
  //  return n
 // }

  report() {
    if(this.workOrder.company ==null){
      this.confirmationService.confirm({ 
        header: 'Action impossible',
        message: "Vous devez affecter l’OT à une entreprise pour saisir un avancement.",
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: "J’ai compris",
        rejectVisible: false   
      });
    }else{
    this.listWorkOrderAdvance = [];
    this.displayModal = true;

    for (const workOrderProjectWork of this.workOrder.listWorkOrderProjectWork){
      let workOrderAdvance = new WorkOrderHistoryProgress(workOrderProjectWork);
      this.listWorkOrderAdvance.push(workOrderAdvance);
    }
    }
  }

  cancel(){
    this.displayModal = false;
  }

  submit(){
    if (this.treatmentArray()){
      // save
      //console.log(this.listWorkOrderAdvance)
      this.save()
    }
    else{
      // pas de prestations terminées
      this.confirmationService.confirm({
        header: 'Validation impossible',
        message: "Veuillez corriger les quantités.",
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'OK',
        rejectVisible: false,
      });
    }
  }

  save(){
    this.workOrderService.createWorkOrderHistory(this.listWorkOrderAdvance).subscribe(

      (res) => {
        if (res.status === 200) {
          console.log(res);
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Opération réussie',
        });

        let path = '/phoenix/show-work-order/' + this.workOrder.id
        this.redirectTo(path)
      }
    );
  }

  treatmentArray(){
    let index = 0;
    let indexes = [];

    for (const workOrderAdvance of this.listWorkOrderAdvance){
      if (workOrderAdvance.quantityDone > workOrderAdvance.quantityAvailable){
        return false;
      }

      if (workOrderAdvance.quantityDone === 0){
        indexes.push(index);
      }
      workOrderAdvance.referenceNumber = this.referenceNumber;
      workOrderAdvance.created = this.date;
      let workOrder= JSON.parse(JSON.stringify(this.workOrder));
      workOrder.listWorkOrderProjectWork = null;
      workOrder.created = null;
      workOrderAdvance.workOrder = workOrder;
      index++;
    }

    if (indexes.length === this.listWorkOrderAdvance.length){
      return false;
    }
    else{
      this.listWorkOrderAdvance = this.listWorkOrderAdvance.filter(function(value, index) {
        return indexes.indexOf(index) == -1;
      })
      return true;
    }


  }

  redirectTo(uri: string) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }

}
