import { Input, OnInit, Directive, ViewContainerRef, TemplateRef, OnDestroy, ComponentFactoryResolver } from "@angular/core";
import { Subject } from "rxjs";
import { TokenStorageService } from "@core/service/token-storage.service";
import {LocalStorageService} from "@shared/service/local-storage.service";

@Directive({
  selector: '[ifPermission]'
})
export class PermissionDirective implements OnInit, OnDestroy {
  @Input() public ifPermission: Array<string>;
  @Input() public ifPermissionElse?: TemplateRef<any>;
  permissionUser: string;
  permission: any;
  stop$ = new Subject();
  isVisible = false;
  private permissionRight: string[];
  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private tokenStorageService: TokenStorageService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit() {
    this.permissionUser = this.tokenStorageService?.getPermission();
    this.permission = this.permissionUser?.split(' ');
    const idx = this.permission?.findIndex((element) => this.ifPermission.indexOf(element) !== -1);

    if (idx >= 0) {
      this.isVisible = true
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      return;
    }
    this.permissionRight = this.localStorageService.getPermissionRight();
   const idRights =  this.permissionRight?.findIndex((element) => this.ifPermission.indexOf(element) !== -1);
    if (idRights && idRights >= 0) {
      this.isVisible = true
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
    else {

      this.isVisible = false
      this.viewContainerRef.clear();
      if (this.ifPermissionElse) {
        this.viewContainerRef.createEmbeddedView(this.ifPermissionElse);
      }
    }
  }

  // Clear the subscription on destroy
  ngOnDestroy() {
    this.stop$.next();
  }

/**
 * exemple d'utilisation du block else
 *
 * *ifPermission="['stock:view']; else showStoreName"
 *
 * <ng-template #showStoreName let-stores>
    <td >
      {{stores.name}}
    </td>
  </ng-template>
 *
 */

}


