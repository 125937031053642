<div class="p-grid p-col-12 p-d-flex">
  <div class="p-col-6">
    <div class="p-fluid">
      <div *ngIf="(project?.status === 'DOING')" class="p-col-10">
        <h3 class="title">{{ project.name }}</h3>
      <span *ngIf="project.status === 'DOING'" class="p-field p-col status-doing">
        En cours
      </span>
      </div>
      <div class="p-grid" *ngIf="!(project?.status === 'DOING')">
        <div class="p-col-10">
          <label for="name" class="p-mb-2 p-md-2 p-mb-md-0"> Nom *  </label>
        </div>
        <div class="p-col-10" >
          <p-autoComplete
            id="name"
            [(ngModel)]="project.name"
            (ngModelChange)="change()"
            [suggestions]="outputName"
            (completeMethod)="filterName($event)"
          >
          </p-autoComplete>
          <p-message
            *ngIf="errorNameRequired"
            severity="error"
            text="Champ obligatoire."
          ></p-message>
        </div>

      </div>
    </div>
  </div>

  <div class="p-col-6">
    <div class="p-fluid">
      <div *ngIf="(project?.status === 'DOING')" class="p-col-10">
      Référence :  {{ project.referenceNumber }}
      </div>
      <div class="p-grid" *ngIf="!(project?.status === 'DOING')">
        <div class="p-col-10">
          <label for="unit" class="p-mb-2 p-md-2 p-mb-md-0"> Référence </label>
        </div>
        <div class="p-col-10">
          <input
            type="text"
            [(ngModel)]="project.referenceNumber"
            (ngModelChange)="change()"
            pInputText
          />
        </div>
      </div>
    </div>
  </div>
  <div class="p-col-6">
    <div class="p-fluid">
      <div class="p-grid">
        <div class="p-col-10">
          <label for="description" class="p-mb-2 p-md-2 p-mb-md-0">
            Description
          </label>
        </div>
        <div class="p-col-10">
          <!-- <textarea [(ngModel)]="project.description" (ngModelChange)="change()" [rows]="4" pInputTextarea> </textarea>  -->
          <textarea
            [(ngModel)]="project.description"
            (ngModelChange)="change()"
            [rows]="4"
            pInputTextarea
          >
          </textarea>
        </div>
      </div>
    </div>
  </div>
</div>
