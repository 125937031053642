import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {EnvService} from "@core/service/env.service";

@Injectable({
  providedIn: 'root'
})
export class SearchItemService {
  private apiURL = this.environment?.readConfig()?.urlApiGateway + 'stock/';
  private tabSearch: any[];

  constructor(private http: HttpClient, private environment: EnvService) { }


  // Search item
  public searchItem(serialNumber: string): Observable<any> {
    return this.http.get(this.apiURL + "search-article-web/" + serialNumber, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }
  //Search all numSerie
  public searchAllItem(serialNumber: string): Observable<any> {
    return this.http.get(this.apiURL + "search-article/" + serialNumber, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }

  filterTableInputChange(event, str, dt1,dt2,dt3,dt4) {
    let word : any;
     word = event.trim();
    this.tabSearch = word;
    dt1.filterGlobal(word, 'contains');
    dt2.filterGlobal(word, 'contains');
    dt3.filterGlobal(word, 'contains');
    dt4.filterGlobal(word, 'contains');
  }
  filterTableInputChangeStock(event, str, dt1) {
    let word : any;
     word = event.trim();
    this.tabSearch = word;
    dt1.filterGlobal(word, 'contains');
  }

  handleChange(event,str,dt1,dt2,dt3,dt4){
    var index = event.index;
    if(index === 0){
      this.filterTableInputNotChange(str,dt1);
    }
    if(index === 1){
      this.filterTableInputNotChange(str,dt2);
    }
    if(index === 2){
      this.filterTableInputNotChange(str,dt3);
    }
    if(index === 3){
      this.filterTableInputNotChange(str,dt4);
    }
  }
  handleChangeStock(event,str,dt1){
    var index = event.index;
    if(index === 0){
      this.filterTableInputNotChange(str,dt1);
    }
  }

  filterTableInputNotChange(str,dt){
    //str = ' ';
    if(str){
      dt.filterGlobal(str.trim(), 'contains');
    }

  }
}
