import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SkillService } from '@data/service/skill.service';
import { PersonaService } from '@data/service/persona.service';
import { Skill } from '@data/schema/Skill';
import { MessageService, SortEvent } from 'primeng/api';
import { CustomSortService } from '@shared/service/custom-sort.service';
import { MatRadioButton } from '@angular/material/radio';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Table } from 'primeng/table';
import { PersonaExpired } from '@data/schema/PersonaExpiring';


@Component({
  selector: 'app-show-skill-expiring',
  templateUrl: './show-skill-expiring.component.html',
  styleUrls: ['./show-skill-expiring.component.css']
})
export class ShowSkillExpiringComponent implements OnInit {

  @ViewChild('dt2') table: Table;  
  selectedValue: string = 'all';
  id: number;
  sub: any;
  currentSkill: Skill;
  personaList: PersonaExpired[];
  
  constructor(private route: ActivatedRoute,
    private SkillService: SkillService,
    private PersonaService: PersonaService,
    private MessageService: MessageService,
    private CustomSortService: CustomSortService,
    public dialogRef: MatDialogRef<ShowSkillExpiringComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { 
      //this.filterData();
    }

  ngOnInit(): void {
    // Get the id from the route param in the url
    // this.sub = this.route.params.subscribe((params) => {
    //   this.id = params['id'];
    // });

    this.id = this.data.id;

    console.log("Popup id : "+this.id);

    this.SkillService.getSkills(this.id).subscribe(
      (res) => {
        if (res.status === 200) {
          //this.currentSkill = res.body;
        }
      },
      (error) => {
        if (error) {
          this.MessageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      }
    );

    this.SkillService.getSkills(this.id).subscribe(
      (res) => {
        if (res.status === 200) {
          this.currentSkill = res.body;
        }
      },
      (error) => {
        if (error) {
          this.MessageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      }
    );

    this.PersonaService.getSkillByIdWithPersona(this.id).subscribe(
      (res) => {
        if (res.status === 200) {
          this.personaList = res.body;
        }
      },
      (error) => {
        if (error) {
          this.MessageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      }
    );

    
    

  }

  parseIntWrap(str: string){
    return parseInt(str);
  }

  naturalSortPersona(event: SortEvent) {
    this.CustomSortService.naturalSort(event);
    console.log("naturalSortPersona appelée");
  }

  //  filterData() {    
  //    this.table.filter(this.getFilter(), 'daysRemaining', 'equals');  
  //  }

  //  getFilter() {    
  //    if (this.selectedValue === 'all') {      
  //      return null;    
  //    } else if (this.selectedValue === 'expired') {      
  //      return (value: any, filter: any): boolean => {        
  //        return value <= 0;      
  //      };    
  //      } else if (this.selectedValue === 'willExpire') {      
  //        return (value: any, filter: any): boolean => {        
  //          return value > 0;      };    
  //        }  
  //      }

}
