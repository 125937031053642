<mat-card
  (click)="selectCard(selectedPersona)"
  [ngClass]="{
  'yellow-border': persona.idParty === responsibleTechnician,
  'selectedItem': isPersonaCardSelected,
  'cardCreation': teamCreation,
  'cardDisplay': teamDisplay
  }"


>
  <mat-card-header>
    <div class="icon-container" mat-card-avatar>
      <img *ngIf="!persona.photo" src="assets/img/technician-default.png" />
      <img *ngIf="persona.photo" [src]="persona.photo" />
      <div *ngIf="persona.idKeycloak || persona.user" class="status-circle"></div>
    </div>
    <mat-card-title class="cardTitle"
      >{{ persona.firstName }} {{ persona.lastName }}</mat-card-title
    >
    <mat-card-subtitle class="publicLabel">{{
      persona.publicName
    }}</mat-card-subtitle>
    <mat-card-subtitle class="techCompanyLabel" *ngIf="!profiles">{{
      persona.company
    }}</mat-card-subtitle>
    <!-- <mat-card-subtitle>{{ persona.company?.name }}</mat-card-subtitle> -->
    <div class="deletePersona">
      <mat-icon
        class="remove-icon"
        *ngIf="editPersonaMode"
        svgIcon="deleteFillIcon"
        (click)="childClick()"
      ></mat-icon>
    </div>
    <div *ngIf="teamScope">
      <mat-icon
        matTooltip="{{ 'creation_equipe_RESPONSABLE' | translate }}"
        matTooltipClass="tooltip"
        matTooltipPosition="above"
        class="card-icons"
        svgIcon="teamLeaderIcon"
        *ngIf="persona.idParty === responsibleTechnician"
      ></mat-icon>
      <mat-icon
        class="card-icons"
        svgIcon="deleteFillIcon"
        *ngIf="persona.idParty !== responsibleTechnician && !displayTeams"
        (click)="childTechClick()"
      ></mat-icon>
    </div>
  </mat-card-header>
  <!-- <mat-card-content>
    <div fxLayout="column wrap">
      <div  class="sub-header-overview">
        <label class="label" fxFlex="60">{{ "NOM" | translate }}</label>
        <span fxFlex="40">{{persona.mail}}</span>
      </div>
      <div  class="sub-header-overview">
        <label fxFlex="65" class="label">TELEPHONE</label>
        <span fxFlex="38">{{persona.phone}}</span>
      </div>
      <div  class="sub-header-overview">
        <label fxFlex="42" class="label">ADRESSE</label>
        <span fxFlex="58">{{persona.address}}</span>
      </div>
    </div>
  </mat-card-content>
  <mat-divider></mat-divider> -->
  <mat-divider *ngIf="profiles" style="margin-top: -3%"></mat-divider>
  <mat-card-content *ngIf="poolMode">
    <div fxLayout="column wrap" fxLayoutAlign="space-around">
      <div>
        <label class="label" fxFlex="60">{{
          "ENTREPRISE" | translate
        }}</label>
        <span class="companyLabel">{{ persona.company?.name }}</span>
      </div>
      <div style="padding-bottom: 1%;">
        <label class="label" fxFlex="60">{{
          "etape_ORGANISATION" | translate
        }}</label>
        <span  class="organizationName">{{ persona.organizationH }}</span>
      </div>
      <div>
        <label class="label" fxFlex="60">{{ "ENTITES" | translate }}</label>
        <span  class="unitName">{{ persona.organizationUnit }}</span>
      </div>
    </div>
  </mat-card-content>
  <mat-divider *ngIf="poolMode" style="margin-top: -4%"></mat-divider>
  <mat-card-content *ngIf="profiles">
    <div style="margin-top: 3%">
      <label fxFlex="10" class="label postsLabel">{{
        "PROFILS" | translate
      }}</label>
      <div fxFlex="90" style="margin-top: -3%">
        <mat-chip-list aria-label="Fish selection">
          <mat-chip
            *ngFor="let profil of persona.listProfil"
            [ngClass]="{
              selectedPost: isPersonaPostSelected && profil.id === selectedPost
            }"
            class="assigned-chip"
          >
            {{ profil.nameFR }}
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
  </mat-card-content>
  <!-- <mat-divider style="margin-top: -3%;"></mat-divider>
  <mat-card-content>
    <div>
      <label class="label" fxFlex="20">ENTITES</label>
      <div fxLayoutAlign="end center">
        <dv *ngFor="let entity of persona.organizationEntities" class="entity">
          {{ entity.name }}
        </dv>
      </div>
    </div>
  </mat-card-content> -->
  <!-- <mat-divider></mat-divider>
  <mat-card-content>
    <div fxLayout="column wrap">
      <div  class="sub-header-overview">
        <label class="label" fxFlex="60">ORGANIZATIONS</label>
        <span fxFlex="40">value</span>
      </div>
      <div class="sub-header-overview">
        <label class="label" fxFlex="60">ENTITES</label>
        <span fxFlex="40">value</span>
      </div>
    </div>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-content>
    <div fxLayout="column wrap">
      <div class="sub-header-overview">
        <label class="label" fxFlex="80">HABILITATIONS</label>
        <span fxFlex="10">value</span>
      </div>
      <div class="sub-header-overview">
        <label class="label" fxFlex="80">SAVOIR-FAIRE</label>
        <span fxFlex="10">value</span>
      </div>
      <div class="sub-header-overview">
        <label class="label" fxFlex="80">SAVOIR-ETRE</label>
        <span fxFlex="10">value</span>
      </div>
    </div>
  </mat-card-content> -->
</mat-card>
