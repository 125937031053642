<h3>{{'menu_PRESTATIONS'|translate}}</h3>
<div class="p-field p-grid">
  <label for="name" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
    {{'NOM'|translate}}
  </label>
  <div class="p-col-12 p-md-10 p-pl-2 p-pr-2 ">
    <span>{{ work.name }}</span>
  </div>
</div>

<div class="p-field p-grid">
  <label for="description" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
    {{'DESCRIPTION'|translate}}
  </label>
  <div class="p-col-12 p-md-10 p-pl-2 p-pr-2">
    <span>{{ work.description }}</span>
  </div>
</div>
