import { Component, OnInit } from '@angular/core';
import { PersonaService } from '@data/service/persona.service';
import { Persona, PersonaSkills } from '@data/schema/Persona';
import { LazyLoadEvent, MessageService, ConfirmationService,SortEvent } from 'primeng/api';
import {CustomSortService} from "@shared/service/custom-sort.service";
import { SkillService } from '@data/service/skill.service';
import {Skill} from '@data/schema/Skill';
import { SearchItemService } from '@data/service/search-item.service';
import { MatDialog, MatDialogConfig  } from '@angular/material/dialog';
import { ShowSkillExpiringComponent } from '../show-skill-expiring/show-skill-expiring.component';
import { ShowPersonaWithSkillsExpiredComponent } from '../show-persona-with-skills-expired/show-persona-with-skills-expired.component';
import { SkillExpired } from '@data/schema/SkillExpired';
import { OverlayContainer } from '@angular/cdk/overlay';


@Component({
  selector: 'app-expiring-skills',
  templateUrl: './expiring-skills.component.html',
  styleUrls: ['./expiring-skills.component.css']
})
export class ExpiringSkillsComponent implements OnInit {
  userPools: number[] = [];
  loading: boolean;
  lazyLoadEvent: LazyLoadEvent;
  persona: Persona[];
  totalRecords: number = 20;
  enabled: boolean = true;
  currentDate : Date = new Date();
  currentDateString : String = new Date().toISOString().split('Z')[0] + "+00:00" ;
  skill: Skill[] = [];
  personaExpiredSkills: Skill[] = [];
  listSkill : SkillExpired[];

  temp: any;

  searchedSkill: string;
  showAll = false;

  isExpanded = false;
  fullDescription: string = '';

  constructor(
    private personaService: PersonaService,
    private messageService: MessageService,
    private customSortService: CustomSortService,
    private skillService: SkillService,
    private searchItemService: SearchItemService,
    private dialog: MatDialog,
    private overlayContainer: OverlayContainer,
  ) { this.overlayContainer.getContainerElement().style.zIndex = '22000'; }

  ngOnInit(): void {
    // this.skillService.getListSkill().subscribe(
    //   (res) => {
    //     if (res.status === 200) {
    //       this.listSkill = res.body;
    //     }
    //   },
    //   (error) => {
    //     if (error) {
    //       this.messageService.add({
    //         severity: 'error',
    //         summary: 'Échec de l’opération : veuillez réessayer',
    //       });
    //     }
    //   }
    // );

   

    this.personaService.getListSkillWithPersonaCount().subscribe(
      (res) => {
        if (res.status === 200) {
          this.listSkill = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      }
    );

    this.personaService.getPersonaListWithExpiredSkills().subscribe(
      (res) => {
        if (res.status === 200) {
          this.persona = res.body;
          console.log(res.body)
          this.loading = false;
          this.totalRecords = res.body.totalElements;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => { }
    );
  }

  showFullDescription(description: string) {
    this.fullDescription = description;
}

onMouseEnter(skill: any): void {
    skill.isHovered = this.isDescriptionLongEnough(skill.skillDescription);
}

onMouseLeave(skill: any): void {
    skill.isHovered = false;
}

isDescriptionLongEnough(description: string): boolean {
    return description.length > 30;
}


  openDialog(idGiven){
    this.dialog.open(ShowSkillExpiringComponent, {
      disableClose: false,
      data :{id:idGiven},
      position: {left:'15%'},
    });
  }

  openDialogPersona(idGiven){
    this.dialog.open(ShowPersonaWithSkillsExpiredComponent, {
      //disableClose: true,
      data :{id:idGiven},
      position: {left:'15%'},
      width: '80%',
    });
  }

  filterTableInputChangeStock(event, str, dt1) {
    this.searchedSkill = event;
    this.searchItemService.filterTableInputChangeStock(this.searchedSkill, str, dt1);
  }

  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  

  toggleShowAll() {
    this.showAll = !this.showAll;
  }

  private getPersonaList(enabled: boolean) {
     this.personaService.getPersonaListWithExpiredSkills().subscribe(
       (res) => {
         if (res.status === 200) {
           this.persona = res.body;
           console.log(res.body)
           this.loading = false;
           this.totalRecords = res.body.totalElements;
         }
       },
       (error) => {
         if (error) {
           this.messageService.add({
             severity: 'error',
             summary: 'Échec de l’opération : veuillez réessayer',
           });
         }
       },
       () => { }
     );
  }

  loadPersona(event: LazyLoadEvent){
    console.log(event)

    let currentPage: number;
    currentPage = event.first / event.rows ;
    console.log(currentPage)
    this.loading = true;
    this.lazyLoadEvent = event;
    this.lazyLoadEvent['page'] = currentPage;
    this.getPersonaList(this.enabled);
    //this.personaService.getPersonaListWithExpiredSkills();
   }


  getExpiredSkillsOfPersona(persona: Persona){
    this.personaExpiredSkills.splice(0);
    for (const personaSkill of persona.listSkill){
      if(this.checkValidDate(personaSkill) || this.checkExpiringDate()){
        this.personaExpiredSkills.push(personaSkill.skill)
      }
    }
    console.log(this.personaExpiredSkills);
   }



  naturalSortPersona(event: SortEvent) {
    this.customSortService.naturalSort(event);
  }

  


   getValidDateSkill(){
    this.skill
   }

   findDate(){
    const currentDateISO: string = new Date().toISOString();
    return currentDateISO;
   }



   getSkillList() {
    this.skillService.getListSkillWithTags().subscribe(res => {
        if (res.status === 200) {
          this.skill = res.body;
          for(let element of this.skill){
            this.alphaNumericSortTag(element.tags)
          }
        }
      },
      error => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur',
            detail: 'Échec de l’opération : veuillez réessayer'
          });

        }
      },
      () => {
      })

  }


  alphaNumericSortTag = (arr = []) => {
    const sorter = (a:any, b:any) => {
    const isNumber = (v:any) => (+v).toString() === v;
    const aPart = a.label.match(/\d+|\D+/g);
    const bPart = b.label.match(/\d+|\D+/g);

    let i = 0; let len = Math.min(aPart.length, bPart.length);
    while (i < len && aPart[i] === bPart[i]) { i++; };
      if (i === len) {
          return aPart.length - bPart.length;
    };
    if (isNumber(aPart[i]) && isNumber(bPart[i])) {
      return aPart[i] - bPart[i];
    };
    return aPart[i].localeCompare(bPart[i]); };
    arr.sort(sorter);
  };

  checkValidDate(personaSkill: PersonaSkills){
    if (personaSkill.skill.type === 'HABILITATION' && personaSkill.skill.validity && new Date(personaSkill.endDate).getTime() < this.currentDate.getTime()){
      return false;
    }
    return true;
  }

  calculateEndDate(date, days, months, years) {
    var d = new Date(date);

    d.setDate(d.getDate() + days);
    d.setFullYear(d.getFullYear() + years);
    d.setMonth(d.getMonth() + +months);

    return d;
  }

  checkExpiringDate(){

  }


  

  naturalSort(event: SortEvent) {
    this.customSortService.naturalSort(event);
  }




}
