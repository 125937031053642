import { Component, Input, IterableDiffers, OnInit } from '@angular/core';
import { ProjectWork } from '@data/schema/Project';
import { MaterialWork } from '@data/schema/Work';
import { WorkOrderProjectWork } from '@data/schema/WorkOrder';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  ConfirmationService,
  ConfirmEventType,
  MessageService,
  SortEvent,
} from 'primeng/api';
import { WorkOrderService } from '@data/service/work-order.service';
import { Router } from '@angular/router';
import { CustomSortService } from '@shared/service/custom-sort.service';
@Component({
  selector: 'app-work-form-list-materials',
  templateUrl: './work-form-list-materials.component.html',
  styleUrls: ['./work-form-list-materials.component.css'],
})
export class WorkFormListMaterialsComponent implements OnInit {
  formG: FormGroup;
  @Input() editMode: boolean;
  @Input() attachedCompany: any;
  // optional parameter listProjectWork
  @Input() listProjectWork?: ProjectWork[];
  // optional parameter listWorkOrderProjectWork
  @Input() listWorkOrderProjectWork?: WorkOrderProjectWork[];
  newItem: any = {};
  editItem: any = {};
  // allow to check multiclick
  submitSaveVoucher: boolean = false;
  @Input() selectedMateriel: any;
  // list item for voucher save
  listItem: any = null;
  // allow to check if array input changed
  iterableDiffer: any;

  // list work material for consumablelistWorkMaterialConsumable
  // list work material for tools
  @Input() listWorkMaterialConsumable: MaterialWork[] = [];
  listWorkMaterialTool: MaterialWork[] = [];

  @Input() fromWorKOrder: Boolean;
  @Input() id: number;
  // boolean to display voucher popup
  displayBon: boolean = false;

  constructor(
    private iterableDiffers: IterableDiffers,
    private confirmationService: ConfirmationService,
    private router: Router,
    private customSortService: CustomSortService,
    private fb: FormBuilder,
    public workOrderService: WorkOrderService,
    private messageService: MessageService
  ) {
    this.iterableDiffer = iterableDiffers.find([]).create(null);
  }

  ngOnChanges() {}

  redirectTo(uri: string) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }

  ngOnInit(): void {}

  // check when input listProjectWork changed
  // do calculate quantity if any change
  ngDoCheck() {
    if (this.listProjectWork) {
      let changes = this.iterableDiffer.diff(this.listProjectWork);
      if (changes) {
        this.listWorkMaterialConsumable = [];
        this.listWorkMaterialTool = [];
        this.calculateQuantity(this.listProjectWork);
      }
    }
    if (this.listWorkOrderProjectWork) {
      let changes = this.iterableDiffer.diff(this.listWorkOrderProjectWork);
      if (changes) {
        this.listWorkMaterialConsumable = [];
        this.listWorkMaterialTool = [];
        this.calculateQuantity(undefined, this.listWorkOrderProjectWork);
      }
    }
  }

  // calculate quantity based on list passed on parameters
  calculateQuantity(
    listProjectWork?: ProjectWork[],
    listWorkOrderProjectWork?: WorkOrderProjectWork[]
  ) {
    // if it's list of project work calculate quantity
    if (listProjectWork) {
      for (const projectWork of listProjectWork) {
        let quantityWork = projectWork.quantityTotal;
        for (const materialWork of projectWork.work.materialsList) {
          // add material work with calculate for each work based on quantity
          this.addMaterialWork(materialWork, quantityWork);
        }
      }
    }

    if (listWorkOrderProjectWork) {
      //console.log(listWorkOrderProjectWork)
      for (const workOrderProjectWork of listWorkOrderProjectWork) {
        let quantityWork = workOrderProjectWork.quantityTotal;
        let quantityDone = workOrderProjectWork.quantityDone;
        for (const materialWork of workOrderProjectWork.projectWork.work
          .materialsList) {
          this.addMaterialWork(materialWork, quantityWork, quantityDone);
        }
      }
    }
  }

  //check if consumable or tool to calculate
  addMaterialWork(materialWork: MaterialWork, quantity: number, quantityDone?: number) {
    if (materialWork.materialDto?.categorie.code === "SER" || materialWork.materialDto?.categorie.code === "CSM") {
      // add list consummable
      this.add("CONSUMABLE", materialWork, quantity, quantityDone)
    } else if (materialWork.materialDto?.categorie.code === "OUT" || materialWork.materialDto?.categorie.code === "POO") {
      // add list tools
      this.add('TOOL', materialWork, quantity);
    }
  }

  // add material work to list consumable or list tools based on type
  add(
    type: string,
    materialWork: MaterialWork,
    quantity: number,
    quantityDone?: number
  ) {
    //console.log(materialWork) ;

    if (type === 'CONSUMABLE') {
      // check if material is already in the list
      for (const material of this.listWorkMaterialConsumable) {
        // already exist material in list, so add quantity to the existant
        if (materialWork.materialDto.id === material.materialDto.id) {
          material.quantityMaterial =
            material.quantityMaterial +
            quantity * materialWork.quantityMaterial;
          material.totalIssuedQuantity = materialWork.totalIssuedQuantity;

          if (quantityDone) {
            material.quantityMaterialUsed =
              material.quantityMaterialUsed +
              quantityDone * materialWork.quantityMaterial;
          }
          return true;
        }
      }
      // doesn't exist material in list so push with work quantity
      let materialWorkWithQuantity = JSON.parse(JSON.stringify(materialWork));
      //console.log(materialWorkWithQuantity)
      materialWorkWithQuantity.quantityMaterial =
        quantity * materialWorkWithQuantity.quantityMaterial;

      if (quantityDone) {
        // PRODUIT EN CROIX
        // FAIT - TOTAL
        // qtyDone -
        materialWorkWithQuantity.quantityMaterialUsed =
          (quantityDone * materialWorkWithQuantity.quantityMaterial) / quantity;
      }
      this.listWorkMaterialConsumable.push(materialWorkWithQuantity);
      return false;
    }

    if (type === 'TOOL') {
      // check if material is already in the list
      for (const material of this.listWorkMaterialTool) {
        // already exist material in list, so add quantity to the existant
        if (materialWork.materialDto.id === material.materialDto.id) {
          // tool quantity is only the maximum
          if (materialWork.quantityMaterial > material.quantityMaterial) {
            material.quantityMaterial = materialWork.quantityMaterial;
          }
          return true;
        }
      }
      // doesn't exist material in list so push with work quantity
      let materialWorkWithQuantity = JSON.parse(JSON.stringify(materialWork));
      this.listWorkMaterialTool.push(materialWorkWithQuantity);
      return false;
    }
  }

  hideDialog() {
    this.confirmationService.confirm({
      message:
        'Vous allez quitter la page, les données seront perdues.' +
        '<br>' +
        'Voulez vous continuer ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'null',
      rejectIcon: 'null',
      acceptLabel: 'Quitter',
      rejectLabel: 'Annuler',
      key: 'cd',
      acceptButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this.displayBon = false;
        this.messageService.add({
          severity: 'info',
          summary: 'Modification annulée',
          detail: '',
        });
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            break;
          case ConfirmEventType.CANCEL:
            break;
        }
      },
    });
  }

  naturalSortMaterial(event: SortEvent) {
    this.customSortService.naturalSort(event);
  }
}
