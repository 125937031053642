import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isObjectNull'
})
export class IsObjectNullPipe implements PipeTransform {

  transform(value: any) {
    if (value == null) return true;
    if (value instanceof Array){
      if (value.length == 0) return true;
      return this.checkIfAnyIsNull(value[0]);
    }
   return this.checkIfAnyIsNull(value);
  }


  checkIfAnyIsNull(object: any){
    return Object.values(object).every(value => value && typeof value === 'object'
        ? this.checkIfAnyIsNull(value)
        : value === null || value == ''
    );
  }



}
