import { Component, OnInit, HostListener } from '@angular/core';
import { MessageService } from 'primeng/api';
import { PersonaSkills, Persona, PartyRoleCatalogue } from '@data/schema/Persona';
import { Skill } from '@data/schema/Skill';
import { PersonaService } from '@data/service/persona.service';
import { SkillService } from '@data/service/skill.service';
import { QrDataFormaterService } from '@shared/service/qr-data-formater.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AddSkillComponent } from './add-skill.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Location } from '@angular/common';
import { TokenStorageService } from '@core/service/token-storage.service';
import { CompanyService } from '@data/service/company.service';
import { Company } from '@data/schema/Company';
import { FileUpload } from 'primeng/fileupload';
import { TranslateService } from '@ngx-translate/core';
import {
  Dimensions,
  ImageCroppedEvent,
  ImageTransform,
} from 'ngx-image-cropper';
import { Observable, BehaviorSubject } from 'rxjs';
import { OrganizationService } from '@data/service/organization.service';
import { CreationTeamService } from '@data/service/intervention/save-team.service';

interface Role {
  id: number,
  code:string,
  nameFR: string,
}

@Component({
  selector: 'app-show-persona',
  templateUrl: './show-persona.component.html',
  styleUrls: ['./show-persona.component.css'],
})

export class ShowPersonaComponent implements OnInit {
  public submited: BehaviorSubject<boolean>;

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.changed || this.submited.value ? true : false;
  }

  changed: boolean = false;
  persona: Persona = new Persona();
  company: Company = new Company();
  listCompany: Company[];
  compasave: any;
  displayTech: boolean = false;
  filteredCompanies: any[];
  filteredCompaniesNum: number[];
  qrData: string = null;
  ref: DynamicDialogRef;
  typeOfTag: string = 'skill';
  //Gestion Photo
  display: boolean = false;
  imageBase64: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = true;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};

  //Error message
  existPhoto: boolean; // Boolean to display photo if it exist or to hide it if it doesn't exist
  errorPhone: boolean = false;
  errorLastNameRequired: boolean = false;
  errorFirstNameRequired: boolean = false;
  errorMailRequired: boolean = false;
  errorMailFormat: boolean = false;
  errorGenderRequired: boolean = false;
  errorCompanyRequired: boolean = false;
  errorPostRequired: boolean = false;
  tmpPersonaListSkill: any;
  personaListSkill: any;

  //Recup identifiant persona depuis url
  id: number;
  sub: any;
  gender: any[];
  technicianAddress: string;

  //Variables lié a l'update
  editPersonaMode: boolean = false; // Boolean to trigger the edit mod in a persona details
  editSkillMode: boolean = false; // Boolean to trigger the edit mode of skills in a persona details
  personaEditHistory: Persona;
  skillOfPersonaEditHistory: PersonaSkills[];
  // Variables for editing posts
  listPosts: Role[] = [];
  profileName:string ;
  selectedPosts: PartyRoleCatalogue[] = [];
  panelClosed: boolean = false;
  currentDate: Date = new Date();

  listSkill: Skill[]; // Tableau contenant tout les skills recup onInit
  //skillOfPersona: Skill[]; //Tableau contenant les skills du persona à afficher
  newSkillsOfPersona: Skill[] = []; //Tableau contenant les skills ajoutées à afficher

  listAddSkills: PersonaSkills[] = []; // Array envoyé à l'api pour ajouter les skills au persona
  listDeleteSkills: PersonaSkills[] = []; // Array envoyé à l'api contenant la liste des skills à delete du persona
  listChangeSkills: PersonaSkills[] = []; // Array envoyé à l'api contenant la liste des skills à mettre à jour
  activeState: boolean[] = [];

  constructor(
    private dialogService: DialogService,
    private route: ActivatedRoute,
    public organizationService: OrganizationService,
    private messageService: MessageService,
    private personaService: PersonaService,
    private qrDataFormaterService: QrDataFormaterService,
    private skillService: SkillService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private tokenStorageService: TokenStorageService,
    private companyService: CompanyService,
    private _location: Location,
    private creationTeamService :CreationTeamService,
    private translate: TranslateService
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params.editMode) {
        this.editPersonaMode = true;
      }
    });
    this.submited = new BehaviorSubject(false);
  }

  filterArrayByTag(newArrayFiltered: any) {
    //console.log('filtre');
    this.persona.listSkill = [];
    // si on vide les tags du filtre
    if (newArrayFiltered.length === this.listSkill.length) {
      this.persona.listSkill = this.tmpPersonaListSkill;
    } else {
      for (var i = 0; i < this.tmpPersonaListSkill.length; i++) {
        for (var j = 0; j < newArrayFiltered.length; j++) {
          if (this.tmpPersonaListSkill[i].idSkill === newArrayFiltered[j].id) {
            this.persona.listSkill.push(this.tmpPersonaListSkill[i]);
          }
        }
      }
    }
  }

  alphaNumericSortTag = (arr = []) => {
    const sorter = (a: any, b: any) => {
      const isNumber = (v: any) => (+v).toString() === v;
      const aPart = a.label.match(/\d+|\D+/g);
      const bPart = b.label.match(/\d+|\D+/g);

      let i = 0;
      let len = Math.min(aPart.length, bPart.length);
      while (i < len && aPart[i] === bPart[i]) {
        i++;
      }
      if (i === len) {
        return aPart.length - bPart.length;
      }
      if (isNumber(aPart[i]) && isNumber(bPart[i])) {
        return aPart[i] - bPart[i];
      }
      return aPart[i].localeCompare(bPart[i]);
    };
    arr.sort(sorter);
  };
  getPartyRoles(){
        // Get list of posts
          this.organizationService.getAllProfilesPersona().toPromise()
        .then((data) => {
          this.listPosts = data.reduce((acc, curr) => {
            acc.push({
              id: curr.id,
              name: curr.nameFR,
              code: curr.code
            });
            return acc;
          }, []);
          if(data){
            this.personaService.getPersona(this.id).subscribe(
              (res) => {
                if (res.status === 200) {
                  this.persona = new Persona(res.body);
                  this.tmpPersonaListSkill = this.persona.listSkill;
                    this.selectedPosts = this.persona.listProfil.reduce((acc, curr) => {
                      acc.push({
                        id: curr.id,
                        name: curr.nameFR,
                        code:curr.code
                      });

                      return acc;
                    }, []);
                  for (let i = 0; i < this.persona.listSkill.length; i++) {
                    for (let j = 0; j < this.personaListSkill.length; j++) {
                      if (
                        this.persona.listSkill[i].skill.id ===
                        this.personaListSkill[j].id
                      ) {
                        this.persona.listSkill[i].skill.tags =
                          this.personaListSkill[j].tags;
                      }
                    }
                  }
                }
              },
              (error) => {
                if (error) {
                  this.messageService.add({
                    severity: 'error',
                    summary: this.translate.instant('toast_ECHEC_OPERATION_REESSAYER'),
                  });
                }
              },
              () => {
                // done when subscribe end
                // existPhoto enable to show the photo only if it exist and hide it when it doesn't exist
                this.existPhoto = this.checkExistPhoto();
                // A OPTIMIZER avec le back : extrait les skills depuis les PersonaSkills de Persona (Persona ne contient que les relations)
                // for (const skillOfPersona of this.persona.listSkill){
                //   for (const skill of this.listSkill){
                //     if (skillOfPersona.idSkill === skill.id){
                //       this.skillOfPersona.push(skill);
                //     }
                //   }
                // }

                this.activeState = new Array(this.persona.listSkill.length).fill(false);

                this.personaEditHistory = new Persona(this.persona);

                let content = {
                  id: this.persona.id,
                };
                this.qrData = this.qrDataFormaterService.generateQrData(
                  'persona',
                  content
                );
              }
            );
          }
      });
  }
  // When page is initialized
  ngOnInit(): void {
   this.getPartyRoles();
    if (
      this.tokenStorageService.decodeToken().realm_access.roles[0] ===
        'manager_operationnel' ||
      this.tokenStorageService.decodeToken().realm_access.roles[0] ===
        'manager_CA'
    ) {
      this.editPersonaMode = false;
    }
    //this.skillOfPersona = [];

    this.gender = [
      { name: 'Femme', value: 'Femme' },
      { name: 'Homme', value: 'Homme' },
      { name: 'Autre', value: 'Autre' },
    ];
    // Get all the companies
    this.companyService.getListAllEnableCompany().subscribe((res) => {
      if (res.status === 200) {
        this.listCompany = res.body;
      }
    });
    // Get all the skills
    this.skillService.getListSkillWithTags().subscribe(
      (res) => {
        if (res.status === 200) {
          this.listSkill = res.body;
          for (let element of this.listSkill) {
            this.alphaNumericSortTag(element.tags);
          }
          this.personaListSkill = this.listSkill;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: this.translate.instant('toast_ECHEC_OPERATION_REESSAYER'),
          });
        }
      },
      () => {
      }
    );

    // Get the id from the route param in the url
    this.sub = this.route.params.subscribe((params) => {
      this.id = +params['id'];
    });

    // Get the specific Persona with his id
  }
  /* CUSTOM METHODS TO INTERACT WITH FRONT DISPLAY */

  toggle(index: number) {
    this.activeState[index] = !this.activeState[index];
  }

  toggleAll(value: boolean) {
    this.activeState = new Array(this.activeState.length).fill(value);
  }

  // Function that fill perso list of posts
  changePost(e) {
    this.persona.listProfil = this.selectedPosts.reduce((acc, curr) => {
      acc.push({
        id: curr.id,
        name: curr.nameFR,
        code: curr.code,
        characteristics: {}
      });
      return acc;
    }, []);
    if(e.itemValue?.code === 'TECH' && e.value.some((x)=> x.code === e.itemValue?.code )){
      this.displayTech = true;
    }
  }
  // Calculate end date based on parameters values
  calculateEndDate(date, days, months, years) {
    var d = new Date(date);

    d.setDate(d.getDate() + days);
    d.setFullYear(d.getFullYear() + years);
    d.setMonth(d.getMonth() + +months);

    return d;
  }

  // auto calculate
  startDateOnChange(index: number) {
    let days = this.persona.listSkill[index].skill.day_duration;
    let month = this.persona.listSkill[index].skill.month_duration;
    let year = this.persona.listSkill[index].skill.year_duration;

    this.addTimeZoneOffsetToCalendarTime(
      this.persona.listSkill[index].startDate
    );
    this.persona.listSkill[index].endDate = this.calculateEndDate(
      this.persona.listSkill[index].startDate,
      days,
      month,
      year
    );
  }

  // auto calculate
  addedStartDateOnChange(index: number) {
    let days = this.listAddSkills[index].skill.day_duration;
    let month = this.listAddSkills[index].skill.month_duration;
    let year = this.listAddSkills[index].skill.year_duration;

    this.listAddSkills[index].endDate = this.calculateEndDate(
      this.listAddSkills[index].startDate,
      days,
      month,
      year
    );
  }
  createTechnicien() {
    this.displayTech = false;
    let technicianPost = this.persona.listProfil.filter(p => p.code === 'TECH');
    if(this.technicianAddress == null){
      this.technicianAddress ="   "
    }
    technicianPost.map((post) => {
      post.characteristics.address = this.technicianAddress
    })
  }
  getValidity(year: number, month: number, day: number) {
    var message = '';
    if (year > 0) {
      message += year + " " + this.translate.instant('ANNEE_S')
    }
    if (month > 0) {
      if (year > 0) {
        message += ', ';
      }
      message += month + " " + this.translate.instant('MOIS_S')
    }
    if (day > 0) {
      if (year > 0 || month > 0) {
        message += ', ';
      }
      message += day + " " + this.translate.instant('JOUR_S')
    }
    return message;
  }

  checkValidDate(personaSkill: PersonaSkills) {
    if (
      personaSkill.skill.type === 'HABILITATION' &&
      personaSkill.skill.validity &&
      new Date(personaSkill.endDate).getTime() < this.currentDate.getTime()
    ) {
      return false;
    }
    return true;
  }

  switchEditPersonaMode(value: boolean) {
    this.editPersonaMode = value;
    this.personaEditHistory = new Persona(this.persona);
  }

  switchEditSkillsMode(value: boolean) {
    this.editSkillMode = value;
    this.skillOfPersonaEditHistory = [...this.persona.listSkill];
    this.toggleAll(value);
  }

  checkExistPhoto() {
    if (this.persona.photo === null) {
      return false;
    } else {
      return true;
    }
  }

  filterCompany(event: any) {
    this.filteredCompanies = this.listCompany
      .filter((c) => c.name.toLowerCase().startsWith(event.query.toLowerCase()))
      .map((company) => company);
  }

  /* CUSTOM METHODS TO HANDLE ERRORS MESSAGES */
  validPhoneFormat(event: any) {
    if (
      event.target.value.charAt(0) === '+' &&
      !isNaN(event.target.value.substr(1))
    ) {
      this.errorPhone = false;
    } else if (!isNaN(event.target.value)) {
      this.errorPhone = false;
    } else {
      this.errorPhone = true;
    }
  }
  valideUpdate() {
    if (this.persona.lastName === '') {
      this.errorLastNameRequired = true;
    }
    if (this.persona.firstName === '') {
      this.errorFirstNameRequired = true;
    }
    if (this.persona.mail === '') {
      this.errorMailRequired = true;
    }
    if (!this.persona.gender) {
      this.errorGenderRequired = true;
    }
    if (this.persona.company === null) {
      this.errorCompanyRequired = true;
    }
    if (this.selectedPosts.length == 0) {
      this.errorPostRequired = true;
    }

    if (
      this.errorLastNameRequired === false &&
      this.errorFirstNameRequired === false &&
      this.errorMailRequired === false &&
      this.errorMailFormat === false &&
      this.errorGenderRequired === false &&
      this.errorCompanyRequired === false &&
      this.errorPostRequired === false
    ) {
      this.updatePersona();
    }
  }

  firsNametFieldValidator(event: any) {
    if (event.target.value === '') {
      this.errorFirstNameRequired = true;
    } else {
      this.errorFirstNameRequired = false;
    }
  }
  lastNametFieldValidator(event: any) {
    if (event.target.value === '') {
      this.errorLastNameRequired = true;
    } else {
      this.errorLastNameRequired = false;
    }
  }
  mailFieldValidator(event: any) {
    var pattern = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$');
    if (event.target.value === '') {
      this.errorMailRequired = true;
    } else {
      this.errorMailRequired = false;
    }

    if (!pattern.test(event.target.value)) {
      this.errorMailFormat = true;
    } else {
      this.errorMailFormat = false;
    }
  }
  genderFieldValidator(event: any) {
    if (!this.persona.gender) {
      this.errorGenderRequired = true;
    } else {
      this.errorGenderRequired = false;
    }
  }
  companyFieldValidator(event: any) {
    if (!this.persona.company) {
      this.errorCompanyRequired = true;
    } else {
      this.errorCompanyRequired = false;
    }
  }
  postFieldValidator(event: any) {
    if (!this.persona.listProfil) {
      this.errorPostRequired = true;
    } else {
      this.errorPostRequired = false;
    }
  }

  addTimeZoneOffsetToCalendarTime(event) {
    /*To be removed when primeng fixes the bug related to p calendar or when migrating to another library*/
    event.setTime(event.getTime() - new Date().getTimezoneOffset() * 60 * 1000);
    /**/
  }
  /* CUSTOM METHODS TO HANDLE LOGIC */
sol
  // PERSONA LOGIC

  updatePersona() {
    if (this.persona.birthDate) {
      this.addTimeZoneOffsetToCalendarTime(this.persona.birthDate);
    }
    this.personaService.updatePersona(this.persona.id, this.persona).subscribe(
      (res) => {
        // Updated
        if (res.status === 200) {
          this.messageService.add({
            severity: 'success',
            summary: this.translate.instant('toast_OPERATION_REUSSI'),
          });

        }

      },
      (error) => {
        if (error.status === 500) {
          this.messageService.add({
            severity: 'error',
            summary: this.translate.instant('toast_ECHEC_OPERATION_REESSAYER'),
          });
        }
        if (error.status === 409) {
          this.messageService.add({
            severity: 'error',
            summary: this.translate.instant('form_message_ELEMENT_EXISTE') ,
          });
        }
      },
      () => {
        // Show photo only if it exist
        this.existPhoto = this.checkExistPhoto();
        this.changed = false;
        this.switchEditPersonaMode(false);
        this.ngOnInit();
      }
    );
  }

  // Triggered when the delete button is pressed
  deletePersona() {
    // Call API in the service to soft delete Persona
    this.personaService.deletePersona(this.persona.id, this.persona).subscribe(
      (res) => {
        // Deleted
        if (res.status === 200) {
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: this.translate.instant('toast_ECHEC_OPERATION_REESSAYER'),
          });
        }
      },
      () => {}
    );
  }

  // Triggered when the delete button is pressed
  activatePersona() {
    // Call API in the service to activate Persona
    this.personaService
      .activatePersona(this.persona.id, this.persona)
      .subscribe(
        (res) => {
          // Activated
          if (res.status === 200) {
          }
        },
        (error) => {
          if (error) {
            this.messageService.add({
              severity: 'error',
              summary: this.translate.instant('toast_ECHEC_OPERATION_REESSAYER'),
            });
          }
        },
        () => {}
      );
  }

  // SKILL LOGIC

  // return an array of all skills - skills of persona & skills added to the persona
  initializeSelectableSkills() {
    // copy of the list of all skills
    let selectableSkills = this.listSkill;

    // remove skill from base persona
    for (let i = 0; i < this.persona.listSkill.length; i++) {
      selectableSkills = selectableSkills.filter(
        (item) => item.id !== this.persona.listSkill[i].idSkill
      );
    }

    // enlève les skills de selectable skill les skills ajouté
    for (let i = 0; i < this.newSkillsOfPersona.length; i++) {
      selectableSkills = selectableSkills.filter(
        (item) => item.id !== this.newSkillsOfPersona[i].id
      );
    }

    // return array of skill - skill of persona - skill added to the persona
    return selectableSkills;
  }

  // Affiche et gere dynamicdialog pour l'ajout de skills
  addNewSkills() {
    //Tableaux contenant les skills que le persona n'a pas (utile pour l'ajout)
    this.personaService.listSelectableSkills =
      this.initializeSelectableSkills();
    this.personaService.personaInformation.persona = this.persona;

    // open dialog with the component
    this.ref = this.dialogService.open(AddSkillComponent, {
      header: this.translate.instant('AJOUT_COMPETENCES'),
      width: '80%',
      styleClass: 'main-page',
    });

    // on close of the dialog, do this
    this.ref.onClose.subscribe((assignedSkills: PersonaSkills[]) => {
      // if return variable assignedSkills is not empty/null, add all skills selected to add
      if (assignedSkills) {
        // loop all assigned skills
        for (let i = 0; i < assignedSkills.length; i++) {
          // add all persona skills
          this.listAddSkills.push(assignedSkills[i]);

          // // loop all existing skills
          // for (let j = 0; j < this.listSkill.length; j++) {
          //   // if it is the skill of persona skills, add to the array that display new skills
          //   if (assignedSkills[i].idSkill === this.listSkill[j].id) {
          //     this.newSkillsOfPersona.push(this.listSkill[j]);
          //   }
          // }
          this.newSkillsOfPersona.push(assignedSkills[i].skill);
        }
      }
    });
  }

  // Delete one skill of the persona
  deleteSkill(skill: Skill) {
    this.changed = true;
    // add the persona skill of the persona in the toDelete array
    for (let i = 0; i < this.persona.listSkill.length; i++) {
      if (this.persona.listSkill[i].idSkill == skill.id) {
        this.listDeleteSkills.push(this.persona.listSkill[i]);
      }
    }

    // // Delete the persona skill from persona
    // this.persona.listSkill = this.persona.listSkill.filter(
    //   (item) => item.idSkill !== skill.id
    // );

    // delete the skill of the persona, showed on front
    this.persona.listSkill = this.persona.listSkill.filter(
      (item) => item.idSkill !== skill.id
    );
  }

  // Delete one of the skill added to the persona
  // ne delete pas quand (add skill, enregistre, modifie, puis delete, enregistre)
  // => temporary fix window.location.reload() on updateSkills()
  deleteAddedSkill(skill: Skill) {
    this.listAddSkills = this.listAddSkills.filter(
      (item) => item.idSkill !== skill.id
    );

    this.newSkillsOfPersona = this.newSkillsOfPersona.filter(
      (item) => item.id !== skill.id
    );
  }

  // Used to save the skills data of the persona
  updateSkills() {
    this.changed = false;
    let listModifsSkills = [];
    listModifsSkills.push(this.listAddSkills);
    listModifsSkills.push(this.persona.listSkill);
    listModifsSkills.push(this.listDeleteSkills);

    this.personaService.updateAllSkills(listModifsSkills).subscribe(
      (res) => {
        if (res.status === 200) {
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: this.translate.instant('toast_ECHEC_OPERATION_REESSAYER'),
          });
        }
      },

      () => {
        this.listAddSkills = [];
        this.listChangeSkills = [];
        this.listDeleteSkills = [];
        this.switchEditSkillsMode(false);
        this.messageService.add({
          severity: 'success',
          summary: this.translate.instant('toast_OPERATION_REUSSI'),
        });
        window.location.reload(); //see comment function deleteAddedSkill/OLD FIX
      }
    );
  }

  //This methode is to rate Persona
  handleRate(event) {
    this.persona.globalRating = event.value;
  }

  confirmDelete() {
      this.creationTeamService.isTechPartOfATeam(this.persona.idParty).subscribe(
        async res => {
          if(res.status === 200){
            let isPartOfATeam = res.body;
            if (!isPartOfATeam) {
              this.confirmationService.confirm({
                header: this.translate.instant('titre_message_exception_CONFIRMATION'),
                message: this.translate.instant('texte_message_exception_DESACTIVER_PERSONA'),
                icon: 'pi pi-exclamation-triangle',
                acceptButtonStyleClass: 'p-button-outlined',
                acceptIcon: 'null',
                rejectIcon: 'null',
                acceptLabel: 'Désactiver',
                rejectLabel: 'Annuler',
                accept: () => {
                  this.deletePersona();
                  this.redirectTo('/phoenix/persona');
                  this.messageService.add({
                    severity: 'success',
                    summary:  this.translate.instant('toast_OPERATION_REUSSI'),
                  });
                },
              });
          }else{
            this.confirmationService.confirm({
              header: this.translate.instant('titre_message_exception_SUPPRESSION_IMPOSSIBLE'),
              message: this.translate.instant('texte_message_exception_DESACTIVER_PERSONA_AVEC_EQUIPE'),
              icon: 'pi pi-exclamation-triangle',
              acceptIcon: 'null',
              rejectIcon: 'null',
              acceptLabel: this.translate.instant('bouton_message_exception_COMPRIS'),
              rejectVisible: false
            });
          }
        }
      }
      );
    
  }

  confirmActivate() {
    this.confirmationService.confirm({
      header: this.translate.instant('titre_message_exception_CONFIRMATION'),
      message: this.translate.instant('texte_message_exception_ACTIVER_PERSONA'),
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button-outlined',
      acceptIcon: 'null',
      rejectIcon: 'null',
      acceptLabel: 'Activer',
      rejectLabel: 'Annuler',
      accept: () => {
        this.activatePersona();
        this.redirectTo('/phoenix/persona');
        this.messageService.add({
          severity: 'success',
          summary: this.translate.instant('toast_OPERATION_REUSSI'),
        });
      },
    });
  }

  confirmReturn() {
    if (
      JSON.stringify(this.persona) !== JSON.stringify(this.personaEditHistory)
    ) {
      this.confirmationService.confirm({
        header: this.translate.instant('titre_message_exception_CONFIRMATION'),
        message:
          this.translate.instant('texte_message_exception_QUITTER_PAGE'),
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: this.translate.instant('bouton_message_exception_QUITTER'),
        rejectLabel:  this.translate.instant('bouton_message_exception_ANNULER'),
        accept: () => {
          this.persona = this.personaEditHistory;
          this.errorPhone = false;
          this.errorLastNameRequired = false;
          this.errorFirstNameRequired = false;
          this.errorMailRequired = false;
          this.errorMailFormat = false;
          this.errorGenderRequired = false;
          this.errorCompanyRequired = false;
          this.errorPostRequired = false;
          this.switchEditPersonaMode(false);
          this.personaService.erasePersonaInformation();
        },
      });
    } else {
      this.errorPhone = false;
      this.errorLastNameRequired = false;
      this.errorFirstNameRequired = false;
      this.errorMailRequired = false;
      this.errorMailFormat = false;
      this.errorGenderRequired = false;
      this.errorCompanyRequired = false;
      this.errorPostRequired = false;
      this.switchEditPersonaMode(false);
    }
  }

  confirmReset() {
    if (
      JSON.stringify(this.persona.listSkill) !==
        JSON.stringify(this.skillOfPersonaEditHistory) ||
      this.listAddSkills.length !== 0
    ) {
      this.confirmationService.confirm({
        header: this.translate.instant('titre_message_exception_CONFIRMATION'),
        message:
          this.translate.instant('texte_message_exception_QUITTER_MODIFICATION'),
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: this.translate.instant('bouton_message_exception_QUITTER'),
        rejectLabel: this.translate.instant('bouton_message_exception_ANNULER'),
        accept: () => {
          this.persona.listSkill = this.skillOfPersonaEditHistory;
          this.newSkillsOfPersona = [];
          // this.listAddSkills = [];
          // this.listDeleteSkills = [];
          // this.listChangeSkills = [];
          this.switchEditSkillsMode(false);
        },
      });
    } else {
      this.switchEditSkillsMode(false);
    }
  }

  confirm() {
    this.confirmationService.confirm({
      header: this.translate.instant('titre_message_exception_CONFIRMATION'),
      message: this.translate.instant('texte_message_exception_QUITTER_PAGE'),
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button-outlined',
      acceptIcon: 'null',
      rejectIcon: 'null',
      acceptLabel: this.translate.instant('bouton_message_exception_QUITTER'),
      rejectLabel: this.translate.instant('bouton_message_exception_ANNULER'),
      accept: () => {
        this.submited.next(true);
        this.router.navigate(['/phoenix/persona']);
        this.personaService.erasePersonaInformation();
      },
    });
  }

  confirmReturnBottom() {
    if (this.editPersonaMode || this.editSkillMode) {
      this.confirmationService.confirm({
        header: this.translate.instant('titre_message_exception_CONFIRMATION'),
        message: this.translate.instant('texte_message_exception_QUITTER_PAGE'),
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: this.translate.instant('bouton_message_exception_QUITTER'),
        rejectLabel: this.translate.instant('bouton_message_exception_ANNULER'),
        accept: () => {
          this.goBack();
        },
      });
    } else {
      this.goBack();
    }
  }

  goBack() {
    this.changed = false;
    this.submited.next(true);
    this._location.back();
  }

  redirectTo(uri: string) {
    this.changed = false;
    this.submited.next(true);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }

  // Detect input changes
  onChange(updatedPersona: Persona): void {
    //console.log('changement');
    if (this.persona != updatedPersona) {
      this.changed = true;
    }
  }

  onSelection(event, fileUpload: FileUpload) {
    this.display = true;
    let fileReader = new FileReader();

    let file = event.files[0];

    fileReader.readAsDataURL(file);
    fileReader.onload = (e) => {
      // Store the base64 string of the image file
      this.imageBase64 = fileReader.result.toString();
    };
  }

  // Used to upload an image file that catch the event
  onUpload(fileUpload: FileUpload) {
    this.display = false;
    fileUpload.clear();
  }

  imageCropped(event: ImageCroppedEvent) {
    let picSize = ((4 * event.base64.length) / 3 + 3) & ~3;

    this.persona.photo = event.base64;
  }

  imageLoaded() {
    this.showCropper = true;
    //console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    //console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    //console.log('Load failed');
  }

  clear(fileUpload: FileUpload) {
    fileUpload.clear();
  }

  deletePhoto() {
    this.persona.photo = null;
  }
}
