import { Component, OnInit, ViewChild , HostListener} from '@angular/core';
import { Project } from '@data/schema/Project';
import { Router } from '@angular/router';
import { ProjectService } from '@data/service/project.service';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ProjectFormGeneralComponent } from '../../components/project/project-form-general/project-form-general.component';
import { Observable, BehaviorSubject } from 'rxjs';
import { LocalStorageService } from '@shared/service/local-storage.service';
import { Currency } from '@data/schema/Currency';
import { ComponentCanDeactivate } from '@core/guard/component-can-deactivate.guard';

@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.css'],
})
export class NewProjectComponent implements OnInit, ComponentCanDeactivate {

public submited: BehaviorSubject<boolean>;

// @HostListener allows us to also guard against browser refresh, close, etc.
@HostListener('window:beforeunload')
canDeactivate(): Observable<boolean> | boolean {
  return (this.submited.value || !this.project?.isDefined() ? true : false)
}

  project: Project = new Project();

  @ViewChild(ProjectFormGeneralComponent)
  private projectGeneral!: ProjectFormGeneralComponent;

  items: MenuItem[];
  currency: Currency;

  constructor(
    private router: Router,
    public projectService: ProjectService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private localStorage: LocalStorageService
  ) {
    this.submited = new BehaviorSubject(false);
    this.currency = new Currency(JSON.parse(this.localStorage.getLocalStorageItem("currency")));
  }

  ngOnInit(): void {
    this.items = [
      {label:'Projets', routerLink:'/phoenix/project'},
      {label:"Création d'un projet", styleClass:'ariane-current'}
    ];

    this.isCurrencyAlreadyConfigured();
  }


  back() {

    if (this.submited.value || !this.project?.isDefined())
    {
      this.gotoList();
    }
    else {

      this.confirmationService.confirm({
        header: 'Confirmation',
        message:
          'Vous allez quitter la page, les données seront perdues.' +
          '<br>' +
          'Voulez vous continuer ?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Quitter',
        rejectLabel: 'Annuler',
        accept: () => {
            this.gotoList();
          },
        });
      }
  }

  submit() {
    let validForm = true;
    if (this.projectGeneral.nameFieldValidator()) {
      validForm = false;
    }
    if (!validForm) {
      this.messageService.add({
        severity: 'error',
        summary: 'Échec de l’opération : veuillez remplir tous les champs',
      });
    } else {
      this.save();
    }
  }
  save() {
    //console.log(this.project);
    this.projectService.createProject(this.project).subscribe(
      (res) => {
        if (res.status === 200) {
          this.messageService.add({
            severity: 'success',
            summary: 'Opération réussie',
          });
        }
      },
      (error) => {
        if (error) {
          if (error.status === 500) {
            this.messageService.add({
              severity: 'error',
              summary: 'Échec de l’opération : veuillez réessayer',
            });
          }
          if (error.status === 409) {
            this.messageService.add({
              severity: 'error',
              summary: 'Cet élément existe déjà',
            });
          }
        }
      },
      () => {
        this.gotoList();
      }
    );
  }
  // redirect to list page
  gotoList() {
    this.redirectTo('/phoenix/project');
  }
  redirectTo(uri: string) {
   this.submited.next(true)
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }
  confirm() {
    this.confirmationService.confirm({
      header: 'Confirmation',
      message:
        'Vous allez quitter la page, les données seront perdues.' +
        '<br>' +
        'Voulez vous continuer ?',
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button-outlined',
      acceptIcon: 'null',
      rejectIcon: 'null',
      acceptLabel: 'Quitter',
      rejectLabel: 'Annuler',
      accept: () => {
        this.submited.next(true)
         this.router.navigate(['/phoenix/project']);
         this.projectService.eraseProjectInformation();
        },
      });
    }

  isCurrencyAlreadyConfigured() {
    if (!this.currency) {
      this.confirmationService.confirm({
        header: 'Action impossible',
        message:
          "La devise n’est pas paramétrée.",
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        acceptLabel: "J'ai compris",
        rejectVisible: false,
        closeOnEscape: false,
        dismissableMask: false,
        accept: () => {
          this.redirectTo('/phoenix/project');
        },
        reject: () => {
          this.redirectTo('/phoenix/project');
        },

      });
    }
  }
}
