<div *ngIf="mode === 'consultation'">

  <!--div class="p-col-12 p-md-12 p-lg-12">
    <h2>Les prestations </h2>
  </div-->

  <div class="p-col-12 p-md-6 p-lg-12">
    <div>
      <div class="p-col-12 p-md-12 p-lg-12">
        <p-table

          #dt1
          (sortFunction)="naturalSortWork($event)"
          [customSort]="true"
          sortField="workId.name"
          [sortOrder]='1'
          selectionMode="multiple"
          [value]="contracts?.contractWorks"
          class="table"
          [scrollable]="true"
          scrollHeight="40vh"
          [paginator]="true"
          [rows]="10"
          styleClass="p-datatable-sm p-datatable-striped"
          [showCurrentPageReport]="true"
          currentPageReportTemplate="{{'PAGINATION'|translate}}"
          [rowsPerPageOptions]="[10, 25, 50]"
          [globalFilterFields]="[
          'workId.name','workId.description','price'
        ]">
          <ng-template pTemplate="colgroup">
            <colgroup>
              <col style="width:35%">
              <col style="width:40%">
              <col style="width:15%">
              <col style="width:10%">
            </colgroup>
          </ng-template>
          <ng-template pTemplate="caption">
            <div class="p-d-flex p-jc-between p-m-3">
              <div>
                <span [style]="{'font-weight': 'bold','margin-left':'-30px','font-size':'x-large'}">Les prestations</span>
              </div>
              <div>
                <span class="p-input-icon-left p-ml-auto" [style]="{'height':'20px'}">
                  <i class="pi pi-search" [style]="{'margin-left':'10%','height': '45px','margin-top':'5px'}"></i>
                  <input
                  [style]="{'margin-left':'10%','height': '45px'}"
                    pInputText
                    type="text"
                    (input)="dt1.filterGlobal($event.target.value, 'contains')"
                    placeholder="Rechercher"
                  />
                </span>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th id="name" pSortableColumn="workId.name">
                Prestation
                <p-sortIcon field="workId.name"></p-sortIcon>
              </th>
              <th id="workId.description" pSortableColumn="workId.description">
                Description
                <p-sortIcon field="workId.description"></p-sortIcon>
              </th>
              <th id="price" [style]="{'text-align':'right'}" pSortableColumn="price">
                Prix
                <p-sortIcon field="price"></p-sortIcon>
              </th>
              <th></th>
          </ng-template>

          <ng-template pTemplate="body" let-contractWorks>
            <tr>
              <td>{{ contractWorks?.workId?.name}}</td>

              <td>{{ contractWorks?.workId?.description}}</td>

              <td
                [style]="{'width':'5%', 'text-align':'right'}">{{ contractWorks?.price.toFixed(2)}}  {{ contracts?.currency}} </td>
              <td></td>
            </tr>
          </ng-template>
        </p-table>

      </div>

    </div>
  </div>
</div>

<div [formGroup]="contractForm" class="p-grid" *ngIf="mode !== 'consultation' && mode !== 'update'">
  <div class="p-grid" [style]="{'minWidth':'100%','padding-top':'2%'}">
    <div style="padding-left: 2%; margin-top: -1%;" class="p-col-12 p-md-12 p-lg-4">
      <h2>Sélection des prestations</h2>
    </div>
    <!--<div class="p-col-12 p-md-12 p-lg-4">
    </div>-->
    <!--<div class="p-col-12 p-md-12 p-lg-4">
      <div class="p-field p-grid">
      <label class="p-col-12 p-mb-2 p-md-4 p-mb-md-0">Devise des préstations</label>
        <div class="p-col-12 p-md-8 p-pl-5 p-pr-5">
          <p-dropdown
            id="type"
            appendTo="body"
            class="currencyDrop"
            formControlName="currency"
            [options]="currency"
            placeholder="Selectioner devise"
            optionLabel="code"
            optionValue="code"
            [showClear]="true"
            [filter]="true" filterBy="code"
            [baseZIndex]="10000"
          ></p-dropdown>
          <div *ngIf="contractForm.controls.currency.invalid && (contractForm.controls.currency.dirty || contractForm.controls.currency.touched || isSubmit || prestationErr)">
            <p-message severity="error" class="currencyDrop"
                      text="Champ obligatoire."></p-message>
          </div>
        </div>
    </div>
  </div>-->
  </div>

  <div *ngIf="prestationControls.length > 0" class="p-col-12 p-md-12 p-lg-12" style="padding-top: 3%;">
    <div class="p-grid" [style]="{'margin-bottom':'10px'}">
      <div class="p-col-12 p-md-12 p-lg-3">
        <label>Prestation</label></div>
      <div class="p-col-12 p-md-12 p-lg-4">
        <label>Description</label>
      </div>
      <div class="p-col-12 p-md-12 p-lg-3">
        <label>Prix de base*</label>
      </div>
    </div>
    <div *ngFor="let presta of prestationControls.controls; let index = index">
      <div class="p-grid" [formGroup]="presta" [style]="{'margin-bottom':'15px'}">
        <div class="p-col-12 p-md-12 p-lg-3">

          <p-dropdown  class="prestation" placeholder="Sélectionner la prestation" filterBy="name" [filter]="true" [showClear]="true" [options]="works"
                      formControlName="workId" optionLabel="name" (onChange)="isContractDataValidByWorkForSave(index)"></p-dropdown>


          <p-message *ngIf="checkDuplicate(presta.controls.workId)"
                     severity="error" class="prestaError"
                     text="Cette prestation existe déjà."
          ></p-message>
          <div
            *ngIf="presta.controls.workId.invalid && (presta.controls.workId.dirty || presta.controls.workId.touched || isSubmit || prestationErr)"
            class="alert alert-danger">
            <div *ngIf="presta.controls.workId.errors.required">
              <p-message class="prestaError" severity="error" text="Champ obligatoire."></p-message>
            </div>

            <!--<div *ngIf="findDuplicationnumeroSerie(art.controls.numeroSerie.value,numeroSeries409, i)">
              <p-message severity="error" text="Ce numéro de série existe déjà !"></p-message>
            </div>
            -->
          </div>
        </div>
        <div class="p-col-12 p-md-12 p-lg-4">
          <p>{{presta?.value?.workId?.description}}</p>
        </div>
        <div class="p-col-12 p-md-12 p-lg-3">
          <p-inputNumber *ngIf="contractForm?.value?.currency !== null " formControlName="price" mode="currency"
                         [currency]="contractForm?.value?.currency"></p-inputNumber>
          <p-inputNumber *ngIf="contractForm?.value?.currency === null " formControlName="price"></p-inputNumber>
          <div
            *ngIf="presta.controls.price.invalid && (presta.controls.price.dirty || presta.controls.price.touched || isSubmit || prestationErr)"
            class="alert alert-danger">
            <div *ngIf="presta.controls.price.errors.required">
              <p-message severity="error" class="prestaError" text="Champ obligatoire."></p-message>
            </div>
          </div>
        </div>
        <div class="p-col-12 p-md-12 p-lg-1" [style]="{ 'text-align':'center'}">
          <button (click)="deletePrestation(index)" pButton pRipple type="button" icon="pi pi-times"
                  class="p-button-rounded p-button-danger p-button-sm p-ml-1"></button>
        </div>
      </div>
    </div>
  </div>
  <div class="p-col-12 p-d-flex p-ai-center">
    <button
      (click)="addPrestation()"
      pButton
      pRipple
      type="button"
      icon="pi pi-plus"
      class="p-button-rounded p-button-text"
    ></button>
    <span (click)="addPrestation()">Ajouter une prestation</span>
  </div>
</div>


<!----------------------------------------EDIT MODE??-------------------------------------------------------------->


<div [formGroup]="contractForm" class="p-grid" *ngIf="contractForm && mode === 'update'">
  <div class="p-grid" [style]="{'minWidth':'100%','padding-top':'2%'}">
    <div style="padding-left: 2%; margin-top: -1%;" class="p-col-12 p-md-12 p-lg-4">
      <h2>Sélection des prestations</h2>
    </div>
    <!--<div class="p-col-12 p-md-12 p-lg-4">
    </div>-->
    <!--<div class="p-col-12 p-md-12 p-lg-4">
      <div class="p-field p-grid">
      <label class="p-col-12 p-mb-2 p-md-4 p-mb-md-0">Devise des prestations</label>
        <div class="p-col-12 p-md-8 p-pl-5 p-pr-5">
          <p-dropdown
            id="type"
            appendTo="body"
            class="currencyDrop"
            formControlName="currency"
            [options]="currency"
            placeholder="Selectioner devise"
            optionLabel="code"
            optionValue="code"
            [showClear]="true"
            [filter]="true" filterBy="code"
          ></p-dropdown>
          <div *ngIf="contractForm.controls.currency.invalid && (contractForm.controls.currency.dirty || contractForm.controls.currency.touched || isSubmit || prestationErr)">
            <p-message severity="error" class="currencyDrop"
                      text="Champ obligatoire."></p-message>
          </div>
        </div>
    </div>
  </div>-->
  </div>

  <div *ngIf="prestationControls.length > 0 || prestationsBeforeUpdate?.length > 0" class="p-col-12 p-md-12 p-lg-12"
       style="padding-top: 3%;">
    <div class="p-grid" [style]="{'margin-bottom':'10px'}">
      <div class="p-col-12 p-md-12 p-lg-3">
        <label>Prestation</label></div>
      <div class="p-col-12 p-md-12 p-lg-4">
        <label>Description</label>
      </div>
      <div class="p-col-12 p-md-12 p-lg-3">
        <label>Prix de base*</label>
      </div>
    </div>

    <!--------------------------------------------
    <div *ngFor="let presta of prestationsBeforeUpdate.controls; let index = index">
      <div class="p-grid" [formGroup]="presta" [style]="{'margin-bottom':'15px'}">
        <div class="p-col-12 p-md-12 p-lg-3">


          <p>{{presta?.value?.workId?.name}}</p>
        </div>
        <div class="p-col-12 p-md-12 p-lg-4">
          <p>{{presta?.value?.workId?.description}}</p>
        </div>
        <div class="p-col-12 p-md-12 p-lg-3">
          <p-inputNumber *ngIf="contractForm?.value?.currency !== null && contractForm?.value?.status?.code === statusContract.INACTIF" (onInput)="priceBeforeUpdateChanged(true)"
                         formControlName="price" mode="currency"
                         [currency]="contractForm?.value?.currency"></p-inputNumber>
          <p-inputNumber *ngIf="contractForm?.value?.currency === null && contractForm?.value?.status?.code === statusContract.INACTIF" (onInput)="priceBeforeUpdateChanged(true)"
                         formControlName="price"></p-inputNumber>
<p *ngIf ="contractForm?.value?.status?.code === statusContract.EN_VIGUEUR">{{presta?.value?.price}} {{contractForm?.value?.currency}}</p>
        </div>
        <div *ngIf="contractForm?.value?.status?.code === statusContract.INACTIF" class="p-col-12 p-md-12 p-lg-1" [style]="{ 'text-align':'center'}">
          <button (click)="deleteBeforeUpdatePrestations(index)" pButton pRipple type="button" icon="pi pi-times"
                  class="p-button-rounded p-button-danger p-button-sm p-ml-1"></button>
        </div>
      </div>

      ---------------------------------------------->
      <div *ngFor="let presta of prestationsBeforeUpdate.controls; let index = index">
        <div class="p-grid" [formGroup]="presta" [style]="{'margin-bottom':'15px'}">
          <div class="p-col-12 p-md-12 p-lg-3">

           <!-- <p-dropdown class="prestation" placeholder="Sélectionner la prestation" filterBy="name" [filter]="true" [showClear]="true" [options]="works"
                        formControlName="workId" optionLabel="name" name="name" dataKey="idWork" (onChange)="getWork($event, index)"></p-dropdown>
            -->

            <p>{{presta?.value?.workId?.name}}</p>
          </div>
          <div class="p-col-12 p-md-12 p-lg-4">
            <p>{{presta?.value?.workId?.description}}</p>
          </div>
          <div class="p-col-12 p-md-12 p-lg-3">
            <p-inputNumber *ngIf="contractForm?.value?.currency !== null && contractForm?.value?.status?.code === statusContract.INACTIF"  (onInput)="priceBeforeUpdateChanged(true)"  formControlName="price" mode="currency" [currency]="contractForm?.value?.currency"></p-inputNumber>
            <p-inputNumber *ngIf="contractForm?.value?.currency === null && contractForm?.value?.status?.code === statusContract.INACTIF"  (onInput)="priceBeforeUpdateChanged(true)" formControlName="price"></p-inputNumber>
            <p *ngIf ="contractForm?.value?.status?.code === statusContract.EN_VIGUEUR">{{presta?.value?.price}} {{contractForm?.value?.currency}}</p>

          </div>
          <div *ngIf="contractForm?.value?.status?.code === statusContract.INACTIF" class="p-col-12 p-md-12 p-lg-1" [style]="{ 'text-align':'center'}">
            <button (click)="deleteBeforeUpdatePrestations(index)" pButton pRipple type="button" icon="pi pi-times"
                    class="p-button-rounded p-button-danger p-button-sm p-ml-1"></button>
          </div>

          <div *ngIf="contractForm?.value?.status?.code === statusContract.EN_VIGUEUR" pTooltip="Impossible de supprimer une prestation d‘un contrat actif" class="p-col-12 p-md-12 p-lg-1" [style]="{ 'text-align':'center'}">
            <button pButton pRipple type="button" [disabled] = true   icon="pi pi-times"
                    class="p-button-rounded p-button-danger p-button-sm p-ml-1"></button>
          </div>


      </div>
    </div>
    <!---------------------------------------------------->
    <div *ngFor="let presta of prestationControls.controls; let index = index">
      <div class="p-grid" [formGroup]="presta" [style]="{'margin-bottom':'15px'}">
        <div class="p-col-12 p-md-12 p-lg-3">

          <p-dropdown class="prestation" placeholder="Sélectionner la prestation" filterBy="name" [filter]="true" [showClear]="true" [options]="works"
                      formControlName="workId" optionLabel="name" name="name" dataKey="idWork" (onChange)="isContractDataValidByWorkForUpdate(index)"></p-dropdown>
          <p-message *ngIf="checkDuplicate(presta.controls.workId)"
                     severity="error" class="prestaError"
                     text="Cette prestation existe déjà."
          ></p-message>
          <div
            *ngIf="presta.controls.workId.invalid && (presta.controls.workId.dirty || presta.controls.workId.touched || isSubmit || prestationErr)"
            class="alert alert-danger">
            <div *ngIf="presta.controls.workId.errors.required">
              <p-message class="prestaError" severity="error" text="Champ obligatoire."></p-message>
            </div>

            <!--<div *ngIf="findDuplicationnumeroSerie(art.controls.numeroSerie.value,numeroSeries409, i)">
              <p-message severity="error" text="Ce numéro de série existe déjà !"></p-message>
            </div>
            -->
          </div>
        </div>
        <div class="p-col-12 p-md-12 p-lg-4">
          <p>{{presta?.value?.workId?.description}}</p>
        </div>
        <div class="p-col-12 p-md-12 p-lg-3">
          <p-inputNumber *ngIf="contractForm?.value?.currency !== null "  formControlName="price"  mode="currency" [currency]="contractForm?.value?.currency"></p-inputNumber>
          <p-inputNumber *ngIf="contractForm?.value?.currency === null "  formControlName="price" ></p-inputNumber>
          <div
            *ngIf="presta.controls.price.invalid && (presta.controls.price.dirty || presta.controls.price.touched || isSubmit || prestationErr)"
            class="alert alert-danger">
            <div *ngIf="presta.controls.price.errors.required">
              <p-message severity="error" class="prestaError" text="Champ obligatoire."></p-message>
            </div>
          </div>
        </div>
        <div class="p-col-12 p-md-12 p-lg-1" [style]="{ 'text-align':'center'}">
          <button (click)="deletePrestation(index)" pButton pRipple type="button" icon="pi pi-times"
                  class="p-button-rounded p-button-danger p-button-sm p-ml-1"></button>
        </div>
      </div>
    </div>
  </div>
  <div class="p-col-12 p-d-flex p-ai-center">
    <button
      (click)="addPrestation()"
      pButton
      pRipple
      type="button"
      icon="pi pi-plus"
      class="p-button-rounded p-button-text"
    ></button>
    <span (click)="addPrestation()">Ajouter une prestation</span>
  </div>
</div>


