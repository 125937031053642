import { Component, OnInit } from '@angular/core';
import {Currency} from "@data/schema/Currency";
import {CurrencyService} from "@data/service/currency.service";
import {Router} from "@angular/router";
import { LocalStorageService } from '@shared/service/local-storage.service';

@Component({
  selector: 'app-show-currency',
  templateUrl: './show-currency.component.html',
  styleUrls: ['./show-currency.component.css']
})
export class ShowCurrencyComponent implements OnInit {

  currency: Currency;
  setting: string = "Devise";

  constructor(private currencyService: CurrencyService, private router: Router, private localStorage: LocalStorageService) {
    this.currency = new Currency(JSON.parse(this.localStorage.getLocalStorageItem("currency")));
   }

  ngOnInit(): void {
   
  }

  update() {
    this.router.navigate(['/phoenix/configure-currency'])
  }

}
