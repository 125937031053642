import { Pipe, PipeTransform } from '@angular/core';
import { ControlsType,  SelectWithNoDisplayBox } from '@data/schema/intervention/Form';

@Pipe({
  name: 'isSelectWithoutDisplayBox'
})
export class IsSelectWithoutDisplayBoxPipe implements PipeTransform {

  transform(controlsType : ControlsType): controlsType is SelectWithNoDisplayBox {
    return controlsType?.code === "selectWithNoDisplayBox";
  }

}
