<h3>{{'COMPETENCES_REQUISES'|translate}}</h3>
<div class="p-grid p-mb-2">
  <div class="p-col-6">
    {{ 'NOM' | translate }}
  </div>
  <div class="p-col-6">
    {{ 'TYPE_COMPETENCE' | translate }}
  </div>
</div>
<div *ngFor="let workSkill of work.listSkillDto" class="p-grid">
  <div class="p-col-6">
    {{workSkill.skillDto.name}}
  </div>
  <div class="p-col-6">
    <span *ngIf="workSkill.skillDto.type === 'HABILITATION'">{{ 'HABILITATION' | translate }}</span>
    <span *ngIf="workSkill.skillDto.type === 'SAVOIR_FAIRE'">{{ 'SAVOIR_FAIRE' | translate }}</span>
    <span *ngIf="workSkill.skillDto.type === 'SAVOIR_ETRE'">{{ 'SAVOIR_ETRE' | translate }}</span>
  </div>
</div>
