import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'occurences'
})
export class OccurencesPipe implements PipeTransform {

  transform(arr: any[], property: string): number {
    return arr.map(item => item[property]).filter(prop => prop !== undefined).length;
  }
}
