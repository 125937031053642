import { Injectable } from '@angular/core';
const PERMISSION_RIGHTS = 'user-rights';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  setLocalStorageItem(key: string, value: any){
    localStorage.setItem(key, value);
  }

  getLocalStorageItem(key: string){
    return localStorage.getItem(key);
  }

  removeLocalStorageKey(key: string){
    localStorage.removeItem(key);
  }

  public keyExists(key:string){
    return localStorage.getItem(key) !== null
  }

  public hasPermission(permission:string){
    return this.getPermissions().includes(permission);
  }

  private getPermissions(): any[] {
    let permissions = sessionStorage.getItem("auth-scope");
    if(permissions){
      return sessionStorage.getItem("auth-scope").split(" ");
    }
    return [];
  }
  public hasRole(role:string){
    return this.getRole().includes(role);
  }

  public getRole() {
    return sessionStorage.getItem("auth-role");
  }

  public setPermissionRight(value: any){
    localStorage.setItem(PERMISSION_RIGHTS, JSON.stringify(value));
  }

  public getPermissionRight(): any {
    return JSON.parse(localStorage.getItem(PERMISSION_RIGHTS));

  }
}
