import { Pipe, PipeTransform } from '@angular/core';
import { ControlsType, SelectWithoutQuantity } from '@data/schema/intervention/Form';

@Pipe({
  name: 'isSelectWithoutQuantity'
})
export class IsSelectWithoutQuantityPipe implements PipeTransform {

  transform(controlsType :ControlsType): controlsType is SelectWithoutQuantity {
    return controlsType?.code === "selectWithoutQuantity";
  }

}
