import { Company } from "./Company";
import { Project, ProjectWork } from "./Project";
import {Voucher} from "./Voucher";

export class WorkOrder {
  id: number;
  name: string;
  description: string;
  hasProject: boolean;
  project: Project;
  created: Date;
  listWorkOrderProjectWork : WorkOrderProjectWork[];
  listWorksCompany : any[];
  attachedToCompany: boolean;
  company: Company;
  status : string;
  listVoucherforot: Voucher[];
  listHistoryProgress: WorkOrderHistory[];

  constructor() {
    this.id= null;
    this.name= '';
    this.description= '';
    this.hasProject = true;
    this.project = null;
    this.status = null;
    //this.created= new Date(Date.now());
    this.listWorkOrderProjectWork = [];
    this.listWorksCompany = [];
    this.attachedToCompany= false;
    this.company= null;
    this.listVoucherforot = [];
    this.listHistoryProgress = [];
  }

  public isDefined(){
    return (
            this.name ||
            this.description 
            )
  }


}

export class WorkOrderProjectWork {
  id: number;
  workOrder: WorkOrder;
  projectWork: ProjectWork;
  quantityTotal: number;
  quantityDone : number;
  created: Date;

  constructor() {
    this.id = null;
    this.workOrder= null;
    this.projectWork= null;
    this.quantityTotal= 1;
    this.quantityDone = 0;
    this.created=  new Date(Date.now());
  }
}

export class WorkOrderHistory{
  reference: string;
  created: Date;
  listWorkOrderHistoryProgress: WorkOrderHistoryProgress[];
}

export class WorkOrderHistoryProgress {
  id: number;
  workOrder: WorkOrder;
  workOrderProjectWork: WorkOrderProjectWork;
  referenceNumber: string;
  quantityTotal: number;
  quantityAvailable: number;
  quantityDone : number;
  created: Date;

  constructor(workOrderProjectWork? : WorkOrderProjectWork) {
    if (workOrderProjectWork){
      this.id = null;
      this.workOrderProjectWork= workOrderProjectWork;
      this.referenceNumber= "";
      this.quantityTotal= workOrderProjectWork.quantityTotal;
      this.quantityAvailable= workOrderProjectWork.quantityTotal - workOrderProjectWork.quantityDone;
      this.quantityDone= 0;
      this.created= new Date(Date.now());
    }
    else{
      this.id = null;
      this.workOrderProjectWork= null;
      this.referenceNumber= "";
      this.quantityTotal= 0;
      this.quantityAvailable= 0;
      this.quantityDone= 0;
      this.created= new Date(Date.now());
    }

  }
}
