import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import 'rxjs/add/operator/map'
import { Observable } from "rxjs";
import { of } from 'rxjs';
import * as fr_FR from '../../../assets/i18n/fr.json';
import * as en_EN from '../../../assets/i18n/en.json';
import * as en_US from '../../../assets/i18n/en.json';

@Injectable({
  providedIn: 'root'
})
export class CustomTranslateLoaderService implements TranslateLoader {

  /**
   * 
   * @param http 
   * @param localStorageService 
   */
  constructor(
    private http: HttpClient
  ) { }

  /**
   * Implement getTranslation of TranslateLoader
   * Determine where to get traduction file to translate
   * 
   * @param lang 
   * @returns 
   */
  getTranslation(lang: string): Observable<any> {

    let languageConfig;

    if (localStorage.getItem("language") !== null) {
        languageConfig = JSON.parse(localStorage.getItem("language"))
      }

    // SI PAS DE LANGUE EN MEMOIRE OU SI LANGUE EN MEMOIRE MAIS PAS DE LANGUE POUR LE TENANT
    if (!languageConfig || !languageConfig.tenant) {
      switch (lang) {

        case 'fr':
          return of(fr_FR);

        case 'fr-FR':
          return of(fr_FR);

        case 'en':
        return of(en_US);

        case 'en-US':
        return of(en_US);
        
        case 'en-EN':
          return of(en_EN);
          
        default:
          return of(fr_FR);
      }
    }
    else {
      return this.http.get(`http://localhost:3000/${lang}`)
        .map((response: JSON) => {
          console.log(response)
          return response;
        });
    }

  }
}
