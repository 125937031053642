import {MessageService} from 'primeng/api';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MultiSelect} from "primeng/multiselect";
import {FilterService} from "@shared/service/filter.service";
import {DataService} from '@shared/service/data.service';
import { async } from '@angular/core/testing';

@Component({
  selector: 'app-filtre-tag',
  templateUrl: './filtre-tag.component.html',
  styleUrls: ['./filtre-tag.component.css']
})
export class FiltreTagComponent implements OnInit {

  @ViewChild('ms') multiselect: MultiSelect;
  @Output() newArrayFilter = new EventEmitter();
  @Output() selectedListFilter = new EventEmitter();
  @Input() resetTag: boolean = false;
  @Input() typeOfTag: string;
  @Input() refreshTag: boolean;
  displayFilter: boolean = false;
  tags: any[] = [{label: 'Favoris', value: 'favoris', items: []}, {label: 'Tous', value: 'tous', items: []}];
  selectedTags: any[] = [];
  iconChange = "pi custom-icon-tag";
  tagsArray: any[] = []
  isBool: boolean = false;

  constructor(private filterService: FilterService, private messageService: MessageService,private dataService: DataService) {
  }

  ngOnInit(): void {
    this.getTagAll();

  }


  ngOnChanges(): void {

    console.log(this.typeOfTag);
    if (this.resetTag){

      this.selectedTags = [];
      this.changeTag(null);

    }

  }

  arrayFiltered(value: any) {
    this.newArrayFilter.emit(value);
  }

  arrayOfSelectedTags() {
    this.selectedListFilter.emit(this.selectedTags);
  }



  showFilterDialog(): void {
    this.displayFilter = true;
  }

  alphaNumericSortTag = (arr = []) => {
    const sorter = (a:any, b:any) => {
    const isNumber = (v:any) => (+v).toString() === v;
    const aPart = a.label.match(/\d+|\D+/g);
    const bPart = b.label.match(/\d+|\D+/g);

    let i = 0; let len = Math.min(aPart.length, bPart.length);
    while (i < len && aPart[i] === bPart[i]) { i++; };
      if (i === len) {
          return aPart.length - bPart.length;
    };
    if (isNumber(aPart[i]) && isNumber(bPart[i])) {
      return aPart[i] - bPart[i];
    };
    return aPart[i].localeCompare(bPart[i]); };
    arr.sort(sorter);
  };


  getTagAll() {

    this.dataService.currentMessage.subscribe((res) => {

      this.refreshTag = res;

      if(this.refreshTag || !this.refreshTag){

        this.filterService.getAllTagsByTypeOfTag(this.typeOfTag).subscribe(
          (res) => {
            if (res.status === 200) {

                this.tagsArray = res.body;
                this.alphaNumericSortTag(this.tagsArray)
                this.tags.forEach(item => item.items = []);
                for (let i = 0; i < this.tagsArray.length; i++) {

                  if (this.tagsArray[i].bookmarked) {
                    this.tags[0].items.push(this.tagsArray[i]);
                  } else {
                    this.tags[1].items.push(this.tagsArray[i]);
                  }
                }

            }
          },
          (error) => {
            if (error) {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Échec de l’opération : veuillez réessayer',
                });
            }
          },
          () => {
          }
        );
       }

      });

    }


  panelOpen() {

    setTimeout(function () {
      let multiSelection = document.getElementById('multiselectTag');
      let heightOfMultiSelection: any = multiSelection.getElementsByClassName('p-multiselect')[0];
      let offsetHeightMultiselect: any = heightOfMultiSelection.offsetHeight;
      let test: any = document.getElementsByClassName('ng-trigger ng-trigger-overlayAnimation p-multiselect-panel p-component ng-star-inserted')[0];
      test.style.removeProperty("left");
      test.style.cssText = "min-width: 250px; right:0px; z-index: 9000; transform-origin: center top;";
      test.style["top"] = offsetHeightMultiselect + "px";
    }, 10);
    setTimeout(function () {
      let group1 = document.getElementsByClassName("p-multiselect-item-group ng-star-inserted")[0];
      let group2 = document.getElementsByClassName("p-multiselect-item-group ng-star-inserted")[1];
      if (group1) {
        let div1 = document.createElement("div");
        div1.style.cssText = 'height:1px;display:inline-block;width:150px;max-width:150px;margin-left:10px;margin-bottom:3px;background: #818181;'
        group1.appendChild(div1);
      }
      if (group2) {
        let div2 = document.createElement("div");
        div2.style.cssText = 'height:1px;display:inline-block;width:150px;max-width:150px;margin-left:26.5px;margin-bottom:3px;background: #818181;'
        group2.appendChild(div2);
      }
    }, 50);

  }

  changeTag(event: any) {
    setTimeout(function () {
      let multiSelection = document.getElementById('multiselectTag');
      let heightOfMultiSelection: any = multiSelection.getElementsByClassName('p-multiselect')[0];
      let offsetHeightMultiselect: any = heightOfMultiSelection.offsetHeight
      let test: any = document.getElementsByClassName('ng-trigger ng-trigger-overlayAnimation p-multiselect-panel p-component ng-star-inserted')[0];
      test.style["top"] = offsetHeightMultiselect + "px";

    }, 50);
    if (this.selectedTags.length === 0) {
      this.resetArrayFilteredByTag();
      this.arrayOfSelectedTags()
    }

    if (this.selectedTags.length > 0) {
      this.iconChange = "pi custom-icon-close";
      this.getListEntitiesByTag();
      this.arrayOfSelectedTags();
    } else {
      this.iconChange = "pi custom-icon-tag";
    }
  }

  //Detects if the user clicks on the clear button to reset the filter
  onClickElement(event: any) {
    let DOMElement_ = event.target;
    let elementClassesList = DOMElement_.classList;
    if (elementClassesList.contains("custom-icon-close")) {
      this.selectedTags = [];
      this.iconChange = "pi custom-icon-tag";

      this.resetArrayFilteredByTag();
      this.arrayOfSelectedTags()
    }
  }

  chipRemove(event: any) {

    this.selectedTags = this.selectedTags.filter(x => x !== event);
    if (this.selectedTags.length === 0) {
      this.iconChange = "pi custom-icon-tag";
      this.resetArrayFilteredByTag();
      this.arrayOfSelectedTags()
    } else {
      this.getListEntitiesByTag();
      this.arrayOfSelectedTags()
    }

  }

  resetArrayFilteredByTag() {
    this.filterService.resetFilterByTypeOfTag(this.typeOfTag).subscribe(
      (res) => {
        if (res.status === 200) {
          this.arrayFiltered(res.body);
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {
      }
    );
  }

  getListEntitiesByTag() {
    this.filterService.getListEntitiesByTag(this.typeOfTag, this.selectedTags).subscribe(
      req => {
        if (req.status === 200) {
          this.arrayFiltered(req.body);
          console.log(req.body)
        }
      }, error => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      }, () => {
      })
  }
}
