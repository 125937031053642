<app-kober-menu module="kober-skill"></app-kober-menu>
<div class="p-grid p-d-flex p-jc-center p-m-2 p-p-2 main-page margin-b-20">
    <div class="p-grid p-col-12 p-md-11 p-m-3 p-p-3 main-content p-shadow-4" style="margin: 1rem 1rem 10rem 1rem !important;">
      <div class="p-col-12">
     <p-toast></p-toast>
     <p-steps [model]="items" [readonly]="true"></p-steps>
     <router-outlet></router-outlet>
      </div>
    </div>
</div>


