<div
  fxLayout="column"
  fxLayoutAlign="space-between center"
  class="sidenav-container"
  [@onSideNavChange]="sideNavState ? 'open' : 'close'"
>
  <div fxLayout="column" fxLayoutGap="10px" style="height: 100%; width:85%">
    <div class="photo" fxLayoutAlign="start center" style="padding-top: 5%;margin-left: 5%;">
      <button mat-icon-button routerLink="/phoenix/home">
        <mat-icon  svgIcon="home"style="width: 48px; height: 48px; margin-left: 0px;"></mat-icon>
      </button>
      <mat-label *ngIf="!sideNavState" style="color: #A0A0A0; margin-left: 30%; font-size: 20px;">{{ 'parametrage' | translate }}</mat-label>
      <mat-label *ngIf="sideNavState" style="color: #A0A0A0; margin-left: 18%; font-size: 20px;">{{ 'parametrage' | translate }}</mat-label>
      <a routerLink="/phoenix/home">
        <img
          *ngIf="!sideNavState"
          width="50px"
          height="50px"
          src="/assets/logo/Logotype-Partenaires.svg"
        />
      </a>
    </div>

    <div class="user-leftMenu">
      <mat-nav-list>
        <a mat-list-item routerLink="/../phoenix/profile/materialSettings">
          <img
            [ngClass]="{
              'profil-picture': sideNavState,
              'profil-picture-reduced': !sideNavState
            }"
            [src]="
              persona?.photo ? persona?.photo : '/assets/img/default-avatar.png'
            "
            alt="avatar"
          />
          <span *ngIf="sideNavState" class="username">
            <div class="menuFont" *ngIf="persona">
              {{ persona?.firstName }} {{ persona?.lastName }}
            </div>
            <label style="color:#A0A0A0" class="persona">{{ role }}</label>
          </span>
        </a>
      </mat-nav-list>
    </div>
    <mat-divider *ngIf="!sideNavState"></mat-divider>
    <div class="menu">
      <mat-nav-list>
        <a fxLayoutAlign="start center" routerLink="/phoenix/settings/tag" mat-list-item class="menu-list-item">
          <span
            [ngClass]="{
              'menu-item': sideNavState
            }"
            class="icon tags-icon"
          >
          </span>
          <span class="menuFont" [@animateText]="linkText ? 'show' : 'hide'">Tags</span>
        </a>
        <a fxLayoutAlign="start center" routerLink="/phoenix/settings/show-currency" mat-list-item class="menu-list-item">
            <span
              [ngClass]="{
                'menu-item': sideNavState
              }"
              class="icon currency-icon"
            >
            </span>
            <span class="menuFont" [@animateText]="linkText ? 'show' : 'hide'">{{"DEVISE"| translate}}</span>
          </a>
        <div *ifPermission="['wo:listview']">
        <a mat-list-item
           routerLink="phoenix/settings/company"
           *ngIf="!sideNavState"
           [matMenuTriggerFor]="menu"
           #menuTrigger="matMenuTrigger"
           (mouseenter)="menuTrigger.openMenu()"
           aria-label="Example icon-button with a menu">
          <span class="icon company-icon menuFont" ></span>
          <mat-menu  [overlapTrigger]="false" #menu="matMenu" [class]="'hover-menu'" >
            <span (mouseleave)="menuTrigger.closeMenu()" >
              <button routerLink="/phoenix/settings/company" mat-menu-item>
                <span class="menuFont">{{"ENTREPRISES"| translate}}</span>
              </button>
              <button routerLink="/phoenix/settings/company-relationship" mat-menu-item>
                <span class="menuFont"> {{"relations_ENTREPRISES"| translate}}
                </span>
              </button>
            </span>
          </mat-menu>
        </a>
        </div>
        <div *ifPermission="['wo:listview']">
        <mat-expansion-panel
          *ngIf="sideNavState"
          class="mat-elevation-z0"
          [expanded]="step === 0"
          (opened)="setStep(0)"
        >
          <mat-expansion-panel-header  class="menu-list-item" [ngClass]="{
            'selected': (step === 0)
          }">
            <mat-panel-title class="menuFont" >
              <span class="icon company-icon"></span>{{"ENTREPRISES"| translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="panel-body"   >
            <a
                fxLayoutAlign="start center"
             [ngClass]="{
            'selected-blue': router.url === '/phoenix/settings/company'
          }" routerLink="/phoenix/settings/company" mat-list-item class="menu-list-item sub-menu-list-item">
            <span style="margin-left:26px;" class="menuFont"   [@animateText]="linkText ? 'show' : 'hide'"
            >
            {{"ENTREPRISES"| translate}}
            </span>
           </a>
           <!--
            <a [ngClass]="{
            'selected-blue': router.url === '/phoenix/settings/company-relationship'
          }" routerLink="/phoenix/settings/company-relationship" mat-list-item class="menu-list-item sub-menu-list-item">
            <div style="margin-left:26px;" class="menuFont" [@animateText]="linkText ? 'show' : 'hide'"
            >

            {{"relations_ENTREPRISES"|translate}}

        </div>
            </a> -->
          </div>
        </mat-expansion-panel>
      </div>
      <div *ifPermission="['organization:listview']">
        <mat-expansion-panel
          *ngIf="sideNavState"
          class="mat-elevation-z0"
          [expanded]="step === 1"
          (opened)="setStep(1)"

        >
          <mat-expansion-panel-header class="menu-list-item" [ngClass]="{
            'selected': (step === 1)
          }">
            <mat-panel-title fxLayoutAlign="start center" class="menuFont matPanelTeam">
                <span class="icon organisation-icon"></span><span class="text">{{"ORGANISATIONS_HIERARCHIQUES"| translate}}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="panel-body" >
            <!-- <a fxLayoutAlign="start center" [ngClass]="{
            'selected-blue': router.url === '/phoenix/settings/organization-entity'
          }" routerLink="/phoenix/settings/organization-entity" mat-list-item class="menu-list-item sub-menu-list-item">
            <span style="margin-left:26px;" class="menuFont " [@animateText]="linkText ? 'show' : 'hide'"
            *ifPermission='["team:list"]'>

            Entités

            </span>
            </a>
            <a fxLayoutAlign="start center" [ngClass]="{
              'selected-blue': router.url === '/phoenix/settings/organization-hierarchy'
            }" selected-blue routerLink="/phoenix/settings/organization-hierarchy" mat-list-item class="menu-list-item sub-menu-list-item">
              <span  style="margin-left:26px;" class="menuFont " [@animateText]="linkText ? 'show' : 'hide'"
              >
              Hiérarchie

              </span>
              </a> -->
            <!-- <a fxLayoutAlign="start center" [ngClass]="{
              'selected-blue': router.url === '/phoenix/settings/organization-templates'
            }" selected-blue routerLink="/phoenix/settings/organization-templates" mat-list-item class="menu-list-item sub-menu-list-item">
              <span  style="margin-left:26px;" class="menuFont " [@animateText]="linkText ? 'show' : 'hide'"
              >
              Templates
              </span>
            </a> -->
            <a  fxLayoutAlign="start center" [ngClass]="{
                'selected-blue': router.url === '/phoenix/settings/my-organizations'
            }" selected-blue routerLink="/phoenix/settings/my-organizations" mat-list-item class="menu-list-item sub-menu-list-item">
                <span  style="margin-left:26px;" class="menuFont " [@animateText]="linkText ? 'show' : 'hide'">
                     {{"MES_ORGANISATIONS"| translate}}
                </span>
            </a>
            <a fxLayoutAlign="start center" [ngClass]="{
              'selected-blue': router.url === '/phoenix/settings/personas-organizations'
            }" selected-blue routerLink="/phoenix/settings/personas-organizations" mat-list-item class="menu-list-item sub-menu-list-item">
              <span  style="margin-left:26px;" class="menuFont " [@animateText]="linkText ? 'show' : 'hide'" (click)="openDialog()"
              >
             {{ "ORGANISATIONS_PERSONAS_MENU" | translate }}
              </span>
            </a>
          </div>



        </mat-expansion-panel>
      </div>
        <a mat-list-item
           routerLink="phoenix/intervention/wo-list"
           *ngIf="!sideNavState"
           [matMenuTriggerFor]="menu"
           #menuTrigger="matMenuTrigger"
           (mouseenter)="menuTrigger.openMenu()"
           aria-label="Example icon-button with a menu">
           <span class="icon organisation-icon"></span>
          <!--span class="icon person-icon menuFont"></span-->
          <mat-menu [overlapTrigger]="false" #menu="matMenu" [class]="'hover-menu'">
            <span (mouseleave)="menuTrigger.closeMenu()">
              <!-- <button routerLink="/phoenix/settings/organization-entity" *ifPermission='["team:list"]' mat-menu-item>
                <span class="menuFont">Entités
                </span>
              </button>
              <button routerLink="/phoenix/settings/organization-hierarchy" *ifPermission='["team:list"]' mat-menu-item>
                <span class="menuFont">Hierarchie
                </span>
              </button> -->
              <!-- <button routerLink="/phoenix/settings/organization-templates" *ifPermission='["team:list"]' mat-menu-item>
                <span class="menuFont">Templates
                </span>
              </button> -->

              <button routerLink="/phoenix/settings/my-organizations" *ifPermission='["team:list"]' mat-menu-item>
                <span class="menuFont">{{"MES_ORGANISATIONS"| translate}}
                </span>
              </button>
              <button routerLink="/phoenix/settings/organization-templates" *ifPermission='["team:list"]' mat-menu-item>
                <span class="menuFont" (click)="openDialog()"> {{ "ORGANISATIONS_PERSONAS_MENU" | translate }}
                </span>
              </button>
            </span>
          </mat-menu>
        </a>
        <div *ifPermission="['pool:configure']">
          <mat-expansion-panel
            *ngIf="sideNavState"
            class="mat-elevation-z0"
            [expanded]="step === 2"
            (opened)="setStep(2)"

          >
            <mat-expansion-panel-header class="menu-list-item" [ngClass]="{
              'selected': (step === 2)
            }">
              <mat-panel-title fxLayoutAlign="start center" class="menuFont matPanelTeam">
                  <span class="icon metier-icon"></span><span class="text">{{'menu_PARAMETRAGE_METIER'|translate}}</span>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="panel-body" >
              <!-- <a fxLayoutAlign="start center" [ngClass]="{
              'selected-blue': router.url === '/phoenix/settings/organization-entity'
            }" routerLink="/phoenix/settings/organization-entity" mat-list-item class="menu-list-item sub-menu-list-item">
              <span style="margin-left:26px;" class="menuFont " [@animateText]="linkText ? 'show' : 'hide'"
              *ifPermission='["team:list"]'>

              Entités

              </span>
              </a>
              <a fxLayoutAlign="start center" [ngClass]="{
                'selected-blue': router.url === '/phoenix/settings/organization-hierarchy'
              }" selected-blue routerLink="/phoenix/settings/organization-hierarchy" mat-list-item class="menu-list-item sub-menu-list-item">
                <span  style="margin-left:26px;" class="menuFont " [@animateText]="linkText ? 'show' : 'hide'"
                >
                Hiérarchie

                </span>
                </a> -->
              <!-- <a fxLayoutAlign="start center" [ngClass]="{
                'selected-blue': router.url === '/phoenix/settings/organization-templates'
              }" selected-blue routerLink="/phoenix/settings/organization-templates" mat-list-item class="menu-list-item sub-menu-list-item">
                <span  style="margin-left:26px;" class="menuFont " [@animateText]="linkText ? 'show' : 'hide'"
                >
                Templates
                </span>
              </a> -->
              <a *ifPermission="['area:view']" fxLayoutAlign="start center" [ngClass]="{
                  'selected-blue': router.url === '/phoenix/settings/zone'
              }" selected-blue routerLink="/phoenix/settings/zone" mat-list-item class="menu-list-item sub-menu-list-item">
                  <span  style="margin-left:26px;" class="menuFont " [@animateText]="linkText ? 'show' : 'hide'">
                    {{"ZONES"| translate}}
                  </span>
              </a>
              <a *ifPermission="['domain:view']" fxLayoutAlign="start center" [ngClass]="{
                'selected-blue': router.url === '/phoenix/settings/specialites'
              }" selected-blue routerLink="/phoenix/settings/specialites" mat-list-item class="menu-list-item sub-menu-list-item">
                <span  style="margin-left:26px;" class="menuFont " [@animateText]="linkText ? 'show' : 'hide'"
                >
                {{"SPECIALITES"| translate}}
                </span>
              </a>
              <a *ifPermission="['activity:consult']" fxLayoutAlign="start center" [ngClass]="{
                'selected-blue': router.url === '/phoenix/settings/activite'
              }" selected-blue routerLink="/phoenix/settings/activite" mat-list-item class="menu-list-item sub-menu-list-item">
                <span  style="margin-left:26px;" class="menuFont " [@animateText]="linkText ? 'show' : 'hide'"
                >
                {{"ACTIVITES"| translate}}
                </span>
              </a>
              <a fxLayoutAlign="start center" (click)="openHelpDialog()" [ngClass]="{
                'selected-blue': router.url === '/phoenix/settings/pools'
              }" selected-blue routerLink="/phoenix/settings/pools" mat-list-item class="menu-list-item sub-menu-list-item">
                <span  style="margin-left:26px;" class="menuFont " [@animateText]="linkText ? 'show' : 'hide'"
                >
                {{"menu_POOLS"| translate}}
                </span>
              </a>
            </div>
          </mat-expansion-panel>
        </div>
          <a mat-list-item
             routerLink="phoenix/intervention/wo-list"
             *ngIf="!sideNavState"
             [matMenuTriggerFor]="menu"
             #menuTrigger="matMenuTrigger"
             (mouseenter)="menuTrigger.openMenu()"
             aria-label="Example icon-button with a menu">
             <span class="icon metier-icon"></span>
            <mat-menu [overlapTrigger]="false" #menu="matMenu" [class]="'hover-menu'">
              <span (mouseleave)="menuTrigger.closeMenu()">

                <button routerLink="/phoenix/settings/zone" *ifPermission='["team:list"]' mat-menu-item>
                  <span class="menuFont">{{"ZONES"| translate}}
                  </span>
                </button>
                <button routerLink="/phoenix/settings/specialites" *ifPermission='["team:list"]' mat-menu-item>
                  <span class="menuFont"> {{"SPECIALITES"| translate}}
                  </span>
                </button>
                <button routerLink="/phoenix/settings/activite" *ifPermission='["team:list"]' mat-menu-item>
                  <span class="menuFont">{{"ACTIVITES"| translate}}
                  </span>
                </button>
                <button routerLink="/phoenix/settings/pools" *ifPermission='["team:list"]' mat-menu-item>
                  <span class="menuFont" (click)="openHelpDialog()"> {{"menu_POOLS"| translate}}
                  </span>
                </button>
              </span>
            </mat-menu>
          </a>

          <div *ifPermission="['pool:configure']">
            <mat-expansion-panel
              *ngIf="sideNavState"
              class="mat-elevation-z0"
              [expanded]="step === 3"
              (opened)="setStep(3)"

            >
              <mat-expansion-panel-header class="menu-list-item" [ngClass]="{
                'selected': (step === 3)
              }">
                <mat-panel-title fxLayoutAlign="start center" class="menuFont matPanelTeam">
                    <span class="icon logistique-icon"></span><span class="text">{{'menu_param_LOGISTIQUE'|translate}}</span>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="panel-body" >
                <a *ifPermission="['area:view']" fxLayoutAlign="start center" [ngClass]="{
                    'selected-blue': router.url === '/phoenix/settings/stocks'
                }" selected-blue routerLink="/phoenix/settings/stocks" mat-list-item class="menu-list-item sub-menu-list-item">
                    <span  style="margin-left:26px;" class="menuFont " [@animateText]="linkText ? 'show' : 'hide'">
                      {{"menu_param_STOCKS"| translate}}
                    </span>
                </a>
              </div>
            </mat-expansion-panel>
          </div>
            <a mat-list-item
               routerLink="phoenix/intervention/wo-list"
               *ngIf="!sideNavState"
               [matMenuTriggerFor]="menu"
               #menuTrigger="matMenuTrigger"
               (mouseenter)="menuTrigger.openMenu()"
               aria-label="Example icon-button with a menu">
               <span class="icon metier-icon"></span>
              <mat-menu [overlapTrigger]="false" #menu="matMenu" [class]="'hover-menu'">
                <span (mouseleave)="menuTrigger.closeMenu()">

                  <button routerLink="/phoenix/settings/stocks" *ifPermission='["team:list"]' mat-menu-item>
                    <span class="menuFont">{{"STOCKS"| translate}}
                    </span>
                  </button>
                </span>
              </mat-menu>
            </a>

      </mat-nav-list>
    </div>
  </div>

  <span class="spacer"></span>
  <div fxLayout="column" fxLayoutGap="10px" style="width: 85%">
    <div>
      <mat-nav-list>
        <a  (click)="redirectSb()" mat-list-item class="menu-list-item">
            <span [ngClass]="{
              'menu-item': sideNavState
            }" class="icon gear-icon menu-item">
            </span>

            <span class="menuFont" [@animateText]="linkText ? 'show' : 'hide'"
            >
            {{"ADVANCED_PARAMETRAGE"| translate}}
            </span>
          </a>
        <a routerLink="/phoenix/home" mat-list-item class="menu-list-item">
            <span [ngClass]="{
              'menu-item': sideNavState
            }" class="icon quit-param-icon">
            </span>
          <span class="menuFont" [@animateText]="linkText ? 'show' : 'hide'"
          >
            {{"quitter_PARAMETRAGE"| translate}}
            </span>
        </a>
        <a mat-list-item class="menu-list-item" (click)="logout()">
          <span [ngClass]="{
            'menu-item': sideNavState
          }" class="icon logout-icon">
          </span>
          <span class="menuFont" [@animateText]="linkText ? 'show' : 'hide'"
          >
          {{"deconnexion"| translate}}
          </span>
        </a>
        <a (click)="onSinenavToggle()" mat-list-item class="menu-list-item">
          <span
            *ngIf="sideNavState"
            class="menu-item icon close-icon"
          >
          </span>
          <span
            *ngIf="!sideNavState"
            class="icon open-icon"
          >
          </span>
          <span class="menuFont" [@animateText]="linkText ? 'show' : 'hide'"
          >
          {{"masquer_le_menu"| translate}}
          </span>
        </a>
        <span style="display: flex; justify-content: center; align-items: center; margin-top: 25px; font-size: small">
          Version {{appVersion}}
        </span>
      </mat-nav-list>
    </div>


  </div>
</div>
