import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Materiel } from '@data/schema/Materiel';
import { MaterialWork, SkillWork, Work } from '@data/schema/Work';
import { MaterialService } from '@data/service/material.service';
import { WorkService } from '@data/service/work.service';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { WorkDetailGeneralComponent } from 'src/app/kober-services/components/work/work-detail-general/work-detail-general.component';
import { WorkDetailCostComponent } from 'src/app/kober-services/components/work/work-detail-cost/work-detail-cost.component';
import { WorkDetailSkillComponent } from 'src/app/kober-services/components/work/work-detail-skill/work-detail-skill.component';
import { WorkDetailConsumableComponent } from 'src/app/kober-services/components/work/work-detail-consumable/work-detail-consumable.component';
import { WorkDetailToolsComponent } from 'src/app/kober-services/components/work/work-detail-tools/work-detail-tools.component';
import { Currency } from '@data/schema/Currency';
import { LocalStorageService } from '@shared/service/local-storage.service';
import { ComponentCanDeactivate } from '@core/guard/component-can-deactivate.guard';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-new-work',
  templateUrl: './new-work.component.html',
  styleUrls: ['./new-work.component.css'],
})
export class NewWorkComponent implements OnInit {

  @ViewChild(WorkDetailGeneralComponent)
  private workGeneral!: WorkDetailGeneralComponent;

  @ViewChild(WorkDetailCostComponent)
  private workCost!: WorkDetailCostComponent;

  @ViewChild(WorkDetailSkillComponent)
  private workSkill!: WorkDetailSkillComponent;

  @ViewChild(WorkDetailConsumableComponent)
  private workConsumable!: WorkDetailConsumableComponent;

  @ViewChild(WorkDetailToolsComponent)
  private workTools!: WorkDetailToolsComponent;

  public submited: BehaviorSubject<boolean>;
  //@HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return (!this.work.isDefined() || this.submited.value  ? true : false);
  }

  work: Work = new Work();
  listSkillSelected: SkillWork[];
  listConsumableSelected: MaterialWork[] = [];
  listToolsSelected: MaterialWork[] = [];
  listMaterial: Materiel[];
  listConsumable: Materiel[];
  listTools: Materiel[];
  items: MenuItem[];
  currency : Currency;

  constructor(
    public materialService : MaterialService,
    public messageService : MessageService,
    public workService : WorkService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private localStorage: LocalStorageService
  ) {
    this.currency = new Currency(JSON.parse(this.localStorage.getLocalStorageItem("currency")));
    this.submited = new BehaviorSubject(false);
   }

  ngOnInit(): void {

    this.materialService.ListMateriel().subscribe(
      (res) => {
        if (res.status === 200) {
          this.listMaterial = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {
        this.listConsumable = this.materialService.getConsummable(this.listMaterial)
        this.listTools = this.materialService.getTools(this.listMaterial)
      }
    );

    this.items = [
      {label:'Prestations', routerLink:'/phoenix/work'},
      {label:"Création d'une prestation", styleClass:'ariane-current'}
    ];

    this.isCurrencyAlreadyConfigured();

  }

  isCurrencyAlreadyConfigured() {
    if (!this.currency?.currencyCode) {
      this.confirmationService.confirm({
        header: 'Action impossible',
        message:
          "La devise n’est pas paramétrée.",
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        acceptLabel: "J'ai compris",
        rejectVisible: false,
        closeOnEscape: false,
        dismissableMask: false,
        accept: () => {
          this.redirectTo('/phoenix/work');
        },
        reject: () => {
          this.redirectTo('/phoenix/work');
        },

      });
    } else {
      this.work.currency =  this.currency?.currencyCode;

    }
  }

  submit(){
    // test quand un des deux tableau est pas rempli
    let material = this.listConsumableSelected.concat(this.listToolsSelected)
    this.work.materialsList = material
    let validForm = true;

    if (this.workGeneral.nameFieldValidator()){
      validForm = false;
    }

    if (this.workSkill.checkAllConditions()){
      validForm = false;
    }
    if (this.workConsumable.checkAllConditions()){
      validForm = false;
    }
    if (this.workTools.checkAllConditions()){
      validForm = false;
    }
    if (!validForm){
      this.messageService.add({
        severity: 'error',
        summary: 'Échec de l’opération : veuillez remplir tous les champs',
      });
    }
    else{
      this.save();
    }
  }

  save(){
    this.workService.createWork(this.work).subscribe(
      (res) => {
        if (res.status === 200) {
          this.messageService.add({
            severity: 'success',
            summary: 'Opération réussie',
          });
        }
      },
      (error) => {
        if (error) {
          if (error.status === 500) {
            this.messageService.add({severity: 'error', summary: 'Échec de l’opération : veuillez réessayer'});
          }
           if (error.status === 409) {
             this.messageService.add({severity: 'error', summary: 'Cet élément existe déjà'});
          }
        }
      },
      () => {
        this.gotoList();
      }
    );
  }

  back(){

    if(!this.work.isDefined() || this.submited.value){
      this.redirectTo('/phoenix/work')
    }
    else{

    this.confirmationService.confirm({
      header: 'Confirmation',
      message:
        'Vous allez quitter la page, les données seront perdues.' +
        '<br>' +
        'Voulez vous continuer ?',
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button-outlined',
      acceptIcon: 'null',
      rejectIcon: 'null',
      acceptLabel: 'Quitter',
      rejectLabel: 'Annuler',
      accept: () => {
          this.redirectTo('/phoenix/work')
        },
      });
    }
  }

  // redirect to list page
  gotoList() {
    this.redirectTo('/phoenix/work');
  }

  redirectTo(uri:string){
    this.submited.next(true);
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
 }

}
