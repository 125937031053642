import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree}
  from '@angular/router';
import {Observable} from 'rxjs';
import {LoginService} from "@core/service/login.service";
import {TokenStorageService} from "@core/service/token-storage.service";
import {MessageService} from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

  constructor(private loginService: LoginService, private tokenStorageService: TokenStorageService, private router: Router, private messageService: MessageService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const expectedPermission = route.data.allowedPermissions;
    if (!this.loginService.isLogin()) {
      this.messageService.add({severity: 'info', summary: 'Connectez-vous', detail: 'Veuillez-vous connecter'});
      this.router.navigate(['']);
      return false;
    }
    if (this.tokenStorageService.isTokenExpired()) {
      this.messageService.add({severity: 'warn', summary: 'Session expirée', detail: 'Veuillez-vous reconnecter'});
      this.router.navigate(['']);
      return false;
    }

    let permission = this.tokenStorageService.decodeToken().scope;
    let permissionAccess = permission.split(" ");
    let intersection = expectedPermission.filter(x => permissionAccess.includes(x));
    // trouvé les permissions qui correspondent
    if (intersection.length === 0) {
      this.messageService.add({severity: 'warn', summary: 'Accès refusé', detail: 'Vous devez disposer d’une autorisation pour effectuer cette action'});
      this.router.navigate(['/phoenix/home']);
      return false;
    }
    return true;
  }
}
