<div class="p-col-12">
  <!--  SELECT THIS DIV IF ONE COLUMN FORM AND DELETE THE ONE ABOVE
      <div class="p-col-12 p-d-flex p-jc-center">-->
  <h3>Coût</h3>
</div>
<div class="p-grid p-col-12 p-d-flex">
  <!--<div class="p-col-4">
    <div class="p-fluid">
      <div class="p-grid">
        <div class="p-col-10">
          <label for="firstname4" class="p-mb-2 p-md-2 p-mb-md-0">
            Devise
          </label>
        </div>
        <div class="p-col-10">
          <p-dropdown
            id="type"
            [(ngModel)]="work.currency"
            [options]="currency"
            placeholder="Selectioner devise"
            optionLabel="code"
            optionValue="code"
            [showClear]="true"
            [filter]="true" filterBy="code"
            (onHide)="currencyFieldValidator()"
          ></p-dropdown>
          <p-message
            *ngIf="errorCurrency"
            severity="error"
            text="Champ obligatoire si montant saisi."
          ></p-message>
        </div>
      </div>
    </div>
  </div> -->
  <div class="p-col-4">
    <div class="p-fluid">
      <div class="p-grid">
        <div class="p-col-10">
          <label for="firstname4" class="p-mb-2 p-md-2 p-mb-md-0">
            Montant
          </label>
        </div>
        <div class="p-col-10">
          <p-inputNumber [(ngModel)]="work.cost" inputId="cost"></p-inputNumber>
          <!--<p-inputNumber (onBlur)="costFieldValidator()" *ngIf="work.currency" [(ngModel)]="work.cost" inputId="cost" mode="currency" [currency]="work.currency"></p-inputNumber> -->
          <p-message
            *ngIf="errorCost"
            severity="error"
            text="Champ obligatoire."
          ></p-message>
        </div>
      </div>
    </div>
  </div>
</div>
