import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { SkillService } from '@data/service/skill.service';
import { PersonaService } from '@data/service/persona.service';
import { MessageService, SortEvent } from 'primeng/api';
import { CustomSortService } from '@shared/service/custom-sort.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Persona } from '@data/schema/Persona';

@Component({
  selector: 'app-show-persona-with-skills-expired',
  templateUrl: './show-persona-with-skills-expired.component.html',
  styleUrls: ['./show-persona-with-skills-expired.component.css']
})
export class ShowPersonaWithSkillsExpiredComponent implements OnInit {

  selectedValue: string = 'all';
  id: number;
  persona: Persona;
  fullDescription: string = '';
  constructor(
    private SkillService: SkillService,
    private PersonaService: PersonaService,
    private CustomSortService: CustomSortService,
    private messageService: MessageService,
    public dialogRef: MatDialogRef<ShowPersonaWithSkillsExpiredComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { 
      //this.filterData();
    }
    

  ngOnInit(): void {
    this.id = this.data.id;

    this.PersonaService.getPersonaWithSkill(this.id).subscribe(
      (res) => {
        if (res.status === 200) {
          this.persona = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      }
    );
  }

  naturalSortPersona(event: SortEvent) {
    this.CustomSortService.naturalSort(event);
  }

  showFullDescription(description: string) {
    this.fullDescription = description;
  }

  onMouseEnter(skill: any): void {
      skill.isHovered = this.isDescriptionLongEnough(skill.skill.description);
  }

  onMouseLeave(skill: any): void {
      skill.isHovered = false;
  }

  isDescriptionLongEnough(description: string): boolean {
      return description.length > 50;
  }

}
