import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Persona } from '@data/schema/Persona';

@Component({
  selector: 'app-persona-card',
  templateUrl: './persona-card.component.html',
  styleUrls: ['./persona-card.component.scss']
})
export class PersonaCardComponent implements OnInit {
  @Input() persona: any;
  connectedPersona: Persona;
  @Input() editPersonaMode: boolean = false;
  @Input() poolMode: boolean = false;
  // determine which persona to select
  @Input() selectedPersona : number;
  @Input() selectedPost : number;
  isPersonaCardSelected : boolean = false;
  @Input() allpersonaSelected : boolean;
  @Input() listOfSelectedPersonaCard : any [] = [];
  isPersonaPostSelected: boolean;
  @Input() profiles: boolean = false;
  @Output() deletePersona = new EventEmitter();
  @Input() count: number;
  @Input() personaCount: number;
  @Input() responsibleTechnician : number;
  @Input() noProfiles: boolean = false;
  @Input() teamScope : boolean;
  @Input() displayTeams : boolean;
  @Input() teamDisplay : boolean;
  @Input() teamCreation : boolean;
  @Output() deleteTechnician = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
    this.connectedPersona =  JSON.parse(sessionStorage.getItem('persona'));
  }
 selectCard(card:number){
    let counter = this.personaCount;
    counter ++;
    if(counter <= this.count){
      this.isPersonaCardSelected = !this.listOfSelectedPersonaCard.some((persona) => persona.id === card);
    } else if (this.isPersonaCardSelected){
      this.isPersonaCardSelected = !this.listOfSelectedPersonaCard.some((persona) => persona.id === card);
    }
 }
 isBorder(): boolean {
  if(this.isPersonaCardSelected && this.personaCount <= this.count){
    return true;
  } return  false
 }
 childClick() {
  this.deletePersona.emit();
}
childTechClick() {
  this.deleteTechnician.emit();
}
}
