import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import {EnvService} from "@core/service/env.service";
import {
  Intervention,
  InterventionDetail,
} from '@data/schema/intervention/interventionSchema';
import * as Papa from 'papaparse';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InterventionApiService {

  public apiURL = this.environment?.readConfig()?.urlApiGateway + "woquery/";


  constructor(private http: HttpClient,private environment: EnvService
  ) {
  }

  public getWorkOrderList(pools? :any,filter?: any, sort?: any, limit?: number, offset?: number, column_filters?:any,search?: any): Observable<any> {

    //filter -> obj and sort-> objSort
    const params = new HttpParams().set('filters', filter)
                                   .set('sorts', sort)
                                   .set('limit', limit)
                                   .set('offset', offset)
                                   .set('column_filters', column_filters)
                                   .set('search',search);

    return this.http.post(this.apiURL + 'wo-list',  pools ? {pools} : {}, {params: params})
  }
  public getWorkOrderListCSV(pools? :any,filter?: any, sort?: any, limit?: number, offset?: number, column_filters?:any,search?: any, projection?: any): Observable<any> {
    const params = new HttpParams().set('filters', filter)
                                   .set('sorts', sort)
                                   .set('limit', limit)
                                   .set('offset', offset)
                                   .set('column_filters', column_filters)
                                   .set('search',search)
                                   .set('projection', projection);

  
    return this.http.post(this.apiURL + 'wo-list/csv',  pools ? {pools} : {}, {params: params, responseType: 'text'}).pipe(
      map(data => {
        const parsedData = Papa.parse(data, {header: true}).data;
        return parsedData;
      })
    );
  }

  public getDistinctValues(distinct?:any,filter?: any,column_filters?:any): Observable<any> {
    const params = new HttpParams().set('distinct', distinct)
                                    .set('filters', filter)
                                    .set('column_filters', column_filters);

    return this.http.get(this.apiURL + 'column-values' , {params: params})

  }

  public getAutocompleteValues(filter?: any, sort?: any, distinct?: any, column_filters?:any): Observable<any> {
    const params = new HttpParams().set('filters', filter)
                                   .set('sorts', sort)
                                   .set('distinct', distinct)
                                   .set('column_filters', column_filters);

    return this.http.get(this.apiURL + 'column-values' , {params: params})
  }

  public getWorkOrdersCountByFilters(filter: any, pools?:any): Observable<any> {
    const params = new HttpParams().set('filters', filter);

    return this.http.post(this.apiURL + 'wo-list/count', pools ? {pools} : {} , {params: params})
  }

  public getWorkOrdersCountByFiltersAndSearch(filter: any, search: any, pools?:any): Observable<any> {
    const params = new HttpParams().set('filters', filter).set('search', search);

    return this.http.post(this.apiURL + 'wo-list/count', pools ? {pools} : {} , {params: params})
  }

  public getWorkOrderDetail(internalNumber: String): Observable<any> {
      return this.http.get(this.apiURL + 'wo-detail/' + internalNumber, {observe: 'response' as 'body'})

  }

  public getWorkOrderFromList(internalNumber: String) : Observable<any> {
    return this.http.get(this.apiURL + 'wo-list/getOne/' + internalNumber, {observe: 'response' as 'body'})
  }

  public getWorkOrderHistory(internalNumber: String, sort?: any) : Observable<any> {
    const params = new HttpParams().set('sorts', sort)
    return this.http.get(this.apiURL + 'history/' + internalNumber,{params: params})
  }

  public canUpdateTeam(id: any) : Observable<any> {
    return this.http.get(this.apiURL + 'team/can-be-updated/'+ id, {observe: 'response' as 'body'})
  }

}
