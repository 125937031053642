import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Persona } from '@data/schema/Persona';
import { Skill } from '@data/schema/Skill';
import { MessageService} from 'primeng/api';
import { PersonaService } from '@data/service/persona.service';
import { SkillService } from '@data/service/skill.service';
import {DialogService} from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-assign-skills-to-personas',
  templateUrl: './personas-of-skill-detail.component.html',
  styleUrls: ['./personas-of-skill-detail.component.css'],
})
export class PersonasOfSkillDetailComponent implements OnInit {

  skill: Skill;
  listPersona: Persona[] = []

  constructor(
    public dialogService: DialogService,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    public personaService: PersonaService,
    public skillService: SkillService,
    public translate: TranslateService,
  ) {}

  ngOnInit() {
    this.skill = this.config.data.selectedSkill

    this.personaService.getPersonaBySkillId(this.skill.id).subscribe(
      (res) => {
        if (res.status === 200) {
          this.listPersona = res.body;
          for (let persona of this.listPersona){
            this.alphaNumericSort(persona.listSkill)
          }
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {

      }
    );

  }

  alphaNumericSort = (arr = []) => {
    const sorter = (a:any, b:any) => {
    const isNumber = (v:any) => (+v).toString() === v;
    const aPart = a.skill.name.match(/\d+|\D+/g);
    const bPart = b.skill.name.match(/\d+|\D+/g);

    let i = 0; let len = Math.min(aPart.length, bPart.length);
    while (i < len && aPart[i] === bPart[i]) { i++; };
      if (i === len) {
          return aPart.length - bPart.length;
    };
    if (isNumber(aPart[i]) && isNumber(bPart[i])) {
      return aPart[i] - bPart[i];
    };
    return aPart[i].localeCompare(bPart[i]); };
    arr.sort(sorter);
  };

  getValidity(year: number, month: number, day: number){
    var message = ""
    if (year>0){
      message += year + " " + this.translate.instant('ANNEE_S')
    }
    if (month>0){
      if (year>0){
        message += ", "
      }
      message += month + " " + this.translate.instant('MOIS_S')
    }
    if (day>0){
      if (year > 0 || month > 0){
        message += ", "
      }
      message += day + " " + this.translate.instant('JOUR_S')
    }
    return message;
  }

 }





