import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { env } from './environments/environment';

if (env.deployment || env.production) {
  enableProdMode();
}

if (env.production) {
  enableProdMode();
  if(window){
    window.console.log=function(){};
   }
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
