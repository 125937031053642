import { OnInit, Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CompanyService } from '@data/service/company.service';
import { Company } from '@data/schema/Company';
import { FileUpload, FileUploadModule } from 'primeng/fileupload';
import { Observable, BehaviorSubject } from 'rxjs';
import { ComponentCanDeactivate } from '@core/guard/component-can-deactivate.guard';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'app-new-company',
  templateUrl: './new-company.component.html',
  styleUrls: ['./new-company.component.css'],
})
export class NewCompanyComponent implements OnInit, ComponentCanDeactivate {
  public submited: BehaviorSubject<boolean>;

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return this.submited.value || !this.company.isDefined() ? true : false;
  }

  company: Company = new Company();
  companys: any[];
  filterCompany: string[];
  errorPhone: boolean = false;
  outputName: string[]; // What the autocompletion is gonna show
  outputAddress: string[]; // What the autocompletion is gonna show
  listCompany: Company[];

  errorNameRequired: boolean = false;
  wantToCreateMore: boolean = false;

  //Gestion Photo
  display: boolean = false;
  imageBase64: any = '';
  croppedImage: string;
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = true;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};

  constructor(
    private messageService: MessageService,
    private companyService: CompanyService,
    private confirmationService: ConfirmationService,
    private router: Router
  ) {
    this.submited = new BehaviorSubject(false);
  }

  ngOnInit(): void {
    this.companyService.getListAllEnableCompany().subscribe(
      (res) => {
        if (res.status === 200) {
          this.listCompany = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => { }
    );

    this.companys = [
      { name: 'Autre', value: 'Autre' },
      { name: 'Filliale', value: 'Filliale' },
      { name: 'Fournisseur', value: 'Fournisseur' },
      { name: 'Kyntus', value: 'Kyntus' },
      { name: 'Maison mère', value: 'Maison mère' },
      { name: 'Partenaire', value: 'Partenaire' },
      { name: 'Sous-traitant', value: 'Sous-traitant' },
    ];
  }

  submit() {
    if (this.company.name === '') {
      this.errorNameRequired = true;
    }
    this.submited.next(true);
    this.companyService.createCompany(this.company).subscribe(
      (data) => {
        if (data.status === 200) {
          this.messageService.add({
            severity: 'success',
            summary: 'Opération réussie',
            detail: data.message,
          });
          //console.log(data)
          this.company = new Company();
          this.gotoList();
        }
      },
      (error) => {
        if (error.status === 409) {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur',
            detail: "Échec de l'opération. Cet élément existe déjà",
          });
        }
      },
      () => { }
    );
  }

  onSelection(event, fileUpload: FileUpload) {
    this.display = true;
    let fileReader = new FileReader();

    let file = event.files[0];

    fileReader.readAsDataURL(file);
    fileReader.onload = (e) => {
      // Store the base64 string of the image file
      this.imageBase64 = fileReader.result.toString();
    };
  }

  onUpload() {
    this.company.logo = this.croppedImage;
    this.display = false;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  cropperReady() {
    // cropper ready
  }

  imageLoaded() {
    // show cropper
    this.display = true;

  }

  loadImageFailed() {
    // show message
  }

  deletePhoto() {
    this.company.logo = null;
  }

  clear(fileUpload: FileUpload) {
    fileUpload.clear();
  }

  validPhoneFormat(event: any) {
    if (
      event.target.value.charAt(0) === '+' &&
      !isNaN(event.target.value.substr(1))
    ) {
      this.errorPhone = false;
    } else if (!isNaN(event.target.value)) {
      this.errorPhone = false;
    } else {
      this.errorPhone = true;
    }
  }

  filterName(event) {
    // filter to get all persona where lastname = event.query and map to get their persona.lastname
    this.outputName = this.listCompany
      .filter((c) => c.name.toLowerCase().startsWith(event.query.toLowerCase()))
      .map((company) => company.name);
  }

  companyType(event) {
    this.filterCompany = this.companys
      .filter((c) => c.name.toLowerCase().startsWith(event.query.toLowerCase()))
      .map((company) => company.name);
  }

  confirm() {
    this.confirmationService.confirm({
      header: 'Confirmation',
      message:
        'Vous allez quitter la page, les données seront perdues.' +
        '<br>' +
        'Voulez vous continuer ?',
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button-outlined',
      acceptIcon: 'null',
      rejectIcon: 'null',
      acceptLabel: 'Quitter',
      rejectLabel: 'Annuler',
      accept: () => {
        this.router.navigate(['/phoenix/company']);
      },
    });
  }

  filterAddress(event) {
    // filter to get all persona where lastname = event.query and map to get their persona.lastname
    this.outputAddress = this.listCompany
      .filter((c) =>
        c.address.toLowerCase().startsWith(event.query.toLowerCase())
      )
      .map((company) => company.address);
  }

  nameFieldValidator(event: any) {
    if (event.target.value === '') {
      this.errorNameRequired = true;
    } else {
      this.errorNameRequired = false;
    }
  }

  wantToCreate(isChecked: any) {
    this.wantToCreateMore = isChecked.checked;
  }

  gotoList() {
    //console.log(this.wantToCreateMore);
    if (this.wantToCreateMore === true) {
      this.router.navigate(['phoenix/new-company']);
    } else {
      this.router.navigate(['/phoenix/company']);
    }
  }
}
