import {Component, HostListener, OnInit} from '@angular/core';
import {PersonaService} from '@data/service/persona.service';
import {Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {Skill} from '@data/schema/Skill';
import {SkillService} from '@data/service/skill.service';
import {Persona, PersonaSkills} from '@data/schema/Persona';
import { ComponentCanDeactivate } from '@core/guard/component-can-deactivate.guard';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-persona-assign-skill',
  templateUrl: './assign-skill.component.html',
  styleUrls: ['./assign-skill.component.css'],
})
export class AssignSkillComponent implements OnInit, ComponentCanDeactivate {

  public submited: BehaviorSubject<boolean>;

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    console.log(this.submited.value)
    return (this.submited.value ? true : false)
  }

  skill: Skill[];
  skillSelected: Skill[];
  assignedSkills: PersonaSkills[] = [];
  persona: Persona = new Persona();
  typeOfTag: string = "skill";

  constructor(
    private messageService: MessageService,
    private skillService: SkillService,
    public personaService: PersonaService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.submited = new BehaviorSubject(false);
  }

  filterArrayByTag(newArrayFiltered: any) {
    this.skill = newArrayFiltered;
  }

  ngOnInit() {
    this.persona = this.personaService.personaInformation.persona;
    // this.assignedSkills = this.personaService.personaInformation.assignedSkills;
    // console.log(this.assignedSkills);

    this.skillService.getListSkillWithTags().subscribe(
      (res) => {
        if (res.status === 200) {
          this.skill = res.body;
          this.skill.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          );
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary:  this.translate.instant('toast_ECHEC_OPERATION_REESSAYER'),
          });
        }
      },
      () => {
      }
    );
  }

  prevPage() {
    // this.personaService.personaInformation.assignedSkills = this.assignedSkills
    this.router.navigate(['/phoenix/new-persona/persona']);
  }

  save() {
    this.persona.listSkill = this.assignedSkills;
    //console.log(this.persona)
    this.personaService.createPersona(this.persona).subscribe(
      (data) => {
        if (data.status === 200) {
          this.messageService.add({
            severity: 'success',
            summary:  this.translate.instant('toast_OPERATION_REUSSI'),
          });
          //console.log(data)
          this.persona = new Persona();
          this.personaService.personaInformation.persona = this.persona;
          this.gotoList();
        }
      },
      (error) => {
        if (error.status === 409) {
          this.messageService.add({
            severity: 'error',
            summary: this.translate.instant('EMAIL_DEJA_UTILISE') ,
          });
        }
      },
      () => {
      }
    );
  }

  gotoList() {
    this.redirectTo('/phoenix/persona')
  }

  redirectTo(uri: string) {
    this.submited.next(true);
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
      this.router.navigate([uri]));
  }

  // Calculate end date based on parameters values
  calculateEndDate(date, days, months, years) {
    var d = new Date(date);

    d.setDate(d.getDate() + days);
    d.setFullYear(d.getFullYear() + years);
    d.setMonth(d.getMonth() + +months);

    return d;
  }

  // auto calculate
  startDateOnChange(index: number) {
    let days = this.skillSelected[index].day_duration
    let month = this.skillSelected[index].month_duration
    let year = this.skillSelected[index].year_duration

    this.addTimeZoneOffsetToCalendarTime(this.assignedSkills[index].startDate)
    this.assignedSkills[index].endDate = this.calculateEndDate(this.assignedSkills[index].startDate, days, month, year)
  }

  addTimeZoneOffsetToCalendarTime(event) {
    /*To be removed when primeng fixes the bug related to p calendar or when migrating to another library*/
    event.setTime(event.getTime() - (new Date().getTimezoneOffset() * 60 * 1000));
    /**/
  }

  // Add skill of assignedSkill based on checkbox event
  assignSkills(event: any) {
    let skill = event.data;
    var now = new Date(Date.now());
    let personaSkill = {
      id: null,
      idPersona: null,
      idSkill: skill.id,
      skill: skill,
      startDate: now,
      endDate: this.calculateEndDate(
        now,
        skill.day_duration,
        skill.month_duration,
        skill.year_duration
      )
    };
    this.assignedSkills.push(personaSkill);
  }

  removeSkills(event: any) {
    this.assignedSkills = this.assignedSkills.filter(x => x !== event.data);
  }

  // Removed skill from checkbox and assignedSkills when removing a chip
  removeSkill(event: any, skill: Skill) {
    this.skillSelected = this.skillSelected.filter(x => x !== skill);
    this.assignedSkills = this.assignedSkills.filter(
      (item) => item.idSkill !== skill.id
    );
  }

  getValidity(year: number, month: number, day: number) {
    var message =  this.translate.instant('VALIDITE') +" : "
    if (year > 0) {
      message += year +  this.translate.instant('AN_S') + "(s)"
    }
    if (month > 0) {
      if (year > 0) {
        message += ", "
      }
      message += month +  this.translate.instant('MOIS_S')
    }
    if (day > 0) {
      if (year > 0 || month > 0) {
        message += ", "
      }
      message += day +  this.translate.instant('JOUR_S')  + "(s)"
    }
    return message;
  }
}
