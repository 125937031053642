<app-kober-menu module="kober-services"></app-kober-menu>
<div class="p-grid p-d-flex p-ml-2 p-pl-2 main-page">
  <div class="p-mt-3 p-pt-3 p-ml-3 p-pl-3">
    <p-breadcrumb *ngIf="editMode" [model]="items.update"></p-breadcrumb>
    <p-breadcrumb *ngIf="!editMode" [model]="items.show"></p-breadcrumb>
  </div>
</div>
<div class="p-grid p-d-flex p-jc-center p-m-2 p-p-2 main-page margin-b-20">
  <div
    *ngIf="editMode"
    class="p-grid p-col-11 p-m-3 p-p-3 main-content p-shadow-2"
  >
    <app-project-form-general
      [(project)]="project"
      class="p-col-12"
    ></app-project-form-general>
  </div>
  <div *ngIf="!editMode" class="p-grid p-col-11 no-spacing-left no-spacing-right">
    <div *ngIf="!editMode" class="p-col-6 p-nogutter no-spacing-left">
      <div class="main-content custom-card p-shadow-2 p-d-flex p-ai-start">
        <app-project-show-general
          [(project)]="project"
          class="p-col-12"
        ></app-project-show-general>
      </div>
    </div>
    <div *ngIf="!editMode" class="p-col-6 p-nogutter no-spacing-right">
      <div class="main-content custom-card p-shadow-2 p-d-flex p-ai-start">
        <app-work-order-show-advancement
          [(project)]="project"
          class="p-col-12"
        >
        </app-work-order-show-advancement>
      </div>
    </div>
  </div>
  <div class="p-grid p-col-11 p-m-3 p-p-3 main-content p-shadow-2">
    <h3 *ngIf="!editMode">Informations</h3>

   
    <app-project-form-detail
      [(editMode)]="editMode"
      [(project)]="project"
      class="p-col-12">
    </app-project-form-detail>


  </div>

</div>

<p-confirmDialog
  baseZIndex="5"
  appendTo="body"
  styleClass="main-page"
  [style]="{ width: '60%' }"
></p-confirmDialog>

<p-sidebar
  class="p-text-right"
  [style]="{ height: '13vh' }"
  [visible]="true"
  [blockScroll]="true"
  [dismissible]="false"
  [showCloseIcon]="false"
  [modal]="false"
  position="bottom"
>
  <div>
    <!--RETOUR A LA LISTE-->
    <p-button
      *ngIf="editMode"
      styleClass="p-button-outlined p-mr-2"
      (click)="back()"
      label="Annuler"
    ></p-button>
    <p-button
      *ngIf="!editMode"
      styleClass="p-button-outlined p-mr-2"
      (click)="returnList()"
      label="Retour"
    ></p-button>
    <ng-templete *ifRoles='["administrateur",  "admin_metier","admin_responsable_DPO","manager_operationnel"]'>
    <p-button [disabled]="!disabled"
      styleClass="p-mr-2"
      (click)="delete()"
      label="Supprimer"
    ></p-button>
    </ng-templete>
    <ng-templete *ifRoles='["administrateur",  "admin_metier","admin_responsable_DPO","manager_operationnel"]'>
    <p-button
      *ngIf="!editMode"
      styleClass="p-mr-2"
      (click)="update()"
      label="Modifier"
    ></p-button>
    </ng-templete>
    <ng-templete *ifRoles='["administrateur",  "admin_metier","admin_responsable_DPO","manager_operationnel"]'>
    <p-button
      *ngIf="editMode"
      styleClass="p-mr-2"
      (click)="submit()"
      label="Valider"
    ></p-button>
    </ng-templete>
  </div>
</p-sidebar>
