<app-kober-menu module="kober-setting"></app-kober-menu>
<div class="p-grid p-d-flex p-jc-center p-m-2 p-p-2 main-page margin-b-20">
  <div class="p-grid p-col-12 p-md-11 p-m-3 p-p-3">
    <app-setting-currency [(setting)]="setting"></app-setting-currency>
  </div>
  <div class="p-grid p-col-12 p-md-11 p-m-3 p-p-3 main-content p-shadow-2">
    <div class="p-grid p-col-12 p-d-flex">
      <div class="p-col-6">
        <div class="p-fluid">
          <div class="p-grid">
            <div class="p-col-12">
              <h2>Devise</h2>
            </div>
            <div class="p-col-12 p-md-10 p-pl-5 p-pr-5 breakWord">
              <div class="p-fluid">
                <div class="p-grid">
                  <div class="p-col-6">
                    <p *ngIf="currency?.currencyCode">{{currency?.currencyLabel}} ({{currency?.currencyCode}})</p>
                    <p *ngIf="!currency?.currencyCode">La devise n'est pas paramétrée.</p>
                  </div>
                  <div class="p-col-6">
                    <button class="btnCurrency" pButton  type="button" label="Paramétrer" (click)="update()"></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-confirmDialog baseZIndex="5" styleClass="main-page" [style]="{ width: '60%' }"></p-confirmDialog>
</div>
