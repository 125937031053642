import { DatePipe, registerLocaleData } from '@angular/common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PersonaSkills } from '@data/schema/Persona';
import localeFr from '@angular/common/locales/fr';
import { TranslateService } from '@ngx-translate/core';
registerLocaleData(localeFr);

@Component({
  selector: 'app-tech-dialog',
  templateUrl: './tech-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./tech-dialog.component.scss']
})
export class TechDialogComponent implements OnInit {

  technician : any;
  listHabilitation: PersonaSkills[] = [];
  listHabilitationAffiche: PersonaSkills[] = [];
  listSavoirFaire: PersonaSkills[] = [];
  listSavoirEtre: PersonaSkills[] = [];
  panelOpenState = false;
  pipe = new DatePipe('fr');
  currentDate : Date = new Date();

  constructor(@Inject(MAT_DIALOG_DATA) public indata: any,
  private translate: TranslateService) { }

  ngOnInit(): void {
    this.technician = this.indata
    this.listHabilitation = this.technician.skills.filter(skills => skills.type === "HABILITATION");
    this.listSavoirEtre = this.technician.skills.filter(skills => skills.type === "SAVOIR_ETRE");
    this.listSavoirFaire = this.technician.skills.filter(skills => skills.type === "SAVOIR_FAIRE");

  }

  transformDate(date){
    return this.pipe.transform(date,'shortDate');
  }

   checkValidDate(endDate:any, validity:any){
    if (validity && new Date(endDate).getTime() < this.currentDate.getTime()){
      return false;
    }
    return true;
  }


}
