import { Pipe, PipeTransform } from '@angular/core';
import { Textbox, Item } from '@data/schema/intervention/Form';

@Pipe({
  name: 'isSeparator'
})
export class IsSeparatorPipe implements PipeTransform {

  transform(item : Item): item is Textbox {
    return item.itemType?.code === "Separator";
  }

}
