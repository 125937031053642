<div class="p-grid p-col-12 p-d-flex">
  <p-tabView>
    <p-tabPanel header="Prestations">
      <app-work-form-list-works *ngIf="editMode" [(works)]="listWork"  [(project)]="project"></app-work-form-list-works>
      <app-work-form-selected-works [(editMode)]="editMode" [(project)]="project"></app-work-form-selected-works>
    </p-tabPanel>
    <p-tabPanel header="Matériel">
      <app-work-form-list-materials [listProjectWork]="project.projectWorks" [editMode]="editMode"></app-work-form-list-materials>
    </p-tabPanel>
    <p-tabPanel header="Budget">
      <app-work-form-list-cost [listProjectWork]="project.projectWorks" [currency]="currency" [projectWorkByCompany]="project.projectCompaniesListWorkOrder"></app-work-form-list-cost>
    </p-tabPanel>

    <p-tabPanel *ngIf="project.id" header="OT">
      <app-work-from-list-work-order [listProjectWorkOder]="project.projectWorkOrders" [(project)]="project"></app-work-from-list-work-order>
    </p-tabPanel>
  </p-tabView>
</div>
