<h1 fxLayoutAlign="space-between center" class="head dialog-spacing-one" mat-dialog-title>
  {{'crud_pool_onboarding_PARAMETRER_POOL' | translate}}
      <button
        mat-icon-button
        aria-label="close dialog"
        mat-dialog-close
        class="close-button"
      >
        <mat-icon>close</mat-icon>
      </button>
  </h1>
  <div mat-dialog-content>
      <div class="dialog-spacing">
          <mat-stepper orientation="vertical" #stepper [selectedIndex]="data">
              <ng-template matStepperIcon="edit">
              </ng-template>
              <mat-step>
                  <form>
                      <ng-template matStepLabel>{{'crud_pool_onboarding_ACTIVITE_NOM'| translate}}</ng-template>
                      <p>{{'crud_pool_onboarding_ACTIVITE_NOM_DETAILS' | translate}}</p>
                      <div>
                          <button mat-raised-button color="primary" matStepperNext>{{ "etape_SUIVANT" | translate }}</button>
                      </div>
                  </form>
              </mat-step>
              <mat-step>
                  <form>
                      <ng-template matStepLabel>{{'crud_pool_onboarding_ZONE_SPECIALITES'| translate}}</ng-template>
                      <p>{{'crud_pool_onboarding_ZONE_SPECIALITES_DETAILS' | translate}}</p>
                      <div>
                          <button mat-stroked-button color="primary" matStepperPrevious>{{ "etape_PRECEDENT" | translate }}</button>
                          <button style="margin-left:0.5em" mat-raised-button color="primary" matStepperNext>{{ "etape_SUIVANT" | translate }}</button>
                      </div>
                  </form>
              </mat-step>
              <mat-step>
                <form>
                    <ng-template matStepLabel>{{'crud_pool_etape_RESSOURCES' | translate}}</ng-template>
                    <p>{{'crud_pool_onboarding_RESSOURCES_DETAILS' | translate}}</p>
                    <p>{{'crud_pool_onboarding_RESSOURCES_PERSONAS_ELIGIBLES' | translate}}</p>
                    <div>
                        <button mat-stroked-button color="primary" matStepperPrevious>{{ "etape_PRECEDENT" | translate }}</button>
                        <button style="margin-left:0.5em" mat-dialog-close mat-raised-button color="primary" matStepperNext>{{ "etape_DEMARRE" | translate }}</button>
                    </div>
                </form>
            </mat-step>
          </mat-stepper>
      </div>
  </div>
