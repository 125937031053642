import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Persona, PersonaSkills } from '@data/schema/Persona';
import { Skill } from '@data/schema/Skill';
import { MessageService} from 'primeng/api';
import { PersonaService } from '@data/service/persona.service';
import { SkillService } from '@data/service/skill.service';
import {DialogService} from 'primeng/dynamicdialog';


@Component({
  selector: 'app-assign-skills-to-personas',
  templateUrl: './assign-skills-to-personas-detail.component.html',
  styleUrls: ['./assign-skills-to-personas-detail.component.css'],
})
export class AssignSkillsToPersonasDetailComponent implements OnInit {

  persona: Persona;
  selectedSkill:   PersonaSkills[] = [];
  filteredSkill: Skill[];

  listHabilitation:  PersonaSkills[] = []
  listSavoirFaire: PersonaSkills[] = []
  listSavoirEtre:  PersonaSkills[] = []

  defaultDate: Date = new Date();
  minDateValue: Date = new Date();
  currentDate : Date = new Date();

  constructor(
    private ref: DynamicDialogRef,
    public dialogService: DialogService,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    public personaService: PersonaService,
    public skillService: SkillService,
  ) {}

  ngOnInit() {

    this.persona = this.config.data.persona
    this.selectedSkill = this.config.data.selectedSkill
    this.alphaNumericSort(this.selectedSkill)
    this.filteredSkill = this.config.data.filteredSkill

    for (const skill of this.selectedSkill){

      if (skill.skill.type === "HABILITATION"){

        this.listHabilitation.push(skill)

      }
      if (skill.skill.type === "SAVOIR_ETRE"){

        this.listSavoirEtre.push(skill)

      }
      if (skill.skill.type === "SAVOIR_FAIRE"){

        this.listSavoirFaire.push(skill)

      }
    }

    console.log("savoir faire",this.listSavoirFaire)
    console.log("savoir etre",this.listSavoirEtre)
    console.log("Habilitation",this.listHabilitation)

  }


  save(){
    let listModifsSkills = [];
    let modifSkills = this.listHabilitation.concat(this.listSavoirEtre, this.listSavoirFaire)
    listModifsSkills.push(modifSkills);
    listModifsSkills.push([]);
    listModifsSkills.push([]);

    this.personaService.updateAllSkills(listModifsSkills).subscribe(
      (res) => {
        if (res.status === 200) {
          //console.log(res.body);
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },

      () => {

        this.messageService.add({
          severity: 'success',
          summary: 'Opération réussie',
        });

        this.ref.close();
      }
    );
  }

  checkValidDate(personaSkill: PersonaSkills){
    if (personaSkill.skill.type === 'HABILITATION' && personaSkill.skill.validity && new Date(personaSkill.endDate).getTime() < this.currentDate.getTime()){
      return false;
    }
    return true;
  }

  // auto calculate
  startDateOnChange(personaSkill : PersonaSkills){
    let days = personaSkill.skill.day_duration
    let month = personaSkill.skill.month_duration
    let year = personaSkill.skill.year_duration

    personaSkill.endDate = this.calculateEndDate(personaSkill.startDate, days, month, year)
  }

  alphaNumericSort = (arr = []) => {
    const sorter = (a:any, b:any) => {
    const isNumber = (v:any) => (+v).toString() === v;
    const aPart = a.skill.name.match(/\d+|\D+/g);
    const bPart = b.skill.name.match(/\d+|\D+/g);

    let i = 0; let len = Math.min(aPart.length, bPart.length);
    while (i < len && aPart[i] === bPart[i]) { i++; };
      if (i === len) {
          return aPart.length - bPart.length;
    };
    if (isNumber(aPart[i]) && isNumber(bPart[i])) {
      return aPart[i] - bPart[i];
    };
    return aPart[i].localeCompare(bPart[i]); };
    arr.sort(sorter);
  };

  // Calculate end date based on parameters values
  calculateEndDate(date, days, months, years) {
    var d = new Date(date);

    d.setDate(d.getDate() + days);
    d.setFullYear(d.getFullYear() + years);
    d.setMonth(d.getMonth() + +months);

    return d;
  }

}
