import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Category } from '@data/schema/Category';
import { Materiel } from '@data/schema/Materiel';
import { MaterialWork } from '@data/schema/Work';
import { Unit } from '@data/schema/Unit';
import { MessageService } from 'primeng/api';
import { MaterialService } from '@data/service/material.service';

@Component({
  selector: 'app-work-detail-consumable',
  templateUrl: './work-detail-consumable.component.html',
  styleUrls: ['./work-detail-consumable.component.css']
})
export class WorkDetailConsumableComponent implements OnInit {

  @Input() listConsumable: Materiel[];
  @Input() listConsumableSelected: MaterialWork[];
  @Output() listConsumableSelectedChange = new EventEmitter();
  listMaterials : Materiel[][] = [];
  typeOfTag: string = "material";
  arrayFiltered: Materiel[];
  indexOfListMaterial: number = 0;
  changesWorkEvent = 1;
  change() {
    this.listConsumableSelectedChange.emit(this.listConsumableSelected);
  }

  errorNotFilled = false;
  errorDuplicate = false;

  constructor(private messageService: MessageService, private materialService: MaterialService) { }
  
  ngOnChanges(changes: SimpleChanges) {
    this.getListMaterials();
  }

  ngOnInit(): void {
  }

  filterArrayByTag(newArrayFiltered: any) {
    this.arrayFiltered = this.materialService.getConsummable(newArrayFiltered);
    this.alphaNumericSort(this.arrayFiltered);
    this.listMaterials[this.indexOfListMaterial] = this.arrayFiltered;

  }

  
  alphaNumericSort = (arr = []) => {
      const sorter = (a, b) => {
      const isNumber = (v) => (+v).toString() === v;
      const aPart = a.label.match(/\d+|\D+/g);
      const bPart = b.label.match(/\d+|\D+/g);
      let i = 0; let len = Math.min(aPart.length, bPart.length);
      while (i < len && aPart[i] === bPart[i]) { i++; };
        if (i === len) {
            return aPart.length - bPart.length;
      };
      if (isNumber(aPart[i]) && isNumber(bPart[i])) {
        return aPart[i] - bPart[i];
      };
      return aPart[i].localeCompare(bPart[i]); };
      arr.sort(sorter);
  };

  getListMaterials() {
    this.materialService.ListMateriel().subscribe(
      (res) => {
        if (res.status === 200) {
          this.arrayFiltered = this.materialService.getConsummable(res.body);
          this.alphaNumericSort(this.arrayFiltered);
          if(this.listMaterials.length === 0) {
            this.listMaterials[0] = this.materialService.getConsummable(this.arrayFiltered);
          }
          if ((this.listConsumableSelected.length > 0 && this.changesWorkEvent === 2) || (this.listConsumableSelected.length > 0 && this.changesWorkEvent === 1)) {
            for (let i = 0; i < this.listConsumableSelected.length; i++) {
              this.listMaterials[i] = this.materialService.getConsummable(res.body);
            }
            this.indexOfListMaterial = this.listMaterials.length;
            this.listMaterials[this.indexOfListMaterial] = this.materialService.getConsummable(this.arrayFiltered);
          }
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {
        this.changesWorkEvent ++;
      }
    );
  }
  addConsumable(){
    
    if (!this.checkAllConditions()){
      let consumable = {} as Materiel
      consumable.label = ""
      consumable.categorie = {} as Category
      consumable.unit = {} as Unit
      consumable.description = ""
      let newConsumable = new MaterialWork();
      newConsumable.materialDto = consumable;

      this.listConsumableSelected.push(newConsumable)
      //this.alphaNumericSort(this.listConsumableSelected);
      this.indexOfListMaterial++;
      this.listMaterials[this.indexOfListMaterial] = this.materialService.getConsummable(this.arrayFiltered);
    }
    
  }

  selectConsumable(){

    this.checkAllConditions()
  }

  deleteConsumable(index: number){
    this.indexOfListMaterial--;
    this.listConsumableSelected.splice(index,1)
    this.listMaterials.splice(index, 1);
    this.checkAllConditions()
  }

  checkLastFilled(){

    if(this.listConsumableSelected.length>0){
      let lastElement = this.listConsumableSelected.slice(-1)[0];
      if (lastElement && !lastElement.materialDto.label ){
        return false;
      }
    }
    return true;
  }

  checkDuplicate(){

    let result =this.listConsumableSelected.map(function(material){
      return material.materialDto.id
    })

    return result.some(function(item, material){
      return result.indexOf(item) != material
    })
  }

  checkIsDuplicate(material: Materiel){
    let result = this.listConsumableSelected.filter(s => s.materialDto.id == material.id)
    return (result.length > 1)
  }

  checkAllConditions(){
    if(this.checkDuplicate()){
      this.errorDuplicate = true;
    }
    else{
      this.errorDuplicate = false;
    }

    if(this.checkLastFilled()){
      this.errorNotFilled = false;
    }
    else{
      this.errorNotFilled = true;
    }

    return (this.errorDuplicate || this.errorNotFilled)
  }

  


}
