<div class="p-col-12">

  <div class="p-grid p-col-12">
    
    <div class= "p-col-9">
      <h3>{{ project.name }}</h3>
    </div>

    <div class="p-col-3">
      <span  *ngIf="project.status === 'TODO'" class="p-field p-col status-todo">
        A faire
      </span>

      <span *ngIf="project.status === 'DOING'" class="p-field p-col status-doing">
        En cours
      </span>

      <span *ngIf="project.status === 'DONE'" class="p-field p-col status-done">
      Terminé
      </span>
    </div>

  </div>

  <div class="p-col-12  reference">
    {{ project.referenceNumber }}
  </div>

  <div class="p-col-12 description">
    {{ project.description }}
  </div>
</div>
