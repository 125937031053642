import { Injectable } from '@angular/core';
import { WorkOrderAnnouncer } from '@data/schema/intervention/WorkOrderAnnouncer';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WorkOrderAnnouncerService {

  private workOrderListUpdatedList = new Subject<WorkOrderAnnouncer>();
  private workOrderListUpdatedSource = new Subject<WorkOrderAnnouncer>();
  private workOrderDetailsUpdatedSource = new Subject<WorkOrderAnnouncer>();

  workOrderListUpdated$ = this.workOrderListUpdatedSource.asObservable();
  workOrderDetailsUpdated$ = this.workOrderDetailsUpdatedSource.asObservable();
  workOrderListUpdatedList$ = this.workOrderListUpdatedList.asObservable();

  constructor() { }

  announceWorkOrderListUpdate(workOrderAnnouncer: WorkOrderAnnouncer) {
    this.workOrderListUpdatedSource.next(workOrderAnnouncer);
  }
  announceWorkOrderDetailsUpdate(workOrderAnnouncer: WorkOrderAnnouncer) {
    this.workOrderDetailsUpdatedSource.next(workOrderAnnouncer);
  }
  announceWorkOrderList(workOrderAnnouncer: WorkOrderAnnouncer) {
    this.workOrderListUpdatedList.next(workOrderAnnouncer);
  }
}


