<app-kober-menu module="kober-skill"></app-kober-menu>
<div class="p-d-flex p-jc-center p-m-2 p-p-2 main-page">
  <div class="p-grid p-jc-center">
    <div class="p-col-9 p-nogutter p-jc-center p-m-3 p-p-3 main-content p-shadow-4">
      <h2>{{ 'personas_competences' | translate }}</h2>
      <div>
        <div>
          <p-panel [showHeader]="false">
              <ng-container *ngIf="!listAllPersonas">
                <p-table
                  #dt1
                  [value]="virtual"
                  class="table"
                  [scrollable]="true"
                  scrollHeight="40vh"
                  [paginator]="true"
                  [rows]="10"
                  styleClass="p-datatable-sm"
                  [showCurrentPageReport]="true"
                  currentPageReportTemplate="Affichage de 0 à 0 entrées sur 0"
                  [rowsPerPageOptions]="[10, 25, 50]"
                  [globalFilterFields]="[
                    'lastName',
                    'firstName',
                    'middleName',
                    'publicName',
                    'position'
                  ]"
                >
                  <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                      <span class="p-input-icon-left p-ml-auto">
                        <i class="pi pi-search"></i>
                        <input
                          pInputText
                          type="text"
                          (input)="
                            dt1.filterGlobal($event.target.value, 'contains')
                          "
                          placeholder="{{ 'recherche' | translate }}"
                        />
                      </span>
                    </div>
                  </ng-template>

                  <ng-template pTemplate="header">
                    <tr>
                      <th class="table-10"></th>
                      <th id="lastName" pSortableColumn="lastName">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          {{ 'NOM_FAMILLE' | translate }}
                          <div class="p-d-flex p-flex-column p-ai-center">
                            <p-columnFilter type="text" field="lastName" display="menu"></p-columnFilter>
                            <p-sortIcon field="lastName"></p-sortIcon>
                          </div>
                        </div>
                      </th>
                      <th id="firstName" pSortableColumn="firstName">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          {{ "PRENOM" | translate }}
                          <div class="p-d-flex p-flex-column p-ai-center">
                            <p-columnFilter type="text" field="firstName" display="menu"></p-columnFilter>
                            <p-sortIcon field="firstName"></p-sortIcon>
                          </div>
                        </div>

                      </th>
                      <th id="middleName" pSortableColumn="middleName">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          {{ 'DEUXIEME_NOM'| translate }}
                          <div class="p-d-flex p-flex-column p-ai-center">
                            <p-columnFilter type="text" field="middleName" display="menu"></p-columnFilter>
                            <p-sortIcon field="middleName"></p-sortIcon>
                          </div>
                        </div>
                      </th>
                      <th id="publicName" pSortableColumn="publicName">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          {{ 'NOM_USAGE'| translate }}
                        <div class="p-d-flex p-flex-column p-ai-center">
                          <p-columnFilter type="text" field="publicName" display="menu"></p-columnFilter>
                          <p-sortIcon field="publicName"></p-sortIcon>
                        </div>
                        </div>
                      </th>
                      <th id="publicName" pSortableColumn="position">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          {{ 'POSTE'| translate }}
                          <div class="p-d-flex p-flex-column p-ai-center">
                            <p-columnFilter type="text" field="position" display="menu"></p-columnFilter>
                            <p-sortIcon field="position"></p-sortIcon>
                          </div>
                        </div>
                      </th>
                      <th class="table-5"></th>
                    </tr>
                  </ng-template>

                  <ng-template pTemplate="body" let-virtual>
                    <tr>
                      <td>
                        <p-skeleton></p-skeleton>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </ng-container>
              <ng-container *ngIf="listAllPersonas">
                <p-table
                  dataKey="lastName"
                  #dt1
                  (sortFunction)="naturalSort($event)"
                  [customSort]="true"
                  sortField="lastName"
                  [sortOrder]='1'
                  dataKey="lastName"
                  [value]="listAllPersonas"
                  class="table"
                  [scrollable]="true"
                  scrollHeight="40vh"
                  [paginator]="true"
                  [rows]="10"
                  styleClass="p-datatable-sm"
                  [showCurrentPageReport]="true"
                  currentPageReportTemplate="{{'PAGINATION'|translate}}"
                  [rowsPerPageOptions]="[10, 25, 50]"
                  [globalFilterFields]="[
                    'lastName',
                    'firstName',
                    'middleName',
                    'publicName',
                    'position'
                  ]"
                >
                  <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                      <!-- <button (click)="confirm($event)" class="p-button-outlined filter-clear" pButton icon="pi pi-filter" label="Colonnes"></button> -->
                      <button pButton label="{{'FILTRES'|translate}}" class="p-button-outlined filter-clear" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>
                      <span class="p-input-icon-left p-ml-auto">
                        <span class="p-input-icon-left p-ml-auto">
                          <i class="pi pi-search"></i>
                          <input
                            pInputText
                            type="text"
                            (input)="
                              dt1.filterGlobal($event.target.value, 'contains')
                            "
                            placeholder="{{ 'recherche' | translate }}"
                          />
                        </span>
                      </span>
                    </div>
                  </ng-template>

                  <ng-template pTemplate="header">
                    <tr>
                      <th class="table-10"></th>
                      <th class="table-10"></th>
                      <th id="lastName" pSortableColumn="lastName">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          {{ 'NOM_FAMILLE' | translate }}
                          <div class="p-d-flex p-flex-column p-ai-center">
                            <p-columnFilter type="text" field="lastName" display="menu"></p-columnFilter>
                            <p-sortIcon field="lastName"></p-sortIcon>
                          </div>
                        </div>
                      </th>
                      <th id="firstName" pSortableColumn="firstName">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          {{ "PRENOM" | translate }}
                          <div class="p-d-flex p-flex-column p-ai-center">
                            <p-columnFilter type="text" field="firstName" display="menu"></p-columnFilter>
                            <p-sortIcon field="firstName"></p-sortIcon>
                          </div>
                        </div>

                      </th>
                      <th id="middleName" pSortableColumn="middleName">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          {{ 'DEUXIEME_NOM'| translate }}
                          <div class="p-d-flex p-flex-column p-ai-center">
                            <p-columnFilter type="text" field="middleName" display="menu"></p-columnFilter>
                            <p-sortIcon field="middleName"></p-sortIcon>
                          </div>
                        </div>
                      </th>
                      <th id="publicName" pSortableColumn="publicName">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          {{ 'NOM_PUBLIC' | translate }}
                        <div class="p-d-flex p-flex-column p-ai-center">
                          <p-columnFilter type="text" field="publicName" display="menu"></p-columnFilter>
                          <p-sortIcon field="publicName"></p-sortIcon>
                        </div>
                        </div>
                      </th>
                      <th id="publicName" pSortableColumn="position">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          {{ 'POSTE'| translate }}
                          <div class="p-d-flex p-flex-column p-ai-center">
                            <p-columnFilter type="text" field="position" display="menu"></p-columnFilter>
                            <p-sortIcon field="position"></p-sortIcon>
                          </div>
                        </div>
                      </th>
                      <th class="table-10"></th>
                    </tr>
                  </ng-template>

                  <ng-template
                    pTemplate="body"
                    let-listAllPersonas
                    let-expanded="expanded"
                  >
                    <tr>
                      <td class="table-10">
                        <div class="p-d-flex p-ai-center">
                          <p-checkbox
                            name="groupname"
                            (onChange)="selectPersona($event, listAllPersonas)"
                            [value]="listAllPersonas"
                          ></p-checkbox>
                          <button
                            type="button"
                            pButton
                            pRipple
                            [pRowToggler]="listAllPersonas"
                            class="
                              p-button-text p-button-rounded p-button-plain
                            "
                            [icon]="
                              expanded
                                ? 'pi pi-chevron-down'
                                : 'pi pi-chevron-right'
                            "
                          ></button>
                        </div>
                      </td>
                      <td class="table-10">
                        <p-avatar styleClass="p-mr-2" size="xlarge" shape="circle"><img style="object-fit: cover; " src="{{ listAllPersonas?.photo }}"
                        /></p-avatar>
                      </td>
                      <td>
                        {{ listAllPersonas.lastName }}
                      </td>
                      <td>
                        {{ listAllPersonas.firstName }}
                      </td>
                      <td>
                        {{ listAllPersonas.middleName }}
                      </td>
                      <td>
                        {{ listAllPersonas.publicName }}
                      </td>
                      <td>
                        <div class="p-grid p-d-flex">
                          <ng-container *ngFor="let post of listAllPersonas.listProfil">
                            <p-chip  label="{{post.name}}" class="postChip"></p-chip>
                          </ng-container>
                        </div>
                      </td>
                      <td class="table-10">
                        <button (click)="show(listAllPersonas)" pButton pRipple type="button" icon="pi pi-search" class="p-button-rounded p-button-text"></button>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="rowexpansion" let-listAllPersonas>
                    <tr>
                      <td colspan="8">
                        <span *ngIf="listAllPersonas.listSkill.length === 0">
                          <p-message severity="warn" text="Aucune compétence pour ce persona" styleClass="p-mr-2"></p-message>
                        </span>
                        <span *ngFor="let skill of listAllPersonas.listSkill, let i = index">
                          <p-tag styleClass="chip-custom-basic p-mb-1 p-mr-1" *ngIf="
                            skill.skill.type === 'HABILITATION' &&
                            checkValidDate(skill)
                          ">
                            {{ skill.skill.name }}
                          </p-tag>
                          <p-tag styleClass="chip-custom-basic p-mb-1 p-mr-1" *ngIf="skill.skill.type === 'SAVOIR_ETRE'">{{ skill.skill.name }}</p-tag>
                          <p-tag styleClass="chip-custom-basic p-mb-1 p-mr-1" *ngIf="skill.skill.type === 'SAVOIR_FAIRE'">{{ skill.skill.name }}</p-tag>
                        </span>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </ng-container>
          </p-panel>
        </div>
      </div>
    </div>
    <div class="p-col-2 p-jc-center p-m-3 p-p-3 main-content p-shadow-4 skills-col">
      <h2> {{ 'COMPETENCES'| translate }}</h2>
      <span *ngFor="let skill of skillsToDisplay">
        <p-chip
          [label]="skill.name" styleClass="p-m-1 chip-custom-basic">
        </p-chip>
        <!-- <p-chip
          *ngIf="skill.type === 'HABILITATION'"
          [label]="skill.name" styleClass="p-m-2 chip-info">
        </p-chip>
        <p-chip
          *ngIf="skill.type === 'SAVOIR_ETRE'"
          [label]="skill.name" styleClass="p-m-2 chip-success">
        </p-chip>
        <p-chip
          *ngIf="skill.type === 'SAVOIR_FAIRE'"
          [label]="skill.name" styleClass="p-m-2 chip-warning">
        </p-chip> -->
      </span>
    </div>
  </div>
</div>
<p-confirmPopup></p-confirmPopup>
