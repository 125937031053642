
<div class="p-grid p-col-12 p-d-flex">
  <div class="p-col-4">
    <div class="p-fluid">
      <div *ngIf="(workOrder?.status === 'DOING')" class="p-col-10">
        <h3 class="title">{{ workOrder.name }}</h3>
        <span *ngIf="workOrder.status === 'DOING'" class="p-field p-col status-doing">
          En cours
        </span>
        </div>
      <div class="p-grid" *ngIf="!(workOrder?.status === 'DOING')">
        <div class="p-col-10">
          <label for="name" class="p-mb-2 p-md-2 p-mb-md-0"> Nom * </label>
        </div>

        <div class="p-col-10">

          <p-autoComplete
            id="name"
            [(ngModel)]="workOrder.name"
            (ngModelChange)="change()"
            [suggestions]="outputName"
            (completeMethod)="filterName($event)"
            (onKeyUp)="nameFieldValidator()"
            [minLength]="1"
          >

          </p-autoComplete>
            <p-message
              *ngIf="errorNameRequired"
              severity="error"
              text="Champ obligatoire."
            ></p-message>

        </div>

      </div>
    </div>
  </div>

  <div class="p-col-4">
    <div class="p-fluid">
      <div class="p-grid">
        <div class="p-col-10">
          <label for="name" class="p-mb-2 p-md-2 p-mb-md-0">
            Lié à un projet
          </label>
        </div>
        <div class="p-col-10 p-mt-3">
          <p-inputSwitch [readonly]="true" [(ngModel)]="workOrder.hasProject" (ngModelChange)="change()" (onChange)="handleSwitch($event)"></p-inputSwitch>
        </div>
      </div>
    </div>
  </div>

  <div class="p-col-4">
    <div class="p-fluid">
      <div class="p-grid">
        <div class="p-col-10">
          <label for="name" class="p-mb-2 p-md-2 p-mb-md-0">
            {{'ENTREPRISE'|translate}}
          </label>
        </div>
        <div class="p-col-10">
          <p-dropdown
            
            *ngIf="!workOrder.id || !workOrder.attachedToCompany && workOrder.status ==='TODO'"
            id="entreprise"
            placeholder="Sélectionner l'entreprise"
            [(ngModel)]="workOrder.company"
            [options]="listCompany"
            optionLabel="name"
            dataKey="id"
            [filter]="true"
            filterBy="name"
            [showClear]="true"
            (ngModelChange)=clearCompany($event)
            (onHide)="selectCompany()"
          ></p-dropdown>
          <p-dropdown
            *ngIf="workOrder.id && workOrder.attachedToCompany && workOrder.status ==='TODO'"
            id="entreprise"
            dataKey="id"
            optionLabel="name"
            name="name"
            [(ngModel)]="workOrder.company"
            [options]="listCompany"
            [filter]="true"
            filterBy="name"
            (onHide)="selectCompany()"
          ></p-dropdown>
          <div *ngIf="workOrder.status ==='DOING'" class="p-col-4 project">
            {{ workOrder.company.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="p-grid p-col-12 p-d-flex">
  <div class="p-col-4">
    <div class="p-fluid">
      <div class="p-grid">
        <div class="p-col-10">
          <label for="description" class="p-mb-2 p-md-2 p-mb-md-0">
            Description
          </label>
        </div>
        <div class="p-col-10">
          <textarea [(ngModel)]="workOrder.description" (ngModelChange)="change()" [rows]="4" pInputTextarea> </textarea>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="workOrder.hasProject" class="p-col-4">
    <div class="p-fluid">
      <div class="p-grid">

        <div class="p-col-10">
          <label for="description" class="p-mb-2 p-md-2 p-mb-md-0">
            Nom du projet
          </label>
        </div>

        <div class="p-col-10">
        
          <p-dropdown
            *ngIf="!workOrder.id && !isRedirectLocalStorage"
            id="project"
            [(ngModel)]="workOrder.project"
            (ngModelChange)="change()"
            [options]="listProject"
            placeholder="Sélectionner le projet"
            optionLabel="name"
            [filter]="true"
            filterBy="name"
            (onHide)="selectProject()"
          >
          </p-dropdown>
          
         
          <div *ngIf="workOrder.id && !isRedirectLocalStorage" class="p-col-4 project">
             {{workOrder.project.name}}
          </div>

          <div *ngIf="isRedirectLocalStorage" class="p-col-4 project">
            {{ projectLocalStorageName }}
          </div>

          <p-message
              *ngIf="errorProjectRequired"
              severity="error"
              text="Champ obligatoire."
            ></p-message>
        </div>

      </div>
    </div>
  </div>
</div>
