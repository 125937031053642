import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import {EnvService} from "@core/service/env.service";

@Injectable({
  providedIn: 'root'
})
export class CreationTeamService {

  public apiURL = this.environment?.readConfig()?.urlApiGateway + "organization/";

  constructor(private http: HttpClient, private environment: EnvService) { }

  public createTeam(team: FormGroup): Observable<any> {
    return this.http.post(this.apiURL + 'team/create', team, {
      observe: 'response' as 'body',
    });
  }
  public updateTeam(team: any): Observable<any> {
    return this.http.put(this.apiURL + 'team/update', team, {
      observe: 'response' as 'body',
    });
  }


  public deleteTeam(id: number): Observable<any> {
    return this.http.delete(this.apiURL + "team/delete/" + id, {observe: 'response' as 'body'});
  }

  /**
 * Checks if a technician is part of team
 * @returns Boolean
 */
   public isTechPartOfATeam(partyRoleID): Observable<any> {
    return this.http.get(this.apiURL + 'technician/is-part-of-team/' + partyRoleID, {
      observe: 'response' as 'body',
    });
  }



}



