import { Work } from "./Work";
import { WorkOrder } from "./WorkOrder";
import {Company} from "./Company";
import { Cost } from "./Cost";

export class Project {
    id: number;
    name:string;
    description : string;
    referenceNumber : string;
    status : string;
    hasCompany : boolean;
    created : Date;
    projectWorks : ProjectWork[];
    projectWorkOrders : ProjectWorkOrder[];
    projectCompaniesListWorkOrder : ProjectCompaniesListWorkOrder[];

    constructor(){
        this.id= null;
        this.name ="";
        this.description ="";
        this.referenceNumber = null;
        this.hasCompany = false;
        this.status = "TODO";
        //this.created = new Date(Date.now());
        this.projectWorks =[];
        this.projectWorkOrders = [];
    }
    public isDefined(){
      return (
              this.name ||
              this.description ||
              this.referenceNumber
              )
    }
}

export class ProjectWork{

    id : number;
    quantityTotal : number;
    quantityFree : number;
    comment : string;
    created : Date;
    project: Project;
    work : Work;

    constructor(){
        this.id = null;
        this.quantityTotal = 1;
        this.quantityFree = 1;
        this.comment = '';
        this.created = null;
        this.work = null;

    }

}

export class ProjectWorkOrder{

    id : number;
    project : Project;
    workOrder : WorkOrder;
    attachedToCompany: boolean;
    company: Company;

    constructor(){
        this.id = null;
        this.project = null;
        this.workOrder = null;
        this.attachedToCompany = null;
        this.company = null;
    }

}

export class ProjectCompaniesListWorkOrder{
    company: Company;
    listWorkOrderCompanyDto: any[]
    listWorksCompany: any[]

    constructor(init?: Partial<ProjectCompaniesListWorkOrder>){
        Object.assign(this, init);
    }
}

export class ProjectCompaniesProjectWork{
    company: Company;
    listWork: ProjectWork[];
    globalCost: Cost;

    constructor(init?: Partial<ProjectCompaniesProjectWork>){
        Object.assign(this, init);
    }


}
