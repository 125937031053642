<app-kober-menu module="kober-services"></app-kober-menu>
<div class="p-grid p-d-flex p-ml-2 p-pl-2 main-page">
  <div class="p-mt-3 p-pt-3 p-ml-3 p-pl-3">
    <p-breadcrumb [model]="items"></p-breadcrumb>
  </div>
</div>
<div class="p-d-flex p-jc-center p-m-2 p-p-2 main-page">
  <div class="p-grid p-ai-center vertical-container p-jc-center">

    <div class="p-col-12 p-md-11 p-jc-center p-m-3 p-p-3 main-content p-shadow-4">
      <div class="p-grid p-jc-between p-p-3">
        <h2>Contrats</h2>
        <div>

          <button *ifRoles='["administrateur","admin_metier","admin_responsable_DPO"]'
                  routerLink="/phoenix/new-contract" class="p-mr-5 p-mt-5" pButton type="button" label="Créer"></button>
        </div>
      </div>
      <p-tabView  (onChange)="handleChangeTabContract($event,'str',dt1,dt2,dt3,dt4)">
        <p-tabPanel header="Tous">
          <p-table

            #dt1
            selectionMode="multiple"
            [(selection)]="selectedContracts"
            (sortFunction)="naturalSortContract($event)"
            [customSort]="true"
            sortField="contractName" [sortOrder]='1'
            [value]="contracts"
            class="table"
            [scrollable]="true"
            scrollHeight="40vh"
            [paginator]="true"
            [rows]="10"
            styleClass="p-datatable-sm p-datatable-striped"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="{{'PAGINATION'|translate}}"
            [rowsPerPageOptions]="[10, 25, 50]"
                [globalFilterFields]="[
                'contractName',
                'description',
                'company.name',
                'effectiveDate',
                'expiryDate'
            ]">
            <ng-template pTemplate="colgroup">
              <colgroup>
                <col style="width:15%">
                <col style="width:20%">
                <col style="width:15%">
                <col style="width:15%">
                <col style="width:15%">
                <col style="width:10%">
                <col style="width:5%">
                <col style="width:5%">
              </colgroup>
            </ng-template>
            <ng-template pTemplate="caption">
              <div class="p-d-flex">

            <span class="p-input-icon-left p-ml-auto" [style]="{'height':'20px','margin-bottom':'30px'}">
              <i class="pi pi-search" [style]="{'height': '45px','margin-top':'5px'}"></i>
              <input p pInputText type="text"
                     [style]="{'height': '45px'}"
                     [value]="contratRecherche!== undefined?contratRecherche:''"
                     (input)="filterTableInputChangeTabContract($event.target.value,'contains', dt1,dt2,dt3,dt4)"
                placeholder="Rechercher" />
            </span>


              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th id="contractName" pSortableColumn="contractName">
                  {{'NOM'|translate}}
                  <p-sortIcon field="contractName"></p-sortIcon>
                </th>
                <th id="description" pSortableColumn="description">
                  {{'DESCRIPTION'|translate}}
                  <p-sortIcon field="description"></p-sortIcon>
                </th>
                <th id="company.name" pSortableColumn="company.name">
                  {{'ENTREPRISE'|translate}}
                  <p-sortIcon field="company.name"></p-sortIcon>
                </th>
                <th id="effectiveDate" pSortableColumn="effectiveDate">
                  {{'ENTREE_VIGUEUR'|translate}}
                  <p-sortIcon field="effectiveDate"></p-sortIcon>
                </th>
                <th id="expiryDate" pSortableColumn="expiryDate">
                  {{'FIN_VALIDITE'|translate}}
                  <p-sortIcon field="expiryDate"></p-sortIcon>
                </th>
                <th id="statut">
                  {{'status'|translate}}
                </th>
                <th id="actions" colspan="2" class="p-text-center table-10"></th>

            </ng-template>
            <ng-template pTemplate="body" let-contracts>
              <tr>
                <td>{{ contracts.contractName }}</td>

                <td>{{ contracts.description }}</td>

                <td>{{ contracts?.company?.name }}</td>

                <td>{{ contracts.effectiveDate | date:'dd/MM/YYYY' }}</td>

                <td>{{ contracts.expiryDate | date:'dd/MM/YYYY' }}</td>

                <td *ngIf="contracts?.status?.code === statusContract.INACTIF">
                  <span class="statut-Inactif"> {{contracts?.status?.label}} </span>
                </td>

                <td *ngIf="contracts?.status?.code === statusContract.EN_VIGUEUR">
                  <span class="statut-en_vigueur">{{contracts?.status?.label}} </span>
                </td>

                <td *ngIf="contracts?.status?.code === statusContract.TERMINE">
                  <span class="status-termine"> {{contracts?.status?.label}}  </span>
                </td>

                <td class="p-text-center table-5">
                  <a href="/phoenix/show-contract/{{contracts.contractId}}"><em class="pi pi-search"></em></a>
                </td>
                <td  class="p-text-center table-5">
                  <div  *ngIf="contracts?.status?.code !== statusContract.TERMINE">
                  <button *ifRoles='["administrateur", "admin_metier","admin_responsable_DPO"]' pButton pRipple
                          type="button" icon="pi pi-ellipsis-h"
                          (click)="getRowIndex(contracts);op.toggle($event)"
                          class="p-button-rounded p-button-text p-button-plain"></button>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
        <p-tabPanel header="Inactif">
          <p-table
            selectionMode="multiple"
            [(selection)]="selectedContracts"
            #dt2
            [value]="contractsInactif"
            (sortFunction)="naturalSortContract($event)"
            [customSort]="true"
            sortField="contractName" [sortOrder]='1'
            class="table"
            [scrollable]="true"
            scrollHeight="40vh"
            [paginator]="true"
            [rows]="10"
            styleClass="p-datatable-sm p-datatable-striped"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="{{'PAGINATION'|translate}}"
            [rowsPerPageOptions]="[10, 25, 50]"
            [globalFilterFields]="[
          'contractName',
          'description',
          'company.name',
          'effectiveDate',
          'expiryDate'
        ]">
            <ng-template pTemplate="colgroup">
              <colgroup>
                <col style="width:15%">
                <col style="width:20%">
                <col style="width:15%">
                <col style="width:15%">
                <col style="width:15%">
                <col style="width:10%">
                <col style="width:5%">
                <col style="width:5%">
              </colgroup>
            </ng-template>
            <ng-template pTemplate="caption">
              <div class="p-d-flex">

                <span class="p-input-icon-left p-ml-auto" [style]="{'height':'20px','margin-bottom':'30px'}">
                  <i class="pi pi-search" [style]="{'height': '45px','margin-top':'5px'}"></i>
                  <input p pInputText type="text"
                         [style]="{'height': '45px'}"
                         [value]="contratRecherche!== undefined?contratRecherche:''"
                         (input)="filterTableInputChangeTabContract($event.target.value,'contains', dt1,dt2,dt3,dt4)"
                    placeholder="Rechercher" />
                </span>


              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th id="contractName" pSortableColumn="contractName">
                  {{'NOM'|translate}}
                  <p-sortIcon field="contractName"></p-sortIcon>
                </th>
                <th id="description" pSortableColumn="description">
                  {{'DESCRIPTION'|translate}}
                  <p-sortIcon field="description"></p-sortIcon>
                </th>
                <th id="company.name" pSortableColumn="company.name">
                  {{'ENTREPRISE'|translate}}
                  <p-sortIcon field="company.name"></p-sortIcon>
                </th>
                <th id="effectiveDate" pSortableColumn="effectiveDate">
                  {{'ENTREE_VIGUEUR'|translate}}
                  <p-sortIcon field="effectiveDate"></p-sortIcon>
                </th>
                <th id="expiryDate" pSortableColumn="expiryDate">
                  {{'FIN_VALIDITE'|translate}}
                  <p-sortIcon field="expiryDate"></p-sortIcon>
                </th>
                <th id="statut">
                  {{'status'|translate}}
                </th>
                <th id="actions" colspan="2" class="p-text-center table-10"></th>

            </ng-template>
            <ng-template pTemplate="body" let-contracts>
              <tr>
                <td>{{ contracts.contractName }} </td>

                <td>{{ contracts.description }}</td>

                <td>{{ contracts?.company?.name }}</td>

                <td>{{ contracts.effectiveDate | date:'dd/MM/YYYY' }}</td>

                <td>{{ contracts.expiryDate | date:'dd/MM/YYYY' }}</td>

                <td>
                  <span class="statut-Inactif"> {{contracts?.status?.label}} </span>
                </td>



                <td class="p-text-center table-5">
                  <a href="/phoenix/show-contract/{{contracts.contractId}}"><em class="pi pi-search"></em></a>
                </td>
                <td class="p-text-center table-5">
                  <button *ifRoles='["administrateur", "admin_metier","admin_responsable_DPO"]' pButton pRipple
                          type="button" icon="pi pi-ellipsis-h"
                          (click)="getRowIndex(contracts);op.toggle($event)"
                          class="p-button-rounded p-button-text p-button-plain"></button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
        <p-tabPanel header="En vigueur">
          <p-table
            selectionMode="multiple"
            [(selection)]="selectedContracts"
            #dt3
            [value]="contractsEnVigueur"
            (sortFunction)="naturalSortContract($event)"
            [customSort]="true"
            sortField="contractName" [sortOrder]='1'
            class="table"
            [scrollable]="true"
            scrollHeight="40vh"
            [paginator]="true"
            [rows]="10"
            styleClass="p-datatable-sm p-datatable-striped"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="{{'PAGINATION'|translate}}"
            [rowsPerPageOptions]="[10, 25, 50]"
            [globalFilterFields]="[
          'contractName',
          'description',
          'company.name',
          'effectiveDate',
          'expiryDate'
        ]">
            <ng-template pTemplate="colgroup">
              <colgroup>
                <col style="width:15%">
                <col style="width:20%">
                <col style="width:15%">
                <col style="width:15%">
                <col style="width:15%">
                <col style="width:10%">
                <col style="width:5%">
                <col style="width:5%">
              </colgroup>
            </ng-template>
            <ng-template pTemplate="caption">
              <div class="p-d-flex">

                <span class="p-input-icon-left p-ml-auto" [style]="{'height':'20px','margin-bottom':'30px'}">
                  <i class="pi pi-search" [style]="{'height': '45px','margin-top':'5px'}"></i>
                  <input p pInputText type="text"
                         [style]="{'height': '45px'}"
                         [value]="contratRecherche!== undefined?contratRecherche:''"
                         (input)="filterTableInputChangeTabContract($event.target.value,'contains', dt1,dt2,dt3,dt4)"
                    placeholder="Rechercher" />
                </span>


              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th id="contractName" pSortableColumn="contractName">
                  {{'NOM'|translate}}
                  <p-sortIcon field="contractName"></p-sortIcon>
                </th>
                <th id="description" pSortableColumn="description">
                  {{'DESCRIPTION'|translate}}
                  <p-sortIcon field="description"></p-sortIcon>
                </th>
                <th id="company.name" pSortableColumn="company.name">
                  {{'ENTREPRISE'|translate}}
                  <p-sortIcon field="company.name"></p-sortIcon>
                </th>
                <th id="effectiveDate" pSortableColumn="effectiveDate">
                  {{'ENTREE_VIGUEUR'|translate}}
                  <p-sortIcon field="effectiveDate"></p-sortIcon>
                </th>
                <th id="expiryDate" pSortableColumn="expiryDate">
                  {{'FIN_VALIDITE'|translate}}
                  <p-sortIcon field="expiryDate"></p-sortIcon>
                </th>
                <th id="statut">
                  {{'status'|translate}}
                </th>
                <th id="actions" colspan="2" class="p-text-center table-10"></th>

            </ng-template>
            <ng-template pTemplate="body" let-contracts>
              <tr>
                <td>{{ contracts.contractName }}</td>

                <td>{{ contracts.description }}</td>

                <td>{{ contracts?.company?.name }}</td>

                <td>{{ contracts.effectiveDate | date:'dd/MM/YYYY' }}</td>

                <td>{{ contracts.expiryDate | date:'dd/MM/YYYY' }}</td>


                <td>
                  <span class="statut-en_vigueur">{{contracts?.status?.label}} </span>
                </td>

                <td class="p-text-center table-5">
                  <a href="/phoenix/show-contract/{{contracts.contractId}}"><em class="pi pi-search"></em></a>
                </td>
                <td class="p-text-center table-5">
                  <button *ifRoles='["administrateur", "admin_metier","admin_responsable_DPO"]' pButton pRipple
                          type="button" icon="pi pi-ellipsis-h"
                          (click)="getRowIndex(contracts);op.toggle($event)"
                          class="p-button-rounded p-button-text p-button-plain"></button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
        <p-tabPanel header="Terminé">
          <p-table
            selectionMode="multiple"
            [customSort]="true"
            [(selection)]="selectedContracts"
            (sortFunction)="naturalSortContract($event)"
            sortField="contractName" [sortOrder]='1'
            #dt4
            [value]="contractsDone"
            class="table"
            [scrollable]="true"
            scrollHeight="40vh"
            [paginator]="true"
            [rows]="10"
            styleClass="p-datatable-sm p-datatable-striped"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="{{'PAGINATION'|translate}}"
            [rowsPerPageOptions]="[10, 25, 50]"
            [globalFilterFields]="[
          'contractName',
          'description',
          'company.name',
          'effectiveDate',
          'expiryDate'
        ]">
            <ng-template pTemplate="colgroup">
              <colgroup>
                <col style="width:15%">
                <col style="width:20%">
                <col style="width:15%">
                <col style="width:15%">
                <col style="width:15%">
                <col style="width:10%">
                <col style="width:5%">
                <col style="width:5%">
              </colgroup>
            </ng-template>
            <ng-template pTemplate="caption">
              <div class="p-d-flex">

                <span class="p-input-icon-left p-ml-auto" [style]="{'height':'20px','margin-bottom':'30px'}">
                  <i class="pi pi-search" [style]="{'height': '45px','margin-top':'5px'}"></i>
                  <input p pInputText type="text"
                         [style]="{'height': '45px'}"
                         [value]="contratRecherche!== undefined?contratRecherche:''"
                         (input)="filterTableInputChangeTabContract($event.target.value,'contains', dt1,dt2,dt3,dt4)"
                    placeholder="Rechercher" />
                </span>


              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th id="contractName" pSortableColumn="contractName">
                  {{'NOM'|translate}}
                  <p-sortIcon field="contractName"></p-sortIcon>
                </th>
                <th id="description" pSortableColumn="description">
                  {{'DESCRIPTION'|translate}}
                  <p-sortIcon field="description"></p-sortIcon>
                </th>
                <th id="company.name" pSortableColumn="company.name">
                  {{'ENTREPRISE'|translate}}
                  <p-sortIcon field="company.name"></p-sortIcon>
                </th>
                <th id="effectiveDate" pSortableColumn="effectiveDate">
                  {{'ENTREE_VIGUEUR'|translate}}
                  <p-sortIcon field="effectiveDate"></p-sortIcon>
                </th>
                <th id="expiryDate" pSortableColumn="expiryDate">
                  {{'FIN_VALIDITE'|translate}}
                  <p-sortIcon field="expiryDate"></p-sortIcon>
                </th>
                <th id="statut">
                  {{'status'|translate}}
                </th>
                <th id="actions" colspan="2" class="p-text-center table-10"></th>

            </ng-template>
            <ng-template pTemplate="body" let-contracts>
              <tr>
                <td>{{ contracts.contractName }}</td>

                <td>{{ contracts.description }}</td>

                <td>{{ contracts?.company?.name }}</td>

                <td>{{ contracts.effectiveDate | date:'dd/MM/YYYY' }}</td>

                <td>{{ contracts.expiryDate | date:'dd/MM/YYYY' }}</td>

                <td>
                  <span class="status-termine"> {{contracts?.status?.label}}  </span>
                </td>

                <td class="p-text-center table-5">
                  <a href="/phoenix/show-contract/{{contracts.contractId}}"><em class="pi pi-search"></em></a>
                </td>
                <td class="p-text-center table-5">
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
      </p-tabView>
      <p-overlayPanel #op appendTo="body" baseZIndex="2">
        <ng-template pTemplate>
          <div #actualTarget>
              <button *ifRoles='["administrateur","admin_metier","admin_responsable_DPO"]' pButton pRipple type="button"
                      icon="pi pi-pencil" class="p-button-rounded p-button-text"
                      (click)="update()"
                      label="Modifier"></button>
              <br>

          </div>
        </ng-template>
      </p-overlayPanel>



    </div>
  </div>
</div>

