import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {MessageService} from "primeng/api";
import {Contracts} from "@data/schema/Contracts";
import {EnvService} from "@core/service/env.service";

@Injectable({
  providedIn: 'root'
})
export class ContractService {
s
  private apiURL = this.environment?.readConfig()?.urlApiGateway + "bl/contract/";
  contract: Contracts;

  constructor(private http: HttpClient, private messageService: MessageService,
  private environment: EnvService,
  ) { }

  // public ListContract(enabled: boolean): Observable<any> {
  //   return this.http.get(this.apiURL + "all/" + enabled, {observe: 'response' as 'body'}).pipe(
  //     map(res => {
  //       return res;
  //     })
  //   )
  // }
  public ListAllContract(): Observable<any> {
    return this.http.get(this.apiURL + "all" , {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }

  public disableContract(id: any): Observable<any> {
    return this.http.put(this.apiURL + "deactivate" ,id, {observe: 'response' as 'body'}).pipe(
    );
  }
  public enableContract(id: any): Observable<any> {
    return this.http.put(this.apiURL + "activate", id , {observe: 'response' as 'body'}).pipe(
    );
  }

  public GetContract(id: number): Observable<any> {
    return this.http.get(this.apiURL + id , {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )

  }


  public SaveContract(contract : any): Observable<any> {
    return this.http.post(this.apiURL + "save", contract.value, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    );

  }

  public editContract(contract : any): Observable<any> {

    return this.http.put(this.apiURL + "edit", contract.value, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    );

  }

  public isAttachedToOngoingWorkOrder(contractID : number): Observable<any> {

    return this.http.get(this.apiURL + "isAttachedToOngoingWO/" + contractID, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    );

  }

  public isAttachedToWorkOrder(contractID : number): Observable<any> {

    return this.http.get(this.apiURL + "isAttachedToWO/" + contractID, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    );

  }


  public isContractDataValidByDateForSave (contract: any): Observable<any> {
    return this.http.post(this.apiURL + "save/ctrl/date",  contract.value, {observe: 'response' as 'body'}).pipe(
      map(res=> {
        return res;
      })
    )
  }

  public isContractDataValidByDateForUpdate(contract: any): Observable<any> {
    return this.http.post(this.apiURL + "update/ctrl/date",  contract.value, {observe: 'response' as 'body'}).pipe(
      map(res=> {
        return res;
      })
    )
  }

  public isContractDataValidByWorkForSave (contract: any) : Observable<any> {
    return this.http.post(this.apiURL + "save/ctrl/work",  contract.value, {observe: 'response' as 'body'}).pipe(
      map(res=> {
        return res;
      })
    )
  }

  public isContractDataValidByWorkForUpdate (contract: any) : Observable<any> {
    return this.http.post(this.apiURL + "update/ctrl/work",  contract.value, {observe: 'response' as 'body'}).pipe(
      map(res=> {
        return res;
      })
    )
  }

  public testContract(id: number): any {

    return this.GetContract(id).subscribe(
      res => {
        if (res.status === 200) {
          this.contract = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l\'opération : veuillez réessayer',          });
        }
      },
      () => {}
    );
  }

  public ListAllContractByCompany(id: number): Observable<any> {
    return this.http.get(this.apiURL + "all/" + id , {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }
}
