<h2 fxLayoutAlign="space-between center" class="head dialog-spacing-one" mat-dialog-title>{{"TECHNICIAN" | translate}}
  <button
  mat-icon-button
  aria-label="close dialog"
  mat-dialog-close
  class="close-button"
>
<mat-icon>close</mat-icon>
</button>
</h2>
<mat-dialog-content class="mat-typography">
  <iframe id="iframe" class="content" *ngIf="iframeUrl" [src]="iframeUrl" (load)="onLoadIFrame()"></iframe>
  <div  *ngIf="isError" fxLayout="row" class="dialog-spacing">
    <p style="margin-right:3%" fxLayoutAlign="start start"><mat-icon svgIcon="informationIcon"></mat-icon></p>
    <p>{{'texte_message_exception_TECHNICIEN_INTROUVABLE'|translate}}</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
</mat-dialog-actions>

<div *ngIf="loading" fxLayoutAlign="center" class="loader">
  <img src="assets/GIF/Loader.gif" fxLayoutAlign="center" width="120px" height="120px" />
</div>
