import { EventEmitter, Injectable, Output } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogSearchOtComponent } from '@modules/kober-intervention/page/component/dialog-search-ot/dialog-search-ot.component';
import { BehaviorSubject } from "rxjs";
import { InterventionApiService } from '../intervention-api.service';
import { filter } from 'lodash';
@Injectable({
  providedIn: 'root'
})
export class SearchService {

  searchIsOpen = false;
  public filteredData: any[];
  public filteredDataCurrent: any[];
  searchForm: FormGroup;
  elementRow: any;
  indexElementRow: number;
  indexAllData: number;
  indexMenuBehaviorSubject: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  shouldChangeRow : boolean = false;
  statusGroup: string;
  dataObject: any = {};
  private dialogRefTriggered = false;
  @Output() reloadAdvancedSearch = new EventEmitter<{ data: any}>();
 
  statusGroupIndex : number = 1;
  foundResult: boolean = false;
  constructor(
    private formBuilder: FormBuilder, private router: Router, public dialog: MatDialog,
    private InterventionApiService: InterventionApiService
  ) {
    this.searchForm = this.formBuilder.group({
      option: ['dn'],
      criteriaSearch: [undefined],
      partialSearch: [true],
      advancedSearch: [false],
    });

    this.indexMenuBehaviorSubject.subscribe(value => {
      this.indexAllData = 0;
      this.shouldChangeRow = true;

    });
  }
  get searchFormControls() {
    return this.searchForm.controls;
  }

  filterDataFromDataSource(filteredData: any, globalViewOn : boolean = false) {

    if(!this.searchFormControls.criteriaSearch.value){
      return;
    }
    else{
      this.filteredDataCurrent = [];
      if(this.searchFormControls.advancedSearch.value || globalViewOn === true){
        this.searchForm.controls.advancedSearch.patchValue(false);
        if(globalViewOn === true){
          this.router.navigate(['/phoenix/intervention/wo/advanced-search'],
          {
            state: {
              advancedSearchCriteriaNoResultFound:
              {
                input: this.searchForm.value
              }
            }
          });
        }
        else{
          this.router.navigate(['/phoenix/intervention/wo/advanced-search'],
            {
              state: {
                advancedSearchCriteria:
                {
                  input: this.searchForm.value,
                  statusGroup: this.statusGroup
                }
              }
            });
            this.resetFilteredData();
            this.resetSearchForm();
            this.searchIsOpen = false;
          }
        }else{
          //remove duplicates from filteredData if they have the same _id
          filteredData = filteredData.filter((value, index, self) => self.findIndex((t) => t._id === value._id) === index);

          //if simple search => if data includes the search criteria push it to the filteredDataChange
          let dataViewAllFiltered = JSON.parse(sessionStorage.getItem('dataViewAllFiltered'));
          if (this.searchFormControls?.partialSearch?.value) {
            this.filteredDataCurrent = filteredData.filter(value => {
              return (value[this.searchFormControls?.option?.value]?.toLowerCase() || '').includes(this.searchFormControls?.criteriaSearch?.value?.toLowerCase() || '')});
            dataViewAllFiltered = filter(dataViewAllFiltered, (value) => {
              return (value[this.searchFormControls?.option?.value]?.toLowerCase() || '').includes(this.searchFormControls?.criteriaSearch?.value?.toLowerCase() || '');});
          } else {
            // else strict search
            this.filteredDataCurrent = filteredData.filter(value => {
              return value[this.searchFormControls?.option?.value]?.toLowerCase() === this.searchFormControls?.criteriaSearch?.value?.toLowerCase()
            });
            dataViewAllFiltered = filter(dataViewAllFiltered, (value) => {
              return value[this.searchFormControls?.option?.value]?.toLowerCase() === this.searchFormControls?.criteriaSearch?.value?.toLowerCase();});
          }
          sessionStorage.setItem('dataViewAllFiltered', JSON.stringify(dataViewAllFiltered));
          //if filteredDataChange not empty
          if(this.filteredDataCurrent.length > 0){
            //order this.filteredDataChange by statusGroup
            this.filteredDataCurrent = this.filteredDataCurrent.sort((a, b) => a.statusGroup - b.statusGroup);

            this.indexElementRow = 0;
            this.indexAllData = 0;
            this.elementRow = this.filteredDataCurrent[this.indexElementRow];
            this.foundResult = true;
            this.dialogRefTriggered = false;
            this.elementRow = this.filteredDataCurrent[this.indexElementRow];
            sessionStorage.setItem('filteredData', JSON.stringify(this.filteredDataCurrent));

          }
          //else dialog search ot
          else if(!this.foundResult && !this.dialogRefTriggered){
            this.dialogRefTriggered = true;
            const dialogRef = this.dialog.open(DialogSearchOtComponent, {
              width: '25%',
              panelClass: 'custom-dialog-container',
            });
            dialogRef.afterClosed().subscribe((result) => {
              if (result) {
                this.router.navigate(['/phoenix/intervention/wo/advanced-search'],
                  {
                    state: {
                      advancedSearchCriteriaNoResultFound:
                      {
                        input: this.searchForm.value
                      }
                    }
                  });
                }
                this.resetFilteredData();
                this.resetSearchForm();
                this.searchIsOpen = false;
                });
          }
        }
    }
  }

  dialogRefTriggeredFalse() {
    this.dialogRefTriggered = false;
  }

  checkFilterRows(row) {
    return this.filteredDataCurrent?.find(value => value._id === row._id);
  }

  resetSearchForm() {
    this.searchFormControls.criteriaSearch.setValue(undefined);
  }
  resetFilteredData() {    
    this.statusGroupIndex = 1;
    this.foundResult = false;
    this.filteredDataCurrent = null;
    this.elementRow = null;
  }

  changeStatusGroupIndex(statusGroup: string) {
    switch (statusGroup) {
      case('to-plan') :
        this.statusGroupIndex = 2;
        break;
      case('not-started') :
        this.statusGroupIndex = 3;
        break;
      case('in-progress') :
        this.statusGroupIndex = 4;
        break;
      case('to-validate') :
        this.statusGroupIndex = 5;  
        break;
      case('on-hold') :
        this.statusGroupIndex = 6;
        break;
      case('in-error') :
        this.statusGroupIndex = 7;
        break;
      case('ended') :
        this.statusGroupIndex = 8;
        break;
      default:
        this.statusGroupIndex = 1;
        break;
    }
  }


}

