import {ConfirmationService, MessageService} from "primeng/api";
import {MultiSelectModule} from 'primeng/multiselect';
import {Component, OnInit, ViewChild,HostListener} from '@angular/core';
import { ComponentCanDeactivate } from '@core/guard/component-can-deactivate.guard';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import {TypeSkill, SelectTypeSkill, Skill} from "@data/schema/Skill";
import {SkillService} from '@data/service/skill.service';
import {TagService} from '@data/service/tag.service';


@Component({
  selector: 'app-new-skill',
  templateUrl: './new-skill.component.html',
  styleUrls: ['./new-skill.component.css']
})
export class NewSkillComponent implements OnInit, ComponentCanDeactivate{

  public submited: BehaviorSubject<boolean>;

   //@HostListener allows us to also guard against browser refresh, close, etc.
   @HostListener('window:beforeunload')
   canDeactivate(): Observable<boolean> | boolean {

     return (this.submited.value || !this.skill.isDefined()  ? true : false)

   }

  skill: Skill = new Skill();
  selectTypeOptions: SelectTypeSkill = new SelectTypeSkill();
  typeOptions: TypeSkill[] = this.selectTypeOptions.typeOptions;

  // conditional display
  isEligibility: boolean = false;
  hasValidity: boolean = false;
  wantToCreateMore : boolean = false;
  displayFilter: boolean = false;

  // errors field boolean
  errorNameRequired: boolean = false;
  errorTypeRequired: boolean = false;
  errorDateRequired: boolean = false;
  errorInvalidValue: boolean = false;
  yearDate: number = null;
  monthDate: number = null;
  dayDate: number = null;

  tags: any[] = [{label: 'Favoris', value: 'favoris', items: []}, {label: 'Tous', value: 'tous', items: []}];
  selectedTags: any[] = [];
  iconChange = "pi custom-icon-tag";


  constructor(private messageService: MessageService,
      private skillService: SkillService,
      private tagService: TagService,
      private router: Router,
      private  multiSelectModule : MultiSelectModule,
      private confirmationService: ConfirmationService) {
        this.submited = new BehaviorSubject(false)
      }

  ngOnInit(): void {

    this.getTagAll();
  }


  showFilterDialog(): void {
    this.displayFilter = true;
  }


  alphaNumericSortTag = (arr = []) => {
    const sorter = (a:any, b:any) => {
    const isNumber = (v:any) => (+v).toString() === v;
    const aPart = a.label.match(/\d+|\D+/g);
    const bPart = b.label.match(/\d+|\D+/g);

    let i = 0; let len = Math.min(aPart.length, bPart.length);
    while (i < len && aPart[i] === bPart[i]) { i++; };
      if (i === len) {
          return aPart.length - bPart.length;
    };
    if (isNumber(aPart[i]) && isNumber(bPart[i])) {
      return aPart[i] - bPart[i];
    };
    return aPart[i].localeCompare(bPart[i]); };
    arr.sort(sorter);
  };



  getTagAll() {
     this.tagService.getAllTags().subscribe(
      (res) => {
        if (res.status === 200) {
          let tags = res.body;
          this.alphaNumericSortTag(tags)
          for (let i = 0; i < tags.length; i++) {
            if (tags[i].bookmarked) {
                this.tags[0].items.push(tags[i]);
            } else {
                this.tags[1].items.push(tags[i]);
            }
          }
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {
      }
    );
  }

  panelOpen() {
    setTimeout(function () {
      let multiSelection = document.getElementById('multiselectTag');
      let heightOfMultiSelection: any = multiSelection.getElementsByClassName('p-multiselect')[0];
      let offsetHeightMultiselect: any = heightOfMultiSelection.offsetHeight;
      let test: any = document.getElementsByClassName('ng-trigger ng-trigger-overlayAnimation p-multiselect-panel p-component ng-star-inserted')[0];
      test.style.removeProperty("left");
      test.style.cssText = "min-width: 33%; right:0px; z-index: 1005; transform-origin: center top;";
      test.style["top"] = offsetHeightMultiselect + "px";
    }, 10);
    setTimeout(function () {
      let group1 = document.getElementsByClassName("p-multiselect-item-group ng-star-inserted")[0];
      let group2 = document.getElementsByClassName("p-multiselect-item-group ng-star-inserted")[1];
      if (group1) {
        let div1 = document.createElement("div");
        div1.style.cssText = 'height:1px;display:inline-block;width:70%;max-width:200px;margin-left:10px;margin-bottom:3px;background: #818181;'
        group1.appendChild(div1);
      }
      if (group2) {
        let div2 = document.createElement("div");
        div2.style.cssText = 'height:1px;display:inline-block;width:70%;max-width:200px;margin-left:26.5px;margin-bottom:3px;background: #818181;'
        group2.appendChild(div2);
      }
    }, 50);

  }

  changeTag(event: any) {
    setTimeout(function () {
      let multiSelection = document.getElementById('multiselectTag');
      let heightOfMultiSelection: any = multiSelection.getElementsByClassName('p-multiselect')[0];
      let offsetHeightMultiselect: any = heightOfMultiSelection.offsetHeight
      let test: any = document.getElementsByClassName('ng-trigger ng-trigger-overlayAnimation p-multiselect-panel p-component ng-star-inserted')[0];
      test.style["top"] = offsetHeightMultiselect + "px";

    }, 50);
    if (this.selectedTags.length > 0) {
      this.iconChange = "pi custom-icon-close";
    } else {
      this.iconChange = "pi custom-icon-tag";
    }
  }


  onClickElement(event: any) {

    this.getTagAll();
    let span = event.path[0];
    let listOfClassSpan = span.classList;
      if (listOfClassSpan.contains("custom-icon-close")) {
        this.selectedTags = [];
        this.iconChange = "pi custom-icon-tag";
      }

  }

  chipRemove(event: any) {
    this.selectedTags = this.selectedTags.filter(x => x !== event);
    if (this.selectedTags.length === 0) {
      this.iconChange = "pi custom-icon-tag";
    }

  }


  submit() {


    if (this.skill.name === "") {
      this.errorNameRequired = true
    }

    if (this.skill.type === "") {
      this.errorTypeRequired = true
    }

    if (this.skill.validity === true && (this.yearDate === null && this.monthDate === null && this.dayDate === null)){
      this.errorDateRequired = true
    }

    if (!this.errorNameRequired && !this.errorTypeRequired && !this.errorDateRequired && !this.errorInvalidValue){

      this.skill.tags = this.selectedTags
      this.alphaNumericSortTag(this.skill.tags)
      this.submited.next(true)

      this.skillService
        .createSkill(this.skill).subscribe(data => {
          if (data.status === 200) {
            this.messageService.add({severity: 'success', summary: 'Opération réussie'});
            this.skill = new Skill();
            this.isEligibility = false
            this.gotoList();
          }
        },
        error => {
          if (error.status === 409) {
            this.messageService.add({severity: 'error', summary: 'Cet élément existe déjà'});
          }
          if (error.status === 500) {
            this.messageService.add({severity: 'error', summary: 'Échec de l’opération : veuillez réessayer'});
          }
        }, () => {
          this.skill.tags =  []
        }
      )
    }

  }

  wantToCreate(isChecked : any){
    this.wantToCreateMore = isChecked.checked
  }

  gotoList() {
    console.log(this.wantToCreateMore)
    if(this.wantToCreateMore === true){
      window.location.reload();
    }
    else{
      this.router.navigate(['/phoenix/skill']);
    }

  }

  // conditional rendering, triggered when select value change
  typeFormRendering(event: any) {
    // enable to reset errors message if selected correct value
    if (this.skill.type === ""){
      this.errorTypeRequired = true
    }
    else{
      this.errorTypeRequired = false
    }

    if (this.skill.type === "HABILITATION"){
      this.isEligibility = true
    }
    else{
      this.isEligibility = false
      this.skill.validity = false
    }
  }

  // Validator
  nameFieldValidator(event: any) {
    if (event.target.value === ""){
      this.errorNameRequired = true
    }
    else{
      this.errorNameRequired = false
    }
  }

  // Validator on blur (when end of dropdown)
  selectValidator() {
    if (this.skill.type === ""){
      this.errorTypeRequired = true
    }
    else{
      this.errorTypeRequired = false
    }

  }

  dateYearFieldValidator(event: any) {

    this.yearDate = event.value

    if ((this.yearDate > 0 && (this.monthDate >= 0 || this.monthDate == null) && (this.dayDate >= 0 || this.dayDate == null)) ||
      (this.yearDate == 0 &&(this.monthDate>=0 || this.monthDate==null)&&this.dayDate>0) ||
      (this.yearDate == 0 &&this.monthDate>0&&(this.dayDate>=0 || this.dayDate==null)) ||
      (this.yearDate == null && (((this.monthDate !== null && this.monthDate > 0)&&(this.dayDate == null || this.dayDate > 0)) ||  ((this.dayDate !== null && this.dayDate > 0)&&(this.monthDate == null || this.monthDate>0))) ) ){
      this.errorInvalidValue = false
    }
    else{
      this.errorInvalidValue = true
    }

    if (this.yearDate !== null || this.monthDate !== null || this.dayDate !== null){
      this.errorDateRequired = false

    }

  }

  dateMonthFieldValidator(event: any) {

    this.monthDate = event.value

    if ((this.monthDate > 0 && (this.dayDate >= 0 || this.dayDate == null) && (this.yearDate >= 0 || this.yearDate == null))||
    (this.monthDate == 0 &&(this.dayDate>=0 || this.dayDate==null)&&this.yearDate>0)||
    (this.monthDate == 0 && this.dayDate>0 && (this.yearDate>=0 || this.yearDate==null)) ||
    (this.monthDate == null && (((this.yearDate !== null && this.yearDate > 0)&&(this.dayDate == null || this.dayDate > 0)) ||  ((this.dayDate !== null && this.dayDate > 0)&&(this.yearDate == null || this.yearDate>0))) ) ){
      this.errorInvalidValue = false
    }
    else{
      this.errorInvalidValue = true

    }

    if (this.yearDate !== null || this.monthDate !== null || this.dayDate !== null){
      this.errorDateRequired = false

    }

  }

  dateDayFieldValidator(event: any) {

    this.dayDate = event.value

    if ((this.dayDate > 0 && (this.monthDate >= 0 || this.monthDate == null) && (this.yearDate >= 0 || this.yearDate == null))||
    (this.dayDate == 0 &&(this.monthDate>=0 || this.monthDate==null)&&this.yearDate>0)||
    (this.dayDate == 0 &&this.monthDate>0&&(this.yearDate>=0 || this.yearDate==null))||
    (this.dayDate == null && (((this.yearDate !== null && this.yearDate > 0)&&(this.monthDate == null || this.monthDate > 0)) ||  ((this.monthDate !== null && this.monthDate > 0)&&(this.yearDate == null || this.yearDate>0))) )){
      this.errorInvalidValue = false
    }
    else{
      this.errorInvalidValue = true
    }

    if (this.yearDate !== null || this.monthDate !== null || this.dayDate !== null){
      this.errorDateRequired = false

    }

  }

confirm(){
    this.confirmationService.confirm({
      header: "Confirmation",
      message: 'Vous allez quitter la page, les données seront perdues.' + '<br>' +'Voulez vous continuer ?',
      icon: "pi pi-exclamation-triangle",
      acceptButtonStyleClass: 'p-button-outlined',
      acceptIcon:'null',
      rejectIcon: 'null',
      acceptLabel: "Quitter",
      rejectLabel: "Annuler",
      accept: () => {

         this.router.navigate(['/phoenix/skill']);

      }
  });
}




}



