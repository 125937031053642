import {Component, Input, IterableDiffers, OnInit} from '@angular/core';
import {Voucher} from "@data/schema/Voucher";
import {WorkOrderHistory, WorkOrderProjectWork} from '@data/schema/WorkOrder';
import {SortEvent } from 'primeng/api';
import {CustomSortService} from "@shared/service/custom-sort.service";

@Component({
  selector: 'app-work-form-history',
  templateUrl: './work-form-history.component.html',
  styleUrls: ['./work-form-history.component.css']
})
export class WorkFormHistoryComponent implements OnInit {

  @Input() listVoucherforot?: Voucher[];

  @Input() listHistoryProgress?: WorkOrderHistory[];

  listVoucher : Voucher[] = [];

  // allow to check if array input changed
  iterableDiffer: any;

  displayOptions: any[];

  mode: string = 'advancement';

  constructor(
    private iterableDiffers: IterableDiffers,
    private customSortService : CustomSortService
  ) {
    this.iterableDiffer = iterableDiffers.find([]).create(null);
  }

  ngOnInit(): void {
    this.displayOptions = [
      {name:'Avancement', mode:'advancement'},
      {name:'Bon de sortie', mode: 'voucher'},
  ];
  }

  naturalSort(event:SortEvent) {
    
    this.customSortService.naturalSort(event);

  }

}
