import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class CustomIconsService {

  constructor( private matIconRegistry: MatIconRegistry,private domSanitizer: DomSanitizer) { }

  customIcon() {
    this.matIconRegistry.addSvgIcon(
      "delete_column",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/filter/Group 2222.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "timeStamp",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/Time-Stamp.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "clock",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/clock.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "geoIcon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/geo-stamp.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "sortAZ_icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/filter/Group 2219.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "sortZA_icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/filter/Group 2305.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "sortNumeric19_icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/filter/Group 2303.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "sortNumeric91_icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/filter/Group 2304.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "invisible",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/filter/invisible 4.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "caretRightFill",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/menu/caret-right-fill.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "caret-up-fill",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/caret-up-fill.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "home",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/menu/Accueil.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "calendarIcon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/filter/calendar.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "searchIcon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/filter/Loupe.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "polygonIcon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/filter/Polygon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "arrow-left",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/arrow-left.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "arrow-up",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/arrow-up.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "arrow-down",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/arrow-down.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "play-sm",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/play-sm.svg")

    );
    this.matIconRegistry.addSvgIcon(
      "caret-down-fill",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/caret-down-fill.svg")

    );
      this.matIconRegistry.addSvgIcon(
        "caret-left-fill",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/caret-left-fill.svg")

    );
    this.matIconRegistry.addSvgIcon(
      "caret-right-fill",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/caret-right-fill.svg")

    );

  this.matIconRegistry.addSvgIcon(
    "three-dots",
    this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/three-dots.svg")

  );
  this.matIconRegistry.addSvgIcon(
    "white-three-dots",
    this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/whiteMenu/three-dots.svg")

  );
  this.matIconRegistry.addSvgIcon(
    "white-caret-down-fill",
    this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/whiteMenu/white-caret-down-fill.svg")

  );
  this.matIconRegistry.addSvgIcon(
    "white-caret-right-fill",
    this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/whiteMenu/white-caret-right-fill.svg")

  );

  this.matIconRegistry.addSvgIcon(
    "person",
    this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/person.svg")

  );

  this.matIconRegistry.addSvgIcon(
    "people",
    this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/people.svg")

  );
  this.matIconRegistry.addSvgIcon(
    "customSearch",
    this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/search.svg")

  );
  this.matIconRegistry.addSvgIcon(
    "peopleSearch",
    this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/peopleSearch.svg")

  );
  this.matIconRegistry.addSvgIcon(
    "personSearch",
    this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/personSearch.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "king",
    this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/king.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "link",
    this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/link.svg")
  );

  this.matIconRegistry.addSvgIcon(
    "alert",
    this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/Alert-triangle-Outlined.svg")
  );

  this.matIconRegistry.addSvgIcon(
  "teamLeaderIcon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/star-fill.svg")
    );

  this.matIconRegistry.addSvgIcon(
    "removeIcon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/x-sm.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "validate-blue-icon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/ValiderOTBlue.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "validate-white-icon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/ValiderOTWhite.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "validate-icon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/rightClickMenu/ValiderOT.svg")
  );
    this.matIconRegistry.addSvgIcon(
      "annuler-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/rightClickMenu/Annuler_OT.svg")
    );
  this.matIconRegistry.addSvgIcon(
    "confirm-blue-icon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/confirmer_ot_primary.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "confirm-white-icon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/confirmer_ot_white.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "confirm-icon-disabled",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/rightClickMenu/confirmer_ot_disabled.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "confirm-icon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/rightClickMenu/confirmer_ot.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "terminate-blue-icon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/Terminer_OT_blue.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "terminate-white-icon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/Terminer_OT_white.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "terminate-icon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/rightClickMenu/Terminer_OT_black.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "deleteFillIcon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/trash.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "editFillIcon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/pencil.svg")
  );

  this.matIconRegistry.addSvgIcon(
    "deleteIcon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/trash-fill.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "editIcon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/pencil-fill.svg")
  );

  this.matIconRegistry.addSvgIcon(
    "cancelIcon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/rightClickMenu/slash-circle.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "assignWO",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/rightClickMenu/Attribuer OT.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "affectWO",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/rightClickMenu/AffectOT.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "reaffectWO",
    this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/rightClickMenu/REAFOT-grey.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "desaffectWO",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/rightClickMenu/Désaffecter l'OT.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "pauseIcon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/rightClickMenu/pause-circle.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "positionIcon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/rightClickMenu/geo-alt.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "planninglIcon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/rightClickMenu/calendar3-event.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "apptIcon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/rightClickMenu/calendar3-range.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "buildingIcon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/rightClickMenu/building.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "fullScreenIcon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/rightClickMenu/arrows-fullscreen.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "desattribuer",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/rightClickMenu/Désattribuer OT.svg")
  );

  this.matIconRegistry.addSvgIcon(
    "ATTOTBlueIcon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/ATTOT_blue.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "AFFECTOTBlueIcon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/AffectOT_blue.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "desAffectOTBlueIcon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/Désaffecter l'OT-blue.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "REAFOTBlueIcon",
    this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/REAFOT-blue.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "REAFOTWhiteIcon",
    this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/REAFOT.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "desAffectOTWhiteIcon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/Désaffecter l'OT.svg")
  );

  this.matIconRegistry.addSvgIcon(
    "arrowsFullScreenIcon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/arrows-fullscreen.svg")
  );

  this.matIconRegistry.addSvgIcon(
    "DESATTOTBlueIcon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/DESATTOT_blue.svg")
  );

  this.matIconRegistry.addSvgIcon(
    "ATTOTDisabledIcon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/ATTOT_gray.svg")
  );

  this.matIconRegistry.addSvgIcon(
    "ATTOTWhiteIcon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/ATTOT_white.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "AFFECTOTWhiteIcon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/AffectOT_white.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "DESATTOTWhiteIcon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/DESATTOT_white.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "reject-icon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/rightClickMenu/Refuser_OT_black.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "update-rdv",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/rightClickMenu/Modifier le RDV.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "notes",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/rightClickMenu/notesOT.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "retenir-icon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/rightClickMenu/Retenir_OT_black.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "reject-icon-blue",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/Refuser_OT_blue.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "reject-icon-white",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/Refuser_OT_white.svg")
  );
    this.matIconRegistry.addSvgIcon(
      "modif-spec-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/Modif_spec_OT.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "modif-spec-icon-blue",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/Modif_spec_OT_blue.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "modif-spec-icon-white",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/Modif_spec_OT_white.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "retenir-icon-blue",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/Retenir_OT_blue.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "recommencer-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/RECOMOT.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "recommencer-icon-blue",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/RECOMOT_blue.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "recommencer-icon-white",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/RECOMOT_white.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "annuler-icon-blue",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/Annuler_OT_Blue.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "annuler-icon-white",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/Annuler_OT_White.svg")
    );
  this.matIconRegistry.addSvgIcon(
    "retenir-icon-white",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/Retenir_OT_white.svg")
  );

  this.matIconRegistry.addSvgIcon(
    "arrowsmIconOne",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/arrow-sm-one.svg")
  );

  this.matIconRegistry.addSvgIcon(
    "arrowsmIconTwo",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/arrow-sm-two.svg")
  );

  this.matIconRegistry.addSvgIcon(
    "arrowsmIconOneBlue",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/arrow-sm-One-Blue.svg")
  );

  this.matIconRegistry.addSvgIcon(
    "arrowsmIconTwoBlue",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/arrow-sm-Two-Blue.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "gear",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/gear.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "people-circle",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/people-circle.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "arrow-right",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/arrow-right-short.svg")
  );

  this.matIconRegistry.addSvgIcon(
    "exclamationIcon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/exclamation-triangle.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "informationIcon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/info-circle.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "plus-icon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/plus-circle.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "alert-circle-red-white",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/Alert-circle-Red&white.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "check-icon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/check-lg.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "check2",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/check2.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "check-icon-filled",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/check-sm.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "pencil-white",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/pencil-white.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "pencil-blue",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/Edit.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "validate-blue",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/Check.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "add-icon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/Add.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "remove-icon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/Remove.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "add-Fill",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/Add-noir.svg")
  );

  this.matIconRegistry.addSvgIcon(
    "bounding-box",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/bounding-box-1.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "card-view",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/Vue carte.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "eye",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/eye.svg")
  );

  this.matIconRegistry.addSvgIcon(
    "menu-list",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/list.svg")
  );

  this.matIconRegistry.addSvgIcon(
    "chevron-blue",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/chevron-up.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "chevron-grey-right",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/chevron-right.svg")
  );

  this.matIconRegistry.addSvgIcon(
    "chevron-down-black",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/chevron-down.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "chevron-double-left",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/chevron-double-left.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "chevron-double-left-white",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/chevron-double-left-white.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "chevron-double-right",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/chevron-double-right.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "chevron-double-right-white",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/chevron-double-right-white.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "check",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/check.svg")
  );

  this.matIconRegistry.addSvgIcon(
    "open-icon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/menu/sidebar_open.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "add-icon-plus",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/Add.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "update-appt-white-icon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/whiteMenu/Modifier le RDV.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "update-appt-blue-icon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/Modifier le RDV_blue.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "wo-report",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/workOrderActions/wo-report.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "chevron-double-right",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/chevron-double-right.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "search",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/Search.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "close-search",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/Close-search.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "close",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/close.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "export",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/Export.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "export-white",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/Export_white.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "layout-sidebar",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/layout-sidebar.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "grip-vertical",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/grip-vertical.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "grid-3x3",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/grid-3x3.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "arrow-return",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/arrow-return.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "add-white",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/AddWhite.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "search-white",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/SearchWhite.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "download-white",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/DownloadWhite.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "delete-white",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/DeleteWhite.svg")
  );

  this.matIconRegistry.addSvgIcon(
    "delete-blue",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/DeleteBlue.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "close-blue",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/CloseBlue.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "chrono-blue",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/chrono.svg")
  );
  this.matIconRegistry.addSvgIcon(
    "chrono-black",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/stopwatch.svg")
  );  
  this.matIconRegistry.addSvgIcon(
    "magasins",
    this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/Magasins.svg")

  );
  this.matIconRegistry.addSvgIcon(
    "arrow-right",
    this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/arrow-right.svg")

  );

  this.matIconRegistry.addSvgIcon(
    "qr-icon",
    this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/SVG/qr-icon.svg")

  );




  }
}
