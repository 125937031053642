<app-kober-menu module="kober-skill"></app-kober-menu>
<div class="p-d-flex p-jc-center p-m-2 p-p-2 main-page">
  <div class="p-grid p-ai-center vertical-container p-jc-center">
    <div class="p-col-12 p-md-11 p-jc-center p-m-3 p-p-3 main-content p-shadow-4">
      <div class="p-grid p-jc-between p-p-3">
        <h2>{{ 'COMPETENCES' | translate }}</h2>
        <div>
          <button *ifRoles='["administrateur","admin_metier","admin_responsable_DPO"]' routerLink="/phoenix/new-skill" class="p-mr-5 p-mt-5" pButton type="button" label="{{ 'CREER' | translate }}"></button>
          <button *ifRoles='["administrateur","admin_metier","admin_responsable_DPO"]' class="p-mr-5 p-mt-5" (click)="showDialogImportFile()" pButton  type="button" label="{{ 'IMPORTER' | translate }}" pTooltip="Importer un fichier csv ou excel" tooltipPosition="bottom" showDelay="250" tooltipStyleClass="tooltip">
          </button>
          <button type="button" pButton pRipple icon="pi pi-file-o" (click)="exportCsv()" class="p-button-info p-mr-2" pTooltip="{{'tooltip_EXPORTER_CSV'|translate}}" tooltipPosition="bottom"></button>
          <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportToExcel()" class="p-button-success p-mr-2"  pTooltip="{{'tooltip_EXPORTER_EXCEL'|translate}}" tooltipPosition="bottom"></button>
          <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportToPdf()"  class="p-button-help p-mr-2"  pTooltip="{{'tooltip_EXPORTER_PDF'|translate}}" tooltipPosition="bottom"></button>

          <p-button *ifRoles='["administrateur","admin_metier","admin_responsable_DPO"]' (click)="checkSize=false " pTooltip="{{ ' MODIFIER_TAGS' | translate }}" tooltipPosition="bottom" type="button"  pRipple  [style]="{'content': 'url(../../../assets/SVG/menu/tag-white.svg)','margin-top': '8px','background-color': '#FFFFFF',
          'height': '43px','background':'#3F51B5','border-radius': '4px','color':'#109CF1'}" type="button" class="p-mr-2" (click)="showSidebar($event)" icon="pi pi-arrow-left"></p-button>

        </div>
      </div>
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12 p-lg-9">
          <app-filtre-tag [typeOfTag]="typeOfTag" (newArrayFilter)="filterArrayByTag($event)"></app-filtre-tag>
        </div>
        <div class="p-col-12 p-md-12 p-lg-3">
        <span class="p-input-icon-left p-ml-auto">
          <i class="pi pi-search"></i>
          <input
            p pInputText
            type="text"
            (input)="dt1.filterGlobal($event.target.value, 'contains')"
            placeholder="{{ 'recherche' | translate }}"
          />
        </span>
        </div>
      </div>
      <p-table
        [(selection)]="selectedSkills"
        responsiveLayout="scroll"
        #dt1
        [value]="skill"
        (sortFunction)="naturalSortSkill($event)"
        [customSort]="true"
        sortField="name"
        [sortOrder]='1'
        dataKey="name"
        styleClass="p-datatable-sm p-datatable-striped"
        class="table"
        [scrollable]="true"
        scrollHeight="40vh"
        [paginator]="true"
        [rows]="10"
        styleClass="p-datatable-sm p-datatable-striped"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{{'PAGINATION'|translate}}"
        [rowsPerPageOptions]="[10, 25, 50]"
        [globalFilterFields]="[
          'name',
          'type',
          'description'
        ]">
        <ng-template pTemplate="caption">
          <div class="p-d-flex">
            <div class="p-field-checkbox">
              <p-checkbox class="p-mr-2 p-mb-2" [(ngModel)]="hab" label="{{'HABILITATION'|translate}}" binary="true" inputId="binary"
                          (onChange)="showTypeSkill($event)"></p-checkbox>
              <p-checkbox class="p-mr-2 p-mb-2" [(ngModel)]="sf" label="{{ 'SAVOIR_FAIRE' | translate }}" binary="true" inputId="binary"
                          (onChange)="showTypeSkill($event)"></p-checkbox>
              <p-checkbox class="p-mr-2 p-mb-2" [(ngModel)]="se" label="{{ 'SAVOIR_ETRE' | translate }}" binary="true" inputId="binary"
                          (onChange)="showTypeSkill($event)"></p-checkbox>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header" >
          <tr>
            <th class="table-5" *ngIf="visibleSidebar==true">
              <p-tableHeaderCheckbox>

              </p-tableHeaderCheckbox>
            </th>
            <th  id="name" pSortableColumn="name">
             {{ 'NOM' | translate }}
              <p-sortIcon field="name"></p-sortIcon>
            </th>
            <th class="table-5" id="type" pSortableColumn="type" style="width: 10rem">
              {{ 'TYPE' | translate }}
              <p-sortIcon field="type"></p-sortIcon>
            </th>
            <th id="tags" pSortableColumn="tags">
              {{ 'TAGS' | translate }}
              <p-sortIcon field="type"></p-sortIcon>
            </th>
            <th id="description" pSortableColumn="color" >
              {{ 'DESCRIPTION' | translate }}
              <p-sortIcon field="color"></p-sortIcon>
            </th>
            <th id="actions" colspan="2" class="p-text-center table-10"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-skill let-rowIndex="rowIndex">

          <tr

            (onDragEnter)="dragEnter(skill)"
            [ngClass] = "{'hightlight' : skill.id == selectedSkillId}"
            [pSelectableRowIndex]="rowIndex"
            pDroppable=""
            [pSelectableRow]="skill"
            (onDrop)="drop($event, skill)"

          >
            <td class="table-5" *ngIf="visibleSidebar==true">

              <p-tableCheckbox   [value]="skill"></p-tableCheckbox>
            </td>
            <td >{{ skill.name }}</td>
            <td style="width: 10rem" *ngIf="skill.type === 'HABILITATION'">Habilitation</td>
            <td style="width: 10rem" *ngIf="skill.type === 'SAVOIR_ETRE'">Savoir être</td>
            <td style="width: 10rem" *ngIf="skill.type === 'SAVOIR_FAIRE'">Savoir faire</td>
            <td id="td" (onDrop)="drop($event)">
              <div class="p-grid p-d-flex p-ai-center">
                <div class="p-col-9" id="{{ 'skill-' + skill.id }}" [ngClass]="{'content' : !skill.toggle && !visibleSidebar}" >

                  <ng-container id="ng-container" class="p-grid" *ngFor="let item of skill.tags">

                    <p-chip  *ngIf="visibleSidebar" [pTooltip]="item?.description" tooltipPosition="top"  [label]="item.label"  (onRemove)="removeTag($event,item, skill)" [removable]="true" styleClass="custom-chip" [style]="{'background': item.colorCode,'border-radius':'6px','height':'35px','margin-right': '8px','margin-bottom': '8px','color':'#ffffff'}" ></p-chip>

                    <p-chip   *ngIf="!visibleSidebar" [pTooltip]="item?.description" tooltipPosition="top"  [label]="item.label"  [style]="{'color':'#ffffff','background': item.colorCode,'border-radius':'6px','height':'35px','margin-right': '8px','margin-bottom': '8px'}" ></p-chip>

                  </ng-container>

                </div>

                <div class="p-col-3" *ngIf="count(skill); let nb">
                  <p-chip *ngIf="!visibleSidebar &&  !skill.toggle"
                          [style]="{'background': 'rgba(252, 208, 207, 0.5)','color':' #DA100B','border-radius':'6px','margin-left':'-20px'}"
                          (click)="skill.toggle=!skill.toggle" label="{{'+' + nb}}"></p-chip>
                  <p-chip *ngIf="!visibleSidebar &&  skill.toggle"
                          [style]="{'background': 'rgba(252, 208, 207, 0.5)','color':' #DA100B','border-radius':'6px','margin-left':'-20px'}"
                          (click)="skill.toggle=!skill.toggle" label="-"></p-chip>
                </div>
              </div>

            </td>

            <td>{{ skill.description }}</td>
            <td class="p-text-center table-5">
              <button
                routerLink="/phoenix/show-skills/{{ skill.id }}"
                pButton
                pRipple
                type="button"
                icon="pi pi-search"
                class="p-button-rounded p-button-text p-button-plain"
              ></button>
            </td>
            <td class="p-text-center table-5">
              <button *ifRoles='["administrateur", "admin_metier","admin_responsable_DPO"]' pButton pRipple
                      type="button" icon="pi pi-ellipsis-h"
                      (click)="getRowIndex(skill.id);op.toggle($event)"
                      class="p-button-rounded p-button-text p-button-plain"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <p-overlayPanel #op appendTo="body" baseZIndex="2">
        <ng-template pTemplate>
          <div #actualTarget>
            <button *ifRoles='["administrateur","admin_metier","admin_responsable_DPO"]' pButton pRipple type="button"
                    icon="pi pi-pencil" class="p-button-rounded p-button-text"
                    [style]="{'margin-right': '5px'}" label="Modifier" (click)="update()"></button>
            <br>
            <button *ifRoles='["administrateur","admin_metier","admin_responsable_DPO"]' pButton pRipple type="button"
                    icon="pi pi-trash" (click)="confirm()" class="p-button-rounded p-button-text"
                    label="Supprimer"></button>
          </div>
        </ng-template>
      </p-overlayPanel>
      <p-confirmDialog baseZIndex=5 styleClass="main-page" [style]="{width: '60%'}"></p-confirmDialog>
      <p-dialog header="Importer une liste de compétences" [(visible)]="displayImportFile"
                [modal]="true" [style]="{width: '50vw'}"
                [baseZIndex]="10000"
                [draggable]="false" [resizable]="false">

        <div class = "import_dialog" >

          

          <div class="import_button_bar">
            <button class="p-button p-fileupload-choose p-component btn_download_template"
              (click)="downloadTemplate()">
              <i class="pi pi-download"></i>{{'tooltip_DOWNLOAD_TEMPLATE'|translate}}
            </button>
            <p-fileUpload
            #fileUpload
            [multiple]="false"
            chooseLabel="Choisir"
            [showCancelButton] = "false"
            uploadLabel="Charger"
            accept=".xlsx,.csv"
            customUpload="true"
            (uploadHandler)="getFile($event,fileUpload)"
            (onRemove)="fileRemoved()"
            (onSelect)="fileSelected()"
            ></p-fileUpload>
  
            
            
            <!-- <div class="ml-3 mt-1 btn_import_info" (click)="openClose()" >
              <i class="fa fa-info-circle text-indigo" style="font-size: 22px; cursor: pointer;"></i>
            </div> -->
          </div>
            <div class="UseGuideOpen" (click)="openClose()">
              <i *ngIf="!isOpen" class="bi bi-chevron-down"></i><i *ngIf="isOpen" class="bi bi-chevron-up"></i>{{'template_USER_GUIDE'|translate}}
            </div>

            <div [@openClose]="isOpen ? 'true' : 'false'" class="card-text" *ngIf="isOpen">
              <h2 style="font-size: 20px; padding-left : left; margin-bottom: 10px;">
                <em>{{enumImportDescription}}</em>
              </h2>
              <ul class="csv_legend">
                <li *ngFor="let enum of enumsInfo">
                  <p>{{ enum.legendName }}</p>
                  <p class="labelComment">{{enum.label}}  {{ (enum.comment != undefined ? ': ' + enum.comment : '') }}</p> 
                </li>
              </ul>
              
            </div>
  
          
          
        </div>


        <br><br>
        <div [style]="{'float': 'right'}">
          <button pButton pRipple type="button" label="Annuler"  class="p-mr-2 p-mb-2  p-button-outlined"
                  (click)="cancelUpload(fileUpload)"></button>
        </div>
      </p-dialog>

      <p-dialog header="Liste de compétences à corriger" [(visible)]="displayErrorFile"
                [modal]="true" [style]="{width: '60vw'}"
                [baseZIndex]="10000"
                [draggable]="false" [resizable]="false">
        <div >
          <p-table
            class="table"
            #dt2
            styleClass="p-datatable-gridlines p-mb-4"
            [style]="{'font-family': 'calibri'}"
            [value]="skillsError"
            [paginator]="true"
            [rows]="10"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="{{'PAGINATION'|translate}}"
            [rowsPerPageOptions]="[10, 25, 50]"
            [globalFilterFields]="[
      'skill.index',
      'skill.type',
      'skill.name',
      'skill.description',
      'skill.validity',
      'skill.year_duration',
      'skill.month_duration',
      'skill.day_duration'
      ]"
          >
            <ng-template pTemplate="caption">
              <div class="p-d-flex">
      <span class="p-input-icon-left p-ml-auto">
      <i class="pi pi-search"></i>
      <input
        pInputText
        type="text"
        (input)="dt2.filterGlobal($event.target.value, 'contains')"
        placeholder="{{ 'recherche' | translate }}"
      />
      </span>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th>Numéro de ligne</th>
                <th>Type</th>
                <th>{{ 'NOM' | translate }}</th>
                <th>Description</th>
                <th>Validité</th>
                <th>{{'ANNEE_S_MAJ'|translate}}</th>
                <th>{{'MOIS_S_MAJ'|translate}}</th>
                <th>{{'JOUR_S_MAJ'|translate}}</th>
                <th>Tags</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-skillsError let-ri="rowIndex" >
              <tr >
                <td >{{skillsError.skill.index}}</td>
                <td >{{skillsError.skill.type}}</td>
                <td [ngStyle]="{'background-color': skillsError.message.code === 600? '#F5B7B1' : '#FFFFFF' }" [pTooltip]="skillsError.message.code === 600? skillsError.message.message : '' " tooltipZIndex="100000000000">{{skillsError.skill.name}}</td>
                <td >{{skillsError.skill.description}}</td>
                <td *ngIf="skillsError.skill.validity === true"  [ngStyle]="{'background-color': skillsError.message.code === 603? '#F5B7B1' : '#FFFFFF' }" [pTooltip]="skillsError.message.code === 603? skillsError.message.message : '' " tooltipZIndex="100000000000">Oui</td>
                <td *ngIf="skillsError.skill.validity === false"  [ngStyle]="{'background-color': skillsError.message.code === 603? '#F5B7B1' : '#FFFFFF' }" [pTooltip]="skillsError.message.code === 603? skillsError.message.message : '' " tooltipZIndex="100000000000">Non</td>
                <td [ngStyle]="{'background-color': skillsError.message.code === 602? '#F5B7B1' : '#FFFFFF' }" [pTooltip]="skillsError.message.code === 602? skillsError.message.message : '' " tooltipZIndex="100000000000">{{ skillsError.skill.year_duration }}</td>
                <td [ngStyle]="{'background-color': skillsError.message.code === 602? '#F5B7B1' : '#FFFFFF' }" [pTooltip]="skillsError.message.code === 602? skillsError.message.message : '' " tooltipZIndex="100000000000">{{ skillsError.skill.month_duration }}</td>
                <td [ngStyle]="{'background-color': skillsError.message.code === 602? '#F5B7B1' : '#FFFFFF' }" [pTooltip]="skillsError.message.code === 602? skillsError.message.message : '' " tooltipZIndex="100000000000">{{ skillsError.skill.day_duration }}</td>
                <td [ngStyle]="{'background-color': skillsError.message.code === 604? '#F5B7B1' : '#FFFFFF' }" [pTooltip]="skillsError.message.code === 604? skillsError.message.message : '' " tooltipZIndex="100000000000">{{ skillsError?.tag}}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </p-dialog>
    </div>
  </div>
  <p-sidebar (onHide)="checkSize=true" [transitionOptions]="'200ms'"  [modal]="false" [showCloseIcon]="true"  [baseZIndex]="10000" [(visible)]="visibleSidebar" position="right" [baseZIndex]="10000">

    <div>
      <h3>{{ 'assigner_les_competences' | translate }}</h3>
      <span  class="p-input-icon-left p-ml-auto">
              <i class="pi pi-search"></i>
              <input
                [style]="{'width': '320px'}"
                pInputText
                type="text"
                (input) ="filterTag($event)"

                placeholder="{{ 'recherche' | translate }}"
              />
            </span>
      <h4>{{ 'FAVORIS' | translate }}</h4>
      <span *ngFor="let tag of tags">

              <span  *ngIf="tag.bookmarked===true">
                <span [style]="{'margin-left':'5px'}">
                  <span   [style]="{'margin-right': '10px','margin-top': '10px'}">

                    <p-chip  (onDrag)="drag($event,tag)"
                              [pTooltip]="tag.description" tooltipPosition="top" tooltipZIndex="100000000"
                             [label]="tag.label" [style]="{'background': tag.colorCode,'border-radius':'6px','height':'40px','margin-top': '10px','color':'#ffffff'}" pDraggable="" >

                    </p-chip>

                  </span>
                </span>
              </span>
            </span>
      <h4>{{ 'TOUS' | translate }}</h4>
      <span *ngFor="let tag of tags">
              <span  *ngIf="tag.bookmarked===false">
                  <span [style]="{'margin-left':'5px'}">
                    <span    [style]="{'margin-right': '10px','margin-top': '10px'}">
                      <p-chip [pTooltip]="tag?.description" tooltipPosition="top" (onDrag)="drag($event,tag)" tooltipZIndex="100000000"
                      [label]="tag.label" [style]="{'background': tag.colorCode,'border-radius':'6px','height':'40px', 'margin-top': '10px','color':'#ffffff'}" pDraggable="" >
                      </p-chip>

                    </span>

                  </span>

            </span>
      </span>
    </div>

  </p-sidebar>

</div>
