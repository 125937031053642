import { Component, OnInit } from '@angular/core';
import {Currency} from "@data/schema/Currency";
import {CurrencyService} from "@data/service/currency.service";
import {MessageService} from "primeng/api";
import {Router} from "@angular/router";
import { LocalStorageService } from '@shared/service/local-storage.service';

@Component({
  selector: 'app-configure-currency',
  templateUrl: './configure-currency.component.html',
  styleUrls: ['./configure-currency.component.css']
})
export class ConfigureCurrencyComponent implements OnInit {

  currency: Currency;
  listCurrency: any;
  currencyList: Currency[];
  mode: string;
  setting: string = "Paramétrage de la devise";

  constructor(private currencyService: CurrencyService,
              private messageService: MessageService,
              private router: Router,
              private localStorage: LocalStorageService) {
    this.currencyList = [];
    this.mode = "consultation";
    this.currency = new Currency(JSON.parse(this.localStorage.getLocalStorageItem("currency")));
  }

  ngOnInit(): void {
    this.listCurrency = this.currencyService.getListCurrency();
    this.currencyList = [];

    // //get currency
    // this.currencyService.GetCurrency().subscribe(
    //   (res)=> {
    //     console.log(res.body.currency);
    //     this.currency = res.body.currency;
    //   }
    // )

    //get list currency
    for (const property in this.listCurrency) {
      let currency: Currency = {
        currencyId: null,
        currencyCode: property,
        currencyLabel: this.listCurrency[property]
      }
      this.currencyList.push(currency)
    }
  }

  configureCurrency(){
    this.currencyService.ConfigureCurrency(this.currency).subscribe(
      (res) => {
        if(res.status === 200){
          this.messageService.add({severity: 'success', summary: 'Opération réussie', detail: res.message});
          this.localStorage.setLocalStorageItem('currency',JSON.stringify(this.currency));
          this.router.navigate(['/phoenix/show-currency']);
        }
      }
    )
  }

  backToCurrency(){
    this.router.navigate(['/phoenix/show-currency']);
  }

}
