<app-kober-menu module="kober-skill"></app-kober-menu>
<div class="p-grid p-d-flex p-jc-center p-m-2 p-p-2 main-page margin-b-20">

  <div class="p-grid p-col-12 p-md-11 p-m-3 p-p-3 main-content p-shadow-4">
    <div class="p-col-12">
      <h2> {{ 'CREER_UNE_COMPETENCE' | translate }} </h2>
    </div>

    <div class="p-grid p-col-12 p-d-flex p-jc-center">


      <div class="p-col-6">

        <div class="p-fluid">
          <div class="p-field p-grid">
            <label for="name" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
              {{ 'NOM_COMPETENCE' | translate }}
            </label>
            <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
              <input id="name" [ngClass]="{'ng-invalid ng-dirty': errorNameRequired}" type="text" pInputText [(ngModel)]="skill.name" (blur)="nameFieldValidator($event)" (input)="nameFieldValidator($event)" />
              <p-message
                *ngIf="errorNameRequired"
                severity="error"
                text="Champ obligatoire."
              ></p-message>
            </div>
          </div>


          <div class="p-field p-grid">
            <label for="description" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
              {{ 'DESCRIPTION' | translate }}
            </label>
            <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
              <textarea [rows]="4"pInputTextarea [(ngModel)]="skill.description"></textarea>
            </div>
          </div>
          <div class="p-field p-grid" *ngIf="isEligibility">
            <label for="validity" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
              {{ 'DUREE_DE_VALIDITE' | translate }}
            </label>
            <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
              <p-inputSwitch [(ngModel)]="skill.validity"></p-inputSwitch>
            </div>
          </div>
          <div class="p-field p-grid" *ngIf="skill?.validity">
            <label for="year_duration" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
              {{ 'ANNEES_S_MAJ' | translate }}*
            </label>
            <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
              <p-inputNumber id="year_duration" (onInput)="dateYearFieldValidator($event)" [(ngModel)]="skill.year_duration"></p-inputNumber>
            </div>
          </div>
          <div class="p-field p-grid" *ngIf="skill?.validity">
            <label for="month_duration" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
              {{ 'MOIS_S_MAJ' | translate }}  *
            </label>
            <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
              <p-inputNumber id="month_duration" (onInput)="dateMonthFieldValidator($event)" [(ngModel)]="skill.month_duration"></p-inputNumber>
            </div>
          </div>
          <div class="p-field p-grid" *ngIf="skill?.validity">
            <label for="day_duration" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
              {{ 'JOUR_S_MAJ' | translate }} *
            </label>
            <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
              <p-inputNumber id="day_duration" (onInput)="dateDayFieldValidator($event)" [(ngModel)]="skill.day_duration"></p-inputNumber>
              <p-message
                *ngIf="errorDateRequired"
                severity="error"
                text="Au moins un champ (année, mois ou jour) doit être rempli."
              ></p-message>
              <p-message
                *ngIf="errorInvalidValue"
                severity="error"
                text="Format non valide (caractères acceptés : numériques positifs uniquement)"
              ></p-message>
            </div>
          </div>


          <div class="p-field p-grid">
            <label for="type" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
              {{ 'TYPE_COMPETENCE' | translate }}*
            </label>
            <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
              <p-dropdown
                id="type"
                [options]="typeOptions"
                placeholder="Type de compétence"
                [(ngModel)]="skill.type"
                optionLabel="name"
                optionValue="value"
                [ngClass]="{'ng-invalid ng-dirty': errorTypeRequired}"
                (onChange)=typeFormRendering($event)
                (onBlur)=selectValidator()
              ></p-dropdown>
              <p-message
                *ngIf="errorTypeRequired"
                severity="error"
                text="Champ obligatoire."
              ></p-message>
            </div>
          </div>

          <div class="p-field p-grid">
            <label for="name" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
              Tags
            </label>
            <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">

              <p-multiSelect id="multiselectTag" #ms [options]="tags" [group]="true" defaultLabel="{{ 'FILTRER_PAR_TAG' | translate }}"
                             [(ngModel)]="selectedTags"
                             optionLabel="label" (onClick)="onClickElement($event)"
                             (onChange)="changeTag($event)"
                             [dropdownIcon]="iconChange">
                <ng-template let-value pTemplate="selectedItems">
                  <div class="p-grid" style="margin:auto">
                    <p-chip [label]="option.label" [style]="{'background': option.colorCode, 'margin':'auto 0','color':'#ffffff','border-radius':'6px'}"
                            (onRemove)="chipRemove(option)" [pTooltip]="option?.description" tooltipPosition="top" tooltipZIndex="100000000000"
                            [removable]="true" *ngFor="let option of selectedTags">
                    </p-chip>
                    <div *ngIf="selectedTags.length === 0" class="tag-placeholder">
                      {{ 'AJOUTER_TAGS' | translate }}
                    </div>
                  </div>
                </ng-template>

                <ng-template let-item pTemplate="item">
                  <div [ngStyle]="{'background-color': item.colorCode,'width':'10px','height':'10px','color':'#ffffff', 'border-radius':'2px','margin-right':'5%'}"></div>
                  <div class="tag-item" [pTooltip]="item.description" tooltipPosition="right" tooltipZIndex="100000000000">
                    {{item.label}}
                  </div>
                </ng-template>
              </p-multiSelect>


            </div>
          </div>

          <div class="p-text-left">
            <p-checkbox (onChange)="wantToCreate($event)" ></p-checkbox>
              {{ 'CREER_AUTRE_COMPETENCE' | translate }}
          </div>

        </div>



      </div>


    </div>

  </div>
</div>


<p-confirmDialog baseZIndex=5 styleClass="main-page" [style]="{width: '60%'}"></p-confirmDialog>
<p-sidebar
  class="p-text-right main-page"
  [style]="{ height: '13vh' }"
  [visible]="true"
  [blockScroll]="true"
  [dismissible]="false"
  [showCloseIcon]="false"
  [modal]="false"
  position="bottom">
  <div>

    <p-button  (click)="confirm()" styleClass="p-button-outlined p-mr-2" label="{{ 'formulaire_RETOUR' | translate }}"></p-button>

    <p-button (click)="submit()" styleClass="p-mr-2" label="{{ 'formulaire_VALIDER' | translate }}"></p-button>
  </div>
</p-sidebar>
