import { Injectable } from '@angular/core';
import { Persona } from "@data/schema/Persona"

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user:Persona;

  constructor() { }
}