
<div class="p-p-2" >

  <div class="p-grid">

    <div class="p-col-4">
        <h3>  {{ 'NOM' | translate }} : </h3>
        {{ skill.name }}
        <h3> Type : </h3>
        <p *ngIf="skill.type === 'HABILITATION'">{{ 'HABILITATION' | translate }}</p>
        <p *ngIf="skill.type === 'SAVOIR_ETRE'">{{ 'SAVOIR_ETRE' | translate }} </p>
        <p *ngIf="skill.type === 'SAVOIR_FAIRE'">{{ 'SAVOIR_FAIRE'| translate }}</p>
        <h3> Description : </h3>
        {{ skill.description }}
        <span *ngIf="skill.type === 'HABILITATION' ">
          <h3> Durée de validité : </h3>
          {{ getValidity(skill.year_duration, skill.month_duration, skill.day_duration) }}
        </span>

    </div>

    <div class="p-col-8">
      <div class="p-grid">
        <div class="p-col-2"></div>
        <div class="p-col-5"><h3> {{ 'NOM_FAMILLE' | translate }}</h3></div>
        <div class="p-col-5"><h3>Poste</h3></div>
      </div>
      <div class="p-grid" *ngFor="let persona of listPersona">
        <div class="p-col-2"><p-avatar styleClass="p-mr-2" size="xlarge" shape="circle"><img style="object-fit: cover; " src="{{ persona?.photo }}"
          /></p-avatar></div>
        <div class="p-col-5"><p>{{ persona.firstName }} {{ persona.lastName }}</p></div>
        <div class="p-col-5"><p>{{ persona.position }} </p></div>
      </div>
    </div>
  </div>

</div>


