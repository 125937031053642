<div class="p-d-flex p-jc-between p-m-3">
  <h4 pTooltip="Somme des coûts et prix des prestations : fourniture et main d’œuvre." tooltipPosition="right">Budget total</h4>
  <div class="p-d-flex p-ai-center">
    <div class="cost p-p-2 p-m-2" *ngIf="companyCost.cost && currency?.currencyCode">
      {{ globalCost?.cost + companyCost?.cost | currency: currency.currencyCode }}
    </div>
    <div class="cost p-p-2 p-m-2" *ngIf="!companyCost.cost && currency?.currencyCode">
      {{ globalCost?.cost | currency: currency.currencyCode }}
    </div>
    <div class="warning">
      <i *ngIf="listUniqueProjectWork && !complete" pTooltip="Prix incomplet : certaines prestations ne sont pas encore planifiées ou affectées à des entreprises" tooltipPosition="left" class="pi pi-exclamation-circle" style="font-size: 2rem; color:red"></i>
    </div>
  </div>
</div>

<div class="p-d-flex p-jc-between p-m-3">
  <h4 pTooltip="Calculé en fonction du nombre de prestations et du cout unitaire paramétré pour chacune d’elles. Les prestations non cotées ne sont pas affichées dans ce tableau. Le cout des prestations représente le cout des fournitures pour la réalisation du projet / OT." tooltipPosition="right">Coût des prestations</h4>
  <div class="p-d-flex p-ai-center">
    <div class="cost p-p-2 p-m-2" *ngIf="currency?.currencyCode">
      {{ globalCost.cost | currency: currency.currencyCode }}
    </div>
    <div class="warning"></div>
  </div>
</div>
<p-table
  #dt1
  (sortFunction)="naturalSortCost($event)"
  [customSort]="true"
  sortField="work.name" 
  [sortOrder]='1'
  [value]="listUniqueProjectWork"
  styleClass="p-datatable-sm p-datatable-striped"
  [globalFilterFields]="['work.name', 'quantityTotal', 'work.cost', 'total']"
>

  <ng-template pTemplate="header">
    <tr>
      <th class="head" pSortableColumn="work.name">
        Prestations<p-sortIcon field="work.name"></p-sortIcon>
      </th>
      <th class="head p-text-right" pSortableColumn="quantityTotal">
        Quantité<p-sortIcon field="quantityTotal"></p-sortIcon>
      </th>
      <th class="head p-text-right" pSortableColumn="work.cost">
        Coût unitaire<p-sortIcon field="work.cost"></p-sortIcon>
      </th>
      <th class="head p-text-right" pSortableColumn="total">
        Coût total<p-sortIcon field="total"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-projectWork>
    <tr>
      <td>{{ projectWork.work.name }}</td>
      <td class="p-text-right">{{ projectWork.quantityTotal }}</td>
      <td class="p-text-right">
        {{ projectWork.work.cost | currency: currency?.currencyCode }}
      </td>
      <td class="p-text-right">
        {{
          calculTotalByWork(projectWork.quantityTotal, projectWork.work.cost) | currency: currency?.currencyCode
        }}
      </td>
    </tr>
  </ng-template>
</p-table>
<br />

<div class="p-d-flex p-jc-between p-m-3">
  <h4 pTooltip="Calculé en fonction des prestataires (entreprises) qui réaliseront les prestations, sur la base des contrats. Le prix des prestations représente le cout de la main d‘œuvre pour le projet / OT." tooltipPosition="right">Prix des prestations</h4>
  <div class="p-d-flex p-ai-center">
    <div class="cost p-p-2 p-m-2" *ngIf="companyCost.cost && currency">
      <span>{{ companyCost.cost | currency: currency?.currencyCode }}</span>
    </div>
    <div class="warning">
      <i *ngIf="listUniqueProjectWork && !complete" pTooltip="Prix incomplet : certaines prestations ne sont pas encore planifiées ou affectées à des entreprises" tooltipPosition="left" class="pi pi-exclamation-circle" style="font-size: 2rem; color:red"></i>
    </div>
  </div>
</div>
<p-table
  #dt2
  (sortFunction)="naturalSortCost($event)"
  sortField="workId.name" 
  [sortOrder]='1'
  [customSort]="true"
  *ngIf="listWorkOrderProjectWork"
  [value]="listWorksWithPrice"
  styleClass="p-datatable-sm p-datatable-striped"
  [globalFilterFields]="['workId.name', 'quantity', 'price', 'total']"
>


  <ng-template pTemplate="header">
    <tr>
      <th class="head" pSortableColumn="workId.name">
        Prestations<p-sortIcon field="workId.name"></p-sortIcon>
      </th>
      <th class="head p-text-right" pSortableColumn="quantity">
        Quantité<p-sortIcon field="quantity"></p-sortIcon>
      </th>
      <th class="head p-text-right" pSortableColumn="price">
        Prix unitaire<p-sortIcon field="price"></p-sortIcon>
      </th>
      <th class="head p-text-right" pSortableColumn="total">
        Prix total<p-sortIcon field="total"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-contractWorkCompany>
    <tr>
      <td>{{ contractWorkCompany?.workId.name }}</td>
      <td class="p-text-right">{{contractWorkCompany?.quantity}}</td>
      <!--<td class="p-text-right">{{ contractWorkCompany?.price | currency:contractWorkCompany?.currency}}</td>-->
      <td class="p-text-right">{{ contractWorkCompany?.price | currency:currency?.currencyCode}}</td>
      <!--<td class="p-text-right">{{ calculTotalByWork(contractWorkCompany?.quantity, contractWorkCompany?.price) | currency:contractWorkCompany?.currency }}</td>-->
      <td class="p-text-right">{{ calculTotalByWork(contractWorkCompany?.quantity, contractWorkCompany?.price) | currency:currency?.currencyCode }}</td>
      <!-- <td class="p-text-right">{{ contractWorkCompany?.quantity }}</td> -->
      <!-- <td class="p-text-right">
        {{
          contractWorkCompany?.price | currency: contractWorkCompany?.currency
        }}
      </td>
      <td class="p-text-right">
        {{
          calculTotalByWork(
            contractWorkCompany?.quantity,
            contractWorkCompany?.price
          ) | currency: contractWorkCompany?.currency
        }}
      </td> -->
    </tr>
  </ng-template>
</p-table>

<p-accordion *ngIf="projectWorkByCompany" [multiple]="true">
  <div *ngFor="let a of listWorkWithPrice">
    <p-accordionTab>
      <ng-template pTemplate="header">
        <div class="p-d-flex p-jc-between accordion">
          <div class="p-d-flex p-ai-center">
            <span class="wrap p-d-flex p-ai-center p-mr-2"><i class="pi company-icon"></i></span>
            {{ a.company.name }}
          </div>
          <div *ngIf="a.globalCost && currency" class="cost p-p-2 p-m-2">
            {{ a.globalCost.cost | currency: a.globalCost.currency}}
          </div>
        </div>

      </ng-template>
      <ng-template pTemplate="content">
        <p-table
          #dt3
          (sortFunction)="naturalSortCost($event)"
          sortField="work.name" [sortOrder]='1'
          [customSort]="true"
          [value]="a.listWork"
          styleClass="p-datatable-sm p-datatable-striped"
          [globalFilterFields]="['work.name', 'quantity', 'work.cost', 'total']"
        >
       
          <ng-template pTemplate="header">
            <tr>
              <th class="head" pSortableColumn="work.name">
                Prestations<p-sortIcon field="work.name"></p-sortIcon>
              </th>
              <th class="head p-text-right" pSortableColumn="quantity">
                Quantité<p-sortIcon field="quantity"></p-sortIcon>
              </th>
              <th class="head p-text-right" pSortableColumn="work.cost">
                Prix unitaire<p-sortIcon field="work.cost"></p-sortIcon>
              </th>
              <th class="head p-text-right" pSortableColumn="total">
                Prix total<p-sortIcon field="total"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-pw>
            <tr>
              <td>{{ pw.work.name }}</td>
              <td class="p-text-right">{{ pw.quantityTotal }}</td>
              <td class="p-text-right">
                {{ pw.work.cost | currency: pw.work.currency }}
              </td>
              <td class="p-text-right">
                {{
                pw.work.cost * pw.quantityTotal | currency: pw.work.currency
                }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </ng-template>
    </p-accordionTab>
  </div>
</p-accordion>
<!--  -->
