import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {FormGroup} from "@angular/forms";
import {Materiel} from "@data/schema/Materiel";
import {Tag} from "@data/schema/Tag";
import {EnvService} from "@core/service/env.service";
import { EnumInfoDto } from '@data/schema/EnumInfoDto';


@Injectable({
  providedIn: 'root'
})
export class MaterialService {


  private apiURL = this.environment?.readConfig()?.urlApiGateway + "material/";

  constructor(private http: HttpClient, private environment: EnvService) {
  }

  public assignTagToMaterials(materials : Materiel[]): Observable<any> {
    return this.http.post(this.apiURL + "list/assign-tags", materials , {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }

  public updateMaterialsWithTags(materials : Materiel[]): Observable<any> {
    return this.http.put(this.apiURL + "list/update-with-tags", materials , {
      observe: 'response' as 'body',
    });
  }


  public updateMaterialMinQuantity(materials: Materiel[], idLocalisation: number, codeTypeStock: string): Observable<any> {
    return this.http.post(this.apiURL + "update/min-quantity", materials, {
      params: {
        idLocalisation: idLocalisation,
        codeTypeStock: codeTypeStock
      },
      observe: 'response' as 'body',
    });
  }

  // GET LIST OF ALL MATERIAL BY TAGS
  public getMaterialsListByTag(listOfTags: Tag[]): Observable<any> {
    return this.http.post(this.apiURL + "listMaterialsByTag", listOfTags, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;

      })
    )
  }

  public getAllMaterials(): Observable<Materiel[]> {
    return this.http.get<Materiel[]>(this.apiURL + "list");
  }


    public getListMaterialsWithTags(): Observable<any> {
      return this.http
        .get(this.apiURL + 'getListMaterial', {observe: 'response' as 'body'})
        .pipe(
          map((res) => {
            return res;
          })
        );
    }


  public ListMateriel(): Observable<any> {
    return this.http.get(this.apiURL + "list", {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }

  public listMaterielWithMinQuantity(idLocalisation : number, codeTypeStock: string): Observable<any> {
    return this.http.get(this.apiURL + "min-quantity/"+idLocalisation+"/"+codeTypeStock, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }

  public createMateriel(materiel: FormGroup): Observable<any> {
    return this.http.post(this.apiURL + "save", materiel.value, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    );
  }

  public getMaterialById(id: number): Observable<any> {
    return this.http.get(this.apiURL + id, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }

  // UPDATE A Material WITH NEW VALUES
public updateMaterial(id: number, material: Materiel): Observable<any> {
  return this.http.post(this.apiURL + `update/` + id, material, {observe: 'response' as 'body'});
}

// DELETE A Material from list
public deleteMaterial(id: number): Observable<any> {
  return this.http.delete(this.apiURL + "delete/" + id, {observe: 'response' as 'body'});
}

  public postFile( materiels: Materiel[]): Observable<any>{
    return this.http.post(this.apiURL + "save-all", materiels, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    );
  }

  public check_reference( stock: any): Observable<any>{
    return this.http.post(this.apiURL + "check-reference", stock, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    );
  }

  isMaterialUsedInStock(id: number): Observable<any> {
    return this.http.get(this.apiURL + "exists-in-stock/" + id , {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }

  getConsummable(list : Materiel[]){
    let array = [];
    for (const material of list){
      if (material.categorie.code === "CSM" || material.categorie.code === "SER"){
        array.push(material)
      }
    }
    return array;
  }

  getTools(list : Materiel[]){
    let array = [];
    for (const material of list){
      if (material.categorie.code === "OUT" || material.categorie.code === "POO"){
        array.push(material)
      }
    }
    return array;
  }

  getTagsMaterial(): Observable<any> {
    return this.http.get(this.apiURL + "tags", {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }


  async getTemplateXLSX(): Promise<Blob> {
    try {
      let base_url = this.apiURL;
      let url = base_url + "import/download_xlsx_template";
      
      const response = await this.http.get(url, { responseType: 'blob' }).toPromise();
      return response as Blob;
    } catch (error) {
      console.error('Error fetching XLSX file:', error);
      throw error;
    }
  }

  getLegendEnumNames( language): Observable<EnumInfoDto[]> {
    let base_url = this.apiURL;
    return this.http.get<EnumInfoDto[]>(base_url + '/import/legend/' + language);
  }

}
