<p-table
  #dt1
  [value]="tags"
  class="table"
  [paginator]="true"
  [rows]="10"
  [sortOrder]='1'
  dataKey="label"
  sortField="label"
  (sortFunction)="naturalSortTag($event)"
  [customSort]="true"
  styleClass="p-datatable-sm p-datatable-striped"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="{{'PAGINATION'|translate}}"
  [rowsPerPageOptions]="[10, 25, 50]"
  [globalFilterFields]="[
          'label',
        ]">
  <ng-template pTemplate="caption">
    <div class="p-d-flex">

            <span class="p-input-icon-left p-ml-auto" [style]="{'height':'20px','margin-bottom':'30px'}">
              <i class="pi pi-search"  [style]="{'height': '45px','margin-top':'5px'}"></i>
              <input p   pInputText
                     [style]="{'height': '45px'}"
                     type="text"
                     (input)="dt1.filterGlobal($event.target.value, 'contains')"
                     placeholder="Rechercher"/>
            </span>
    </div>
  </ng-template>
  <ng-template pTemplate="colgroup">
    <colgroup>
      <col style="width:92%">
      <col style="width:3%">
      <col style="width:3%">
      <col style="width:3%">
      <col style="width:2%">
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" >
    <tr>
      <th pResizableColumn pSortableColumn="label">
        Tag
        <p-sortIcon field="label"></p-sortIcon>
      </th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
  </ng-template>

  <ng-template pTemplate="body" let-tag>
    <tr>
      <td>
        <p-chip
        [pTooltip]="tag.description" tooltipPosition="right"
        [label]="tag.label"
        [style]="{'background': tag.colorCode,'border-radius':'6px','height':'40px','margin-top': '1px','color':'#ffffff'}"
        class ="Tag" [pTooltip]="tag?.description" tooltipZIndex="100000000000">
        </p-chip>

      </td>
      <td *ngIf="tag?.bookmarked">
        <img (click)="bookmarked(tag?.tagId)"
          width="18px"
          height="18px"
          src="../../../assets/SVG/menu/star-fill.svg"
        />
      </td>

      <td *ngIf="!tag?.bookmarked">
        <img (click)="bookmarked(tag?.tagId)"
          width="18px"
          height="18px"
          src="../../../assets/SVG/menu/star.svg"
        />
      </td>

      <td>
        <img *ifRoles='["administrateur",  "admin_metier","admin_responsable_DPO", "manager_operationnel","manager_operationnel_prestataire",
        ]'
             width="18px"
             height="18px"
             src="../../../assets/SVG/menu/pencil.svg"
             (click)="update(tag?.tagId)"
        />
      </td>
      <td>
        <img *ifRoles='["administrateur",  "admin_metier","admin_responsable_DPO", "manager_operationnel","manager_operationnel_prestataire",
      ]'
             width="18px"
             height="18px"
             src="../../../assets/img/trash.svg"
             (click)="tagUsed(tag?.tagId)"
        />
      </td>
      <td></td>
    </tr>
  </ng-template>
</p-table>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" [style]="{width: '60%'}"></p-confirmDialog>


