<app-kober-menu module="kober-skill"></app-kober-menu>
<div class="p-d-flex p-jc-center p-m-2 p-p-2 main-page">
  <div class="p-grid p-ai-center vertical-container p-jc-center">
    <div class="p-col-12 p-md-11 p-jc-center p-m-3 p-p-3 main-content p-shadow-4">
      <div class="p-grid p-jc-between p-p-3">
        <h2>{{'ENTREPRISES'|translate}}</h2>
        <!-- <button *ifRoles='["administrateur",  "admin_metier","admin_responsable_DPO"]' class="p-mr-5 p-mt-5" pButton routerLink="/phoenix/new-company" type="button" label="Créer"></button> -->
      </div>

      <p-table  #dt1 [value]="company"
                class ="table"
                [scrollable]="true"
                scrollHeight="40vh"
                [paginator]="true"
                [rows]="10"
                (sortFunction)="naturalSortCompany($event)"
                [customSort]="true"
                sortField="name"
                [sortOrder]='1'
                dataKey="name"
                styleClass="p-datatable-sm p-datatable-striped"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{{'PAGINATION'|translate}}"
                [rowsPerPageOptions]="[10, 25, 50]"
                [globalFilterFields]="[
                'name',
                'type',
                'manager',
                'phone',
                'email'
              ]">

        <ng-template pTemplate="caption">
          <div class="p-d-flex">
            <p-button (click)="onPress()">{{display}}</p-button>
            <span class="p-input-icon-left p-ml-auto">
              <i class="pi pi-search"></i>
              <input p   pInputText
                     type="text"
                     (input)="dt1.filterGlobal($event.target.value, 'contains')"
                     placeholder="{{ 'recherche' | translate }}"/>
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th id="name" pSortableColumn="name">
              {{ 'NOM' | translate }}
              <p-sortIcon field="name"></p-sortIcon>
            </th>
            <th id="type" pSortableColumn="type">
              {{ 'TYPE' | translate }}
              <p-sortIcon field="type"></p-sortIcon>
            </th>
            <th id="manager" pSortableColumn="manager">
              {{'DIRIGEANT'|translate}}
              <p-sortIcon field="manager"></p-sortIcon>
            </th>
            <th id="mail" pSortableColumn="email" >
              {{'EMAIL'|translate}}
              <p-sortIcon field="email"></p-sortIcon>
            </th>
            <th id="phone" pSortableColumn="phone">
              {{'TELEPHONE'|translate}}
              <p-sortIcon field="phone"></p-sortIcon>
            </th>
            <th id="actions" colspan="2"  class="p-text-center table-10"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-company  let-ri="rowIndex" let-columns>
          <tr>
            <td>{{ company.name }}</td>
            <td>{{ company.type }}</td>
            <td>{{ company.manager }}</td>
            <td>{{ company.email }}</td>
            <td>{{ company.phone }}</td>
            <td class="p-text-center table-10">
              <button
                routerLink="/phoenix/show-company/{{ company.companyId }}"
                pButton
                pRipple
                type="button"
                icon="pi pi-search"
                class="p-button-rounded p-button-text p-button-plain"
              ></button>
            </td>
            <!-- <td class="p-text-center table-5">
              <button *ifRoles='["administrateur",  "admin_metier","admin_responsable_DPO", "manager_operationnel"]' pButton pRipple type="button" icon="pi pi-ellipsis-h"
                      (click)="getRowCompany(company);op.toggle($event)"  class="p-button-rounded p-button-text p-button-plain"></button>
            </td> -->
          </tr>
        </ng-template>
      </p-table>

      <!-- <p-overlayPanel #op appendTo="body" baseZIndex="2">
        <ng-template pTemplate>
          <div #actualTarget>
            <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text"
                    [style]="{'margin-right': '5px'}" label="Modifier" (click)="update()"></button><br>
            <ng-container *ifRoles='["administrateur",  "admin_metier","admin_responsable_DPO"]'>
              <button *ngIf="disabled" pButton pRipple type="button" icon="pi pi-trash" (click)="confirm()" class="p-button-rounded p-button-text"label="Supprimer"></button>
            </ng-container>
            <ng-container *ifRoles='["administrateur",  "admin_metier","admin_responsable_DPO"]'>
              <button *ngIf="!disabled" pButton pRipple type="button" icon="pi pi-undo" (click)="confirm()" class="p-button-rounded p-button-text"label="Activer"></button>
            </ng-container>
          </div>
        </ng-template>
      </p-overlayPanel> -->
      <p-confirmDialog baseZIndex=5 styleClass="main-page" [style]="{width: '60%'}"></p-confirmDialog>

    </div>
  </div>
</div>
