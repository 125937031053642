<app-kober-menu module="kober-setting"></app-kober-menu>
<div class="p-grid p-d-flex p-jc-center p-m-2 p-p-2 main-page margin-b-20">
  <div class="p-grid p-col-12 p-md-11 p-m-3 p-p-3">
    <app-setting-currency [(setting)]="setting"></app-setting-currency>
  </div>
  <div class="p-grid p-col-12 p-md-11 p-m-3 p-p-3 main-content p-shadow-2">
    <div class="p-grid p-col-12 p-d-flex">
      <div class="p-col-12">
        <h2>Devise</h2>
      </div>
      <div class="p-col-12">
        <label for="label" class="p-mb-2 p-md-2 p-mb-md-0"> Sélectionner la devise </label>
      </div>

      <div class="p-col-12">
        <div class="p-fluid">
          <div class="p-grid">
            <div class="p-col-6">
              <p-dropdown *ngIf="!currency" placeholder=""
                          [options]="currencyList" filterBy="currencyCode" [(ngModel)]="currency"
                          [filter]="true"
                          optionLabel="currencyLabel">
                <ng-template let-item pTemplate="item">
                  {{item.currencyCode}} ({{item.currencyLabel}})
                </ng-template>

                <ng-template let-item pTemplate="selectedItem">
                  {{item.currencyCode}}  ({{item.currencyLabel}})
                </ng-template>
              </p-dropdown>

              <p-dropdown *ngIf="currency" dataKey="currencyCode" name="currencyCode" id="currency"
                          [options]="currencyList" filterBy="currencyCode" [(ngModel)]="currency"
                          [filter]="true"
                          optionLabel="currencyLabel">
                <ng-template let-item pTemplate="item">
                  {{item.currencyCode}} ({{item.currencyLabel}})
                </ng-template>

                <ng-template let-item pTemplate="selectedItem">
                  {{item.currencyCode}}  ({{item.currencyLabel}})
                </ng-template>
              </p-dropdown>

              <p-message
                *ngIf="false"
                severity="error"
                text="Champ obligatoire."
              ></p-message>
              <p-message
                *ngIf="false"
                severity="error"
                text="Devise déja existant."
              ></p-message>
            </div>
            <div class="p-col-6">
              <button class="btnCurrency" pButton type="button" label="Paramétrer"
                      (click)="configureCurrency()"></button>
            </div>
          </div>
      </div>


    </div>


  </div>
  <p-confirmDialog baseZIndex="5" styleClass="main-page" [style]="{ width: '60%' }"></p-confirmDialog>
</div>


