import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Tag} from "@data/schema/Tag";
import {EnvService} from "@core/service/env.service";

@Injectable({
  providedIn: 'root'
})
export class TagService {

  private apiURL = this.environment?.readConfig()?.urlApiGateway + "tag/";
  //tag: Tag;

  constructor(private http: HttpClient, private environment: EnvService) { }

  public getAllTags(): Observable<any> {
    return this.http.get(this.apiURL + "getAll" , {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }



  public saveTag(tag : Tag): Observable<any> {
    return this.http.post(this.apiURL + "save", tag , {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }


  public getAllTagsBookmarkted(): Observable<any> {
    return this.http.get(this.apiURL + "getAllBookmarked" , {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }


  public Bookmark(idTag : number): Observable<any> {
    return this.http.put(this.apiURL + "activateBookmarked" , idTag, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }


  public deleteTag(idTag : number): Observable<any> {
    return this.http.delete(this.apiURL + "deleteTag/" + idTag, {observe: 'response' as 'body'}).pipe(
      map(res => {

        return res;
      })
    )
  }

  // Update tag with new value
  public updateTag(idTag: number, tag: Tag): Observable<any> {
    return this.http.put(this.apiURL + `updateTag/`+idTag, tag, {observe: 'response' as 'body',}).pipe(
      map(res => {
        return res;
      })
    );
  }


  //to check if tag is used
  public tagUsed(idTag: number): Observable<any> {
    return this.http.get(this.apiURL + 'tagUsed/'+idTag, {observe: 'response' as 'body'}).pipe(
      map(res => {
        console.log(res);
        return res;
      })
    )
  }

  public getTagById(idTag: number): Observable<any> {
    return this.http.get(this.apiURL + idTag, {observe: 'response' as 'body'}).pipe(
      map(res => {
        console.log(res);
        return res;
      })
    )
  }
}
