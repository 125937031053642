<div fxLayout="column" fxLayoutAlign="space-between center" class="sidenav-container"
  [@onSideNavChange]="sideNavState ? 'open' : 'close'">
  <div fxLayout="column" fxLayoutGap="10px" style="height: 100%;width: 85%;">
    <div class="photo" fxLayoutAlign="start center" style="padding-top: 5%;margin-left: 5%;">
      <button mat-icon-button routerLink="/phoenix/home">
        <mat-icon svgIcon="home" *ngIf="!sideNavState" style="width: 48px; height: 48px; margin-left: 0px; margin-right:10px;"></mat-icon>
        <mat-icon svgIcon="home" *ngIf="sideNavState"  style="width: 48px; height: 48px; margin-left: 20px;"></mat-icon>
      </button>
      <!-- <mat-icon svgIcon="chevron-grey-right" style="width: 15px;"></mat-icon> -->
      <mat-label *ngIf="!sideNavState" style="color: #A0A0A0; margin-left: 30%; font-size: 20px;">{{ "univers_INTERVENTIONS" | translate }}
      </mat-label>
      <mat-label *ngIf="sideNavState" style="color: #A0A0A0; margin-left: 20%; font-size: 20px;">{{ "univers_INTERVENTIONS" | translate }}
      </mat-label>
    </div>

    <div class="user-leftMenu">
      <mat-nav-list>
        <a mat-list-item routerLink="/../phoenix/profile/intervention">
          <img [ngClass]="{
              'profil-picture': sideNavState,
              'profil-picture-reduced': !sideNavState
            }" [src]="
              persona?.photo ? persona?.photo : '/assets/img/default-avatar.png'
            " alt="avatar" />
          <span *ngIf="sideNavState" class="username">
            <div class="menuFont" *ngIf="persona">
              {{ persona?.firstName }} {{ persona?.lastName }}
            </div>
            <label style="color:#A0A0A0" class="persona">{{ role }}</label>
          </span>
        </a>
      </mat-nav-list>
    </div>
    <mat-divider *ngIf="!sideNavState"></mat-divider>
    <div class="menu">
      <mat-nav-list>
        <a mat-list-item class="menu-list-item" (click)="setStep(3)" [ngClass]="{
          'selected': (step === 3)
        }" routerLink="/phoenix/intervention/dashboard">
          <span [ngClass]=" { 'menu-item' : sideNavState }" class="icon dashboard-icon">
          </span>
          <span class="menuFont" [@animateText]="linkText ? 'show' : 'hide'">{{"tableau_de_bord"| translate}}</span>
        </a>
        <div *ifPermission='["wo:listview"]'>
          <a mat-list-item routerLink="phoenix/intervention/wo-list" *ngIf="!sideNavState" [matMenuTriggerFor]="menu"
            #menuTrigger="matMenuTrigger" (mouseenter)="menuTrigger.openMenu()"
            aria-label="Example icon-button with a menu">
            <span class="icon orders-icon menuFont"></span>
            <mat-menu [overlapTrigger]="false" #menu="matMenu" [class]="'hover-menu'">
              <span (mouseleave)="menuTrigger.closeMenu()">
                <button routerLink="/phoenix/intervention/wo-list" mat-menu-item>
                  <mat-icon class="material-icons-outlined default">radio_button_unchecked</mat-icon>
                  <span class="menuFont">{{ "menu_TOUS" | translate }}</span>
                </button>
                <button routerLink="/phoenix/intervention/wo-list/viewByStatusGroup?statusGroup=to-plan" mat-menu-item>
                  <mat-icon class="material-icons-outlined dark-blue">radio_button_unchecked</mat-icon>
                  <span class="menuFont">{{ "menu_A_PLANIFIER" | translate }}
                  </span>
                </button>
                <button routerLink="/phoenix/intervention/wo-list/viewByStatusGroup?statusGroup=not-started" mat-menu-item>
                  <mat-icon class="material-icons-outlined blue">radio_button_unchecked</mat-icon>
                  <span class="menuFont">{{ "menu_NON_DEMARRES" | translate }}</span>
                </button>
                <button routerLink="/phoenix/intervention/wo-list/viewByStatusGroup?statusGroup=in-progress" mat-menu-item>
                  <mat-icon class="material-icons-outlined orange">radio_button_unchecked</mat-icon>
                  <span class="menuFont"> {{ "menu_EN_COURS" | translate }}
                  </span>
                </button>
                <button routerLink="/phoenix/intervention/wo-list/viewByStatusGroup?statusGroup=to-validate" mat-menu-item>
                  <mat-icon class="material-icons-outlined green">radio_button_unchecked</mat-icon>
                  <span class="menuFont">{{ "menu_A_VALIDER" | translate }}
                  </span>
                </button>
                <button routerLink="/phoenix/intervention/wo-list/viewByStatusGroup?statusGroup=on-hold" mat-menu-item>
                  <mat-icon class="material-icons-outlined yellow">radio_button_unchecked</mat-icon>
                  <span class="menuFont"> {{ "menu_EN_ATTENTE" | translate }}</span>
                </button>
                <button routerLink="/phoenix/intervention/wo-list/viewByStatusGroup?statusGroup=in-error" mat-menu-item>
                  <mat-icon class="material-icons-outlined red">radio_button_unchecked</mat-icon>
                  <span class="menuFont"> {{ "menu_EN_ERREUR" | translate }}</span>
                </button>
                <button routerLink="/phoenix/intervention/wo-list/viewByStatusGroup?statusGroup=ended" mat-menu-item>
                  <mat-icon class="material-icons-outlined grey">radio_button_unchecked</mat-icon>
                  <span class="menuFont">{{ "menu_TERMINES" | translate }}</span>
                </button>
                <button routerLink="/phoenix/intervention/wo-list/viewByTeam"  mat-menu-item>
                  <span class="menuFont">{{ "menu_ot_VUE_EQUIPE" | translate }}</span>
                </button>
                <button routerLink="/phoenix/intervention/wo/advanced-search" mat-menu-item>
                  <span class="menuFont">{{ "menu_RECHERCHE_OT" | translate }}</span>
                </button>
              </span>
            </mat-menu>
          </a>
        </div>
        <div *ifPermission='["wo:listview"]'>
          <mat-expansion-panel *ngIf="sideNavState" class="mat-elevation-z0" [expanded]="step === 0"
            (opened)="setStep(0)">
            <mat-expansion-panel-header class="menu-list-item" [ngClass]="{
            'selected': (step === 0)
          }">
              <mat-panel-title class="menuFont">
                <span class="icon orders-icon"></span>{{"ot"| translate}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="panel-body">
              <a #woListButton id="button1" [ngClass]="{
            'selected-blue': router.url === '/phoenix/intervention/wo-list/'
          }" (click)="goToUrl('/phoenix/intervention/wo-list/')" mat-list-item
                class="menu-list-item sub-menu-list-item">
                <span class="menuFont" [@animateText]="linkText ? 'show' : 'hide'">
                  <mat-icon class="material-icons-outlined default">radio_button_unchecked</mat-icon>
                  {{ "menu_TOUS" | translate }}
                </span>
              </a>
              <a #toPlanButton id="button2" [ngClass]="{
            'selected-blue': router.url === '/phoenix/intervention/wo-list/viewByStatusGroup?statusGroup=to-plan'
          }" (click)="goToUrl('/phoenix/intervention/wo-list/viewByStatusGroup', 'to-plan')" mat-list-item
                class="menu-list-item sub-menu-list-item">
                <span class="menuFont" [@animateText]="linkText ? 'show' : 'hide'">
                  <mat-icon class="material-icons-outlined dark-blue">radio_button_unchecked</mat-icon>

                  {{ "menu_A_PLANIFIER" | translate }}

                </span>
              </a>
              <a #notStartedButton id="button3" [ngClass]="{
            'selected-blue': router.url === '/phoenix/intervention/wo-list/viewByStatusGroup?statusGroup=not-started'
          }" (click)="goToUrl('/phoenix/intervention/wo-list/viewByStatusGroup', 'not-started')" mat-list-item
                class="menu-list-item sub-menu-list-item">
                <span class="menuFont" [@animateText]="linkText ? 'show' : 'hide'">
                  <mat-icon class="material-icons-outlined blue">radio_button_unchecked</mat-icon>
                  {{ "menu_NON_DEMARRES" | translate }}

                </span>
              </a>
              <a #inProgressButton id="button4" [ngClass]="{
            'selected-blue': router.url === '/phoenix/intervention/wo-list/viewByStatusGroup?statusGroup=in-progress'
          }" (click)="goToUrl('/phoenix/intervention/wo-list/viewByStatusGroup', 'in-progress')" mat-list-item
                class="menu-list-item sub-menu-list-item">
                <span class="menuFont" [@animateText]="linkText ? 'show' : 'hide'">
                  <mat-icon class="material-icons-outlined orange">radio_button_unchecked</mat-icon>
                  {{ "menu_EN_COURS" | translate }}


                </span>
              </a>
              <a #toValidateButton id="button5" [ngClass]="{
            'selected-blue': router.url === '/phoenix/intervention/wo-list/viewByStatusGroup?statusGroup=to-validate'
          }" (click)="goToUrl('/phoenix/intervention/wo-list/viewByStatusGroup', 'to-validate')" mat-list-item
                class="menu-list-item sub-menu-list-item">
                <span class="menuFont" [@animateText]="linkText ? 'show' : 'hide'">
                  <mat-icon class="material-icons-outlined green">radio_button_unchecked</mat-icon>
                  {{ "menu_A_VALIDER" | translate }}


                </span>
              </a>
              <a #onHoldButton id="button6" [ngClass]="{
            'selected-blue': router.url === '/phoenix/intervention/wo-list/viewByStatusGroup?statusGroup=on-hold'
          }" (click)="goToUrl('/phoenix/intervention/wo-list/viewByStatusGroup', 'on-hold')" mat-list-item
                class="menu-list-item sub-menu-list-item">
                <span class="menuFont" [@animateText]="linkText ? 'show' : 'hide'">
                  <mat-icon class="material-icons-outlined yellow">radio_button_unchecked</mat-icon>
                  {{ "menu_EN_ATTENTE" | translate }}

                </span>
              </a>
              <a #inErrorButton id="button7" [ngClass]="{
            'selected-blue': router.url === '/phoenix/intervention/wo-list/viewByStatusGroup?statusGroup=in-error'
          }" (click)="goToUrl('/phoenix/intervention/wo-list/viewByStatusGroup' , 'in-error')" mat-list-item
                class="menu-list-item sub-menu-list-item">
                <span class="menuFont" [@animateText]="linkText ? 'show' : 'hide'">
                  <mat-icon class="material-icons-outlined red">radio_button_unchecked</mat-icon>
                  {{ "menu_EN_ERREUR" | translate }}

                </span>
              </a>
              <a #endedButton id="button8" [ngClass]="{
            'selected-blue': router.url === '/phoenix/intervention/wo-list/viewByStatusGroup?statusGroup=ended'
          }" (click)="goToUrl('/phoenix/intervention/wo-list/viewByStatusGroup' , 'ended')" mat-list-item
                class="menu-list-item sub-menu-list-item">
                <span class="menuFont" [@animateText]="linkText ? 'show' : 'hide'">
                  <mat-icon class="material-icons-outlined grey">radio_button_unchecked</mat-icon>
                  {{ "menu_TERMINES" | translate }}
                </span>
              </a>
              <a #viewByTeamButton id="button10" [ngClass]="{
                'selected-blue': router.url === '/phoenix/intervention/wo-list/viewByTeam'
              }" (click)="goToUrl('/phoenix/intervention/wo-list/viewByTeam', null)" mat-list-item
                    class="menu-list-item sub-menu-list-item">
                    <span class="menuFont" [@animateText]="linkText ? 'show' : 'hide'">
                      <mat-icon class="material-icons-outlined invisible">radio_button_unchecked</mat-icon>
                      {{ "menu_ot_VUE_EQUIPE" | translate }}

                    </span>
                  </a>
              <a #endedButton id="button9" [ngClass]="{
              'selected-blue': router.url === '/phoenix/intervention/wo/advanced-search'
          }" (click)="goToUrl('/phoenix/intervention/wo/advanced-search', null)" mat-list-item
                class="menu-list-item sub-menu-list-item">
               <!-- TODO add space instead of invisible icon -->
                <span class="menuFont" [@animateText]="linkText ? 'show' : 'hide'">
                  <mat-icon class="material-icons-outlined invisible">radio_button_unchecked</mat-icon>
                  {{ "menu_RECHERCHE_OT" | translate }}
                </span>
              </a>
              <a #endedButton id="button10" [ngClass]="{
                'selected-blue': router.url === '/phoenix/intervention/work-order-creation'
          }" (click)="goToUrl('/phoenix/intervention/work-order-creation')" mat-list-item *ifPermission='["wo:create"]'
                class="menu-list-item sub-menu-list-item">
               <!-- TODO add space instead of invisible icon -->
                <span class="menuFont" [@animateText]="linkText ? 'show' : 'hide'">
                  <mat-icon class="material-icons-outlined invisible">radio_button_unchecked</mat-icon>
                  {{ "menu_CREATION_MANUELLE" | translate }}
                </span>
              </a>
            </div>
          </mat-expansion-panel>
        </div>
        <mat-expansion-panel *ngIf="sideNavState" class="mat-elevation-z0" [expanded]="step === 1"
          (opened)="setStep(1)">
          <mat-expansion-panel-header class="menu-list-item" [ngClass]="{
            'selected': (step === 1)
          }">
            <mat-panel-title class="menuFont matPanelTeam">
              <mat-icon style="width:19px" svgIcon="people"></mat-icon><span class="text">{{"gestion_dequipe"|
                translate}}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="panel-body">
            <!-- <a [ngClass]="{
            'selected-blue': router.url === '/phoenix/intervention/team-management/by-skill'
          }" routerLink="/phoenix/intervention/team-management/by-skill" mat-list-item class="menu-list-item sub-menu-list-item">
            <span class="menuFont" [@animateText]="linkText ? 'show' : 'hide'"
            >
              Par compétences
            </span>
            </a>
            <a [ngClass]="{
            'selected-blue': router.url === '/phoenix/intervention/team-management/by-planning'
          }" routerLink="/phoenix/intervention/team-management/by-planning" mat-list-item class="menu-list-item sub-menu-list-item">
            <span class="menuFont" [@animateText]="linkText ? 'show' : 'hide'"
            >
              Par planning
            </span>
            </a> -->
            <a [ngClass]="{ 'selected-blue': router.url === '/phoenix/intervention/team-management/teams'}"
              routerLink="/phoenix/intervention/team-management/teams" mat-list-item
              class="menu-list-item sub-menu-list-item">
              <span style="margin-left:26px;" class="menuFont " [@animateText]="linkText ? 'show' : 'hide'"
                *ifPermission='["team:list"]'>

                {{"menu_CONSULTATION_DES_EQUIPES"| translate}}

              </span>
            </a>
            <a [ngClass]="{
              'selected-blue': router.url === '/phoenix/intervention/team-management/technicians-list'
            }" selected-blue routerLink="/phoenix/intervention/team-management/technicians-list" mat-list-item
              class="menu-list-item sub-menu-list-item">
              <span style="margin-left:26px;" class="menuFont " [@animateText]="linkText ? 'show' : 'hide'">
                {{"INTERVENANTS"| translate}}

              </span>
            </a>
            <!-- Add correct url navigation-->
            <a [ngClass]="{
              'selected-blue': router.url === '/phoenix/intervention/planning-technicians'
            }" selected-blue routerLink="/phoenix/intervention/planning-technicians" mat-list-item class="menu-list-item sub-menu-list-item" style="height:40px !important;">
              <span  style="margin-left:26px;" class="menuFont " [@animateText]="linkText ? 'show' : 'hide'"
              >
              {{"disponiblite_EQUIPES " | translate}}

              </span>
            </a>

          </div>
        </mat-expansion-panel>
        <a mat-list-item routerLink="phoenix/intervention/wo-list" *ngIf="!sideNavState" [matMenuTriggerFor]="menu"
          #menuTrigger="matMenuTrigger" (mouseenter)="menuTrigger.openMenu()"
          aria-label="Example icon-button with a menu">
          <mat-icon style="width:19px" svgIcon="people"></mat-icon>
          <!--span class="icon person-icon menuFont"></span-->
          <mat-menu [overlapTrigger]="false" #menu="matMenu" [class]="'hover-menu'">
            <span (mouseleave)="menuTrigger.closeMenu()">
              <!-- <button routerLink="/phoenix/intervention/team-management/by-skill" mat-menu-item>
                <span class="menuFont">Par compétences</span>
              </button>
              <button routerLink="/phoenix/intervention/team-management/by-planning" mat-menu-item>
                <span class="menuFont">Par planning</span>
              </button> -->
              <button routerLink="/phoenix/intervention/team-management/teams" *ifPermission='["team:list"]'
                mat-menu-item>
                <span class="menuFont">{{"menu_CONSULTATION_DES_EQUIPES"| translate}}
                </span>
              </button>
              <button routerLink="/phoenix/intervention/team-management/technicians-list"
                *ifPermission='["tech-list:view"]' mat-menu-item>
                <span class="menuFont">{{"INTERVENANTS"| translate}}
                </span>
              </button>
                <!-- Add correct url navigation-->
              <button routerLink="/phoenix/intervention/planning-technicians" *ifPermission='["tech-list:view"]' mat-menu-item>
                <span class="menuFont">    {{"disponiblite_EQUIPES " | translate}}
                </span>
              </button>
            </span>
          </mat-menu>
        </a>
        <a mat-list-item class="menu-list-item" (click)="setStep(2)" [ngClass]="{
          'selected': (step === 2)
        }"
          routerLink="/phoenix/intervention/planning-interventions">

          <span [ngClass]="{
              'menu-item': sideNavState
            }" class="icon planning-icon"></span>

          <span class="menuFont" [@animateText]="linkText ? 'show' : 'hide'">Planning  </span>
        </a>
     <!--   <a mat-list-item class="menu-list-item">
          <span [ngClass]="{
              'menu-item': sideNavState
            }" class="icon rdv-icon"></span>
          <span class="menuFont" [@animateText]="linkText ? 'show' : 'hide'">
            {{"grille_rdv" | translate}}

          </span>
        </a>
         <a mat-list-item *ngFor="let page of pages">
          <mat-icon style="padding-right: 5px">{{ page?.icon }}</mat-icon>
          <span [@animateText]="linkText ? 'show' : 'hide'"
            >{{ page?.name }}
          </span>
        </a> -->
      </mat-nav-list>
    </div>
  </div>

  <span class="spacer"></span>
  <div fxLayout="column" fxLayoutGap="10px" style="width: 85%">
    <div>
      <mat-nav-list>
        <a mat-list-item class="menu-list-item" (click)="logout()">
          <span [ngClass]="{
            'menu-item': sideNavState
          }" class="icon logout-icon">
          </span>
          <span class="menuFont" [@animateText]="linkText ? 'show' : 'hide'">
            {{"deconnexion"| translate}}
          </span>
        </a>
        <a mat-list-item routerLink="/phoenix/settings/company" class="menu-list-item"
          *ifPermission="['kober:parametrage']">
          <span [ngClass]="{
            'menu-item': sideNavState
          }" class="icon gear-icon">
          </span>
          <span class="menuFont" [@animateText]="linkText ? 'show' : 'hide'">

            {{"parametrage"| translate}}
          </span>
        </a>
        <a (click)="onSinenavToggle()" mat-list-item class="menu-list-item">
          <span *ngIf="sideNavState" class="menu-item icon close-icon">
          </span>
          <span *ngIf="!sideNavState" class="icon open-icon">
          </span>
          <span class="menuFont" [@animateText]="linkText ? 'show' : 'hide'">
            {{"masquer_le_menu"| translate}}
          </span>
        </a>
      </mat-nav-list>
    </div>


  </div>
</div>
