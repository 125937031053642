import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {FormGroup} from "@angular/forms";
import {map} from "rxjs/operators";
import {Stock} from "@data/schema/Stock";
import {TypeStock} from "@data/schema/TypeStock";
import {Stat} from '@data/schema/Stat';
import {Tag} from "@data/schema/Tag";
import {EnvService} from "@core/service/env.service";
import { StockRef } from '@data/schema/StockRef';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  private apiURL = this.environment?.readConfig()?.urlApiGateway + "stock/";
  private stocks: Stock[];

  constructor(private http: HttpClient, private environment: EnvService) {
  }

  public listStock(): Observable<any> {
    return this.http.get(this.apiURL + "list", {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }

  public getStockByArticles(stockForm: any): Observable<any> {
    return this.http.post(this.apiURL + "find-article", stockForm, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }

  public listEtat(): Observable<any> {
    return this.http.get(this.apiURL + "states", {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }

  public getStatsFromStatSendCons(indexTab: number): Observable<any> {
    let obj: any = {};
    switch (indexTab) {
      case 1:
        obj.codeEtatStock = "DISPONIBLE";
        break;
      case 2:
        obj.codeEtatStock = "RETOUR_MAGASIN";
        break;
      case 3:
        obj.codeEtatStock = "RESERVE";
        break;
      case 4:
        obj.codeEtatStock = "RETOUR_MAGASIN";
        break;
      default:
        break;
    }
    return this.http.post(this.apiURL + "transition-states", obj, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }

  public getStatsFromStatSendConsS(obj: any): Observable<any> {
    return this.http.post(this.apiURL + "transition-states", obj, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }

  public newStat(stockDto: any): Observable<any> {
    return this.http.post(this.apiURL + "change-state", stockDto, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }

  public createStock(materiel: FormGroup): Observable<any> {
    return this.http.post(this.apiURL + "save", materiel.value, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    );
  }

    // get boolean that check if a technician has stock or not
 public technicianHasStock(id: number): Observable<any> {
      return this.http.get(this.apiURL + "tech-stock-exists/" +id, {observe: 'response' as 'body'}).pipe(
        map(res => {
          return res;
        })
      )
    }
  public getStockIntervenant(typeStock: TypeStock, id: number): Observable<any> {
    console.log("dede");
    console.log(typeStock);
    console.log(id);
    return this.http.post(this.apiURL + "on-board/" +id, typeStock, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }

  public historiqueArticle(mouvement: any): Observable<any> {
    return this.http.post(this.apiURL + "mouvementStock", mouvement, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }

  public postFile(stock: any): Observable<any> {
    return this.http.post(this.apiURL + "upload-material", stock, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    );
  }

  // Send technician stock (on board) id with list of selectedTags
  public filterOnboardStock(id: number,tags: Tag[]): Observable<any> {
    return this.http.post(this.apiURL + "on-board/" + id +"/filter-by-tags", tags, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    );
  }

  public filterStoreStock(id: number,tags: Tag[]): Observable<any> {
    return this.http.post(this.apiURL  + id +"/filter-by-tags", tags, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    );
  }

  public materialStateFilter(id: number, stockState: Stat): Observable<any> {
    return this.http.post(this.apiURL + id + "/state-filter", stockState, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    );
  }


  public getUsedMaterialForWorkOrder(workOrderReference? :any): Observable<any> {
    const params = new HttpParams().set('workOrderRef',workOrderReference);
    return this.http.get(this.apiURL + 'search-used-equi-wo' , {params: params})
  }


  public validateExcelOfMaterialsToImport(worksheetMaterialStock: Stock, selectedStoreId : number, worksheetArticleStock : Stock): Observable<any>{
    const requestBody = {
      worksheetMaterialStock: worksheetMaterialStock,
      selectedStoreId: selectedStoreId,
      worksheetArticleStock : worksheetArticleStock
    };
    console.log(worksheetArticleStock)
    return this.http.post(this.apiURL + "validateExcelOfMaterialsToImport", requestBody, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    );
  }
}
