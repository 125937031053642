<app-kober-menu module="kober-services"></app-kober-menu>
<div class="p-grid p-d-flex p-ml-2 p-pl-2 main-page">
  <div class="p-mt-3 p-pt-3 p-ml-3 p-pl-3">
    <p-breadcrumb [model]="items"></p-breadcrumb>
  </div>
</div>
<div class="p-d-flex p-jc-center p-m-2 p-p-2 main-page">
  <div class="p-grid p-ai-center vertical-container p-jc-center">
    <div class="p-col-12 p-md-11 p-jc-center p-m-3 p-p-3 main-content p-shadow-4" >
      <div class="p-grid p-jc-between p-p-3">
        <h2>Projets</h2>
        <button *ifRoles='["administrateur",  "admin_metier","admin_responsable_DPO","manager_operationnel"]' class="p-mr-5 p-mt-5" pButton
          routerLink="/phoenix/new-project" type="button" label="{{'CREER'|translate}}"></button>
      </div>
      <p-tabView (onChange)="handleChangeTabProject($event,'str',dt1,dt2,dt3,dt4)">
        <p-tabPanel header="Tous" >

        <p-table #dt1 [value]="projects" class="table" [scrollable]="true" scrollHeight="40vh" [paginator]="true"
          (sortFunction)="naturalSortProject($event)"
          sortField="name" [sortOrder]='1'
          [customSort]="true"
          [rows]="10" styleClass="p-datatable-sm p-datatable-striped" [showCurrentPageReport]="true"
          currentPageReportTemplate="{{'PAGINATION'|translate}}"
          [rowsPerPageOptions]="[10, 25, 50]" [globalFilterFields]="[
                  'name',
                  'description'
                ]">
          <ng-template pTemplate="caption">
            <div class="p-d-flex">
              <span class="p-input-icon-left p-ml-auto" [style]="{'height':'20px','margin-bottom':'30px'}">
                <i class="pi pi-search" [style]="{'height': '45px','margin-top':'5px'}"></i>
                <input p pInputText type="text"
                       [style]="{'height': '45px'}"
                       [value]="projetRecherche!== undefined?projetRecherche:''"
                       (input)="filterTableInputChangeTabProject($event.target.value,'contains', dt1,dt2,dt3,dt4)"
                  placeholder="Rechercher" />
              </span>


            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th id="name" pSortableColumn="name">
                  {{'NOM'|translate}}
                  <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                  <p-sortIcon field="name"></p-sortIcon>

              </th>
              <th id="description" pSortableColumn="description">
                {{'DESCRIPTION'|translate}}
                <p-columnFilter type="text" field="description" display="menu"></p-columnFilter>
                <p-sortIcon field="description"></p-sortIcon>
              </th>
              <th id="Status" pSortableColumn="Status">
                {{'status'|translate}}
                <p-columnFilter type="text" field="Status" display="menu"></p-columnFilter>
                <p-sortIcon field="Status"></p-sortIcon>
              </th>
              <th id="Avancement" >
                {{'liste_ot_AVANCEMENT'|translate}}
                <p-sortIcon field="Avancement"></p-sortIcon>
              </th>
              <th id="actions" colspan="2" class="p-text-center table-10"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-projects let-ri="rowIndex" let-columns>
            <tr>
              <td>{{ projects.name }}</td>
              <td>{{ projects.description }}</td>
              <td *ngIf="projects.status === 'TODO'">
                <span class="status-todo"> {{'etat_a_faire'|translate}} </span>
              </td>

              <td *ngIf="projects.status === 'DOING'">
                <span class="status-doing"> {{'group_en_cours'|translate}} </span>
              </td>

              <td *ngIf="projects.status === 'DONE'">
                <span class="status-done"> {{'etat_termine'|translate}}  </span>
              </td>
              <td>
                <app-progress-bar
                [progress]="getProgress(projects)"
                [total]="100"
                color="blue">
              </app-progress-bar>
              </td>
              <td class="p-text-center table-5">
                <button
                  routerLink="/phoenix/show-project/{{ projects.id }}"
                  pButton
                  pRipple
                  type="button"
                  icon="pi pi-search"
                  class="p-button-rounded p-button-text p-button-plain"
                ></button>
              </td>
              <td class="p-text-center table-5">
                <button *ifRoles='["administrateur",  "admin_metier","admin_responsable_DPO", "manager_operationnel"]'
                  pButton pRipple type="button" icon="pi pi-ellipsis-h"
                  (click)="getRowProjects(projects);op.toggle($event)"
                  class="p-button-rounded p-button-text p-button-plain"></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
        </p-tabPanel>
        <p-tabPanel header="A faire" >
          <p-table
           #dt2 [value]="projectsTodo" class="table" [scrollable]="true" scrollHeight="40vh" [paginator]="true"
           (sortFunction)="naturalSortProject($event)"
           [customSort]="true"
            sortField="name" [sortOrder]='1'
            [rows]="10" styleClass="p-datatable-sm p-datatable-striped" [showCurrentPageReport]="true"
            currentPageReportTemplate="{{'PAGINATION'|translate}}"
            [rowsPerPageOptions]="[10, 25, 50]" [globalFilterFields]="[
                    'name',
                    'description'
                  ]">
            <ng-template pTemplate="caption">
              <div class="p-d-flex">
                <span class="p-input-icon-left p-ml-auto" [style]="{'height':'20px','margin-bottom':'30px'}">
                  <i class="pi pi-search" [style]="{'height': '45px','margin-top':'5px'}"></i>
                  <input p pInputText type="text"
                         [style]="{'height': '45px'}"
                         [value]="projetRecherche!== undefined?projetRecherche:''"
                         (input)="filterTableInputChangeTabProject($event.target.value,'contains', dt1,dt2,dt3,dt4)"
                    placeholder="Rechercher" />
                </span>

              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th id="name" pSortableColumn="name">
                  {{'NOM'|translate}}
                  <p-sortIcon field="name"></p-sortIcon>
                </th>
                <th id="type" pSortableColumn="description">
                  {{'DESCRIPTION'|translate}}
                  <p-sortIcon field="description"></p-sortIcon>
                </th>
                <th id="type">
                  {{'status'|translate}}
                </th>
                <th id="type">
                  {{'liste_ot_AVANCEMENT'|translate}}
                </th>
                <th id="actions" colspan="2" class="p-text-center table-10"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-projectsTodo let-ri="rowIndex" let-columns>
              <tr>
                <td>{{ projectsTodo.name }}</td>
                <td>{{ projectsTodo.description }}</td>
                <td *ngIf="projectsTodo.status === 'TODO'">
                  <span class="status-todo"> {{'etat_a_faire'|translate}} </span>
                </td>

                <td *ngIf="projectsTodo.status === 'DOING'">
                  <span class="status-doing"> {{'group_en_cours'|translate}} </span>
                </td>

                <td *ngIf="projectsTodo.status === 'DONE'">
                  <span class="status-done"> {{'etat_termine'|translate}}  </span>
                </td>
                <td>
                  <app-progress-bar
                  [progress]="getProgress(projectsTodo)"
                  [total]="100"
                  color="blue">
                </app-progress-bar>
                </td>
                <td class="p-text-center table-5">
                  <button
                    routerLink="/phoenix/show-project/{{ projectsTodo.id }}"
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-search"
                    class="p-button-rounded p-button-text p-button-plain"
                  ></button>
                </td>
                <td class="p-text-center table-5">
                  <button *ifRoles='["administrateur",  "admin_metier","admin_responsable_DPO", "manager_operationnel"]'
                    pButton pRipple type="button" icon="pi pi-ellipsis-h"
                    (click)="getRowProjects(projectsTodo);op.toggle($event)"
                    class="p-button-rounded p-button-text p-button-plain"></button>
                </td>
              </tr>
            </ng-template>
          </p-table>
          </p-tabPanel>
          <p-tabPanel header="En cours" >
            <p-table #dt3 [value]="projectsDoing" class="table" [scrollable]="true" scrollHeight="40vh" [paginator]="true"
              (sortFunction)="naturalSortProject($event)"
              [customSort]="true"
              sortField="name" [sortOrder]='1'
              [rows]="10" styleClass="p-datatable-sm p-datatable-striped" [showCurrentPageReport]="true"
              currentPageReportTemplate="{{'PAGINATION'|translate}}"
              [rowsPerPageOptions]="[10, 25, 50]" [globalFilterFields]="[
                      'name',
                      'description'
                    ]">
              <ng-template pTemplate="caption">
                <div class="p-d-flex">
                  <span class="p-input-icon-left p-ml-auto" [style]="{'height':'20px','margin-bottom':'30px'}">
                    <i class="pi pi-search" [style]="{'height': '45px','margin-top':'5px'}"></i>
                    <input p pInputText type="text"
                           [style]="{'height': '45px'}"
                           [value]="projetRecherche!== undefined?projetRecherche:''"
                           (input)="filterTableInputChangeTabProject($event.target.value,'contains', dt1,dt2,dt3,dt4)"
                      placeholder="Rechercher" />
                  </span>
                </div>
              </ng-template>
              <ng-template pTemplate="header">
                <tr>
                  <th id="name" pSortableColumn="name">
                    {{'NOM'|translate}}
                    <p-sortIcon field="name"></p-sortIcon>
                  </th>
                  <th id="type" pSortableColumn="description">
                    {{'DESCRIPTION'|translate}}
                    <p-sortIcon field="description"></p-sortIcon>
                  </th>
                  <th id="type">
                    {{'liste_ot_AVANCEMENT'|translate}}
                  </th>
                  <th id="type">
                    {{'status'|translate}}
                  </th>
                  <th id="actions" colspan="2" class="p-text-center table-10"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-projectsDoing let-ri="rowIndex" let-columns>
                <tr>
                  <td>{{ projectsDoing.name }}</td>
                  <td>{{ projectsDoing.description }}</td>
                  <td *ngIf="projectsDoing.status === 'TODO'">
                    <span class="status-todo"> {{'etat_a_faire'|translate}} </span>
                  </td>

                  <td *ngIf="projectsDoing.status === 'DOING'">
                    <span class="status-doing"> {{'group_en_cours'|translate}} </span>
                  </td>

                  <td *ngIf="projectsDoing.status === 'DONE'">
                    <span class="status-done"> {{'etat_termine'|translate}}  </span>
                  </td>
                  <td>
                    <app-progress-bar
                    [progress]="getProgress(projectsDoing)"
                    [total]="100"
                    color="blue">
                  </app-progress-bar>
                  </td>
                  <td class="p-text-center table-5">
                    <button
                      routerLink="/phoenix/show-project/{{ projectsDoing.id }}"
                      pButton
                      pRipple
                      type="button"
                      icon="pi pi-search"
                      class="p-button-rounded p-button-text p-button-plain"
                    ></button>
                  </td>
                  <td class="p-text-center table-5">
                    <button *ifRoles='["administrateur",  "admin_metier","admin_responsable_DPO", "manager_operationnel"]'
                      pButton pRipple type="button" icon="pi pi-ellipsis-h"
                      (click)="getRowProjects(projectsDoing);op.toggle($event)"
                      class="p-button-rounded p-button-text p-button-plain"></button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
            </p-tabPanel>
            <p-tabPanel header="Terminé" >
              <p-table #dt4 [value]="projectsDone" class="table" [scrollable]="true" scrollHeight="40vh" [paginator]="true"
                (sortFunction)="naturalSortProject($event)"
                [customSort]="true"
                sortField="name" [sortOrder]='1'
                [rows]="10" styleClass="p-datatable-sm p-datatable-striped" [showCurrentPageReport]="true"
                currentPageReportTemplate="{{'PAGINATION'|translate}}"
                [rowsPerPageOptions]="[10, 25, 50]" [globalFilterFields]="[
                        'name',
                        'description'
                      ]">
                <ng-template pTemplate="caption">
                  <div class="p-d-flex">
                    <span class="p-input-icon-left p-ml-auto" [style]="{'height':'20px','margin-bottom':'30px'}">
                      <i class="pi pi-search" [style]="{'height': '45px','margin-top':'5px'}"></i>
                      <input p pInputText type="text"
                             [style]="{'height': '45px'}"
                             [value]="projetRecherche!== undefined?projetRecherche:''"
                             (input)="filterTableInputChangeTabProject($event.target.value,'contains', dt1,dt2,dt3,dt4)"
                        placeholder="Rechercher" />
                    </span>
                  </div>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th id="name" pSortableColumn="name">
                      {{'NOM'|translate}}
                      <p-sortIcon field="name"></p-sortIcon>
                    </th>
                    <th id="type" pSortableColumn="description">
                      {{'DESCRIPTION'|translate}}
                      <p-sortIcon field="description"></p-sortIcon>
                    </th>
                    <th id="type">
                      {{'status'|translate}}
                    </th>
                    <th id="type">
                      {{'liste_ot_AVANCEMENT'|translate}}
                    </th>
                    <th id="actions" colspan="2" class="p-text-center table-10"></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-projectsDone let-ri="rowIndex" let-columns>
                  <tr>
                    <td>{{ projectsDone.name }}</td>
                    <td>{{ projectsDone.description }}</td>
                    <td *ngIf="projectsDone.status === 'TODO'">
                      <span class="status-todo"> {{'etat_a_faire'|translate}} </span>
                    </td>

                    <td *ngIf="projectsDone.status === 'DOING'">
                      <span class="status-doing"> {{'group_en_cours'|translate}} </span>
                    </td>

                    <td *ngIf="projectsDone.status === 'DONE'">
                      <span class="status-done"> {{'etat_termine'|translate}}  </span>
                    </td>
                    <td>
                      <app-progress-bar
                      [progress]="getProgress(projectsDone)"
                      [total]="100"
                      color="blue">
                    </app-progress-bar>
                    </td>
                    <td class="p-text-center table-5">
                      <button
                        routerLink="/phoenix/show-project/{{ projectsDone.id }}"
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-search"
                        class="p-button-rounded p-button-text p-button-plain"
                      ></button>
                    </td>
                    <td class="p-text-center table-5">
                      <button *ifRoles='["administrateur",  "admin_metier","admin_responsable_DPO", "manager_operationnel"]'
                        pButton pRipple type="button" icon="pi pi-ellipsis-h"
                        (click)="getRowProjects(projectsDone);op.toggle($event)"
                        class="p-button-rounded p-button-text p-button-plain"></button>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
              </p-tabPanel>

      </p-tabView>
      <p-overlayPanel #op appendTo="body" baseZIndex="2">
        <ng-template pTemplate>
          <div #actualTarget>
            <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text"
              [style]="{'margin-right': '5px'}" label="Modifier" (click)="update()"></button><br>
            <ng-container *ifRoles='["administrateur",  "admin_metier","admin_responsable_DPO","manager_operationnel"]'>
              <button *ngIf="disabled" pButton pRipple type="button" icon="pi pi-trash" (click)="confirm()"
                class="p-button-rounded p-button-text" label="Supprimer"></button>
            </ng-container>
            <ng-container *ifRoles='["administrateur",  "admin_metier","admin_responsable_DPO", "manager_operationnel"]'>
              <button *ngIf="!disabled" pButton pRipple type="button" icon="pi pi-undo" (click)="confirm()"
                class="p-button-rounded p-button-text" label="Activer"></button>
            </ng-container>
          </div>
        </ng-template>
      </p-overlayPanel>
      <p-confirmDialog baseZIndex=5 styleClass="main-page" appendTo="body" [style]="{width: '60%'}"></p-confirmDialog>

    </div>
  </div>
</div>
