import { Directive } from '@angular/core';
import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

@Directive({
  selector: '[appDuplicationName]'
})
export class DuplicationNameDirective {

  constructor() { }

}
export function forbiddenName(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    /*console.log(prestations);
    let arr = prestations.map(x => x.workId);
    console.log(arr);
    console.log(checkIfArrayIsUnique(arr));
    let forbidden = false;
     */
    /*if (prestations.value.length > 0 && arr.includes(null) === false && arr !== undefined && arr != null){
      forbidden = this.checkIfArrayIsUnique(arr);
    }*/
    let forbidden = false;
    return forbidden ? {forbiddenName: {value: control.value}} : null;
  }
}
