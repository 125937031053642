<p-button [style]="{'background': '#2196F3', 'text-right': 'right !important', 'margin':'auto !important'}"
          *ngIf="editMode === false && listWorkOrderProjectWork !== undefined"
          styleClass="p-button"
          class="p-col-12 p-md-12 p-lg-2 btn-custom-bon"
          (click)="openVoucher()"
          label="Bon de sortie"
></p-button>
<p-dialog header="Bon de sortie" [(visible)]="displayBon" [style]="{maxWidth: '900px', width: '90%'}">
  <ng-template pTemplate="content">
    <form [formGroup]="formG">
      <app-work-new-voucher-add-materials [formG]="formG" [editItem]="editItem" (sendMaterial)="sendAtMaterial($event)" [(listWorkMaterialConsumable)]="listWorkMaterialConsumable"></app-work-new-voucher-add-materials>
      <app-work-new-voucher-list-materials [formG]="formG" [newItem]="newItem" (getAllMaterial)="getAllAtMaterial($event)" (getMaterial)="getAtMaterial($event)"></app-work-new-voucher-list-materials>
    </form>
  </ng-template>
  <ng-template pTemplate="footer">
    <div>
      <button pButton pRipple type="button" label="Annuler" class="p-button-outlined"
              (click)="hideDialog()"></button>
      <p-button styleClass="p-mr-2"
                [disabled]="(listItem === null || listItem.listItem.length <1 || editItem.edit === true || editItem.lastMat === true) && submitSaveVoucher === false" label="Valider"
                (click)="saveVoucher()"></p-button>
    </div>
  </ng-template>
</p-dialog>
<p-confirmDialog key="cd" header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
