<p-multiSelect id="multiselectTag" #ms [options]="tags" [resetFilterOnHide]="true" [group]="true" defaultLabel="{{ 'FILTRER_PAR_TAG' | translate }}"
               [(ngModel)]="selectedTags"
               (onPanelShow)="panelOpen()"
               (onFilter)="panelOpen()"
               optionLabel="label" (onClick)="onClickElement($event)"
               (onChange)="changeTag($event)"
               [dropdownIcon]="iconChange" >
  <ng-template let-value pTemplate="selectedItems">
    <div class="p-grid" style="margin:auto">
      <p-chip [label]="option.label" [style]="{'background': option.colorCode, 'color': '#ffffff', 'margin':'auto 0'}"
              (onRemove)="chipRemove(option)"
              [pTooltip]="option?.description" tooltipPosition="top"
              [removable]="true" *ngFor="let option of selectedTags">
      </p-chip>
      <div *ngIf="selectedTags.length === 0" class="tag-placeholder">
        {{ "FILTRER_PAR_TAG" | translate }}
      </div>
    </div>
  </ng-template>

  <ng-template let-item pTemplate="item">
    <div [ngStyle]="{'background-color': item.colorCode,'width':'10px','height':'10px', 'border-radius':'2px'}"></div>
    <div class="tag-item" [pTooltip]="item?.description" tooltipPosition="right" tooltipZIndex="100000000000" >
      {{item.label}}
    </div>
  </ng-template>
</p-multiSelect>
