import { Pipe, PipeTransform, NgModule } from '@angular/core';

@Pipe({
  name: 'range',
})
export class RangePipe implements PipeTransform {
  transform(input: number, size: number = 0, start: number = 1, step: number = 1): any {
    // If size is not provided, default it to the length of the input array
    if (size === 0) {
      size = input;
    }

    const range: number[] = [];
    for (let length = 0; length < size; ++length) {
      range.push(start);
      start += step;
    }

    return range;
  }
}