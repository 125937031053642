import { PartyRoleCatalogue, Persona, PersonaSkills } from "./Persona";
import {Tag} from "./Tag";

export class Skill {

  id: number;
  type: string;
  name: string;
  description: string;
  validity: boolean;
  month_duration: number;
  year_duration: number;
  day_duration: number;
  index : number
  tags?: Tag[];
  nbPersonas?: number;
  listPersonas?: Persona[];


  constructor(skill?: Partial<Skill>) {

    if (skill){

        Object.assign(this, skill)

    }

    else{

        this.name = "";
        this.type = "";
        this.description = "";
        this.validity = false;
        this.month_duration = null;
        this.year_duration = null;
        this.day_duration = null;
        this.index = null;
        this.nbPersonas=0
        //this.tags = []

    }

  }

  public isDefined(){

    return (
      this.name ||
      this.type ||
      this.description ||
      this.validity ||
      this.month_duration ||
      this.year_duration ||
      this.day_duration ||
      this.tags ||
      this.nbPersonas ||
      this.listPersonas

    )

  }


}


export interface TypeSkill {
  name: string,
  value: string

}

export class SelectTypeSkill {

  typeOptions: TypeSkill[];

  selectedType: string;

  constructor() {
      this.typeOptions = [
        {name: 'Habilitation', value: 'HABILITATION'},
        {name: 'Savoir-être', value: 'SAVOIR_ETRE'},
        {name: 'Savoir-faire', value: 'SAVOIR_FAIRE'},
      ];
  }



}

