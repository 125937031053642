import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService, SortEvent } from 'primeng/api';
import { ProjectWork } from '@data/schema/Project';
import { WorkOrder, WorkOrderProjectWork } from '@data/schema/WorkOrder';
import {CustomSortService} from "@shared/service/custom-sort.service";

@Component({
  selector: 'app-work-order-form-list-selected-works',
  templateUrl: './work-order-form-list-selected-works.component.html',
  styleUrls: ['./work-order-form-list-selected-works.component.css'],
})
export class WorkOrderFormListSelectedWorksComponent implements OnInit {
  //recupérer l'objet parent
  @Input() workOrder: WorkOrder;

  @Input() editMode?: boolean;

  @Output() isChangeListWork = new EventEmitter();

  changeListWork() {
    this.isChangeListWork.emit(this.workOrder.listWorkOrderProjectWork);
  }

  index: number;
  editedWorkOrderProjectWork: WorkOrderProjectWork = new WorkOrderProjectWork();
  displayModal: boolean = false;
  maxQuantityFreePossible: number = 0;
  minQuantityFreePossible: number = 1;
  totalWorkOrder: number = 0;

  @ViewChild("input") elm;

  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private customSortService : CustomSortService
  ) {}

  ngOnInit(): void {}

  update(workOrderProjectWork: WorkOrderProjectWork) {
    if (this.workOrder.status === 'DOING') {
      this.confirmationService.confirm({
        header: 'Confirmation',
        message:
          "La modification des prestations impactera le budget de l'OT." +
          '<br>' +
          'Voulez vous continuer ?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Confirmer',
        rejectLabel: 'Annuler',
        accept: () => {
          this.index =
            this.getlistWorkOrderProjectWorkIndex(workOrderProjectWork);
          this.displayModal = true;

          this.editedWorkOrderProjectWork = JSON.parse(
            JSON.stringify(workOrderProjectWork)
          );

          this.calculateMaxQuantityFreePossible();
          if (workOrderProjectWork.quantityDone === 0) {
            this.minQuantityFreePossible = 1;
          }
          if (workOrderProjectWork.quantityDone > 0) {
            this.minQuantityFreePossible = workOrderProjectWork.quantityDone;
          }

          this.totalWorkOrder = workOrderProjectWork.quantityTotal;
        },
      });
    } else {
      this.index = this.getlistWorkOrderProjectWorkIndex(workOrderProjectWork);
      this.displayModal = true;

      this.editedWorkOrderProjectWork = JSON.parse(
        JSON.stringify(workOrderProjectWork)

      );

      this.calculateMaxQuantityFreePossible();
      if (workOrderProjectWork.quantityDone === 0) {
        this.minQuantityFreePossible = 1;
      }
      if (workOrderProjectWork.quantityDone > 0) {
        this.minQuantityFreePossible = workOrderProjectWork.quantityDone;
      }

      this.totalWorkOrder = workOrderProjectWork.quantityTotal;
    }
  }

  saveUpdate() {

    let indexNotExist : boolean = false;

    if (
      this.editedWorkOrderProjectWork.quantityTotal <=
      this.maxQuantityFreePossible
      &&
      this.editedWorkOrderProjectWork.quantityTotal >=
      this.editedWorkOrderProjectWork.quantityDone
    ) {
      for (const projectWork of this.workOrder.project.projectWorks) {
        if (
          projectWork.id ===
          this.workOrder.listWorkOrderProjectWork[this.index].projectWork.id
        ) {
          projectWork.quantityFree +=
            this.workOrder.listWorkOrderProjectWork[this.index].quantityTotal -
            this.editedWorkOrderProjectWork.quantityTotal;
            indexNotExist=true;
        }
      }
      if(!indexNotExist){
          let projectWork = new ProjectWork();
          projectWork = this.workOrder.listWorkOrderProjectWork[this.index].projectWork
          projectWork.quantityFree = this.workOrder.listWorkOrderProjectWork[this.index].quantityTotal - this.editedWorkOrderProjectWork.quantityTotal

          this.workOrder.project.projectWorks.push(projectWork)
      }

      this.workOrder.listWorkOrderProjectWork[this.index] = JSON.parse(
        JSON.stringify(this.editedWorkOrderProjectWork)
      );

      // UNE GROSSE BLAGUE PRIMENG
      this.workOrder.listWorkOrderProjectWork =
        this.workOrder.listWorkOrderProjectWork.slice();

      this.editedWorkOrderProjectWork = new WorkOrderProjectWork();

      this.displayModal = false;
    }
  }

  delete(workOrderProjectWork: WorkOrderProjectWork) {
    if(workOrderProjectWork.id != null){
    this.confirmationService.confirm({
      header: 'Confirmation',
      message:
        "Vous allez supprimer cette prestation de l'OT. Cela impactera le budget." +
        '<br>' +
        'Voulez vous continuer ?',
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button-outlined',
      acceptIcon: 'null',
      rejectIcon: 'null',
      acceptLabel: 'Supprimer',
      rejectLabel: 'Annuler',
      accept: () => {
        this.deleteWorkInProject(workOrderProjectWork);
        this.changeListWork();
        this.messageService.add({
          severity: 'success',
          summary: 'Opération réussie',
        });
      },
    });
    }else{
      this.deleteWorkInProject(workOrderProjectWork);
      this.changeListWork();
    }
  }

  deleteWorkInProject(workOrderProjectWork: WorkOrderProjectWork) {

    let indexNotExist : boolean = false

    let index = this.getlistWorkOrderProjectWorkIndex(workOrderProjectWork);
    for (const projectWork of this.workOrder.project.projectWorks) {
      if (
        projectWork.id ===
        this.workOrder.listWorkOrderProjectWork[index].projectWork.id
      ) {
        projectWork.quantityFree +=
          this.workOrder.listWorkOrderProjectWork[index].quantityTotal;
          indexNotExist = true;
          //console.log("EEExiste")
      }
    }
   if(!indexNotExist){
     //console.log("NOt existe")
     let projectWork = new ProjectWork();
      projectWork = this.workOrder.listWorkOrderProjectWork[index].projectWork
      projectWork.quantityFree = this.workOrder.listWorkOrderProjectWork[index].quantityTotal

      this.workOrder.project.projectWorks.push(projectWork)
    }
    this.workOrder.listWorkOrderProjectWork.splice(index, 1);

    //console.log("AAAAA",this.workOrder.project.projectWorks)
    //console.log("BBBBBBBBBBB",this.workOrder.listWorkOrderProjectWork)

  }

  annuler() {
    this.displayModal = false;
  }

  calculateMaxQuantityFreePossible() {
    let projectWorkQuantityFree = 0;

    for (const projectWork of this.workOrder.project.projectWorks) {
      if (projectWork.id === this.editedWorkOrderProjectWork.projectWork.id) {
        projectWorkQuantityFree = projectWork.quantityFree;
      }
    }
    //console.log(projectWorkQuantityFree,this.workOrder.listWorkOrderProjectWork[this.index].quantityTotal)

    this.maxQuantityFreePossible =
      projectWorkQuantityFree +
      this.workOrder.listWorkOrderProjectWork[this.index].quantityTotal;
  }

  getlistWorkOrderProjectWorkIndex(workOrderProjectWork: WorkOrderProjectWork) {
    return this.workOrder.listWorkOrderProjectWork.findIndex(
      (i) => i.projectWork.id === workOrderProjectWork.projectWork.id
    );
  }

  setFocus() {
    this.elm.input.nativeElement.focus();
  }

  naturalSortWork(event:any) {
    
    this.customSortService.naturalSort(event);

  }

  
}
