import {Component, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {WorkService} from "@data/service/work.service";
import {ConfirmationService, MessageService, SortEvent} from "primeng/api";
import {Work} from "@data/schema/Work";
import {CurrencyService} from "@data/service/currency.service";
import {Contracts} from "@data/schema/Contracts";
import {ContractService} from '@data/service/contract.service';
import {EventEmitter} from '@angular/core';
import {ContractCustomResponse} from "@data/schema/ContractCustomResponse";
import {StatusEnumContract} from "@data/schema/StatusEnumContract";
import {CustomSortService} from "@shared/service/custom-sort.service";


//import EventEmitter = require("events");

@Component({
  selector: 'app-contract-form-select-prestation',
  templateUrl: './contract-form-select-prestation.component.html',
  styleUrls: ['./contract-form-select-prestation.component.css']
})
export class ContractFormSelectPrestationComponent implements OnInit {
  @Input() contractForm: FormGroup;
  @Input() mode: String;
  @Input() contracts: Contracts;
  @Input() isSubmit: boolean;
  @Input() prestationsFrom;
  works: Work[] = [];
  listCurrency = {};
  errorCurrency: boolean = false;
  currency = [];
  currencyContract: any;
  //@Output() newItemEvent = new EventEmitter<string>();
  displayErrorSubmit: boolean = false;
  workAdded: Work[] = [];
  workDropdown: Work;
  newPrestation: boolean = false;
  prestationErr: boolean = false;
  workAfter: any [] = [];
  @Input() contractIsAttachedToWorkOrder: boolean;
  updateMode: string = 'update';
  @Input() contractIsActive: boolean;
  @Output() oldPrestationsPriceChanged = new EventEmitter();
  contractCustomResponse: ContractCustomResponse;
  statusContract = StatusEnumContract;
  contractToUpdate: FormGroup;
  contractFormTemp: FormGroup

  constructor(private fb: FormBuilder, private workService: WorkService, private messageService: MessageService,
              private currencyService: CurrencyService, private contractService: ContractService,
              private confirmationService: ConfirmationService,
              private customSortService : CustomSortService) {
  }

  
  alphaNumericSort = (arr = []) => {
      const sorter = (a, b) => {
      const isNumber = (v) => (+v).toString() === v;
      const aPart = a.name.match(/\d+|\D+/g);
      const bPart = b.name.match(/\d+|\D+/g);
      let i = 0; let len = Math.min(aPart.length, bPart.length);
      while (i < len && aPart[i] === bPart[i]) { i++; };
        if (i === len) {
            return aPart.length - bPart.length;
      };
      if (isNumber(aPart[i]) && isNumber(bPart[i])) {
        return aPart[i] - bPart[i];
      };
      return aPart[i].localeCompare(bPart[i]); };
      arr.sort(sorter);
  };

  ngOnInit(): void {
    this.workService.getListWork().subscribe(
      (res) => {
        if (res.status === 200) {
          this.works = res.body;
          this.alphaNumericSort(this.works);
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {

      }
    );


    this.listCurrency = this.currencyService.getListCurrency();
    for (const property in this.listCurrency) {
      let currency = {
        code: property,
        name: this.listCurrency[property]
      }
      this.currency.push(currency)
    }
    if (this.mode !== "consultation" && this.mode !== "update") {
      this.initPrestation();
    }
  }

  get contractFormControls() {
    return this.contractForm.controls;
  }

  get prestationControls() {
    
    return this.contractFormControls.contractWorks as FormArray;
  }

  get prestationFormControls() {
    return this.prestationsFrom.controls;
  }

  get prestationsBeforeUpdate() {
    return this.prestationFormControls.contractWorks as FormArray;
  }

//check for duplicates when adding works to contract in update (works before update and after update are separated )
  filterPrestationsForUpdate() {
    this.workAfter = [];
    this.works = this.works.filter(work =>
      !this.prestationsBeforeUpdate.value.some(workBeforeUpdate => work.idWork === workBeforeUpdate.workId.idWork)
    );
  }

  addPrestation() {
    console.log("add prestation");
    if (this.mode === this.updateMode) {
      console.log("update");
      this.filterPrestationsForUpdate();
    }
    this.newPrestation = true;

    if (this.prestationControls.valid === true && this.contractForm.value.currency !== null) {
      console.log("update 1");
      this.prestationControls.push(this.fb.group({
        workId: [null, [Validators.required]],
        price: [null, [Validators.required]],
      }))
      this.prestationErr = false;
    } else {
      this.prestationErr = true;
    }

    console.log(this.prestationControls.valid);
    console.log(this.contractForm.value.currency);

  }

  initPrestation() {
    if (this.mode === this.updateMode) {
      this.filterPrestationsForUpdate();
    }
    this.newPrestation = true;


    this.prestationControls.push(this.fb.group({
      workId: [null, [Validators.required]],
      price: [null, [Validators.required]],
    }))


  }

  deletePrestation(index) {
    if (this.prestationsFrom && this.prestationsBeforeUpdate.length !== 0) {
      this.prestationControls.removeAt(index);
    } else {
      if (this.contractForm && this.prestationControls.length === 1) {
        this.prestationControls.at(index).patchValue({workId: null});
        this.prestationControls.at(index).patchValue({price: null});
        return;
      }
      this.prestationControls.removeAt(index);
      if (this.mode !== "update" && this.prestationControls.length === 0) {
        this.initPrestation();
      }
    }
  }

  deleteBeforeUpdatePrestations(index) {
    if (this.mode === this.updateMode) {
      let deletedWork = this.prestationsBeforeUpdate.at(index).value.workId;
      this.prestationsBeforeUpdate.removeAt(index);

      //push the deleted work into list of works
      this.works.push(deletedWork);
      if (this.prestationsBeforeUpdate.length === 0 && this.prestationControls.length === 0) {
        this.initPrestation();
      }
    }
  }

  checkDuplicate(work: any) {
    let result = this.prestationControls.value.filter(s => s.workId === work.value && work.value !== null && s.workId !== null);
    if (result.length >= 2) {
      return true;
    } else {
      return false;
    }
  }

  priceBeforeUpdateChanged(event: boolean) {
    if (this.contractIsAttachedToWorkOrder) {
      this.oldPrestationsPriceChanged.emit(event);
    }

  }

  initFormToCheckDataWhenSelectingWork(){
    this.contractFormTemp = new FormGroup({
      contractId: new FormControl(this.contractForm.value.contractId),
      effectiveDate: new FormControl(this.contractForm.value.effectiveDate),
      expiryDate: new FormControl(this.contractForm.value.expiryDate),
      company: new FormControl(this.contractForm.value.company),
      status: new FormControl(this.contractForm.value.status),
      contractWorks: new FormArray([
      ]),
    })
  }

  isContractDataValidByWorkForSave(index) {

    this.initFormToCheckDataWhenSelectingWork();

    this.contractFormTemp.value.contractWorks.push(
        this.contractForm.controls.contractWorks.value.at(index));

    if(this.contractForm.controls.company === null || (this.contractForm.controls.effectiveDate.value === null
      && this.contractForm.controls.expiryDate.value === null)){
      return;
    }

    console.log(this.contractForm);
    this.contractService.isContractDataValidByWorkForSave(this.contractFormTemp).subscribe(
      res => {
        if (res.status === 200) {
          this.contractCustomResponse = res.body;
          console.log(this.contractCustomResponse);
          this.getMessageError(this.contractCustomResponse, index);
        }
      }, error => {
      }
    )


  }

  get contractToUpdateContractWorks(){
    return this.contractToUpdate.controls.contractWorks as FormArray;
  }

  combinePrestationBeforeAndAfterUpdate(){

    this.contractToUpdate = new FormGroup({
      contractId :  new FormControl(this.contracts.contractId),
      contractName: new FormControl(this.contractForm.value.contractName,Validators.required),
      description: new FormControl(this.contractForm.value.description),
      creationDate: new FormControl(this.contractForm.value.creationDate),
      effectiveDate: new FormControl(this.contractForm.value.effectiveDate),
      expiryDate: new FormControl(this.contractForm.value.expiryDate),
      validate: new FormControl(this.contractForm.value.validate),
      currency: new FormControl(this.contractForm.value.currency, Validators.required),
      company: new FormControl(this.contracts.company, Validators.required),
      contractWorks: new FormArray([])

    })

    for(let prestaBeforeUpdate of this.prestationsBeforeUpdate.controls){
      this.contractToUpdateContractWorks.push(prestaBeforeUpdate);
    }
    for(let prestaAfterUpdate of this.prestationControls.controls){
      this.contractToUpdateContractWorks.push(prestaAfterUpdate);
    }

    console.log(this.contractToUpdate);



  }


  isContractDataValidByWorkForUpdate(index: number){

    this.initFormToCheckDataWhenSelectingWork();

    this.contractFormTemp.value.contractWorks.push(
      this.contractForm.controls.contractWorks.value.at(index));

    if(this.contractForm.controls.company === null || (this.contractForm.controls.effectiveDate.value === null
      && this.contractForm.controls.expiryDate.value === null)){
      return;
    }
    //this.combinePrestationBeforeAndAfterUpdate();

    this.contractService.isContractDataValidByWorkForUpdate(this.contractFormTemp).subscribe(
      res => {
        if (res.status === 200) {
          this.contractCustomResponse = res.body;
          console.log(this.contractCustomResponse);
          this.getMessageError(this.contractCustomResponse, index);
        }
      }, error => {
      }
    )
  }

  getMessageError(contractCustomResponse: ContractCustomResponse, index){

    console.log(index);

    console.log(this.contractForm.controls.contractWorks.value.at(index));

    if (contractCustomResponse.code === 603) {
      this.confirmationService.confirm({
        header: 'Action impossible',
        message:
          'Cette prestation est déjà incluse dans un autre contrat avec les mêmes dates de validité : '  + contractCustomResponse.contract.contractName.link('phoenix/show-contract/'
          + contractCustomResponse.contract.contractId) + '.',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: "J'ai compris",
        rejectVisible: false,
      });
      this.prestationControls.at(index).patchValue({workId: null});
    } else if (contractCustomResponse.code === 604) {
      this.confirmationService.confirm({
        header: 'Action impossible',
        message:
          'Cette prestation est déjà incluse dans un contrat en vigueur : ' + contractCustomResponse.contract.contractName.link('phoenix/show-contract/'
          + contractCustomResponse.contract.contractId) + '.',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: "J'ai compris",
        rejectVisible: false,
      })
      this.prestationControls.at(index).patchValue({workId: null});
    }
  }

  naturalSortWork(event: SortEvent) {
    this.customSortService.naturalSort(event);
  }


}
