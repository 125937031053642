import {HttpBackend, HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Env} from "../../../environments/env";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class EnvService {
  env: Env;
    private httpClient: HttpClient;
  constructor(
    private httpBackend: HttpBackend
  ) {
    this.httpClient = new HttpClient(httpBackend);
  }
  setConfig(): Observable<Env> {
    return this.httpClient
      .get<Env>('./assets/config/configuration.json')
      .pipe(map(config =>{
        this.env = config;
        return this.env;
      }));
  }

  readConfig(): Env {
    return this.env;
  }
}

