import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import {EnvService} from "@core/service/env.service";
import { Team } from '@data/schema/intervention/teamSchema';

@Injectable({
  providedIn: 'root'
})
export class PartyApiService {


  public apiURL = this.environment?.readConfig()?.urlApiGateway + "partyquery";

  constructor(private http: HttpClient,private environment: EnvService
  ) { }

  public getAllTeams(): Observable<Team[]> {
    return this.http.get<Team[]>(this.apiURL + '/party-list')
  }



  public getTeamById(id: number): Observable<any> {
    return this.http.get(this.apiURL + '/party-by-id/' + id, { observe: 'response' as 'body' })
  }

  /**
   * get all teams names to do autocomplete
   * @returns a list of teams names
   */
  public getAllDistinctTeamNames(): Observable<any> {

    return this.http.get(this.apiURL + '/party-list-names');
  }


  /**
   * get all available technicians to form a team
   * @returns a list of technicians
   */
  public getAllAvailableTechnicians(id: number): Observable<any> {
    return this.http.get(this.apiURL + '/available-techs-list/' + id);
  }

  /**
   * get technicians searched by their full name
   * @returns a list of technicians
   */
  public getTechniciansBySearchedFullName(fullName: any): Observable<any> {

    const params = new HttpParams().set('members', fullName);

    return this.http.get(this.apiURL + '/technicians-searched-list', { params: params });
  }

  /**
   * get teams searched by their technicians full names.
   * @returns a list of teams
   */
  public getTeamsWithSearchedTechnician(searchCriteria: any): Observable<any> {

    const params = new HttpParams().set('technicianFullName', searchCriteria.fullName).set('pool', searchCriteria.pool);

    return this.http.get(this.apiURL + '/party-list-searchedByTechName', { params: params });
  }

  /**
   * get technicians searched by their full name
   * @returns a list of technicians
   */
  public getTeamsByNames(searchCriteria: any, partyId: any, selection: boolean): Observable<any> {

    const params = new HttpParams().set('teamName', searchCriteria.team).set('partyId', partyId).set('showAllTeams', selection);

    return this.http.get(this.apiURL + '/party-list-searchedByTeamName', { params: params });
  }



  /**
* get all  technicians to form a team
* @returns a list of technicians
*/
  public getAllTechnicians(sort?: any, search?: any, limit?: number, offset?: number): Observable<any> {

    const params = new HttpParams().set('sorts', sort)
      .set('search', search)
      .set('limit', limit)
      .set('offset', offset);

    return this.http.get(this.apiURL + '/techs-list', { params: params });
  }

  public getTeamsByPool(pool: number): Observable<any> {
    return this.http.get(this.apiURL + '/teams/' + pool)
  }

  public getUserTeams(poolIds): Observable<any> {
    return this.http.post(this.apiURL + '/teams-by-pools', poolIds)
  }

  public getUserTechnicians(poolIds, sort?: any, search?: any, limit?: number, offset?: number): Observable<any> {
    const params = new HttpParams().set('sorts', sort)
      .set('search', search)
      .set('limit', limit)
      .set('offset', offset);

    return this.http.post(this.apiURL + '/technicians-by-pools', poolIds, { params: params });
  }

  public getTechniciansCount(pools?: number[], search?: any) {
    const params = new HttpParams().set('search', search);
    return this.http.post(this.apiURL + '/technicians-count', pools, { params: params });
  }
}
