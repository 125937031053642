
<div class="dialogHeaderTech">
    <h1 class="h1" mat-dialog-title>
      {{"details_intervenant" | translate}}
      <button
        mat-icon-button
        aria-label="close dialog"
        mat-dialog-close
        class="close-button"
      >
        <mat-icon>close</mat-icon>
      </button>
    </h1>
  </div>

  <mat-dialog-content >
    <div fxLayout="row wrap">
      <div style="margin-left:20px" fxFlex="10" fxLayoutAlign="space-around">
        <img
          class="img-tech"
          *ngIf="technician?.photo"
          [src]="technician?.photo"
          mat-card-avatar
        />
        <img
          class="img-tech"
          *ngIf="!technician?.photo"
          src="assets/img/technician-default.png"
          mat-card-avatar
        />
      </div>
      <div fxFlex="85">
        <div fxLayout="column">
          <div fxLayout="row wrap">
            <div fxFlex="20" fxLayoutAlign="start">
              <span class="text-title">{{ "NOM_FAMILLE" | translate }}</span>
            </div>
            <div fxFlex="20" fxLayoutAlign="start">
              <span class="text-title">{{ "PRENOM" | translate }}</span>
            </div>
            <div fxFlex="20" fxLayoutAlign="start">
              <span class="text-title">{{ "DEUXIEME_NOM" | translate }}</span>
            </div>
            <div fxFlex="20" fxLayoutAlign="start">
              <span class="text-title">{{ "NOM_PUBLIC" | translate }}</span>
            </div>
            <div fxFlex="20" fxLayoutAlign="start">
              <span class="text-title">{{ "EQUIPE" | translate }}</span>
            </div>
          </div>
          <br />
          <div fxLayout="row wrap">
            <div fxFlex="20" fxLayoutAlign="start">
              <span class="text-wrap text-info">{{ technician.lastName }}</span>
            </div>
            <div fxFlex="20" fxLayoutAlign="start">
              <span class="text-wrap text-info">{{ technician.firstName }}</span>
            </div>
            <div fxFlex="20" fxLayoutAlign="start">
              <span class="text-wrap text-info">{{ technician.middleName }}</span>
            </div>
            <div fxFlex="20" fxLayoutAlign="start">
              <span class="text-wrap text-info">{{ technician.publicName }}</span>
            </div>
            <div class="team-container" fxFlex="20" fxLayoutAlign="start">
                <div class="team-text " *ngFor="let pool of technician.pools; let i = index" [hidden]="i >= 2 
                  ">
                  <span *ngIf="pool.team && pool.team.name"> {{ pool.team?.name }} </span>
                </div>
                <div class="item-list" *ngIf="(technician.pools | occurences : 'team') >2">...</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div fxLayout="column">
      <div class="skills-header">
        <div fxLayout="row wrap">
          <mat-accordion class="mat-accordionTech" multi>
            <h4>{{"HABILITATIONS" | translate}}</h4>
            <mat-expansion-panel
              fxFlex="100"
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
              *ngFor="let habilitation of listHabilitation; let index = index"
            >
              <mat-expansion-panel-header >
                <mat-panel-title >

                <div class="text-info" style="margin-right: 5%;"  fxLayoutAlign="start center">
                  <span >{{ habilitation.name }}</span>
                </div>

                <div class="text-info" style="margin-right: 42%;"  fxLayoutAlign="start center" >
                  <span  *ngIf="habilitation.validity"> {{" VALIDE" | translate}} {{"DU" | translate}} {{transformDate(habilitation.startDate)}} {{"AU" | translate}} {{transformDate(habilitation.endDate)}}</span>
                </div>

                <div  fxLayoutAlign="start center" class="tag" *ngIf="!checkValidDate(habilitation.endDate,habilitation.validity)">
                  <span>
                    <mat-icon class="iconSvg" svgIcon="exclamationIcon">
                    </mat-icon>
                  </span>
                  <span >{{"EXPIREE"| translate}}</span>
                </div>

                </mat-panel-title>
              </mat-expansion-panel-header>
              <div>
                <span class="description">Description</span> <span style="margin-left:10px">{{ habilitation.description }}</span>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div fxLayout="row wrap">
          <mat-accordion class="mat-accordionTech" multi>
            <h4 class="text-info">{{"SAVOIR_FAIRES" | translate}}</h4>
            <mat-expansion-panel
              fxFlex="100"
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
              *ngFor="let savoirFaire of listSavoirFaire; let index = index"
            >
              <mat-expansion-panel-header>
                <mat-panel-title class="text-info">
                  {{ savoirFaire.name }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div>
                <span class="description">Description</span> <span style="margin-left:10px">{{ savoirFaire.description }}</span>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div fxLayout="row wrap">
          <mat-accordion class="mat-accordionTech" multi>
            <h4 class="text-info">{{"SAVOIR_ETRES" | translate}}</h4>
            <mat-expansion-panel
              fxFlex="100"
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
              *ngFor="let savoirEtre of listSavoirEtre; let index = index"
            >
              <mat-expansion-panel-header>
                <mat-panel-title class="text-info">
                  {{ savoirEtre.name }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div>
                <span class="description">Description</span> <span style="margin-left:10px">{{ savoirEtre.description }}</span>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </mat-dialog-content>

