<div class="p-col-12">
  <div class="p-col-12 p-d-flex p-jc-between">
    <h3>Progression</h3>
    <i *ngIf="project && unaffiliatedWork > 0" pTooltip="{{ unaffiliatedWork }} prestations à planifier" tooltipPosition="left" class="pi pi-exclamation-circle" style="font-size: 2rem; color:red"></i>
  </div>
  <div class="p-col-12 progress">Linéaire</div>
  <div class="p-col-12">
      <app-progress-bar
        [progress]="progress" [total]="total" color="blue"
      >
    </app-progress-bar>
  </div>
</div>
