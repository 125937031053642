import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Skill } from '@data/schema/Skill';
import { SkillWork, Work } from '@data/schema/Work';
import { SkillService } from '@data/service/skill.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-work-show-skill',
  templateUrl: './work-show-skill.component.html',
  styleUrls: ['./work-show-skill.component.css']
})
export class WorkShowSkillComponent implements OnInit {
  @Input() work: Work;
  
  @Output() workChange = new EventEmitter();
  change() {
    this.workChange.emit(this.work);
  }

  listSkill : Skill[];

  errorNotFilled = false;
  errorDuplicate = false;

  constructor(
     public skillService: SkillService,
     private messageService: MessageService,
     ) { }

  ngOnInit(): void {
  }

}
