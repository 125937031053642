<label class="settingCurrencyHeadFirst"><a routerLink="/phoenix/show-currency">Paramétrage</a></label>
<img
  width="12px"
  height="12px"
  src="../../../assets/SVG/menu/caret-right-fill.svg"
/>
<label *ngIf="setting!=='Devise'" class="settingCurrencyHeadSecond"><a routerLink="/phoenix/show-currency">Devise</a></label>

<img *ngIf="setting!=='Devise'"
  width="12px"
  height="12px"
  src="../../../assets/SVG/menu/caret-right-fill.svg"
/>
<label class="settingCurrency">{{setting}}</label>
