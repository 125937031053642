    <h1 mat-dialog-title class="head dialog-spacing" fxLayoutAlign="space-between start">
     {{"ASSOCIER_PERSONAS_ORGANISATIONS" | translate }}
      <button fxLayoutAlign="end"
        mat-icon-button
        aria-label="close dialog"
        mat-dialog-close
        class="close-button"
      >
        <mat-icon>close</mat-icon>
      </button>
    </h1>
  <div mat-dialog-content class="dialog-spacing">
        <mat-stepper orientation="vertical" #stepper [selectedIndex]="currentStep">
          <ng-template matStepperIcon="edit">
            <mat-icon [inline]="true" svgIcon="check-icon"></mat-icon>
          </ng-template>
          <mat-step>
            <form>
              <ng-template matStepLabel>{{"SELECT_ORAGNISATION" | translate}}</ng-template>
                <p>
                  {{"SELECTIONNER_ORGANISATION_ASSOCIATION_PERSONA" | translate }}
                </p>
                <div fxLayoutAlign="end">
                  <button mat-raised-button color="primary" matStepperNext>{{"SUIVANT" |translate}}</button>
                </div>
            </form>
          </mat-step>
          <mat-step>
            <form>
              <ng-template matStepLabel>{{"SELECTIONNER_ENTITE" | translate}}</ng-template>
              <p>
                {{"SELECTIONNER_ENTITE_ORGANISATION" | translate }}
              </p>
              <div fxLayoutAlign="end">
                <button mat-button matStepperPrevious>{{"PRECEDENT" | translate}}</button>
                <button mat-raised-button color="primary" matStepperNext>{{"SUIVANT" |translate}}</button>
            </div>
            </form>
          </mat-step>
          <mat-step>
            <form>
              <ng-template matStepLabel>{{"ASSOCIER_PERSONAS" | translate}}</ng-template>
              <p>
              {{"CHOISIR_PERSONAS" | translate }}
              </p>
              <div fxLayoutAlign="end">
                <button mat-button matStepperPrevious>Précédent</button>
                <button mat-dialog-close mat-raised-button color="primary" matStepperNext>{{'etape_DEMARRE'| translate}}</button>
            </div>
            </form>
           </mat-step>
        </mat-stepper>
  </div>
