import {
  Component,
  Input,
  IterableDiffers,
  OnInit,
  Output,
} from '@angular/core';
import {
  ProjectCompaniesListWorkOrder,
  ProjectCompaniesProjectWork,
  ProjectWork,
} from '@data/schema/Project';
import { Work } from '@data/schema/Work';
import { WorkOrderProjectWork } from '@data/schema/WorkOrder';
import { Contracts } from '@data/schema/Contracts';
import { ContractWork } from '@data/schema/ContractWork';
import { Cost } from '@data/schema/Cost';
import { Currency } from '@data/schema/Currency';
import {CustomSortService} from "@shared/service/custom-sort.service";
import {SortEvent} from 'primeng/api';

@Component({
  selector: 'app-work-form-list-cost',
  templateUrl: './work-form-list-cost.component.html',
  styleUrls: ['./work-form-list-cost.component.css'],
})
export class WorkFormListCostComponent implements OnInit {
  // optional parameter listProjectWork
  @Input() listProjectWork?: ProjectWork[];

  // optional parameter listWorkOrderProjectWork
  @Input() listWorkOrderProjectWork?: WorkOrderProjectWork[];

  // list of distinct project work
  listUniqueProjectWork: ProjectWork[] = [];

  @Input() listWorksCompany?: any[];

  @Input() projectWorkByCompany: ProjectCompaniesListWorkOrder[];

  listWorkWithPrice: ProjectCompaniesProjectWork[] = [];

  listWorksWithPrice: ContractWork[] = [];

  lgth: number;

  complete: boolean;

  globalCost: Cost = new Cost();

  companyCost: Cost = new Cost();

  budget: Cost[] = [];

  @Input() currency: Currency;

  // allow to check if array input changed
  iterableDiffer: any;

  constructor(private iterableDiffers: IterableDiffers,private customSortService: CustomSortService) {
    this.iterableDiffer = iterableDiffers.find([]).create(null);
    this.complete = true;
  }

  ngOnInit(): void {}

  // check when input listProjectWork changed
  // do calculate quantity if any change
  ngDoCheck() {
    if (this.listProjectWork) {
      let changes = this.iterableDiffer.diff(this.listProjectWork);
      if (changes) {
        this.assembleDistinctProjectWork(this.listProjectWork);
        this.calculTotalByProject();
      }
    }

    if (this.projectWorkByCompany) {
      let changes = this.iterableDiffer.diff(this.projectWorkByCompany);
      if (changes) {
        if (changes.length !== this.lgth) {
          this.lgth = changes.length;
          console.log(this.projectWorkByCompany)
          this.generate();
        }
      }
    }

    if (this.listWorksCompany) {
      let companiesChange = this.iterableDiffer.diff(this.listWorksCompany);
      if (companiesChange) {
        this.getPrestationCompany(this.listWorksCompany);
        //this.calculTotalBudget();
      }
    } else {
      //this.calculTotalBudget();
    }
    if (this.listWorkOrderProjectWork) {
      let changes = this.iterableDiffer.diff(this.listWorkOrderProjectWork);
      if (changes) {
        this.assembleDistinctWorkOrderProjectWork(
          this.listWorkOrderProjectWork
        );
        this.calculTotalByWorkOrder();
      }
    }
  }

  assembleDistinctProjectWork(listProjectWork?: ProjectWork[]) {
    this.listUniqueProjectWork = [];
    let indexId = [];

    if (listProjectWork) {
      for (const projectWork of listProjectWork) {
        if (indexId.includes(projectWork.work.idWork)) {
          let index = indexId.indexOf(projectWork.work.idWork);
          this.listUniqueProjectWork[index].quantityTotal +=
            projectWork.quantityTotal;
        } else {
          this.listUniqueProjectWork.push(
            JSON.parse(JSON.stringify(projectWork))
          );
          indexId.push(projectWork.work.idWork);
        }
      }
    }
  }

  assembleDistinctWorkOrderProjectWork(
    listWorkOrderProjectWork?: WorkOrderProjectWork[]
  ) {
    this.listUniqueProjectWork = [];
    let indexId = [];
    if (listWorkOrderProjectWork) {
      for (const workOrderProjectWork of listWorkOrderProjectWork) {
        if (indexId.includes(workOrderProjectWork.projectWork.work.idWork)) {
          let index = indexId.indexOf(
            workOrderProjectWork.projectWork.work.idWork
          );
          this.listUniqueProjectWork[index].quantityTotal +=
            workOrderProjectWork.quantityTotal;
        } else {
          let projectWork = JSON.parse(
            JSON.stringify(workOrderProjectWork.projectWork)
          );
          projectWork.quantityTotal = workOrderProjectWork.quantityTotal;
          this.listUniqueProjectWork.push(projectWork);

          indexId.push(workOrderProjectWork.projectWork.work.idWork);
        }
      }
    }
  }

  getPrestationCompany(listWorksCompany?: any[]) {
    this.listWorksWithPrice = [];
    this.companyCost = new Cost();
    let listCurrency = [];
    listWorksCompany.forEach((workCompany) => {
      this.listUniqueProjectWork.forEach((projectWork) => {
        if (projectWork.work.idWork === workCompany.workId.idWork) {
          workCompany.quantity = projectWork.quantityTotal;
          this.listWorksWithPrice.push(workCompany);
          const cost: Cost = {
            cost: workCompany.price * workCompany.quantity,
            currency: this.currency?.currencyCode,
          };
          if (!this.companyCost.cost){
            this.companyCost = cost
          }
          else{
            this.companyCost.cost += cost.cost
          }

        }
      });
    });
  }



  calculTotalByWork(quantity: number, cost: number) {
    return quantity * cost;
  }

  calculTotalByProject() {
    this.globalCost = new Cost();
    let listCurrency = [];

    for (const projectWork of this.listProjectWork) {
      let costToAdd = {
        currency: this.currency?.currencyCode,
        cost: projectWork.quantityTotal * projectWork.work.cost,
      };

      if (!this.globalCost.cost){
        this.globalCost = costToAdd;
      }
      else{
        this.globalCost.cost += costToAdd.cost
      }


    }
  }

  calculTotalByWorkOrder() {
    this.globalCost = new Cost();
    let listCurrency = [];

    for (const workOrderProjectWork of this.listWorkOrderProjectWork) {
      let costToAdd = {
        currency: this.currency?.currencyCode,
        cost:
          workOrderProjectWork.quantityTotal *
          workOrderProjectWork.projectWork.work.cost,
      };

      if (!this.globalCost.cost){
        this.globalCost = costToAdd;
      }
      else{
        this.globalCost.cost += costToAdd.cost;
      }

    }
  }

  generate() {
    this.listWorkWithPrice = [];
    this.companyCost = new Cost();

    if (this.projectWorkByCompany.length){
      this.complete = true;
    }
    else{
      this.complete = false;
    }

    for (const a of this.projectWorkByCompany) {
      if (a) {
        let b = new ProjectCompaniesProjectWork();
        b.company = a.company;
        let projectwork: ProjectWork;
        let listWork: ProjectWork[] = [];
        let cost: Cost;

        for (const wo of a.listWorkOrderCompanyDto) {
          if (wo.company && wo.company.companyId === a.company.companyId) {
            for (const wopw of wo.workOrder.listWorkOrderProjectWork) {
              projectwork = wopw.projectWork;
              if (projectwork.quantityFree > 0){
                this.complete = false;
              }
              projectwork.quantityTotal = wopw.quantityTotal;

              let isPresent = false;
              for (const worklist of listWork) {
                if (projectwork.work.idWork === worklist.work.idWork) {
                  isPresent = true;
                  worklist.quantityTotal += wopw.quantityTotal;
                  for (const lwc of a.listWorksCompany) {
                    if (lwc.workId.idWork === projectwork.work.idWork) {
                      projectwork.work.cost = lwc.price;
                      projectwork.work.currency = this.currency?.currencyCode;
                      cost.cost += wopw.quantityTotal * projectwork.work.cost;

                    }
                  }
                }
              }
              if (!isPresent && a.listWorksCompany) {

                for (const lwc of a.listWorksCompany) {
                  if (lwc.workId.idWork === projectwork.work.idWork) {
                    projectwork.work.cost = lwc.price;
                    projectwork.work.currency = this.currency?.currencyCode;
                    if (!cost) {
                      cost = {
                        cost: lwc.price * projectwork.quantityTotal,
                        currency: this.currency?.currencyCode,
                      };
                    } else {
                      cost.cost += lwc.price * projectwork.quantityTotal;
                    }


                  }
                }

                listWork.push(projectwork);
              }
            }
            b.listWork = listWork;
          }
          else if (!wo.company){
            this.complete = false;
          }
        }
        b.globalCost = JSON.parse(JSON.stringify(cost));

        if (!this.companyCost.cost){
          this.companyCost = b.globalCost
        }
        else{
          this.companyCost.cost += b.globalCost.cost
        }
        let projectCompaniesProjectWork = new ProjectCompaniesProjectWork(JSON.parse(JSON.stringify(b)))
        this.listWorkWithPrice.push(projectCompaniesProjectWork);
      }
    }
  }

  naturalSortCost(event: SortEvent) {
    this.customSortService.naturalSort(event);
  }


}
