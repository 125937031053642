<h2>{{ 'assigner_les_competences' | translate }}</h2>
<div>
  <div class="p-fluid p-grid">
    <div class="p-col-12 p-md-12 p-lg-9">
      <app-filtre-tag (newArrayFilter)="filterArrayByTag($event)" [typeOfTag]="typeOfTag"></app-filtre-tag>
    </div>
    <div class="p-col-12 p-md-12 p-lg-3">
      <span class="p-input-icon-left p-ml-auto">
        <i class="pi pi-search"></i>
        <input
          p pInputText
          type="text"
          (input)="dt.filterGlobal($event.target.value, 'contains');dt1.filterGlobal($event.target.value, 'contains');
                dt2.filterGlobal($event.target.value, 'contains');dt3.filterGlobal($event.target.value, 'contains')"
          placeholder="{{ 'RECHERCHER' | translate }}"
        />
      </span>
    </div>
  </div>
  <div>

    <!----
      PANEL THAT DISPLAY BOTH TABLES AND SELECTED RESULTS
      ---->
    <p-panel [showHeader]="false">
      <!----
      TABVIEW THAT SHOW : ALL/HABILITATION/SAVOIR-ETRE/SAVOIR FAIRE
      ---->
      <p-tabView>
        <!----
        SHOW ALL SKILLS
        ---->
        <p-tabPanel header="{{ 'TOUT' | translate }}">
          <p-table
            #dt
            [value]="skill"
            class="table"
            selectionMode="multiple"
            (onRowSelect)="assignSkills($event)"
            (onRowUnSelect)="removeSkills($event)"
            [(selection)]="skillSelected"
            [scrollable]="true"
            scrollHeight="40vh"
            [paginator]="true"
            [rows]="10"
            styleClass=" p-datatable-striped"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="{{'PAGINATION'|translate}}"
            [rowsPerPageOptions]="[10, 25, 50]"
            [globalFilterFields]="[
              'id',
              'name',
              'type',
            ]"
          >

            <ng-template pTemplate="header">
              <tr>
                <th width="100"></th>
                <th id="name" pSortableColumn="name">
                   {{ 'NOM' | translate }}
                  <p-sortIcon field="name"></p-sortIcon>
                </th>
                <th id="type" pSortableColumn="type">
                  {{ 'TYPE' | translate }}
                  <p-sortIcon field="type"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-skill>
              <tr
                >
                <td width="100">
                  <p-tableCheckbox [value]="skill"></p-tableCheckbox>
                </td>
                <td
                  pTooltip="{{
                    skill.description ? skill.description : 'Pas de description'
                  }}"
                >
                  {{ skill.name }}
                </td>
                <td
                  pTooltip="{{
                    skill.description ? skill.description : 'Pas de description'
                  }}"
                >
                  <span *ngIf="skill.type === 'HABILITATION'">{{ 'HABILITATION' | translate }}</span>
                  <span *ngIf="skill.type === 'SAVOIR_ETRE'">{{ 'SAVOIR_ETRE' | translate }}   </span>
                  <span *ngIf="skill.type === 'SAVOIR_FAIRE'">{{ 'SAVOIR_FAIRE'| translate }}</span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
        <!----
        SHOW ALL HABILITATION
        ---->
        <p-tabPanel header="{{ 'HABILITATION' | translate }}">
          <p-table
            #dt1
            selectionMode="multiple"
            (onRowSelect)="assignSkills($event)"
            (onRowUnSelect)="removeSkills($event)"
            [(selection)]="skillSelected"
            [value]="skill"
            styleClass=" p-datatable-striped"
            [scrollable]="true"
            scrollHeight="200px"
            [globalFilterFields]="[
              'id',
              'name',
            ]"
          >

            <ng-template pTemplate="header">
              <tr>
                <th width="100"></th>
                <th id="name" pSortableColumn="name">
                  {{ 'NOM' | translate }}
                  <p-sortIcon field="name"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-skill>
              <tr *ngIf="skill?.type === 'HABILITATION'"
                  >
                <td width="100">
                  <p-tableCheckbox [value]="skill"></p-tableCheckbox>
                </td>
                <td
                  pTooltip="{{
                    skill.description ? skill.description : 'Pas de description'
                  }}"
                >
                  {{ skill.name }}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
        <!----
        SHOW ALL SAVOIR FAIRE
        ---->
        <p-tabPanel header="{{ 'SAVOIR_FAIRE' | translate }}">
          <p-table
            #dt2
            (onRowSelect)="assignSkills($event)"
            (onRowUnSelect)="removeSkills($event)"
            [value]="skill"
            [scrollable]="true"
            scrollHeight="200px"
            selectionMode="multiple"
            styleClass=" p-datatable-striped"
            [(selection)]="skillSelected"
            [globalFilterFields]="[
              'id',
              'name',
            ]"
          >

            <ng-template pTemplate="header">
              <tr>
                <th width="100"></th>
                <th id="name" pSortableColumn="name">
                  {{ 'NOM' | translate }}
                  <p-sortIcon field="name"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-skill>
              <tr *ngIf="skill?.type === 'SAVOIR_FAIRE'"
                  >
                <td width="100">
                  <p-tableCheckbox [value]="skill"></p-tableCheckbox>
                </td>
                <td
                  pTooltip="{{
                    skill.description ? skill.description : 'Pas de description'
                  }}"
                >
                  {{ skill.name }}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
        <!----
        SHOW ALL SAVOIR ETRE
        ---->
        <p-tabPanel header="{{ 'SAVOIR_ETRE' | translate }} ">
          <p-table
            #dt3
            selectionMode="multiple"
            (onRowSelect)="assignSkills($event)"
            (onRowUnSelect)="removeSkills($event)"
            [value]="skill"
            [scrollable]="true"
            styleClass=" p-datatable-striped"
            scrollHeight="200px"
            [(selection)]="skillSelected"
            [globalFilterFields]="[
              'id',
              'name',
            ]"
          >

            <ng-template pTemplate="header">
              <tr>
                <th width="100"></th>
                <th id="name" pSortableColumn="name">
                  {{ 'NOM' | translate }}
                  <p-sortIcon field="name"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-skill>
              <tr *ngIf="skill?.type === 'SAVOIR_ETRE'"
                  >
                <td width="100">
                  <p-tableCheckbox [value]="skill"></p-tableCheckbox>
                </td>
                <td
                  pTooltip="{{
                    skill.description ? skill.description : 'Pas de description'
                  }}"
                >
                  {{ skill.name }}
                </td>

              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
      </p-tabView>

      <!----
      DISPLAY SELECTED SKILLS FROM TAB
      ---->
      <!-- <ng-template pTemplate="footer">
        <h3>Compétences sélectionnées</h3>
        <div *ngFor="let skill of skillSelected; let i = index" style="margin-bottom: 15px">
          <div class="p-field p-grid p-ai-center vertical-container p-m-2">
            <span class="p-col-12 p-md-3"
            ><p-chip
              *ngIf="skill.type === 'HABILITATION'"
              [tooltipDisabled]="!skill.validity"
              [pTooltip]="getValidity(skill.year_duration, skill.month_duration, skill.day_duration)"
              label="{{ skill.name }}"
              (onRemove)="removeSkill($event, skill)"
              [removable]="true"
              styleClass="chip-info"
            ></p-chip>
              <p-chip
                *ngIf="skill.type === 'SAVOIR_ETRE'"
                [tooltipDisabled]="!skill.validity"
                [pTooltip]="getValidity(skill.year_duration, skill.month_duration, skill.day_duration)"
                label="{{ skill.name }}"
                (onRemove)="removeSkill($event, skill)"
                [removable]="true"
                styleClass="chip-success"
              ></p-chip>
              <p-chip
                *ngIf="skill.type === 'SAVOIR_FAIRE'"
                [tooltipDisabled]="!skill.validity"
                [pTooltip]="getValidity(skill.year_duration, skill.month_duration, skill.day_duration)"
                label="{{ skill.name }}"
                (onRemove)="removeSkill($event, skill)"
                [removable]="true"
                styleClass="chip-warning"
              ></p-chip>
            </span>
            <span
              class="p-grid p-col-12 p-md-8"
              *ngIf="skill?.type === 'HABILITATION' && skill?.validity"
            >
              <span class="p-col-12 p-md-6">
                Valide du
                <p-calendar
                  pTooltip="La modification de la date début de validité entrainera la modification automatique de la date de fin (calcul en fonction de la durée de validité de l'habilitation)"
                  dateFormat="dd.mm.yy"
                  [(ngModel)]="assignedSkills[i].startDate"
                  [monthNavigator]="true"
                  [yearNavigator]="true"
                  yearRange="1970:2050"
                  (onClose)="startDateOnChange(i)"
                ></p-calendar>
              </span>
              <span class="p-col-12 p-md-6">
                au
                <p-calendar
                  dateFormat="dd.mm.yy"
                  [(ngModel)]="assignedSkills[i].endDate"
                  [monthNavigator]="true"
                  [yearNavigator]="true"
                  yearRange="1970:2050"
                ></p-calendar>
              </span>
            </span>
          </div>
        </div>
      </ng-template> -->
      <ng-template pTemplate="footer">
        <h3> {{ 'COMPETENCES_SELECTIONNEES'| translate }}</h3>
        <div *ngFor="let skill of skillSelected; let i = index">
          <div class="p-m-1" *ngIf="skill.type === 'HABILITATION'">
              <p-chip
              [tooltipDisabled]="!skill.validity"
              [pTooltip]="getValidity(skill.year_duration, skill.month_duration, skill.day_duration)"
              label="{{ skill.name }}"
              (onRemove)="removeSkill($event, skill)"
              [removable]="true"
              styleClass="chip-custom-basic"
            ></p-chip>

            <span
              *ngIf="skill?.type === 'HABILITATION' && skill?.validity"
            >
                  {{ 'VALIDE_DU' | translate }}
                <p-calendar
                  pTooltip="La modification de la date début de validité entrainera la modification automatique de la date de fin (calcul en fonction de la durée de validité de l'habilitation)"
                  dateFormat="dd.mm.yy"
                  [(ngModel)]="assignedSkills[i].startDate"
                  [monthNavigator]="true"
                  [yearNavigator]="true"
                  yearRange="1970:2050"
                  (onClose)="startDateOnChange(i)"
                  [style]="{'width':'130px'}"
                ></p-calendar>

                {{ 'AU' | translate }}
                <p-calendar
                  dateFormat="dd.mm.yy"
                  [(ngModel)]="assignedSkills[i].endDate"
                  [monthNavigator]="true"
                  [yearNavigator]="true"
                  yearRange="1970:2050"
                  [style]="{'width':'130px'}"
                ></p-calendar>

            </span>
          </div>
        </div>
        <span class="p-m-1" *ngFor="let skill of skillSelected; let i = index">

              <p-chip
              *ngIf="skill.type !== 'HABILITATION'"
              [tooltipDisabled]="!skill.validity"
              [pTooltip]="getValidity(skill.year_duration, skill.month_duration, skill.day_duration)"
              label="{{ skill.name }}"
              (onRemove)="removeSkill($event, skill)"
              [removable]="true"
              styleClass="chip-custom-basic"
            ></p-chip>
            </span>


      </ng-template>
    </p-panel>
  </div>
  <div [style]="{ height: '13vh' }"></div>
</div>

<p-sidebar
  class="p-text-right"
  [style]="{ height: '13vh' }"
  [visible]="true"
  [blockScroll]="true"
  [dismissible]="false"
  [showCloseIcon]="false"
  [modal]="false"
  position="bottom"
>

  <p-button
    (onClick)="prevPage()"
    styleClass="p-button-outlined p-mr-2"
    label="{{ 'formulaire_RETOUR' | translate }}"
  ></p-button>

  <p-button (onClick)="save()" styleClass="p-mr-2" label="{{ 'formulaire_VALIDER' | translate }}"></p-button>
</p-sidebar>
<p-confirmDialog baseZIndex=5 styleClass="main-page" [style]="{width: '60%'}"></p-confirmDialog>
