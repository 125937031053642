import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Category } from '@data/schema/Category';
import { Materiel } from '@data/schema/Materiel';
import { Unit } from '@data/schema/Unit';
import { MaterialWork } from '@data/schema/Work';

@Component({
  selector: 'app-work-show-consumable',
  templateUrl: './work-show-consumable.component.html',
  styleUrls: ['./work-show-consumable.component.css']
})
export class WorkShowConsumableComponent implements OnInit {
  @Input() listConsumable: Materiel[];
  @Input() listConsumableSelected: MaterialWork[];
  @Output() listConsumableSelectedChange = new EventEmitter();


  change() {
    this.listConsumableSelectedChange.emit(this.listConsumableSelected);
  }



  constructor() { }

  ngOnInit(): void {
  }

}
