import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {WorkOrderRequestAttributes} from "@data/schema/WorkOrderRequestAttributes";
import {FormGroup} from "@angular/forms";
import {EnvService} from "@core/service/env.service";

@Injectable({
  providedIn: 'root'
})
export class BlService {

  private apiURL = this.environment?.readConfig()?.urlApiGateway + "bl/";

  constructor(private http: HttpClient, private environment: EnvService) {

  }

  public listProjectCompany(workOrderRequestAttributes: any): Observable<any> {
    return this.http.post(this.apiURL + "project-companies", workOrderRequestAttributes, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }

  public getWorksCompany(id: number,workOrderRequestAttributes: any): Observable<any> {
    return this.http.post(this.apiURL + "ot/company-works/"+id, workOrderRequestAttributes, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }

  public getVouchersForOT(id: number): Observable<any> {
    return this.http.get(this.apiURL + "voucher/all/"+id, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }

}
