import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService, SortEvent } from 'primeng/api';
import { PersonaService } from '@data/service/persona.service';
import { Technician } from '@data/schema/Technician';
import { Router, NavigationExtras } from '@angular/router';
import { StockService } from '@data/service/stock.service';
import {CustomSortService} from "@shared/service/custom-sort.service";
import { CreationTeamService } from '@data/service/intervention/save-team.service';

@Component({
  selector: 'app-technician',
  templateUrl: './technician.component.html',
  styleUrls: ['./technician.component.css'],
})
export class TechnicianComponent implements OnInit {
  // Arrays
  personaIdOnToggle: number;
  techIDOnToggle: number;
  intervenant: Technician = new Technician();
  personaIntervenant: any[];
  // Booleans
  hasStock: boolean = false;
  hasManyPosts: boolean = false;
  isPartOfATeam: boolean = false;
  partyIdOnToggle: any;

  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private personaService: PersonaService,
    private stockService: StockService,
    private router: Router,
    private customSortService: CustomSortService,
    private creationTeamService :CreationTeamService,
  ) {}

  ngOnInit() {
    this.personaService.getListTechnician().subscribe(
      (res) => {
        if (res.status === 200) {
          this.personaIntervenant = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      }
    );

  }

  getRowIndex(personaID: any, techID: any, partyID: any) {
    this.personaIdOnToggle = personaID;
    this.techIDOnToggle = techID;
    this.partyIdOnToggle = partyID;
  }

  update() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        editMode: true,
      },
    };
    this.router.navigate(
      ['/phoenix/show-technician/', this.personaIdOnToggle],
      navigationExtras
    );
  }



 canTechBeDeleted(){
    this.stockService.technicianHasStock(this.techIDOnToggle).subscribe(
      async res => {
        if(res.status === 200){
          this.hasStock = res.body;
          if(this.hasStock){
            await this.confirm();
          }else{
            this.isTechPartOfTeam(this.partyIdOnToggle);
          }
        }
      }
    );
  }

  isTechPartOfTeam(partyID : any){
    this.creationTeamService.isTechPartOfATeam(partyID).subscribe(
      async res => {
        if(res.status === 200){
          this.isPartOfATeam = res.body;
          await this.confirm();
        }
      }
    )
  }


  confirm() {
  //ajouter le test si le tech est le seul post du persona
    if(!this.hasStock && !this.isPartOfATeam){
      this.confirmationService.confirm({
        header: 'Confirmation',
        message:
          "Vous allez supprimer l'intervenant et les informations qui lui sont associées (le persona sera toujours actif)." +
          '<br>' +
          'Voulez vous continuer ?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Supprimer',
        rejectLabel: 'Annuler',
        key:'deleteTech',
        accept: () => {
          this.delete(this.techIDOnToggle);
          this.router.navigate(['/phoenix/technician']);
          this.messageService.add({
            severity: 'success',
            summary: 'Opération réussie',
          });
        },
      });

    }
    else if(this.hasStock){
      this.confirmationService.confirm({
        header: 'Suppression impossible',
        message: "Cet intervenant dispose d'un stock intervenant (matériel embarqué ou à récupérer) : vous devez supprimer le stock pour supprimer l'intervenant",
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: "J’ai compris",
        rejectVisible: false,
        key:'noStock',
        accept: () => {
          this.router.navigate(['/phoenix/technician']);
        },
      });
    }else if(this.isPartOfATeam){
      this.confirmationService.confirm({
        header: 'Suppression impossible',
        message: "Cet intervenant est associé à une équipe : vous devez supprimer l’association pour supprimer l'intervenant.",
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: "J’ai compris",
        rejectVisible: false,
        key:'partOfTeam',
        accept: () => {
          this.router.navigate(['/phoenix/technician']);
        },
      });
    }
    this.hasStock = false;
    this.isPartOfATeam = false;
  }


  delete(id: number) {
    let technician;
    this.personaIntervenant = this.personaIntervenant.filter(
      (personaIntervenant) => {
        if (personaIntervenant.id === id){
          technician = personaIntervenant;
          technician.id = personaIntervenant.id;
          technician.idParty = personaIntervenant.idParty;
        }
        return personaIntervenant.id !== id
      }
    );
      this.personaService.deleteTechnician(technician).subscribe(
        (res) => {
          // Deleted
          if (res.status === 200) {
            console.log(res.body);
          }
        },
        (error) => {
          if (error) {
            this.messageService.add({
              severity: 'error',
              summary: 'Échec de l’opération : veuillez réessayer',
            });
          }
        },
        () => {}
      );
  }

  naturalSortTechnician(event: SortEvent) {
    this.customSortService.naturalSort(event);
  }

  //check if persona has one party role who is tech
  technicianHasOtherProfiles(){
    this.personaService.technicianHasOtherProfiles(this.personaIdOnToggle).subscribe(
      (res) => {
        if (res.status === 200) {
          this.hasManyPosts = res.body;
          if(this.hasManyPosts ){
            this.canTechBeDeleted();
          }else{
            this.confirmationService.confirm({
              header: 'Suppression impossible',
              message: "Cet intervenant ne peut pas être supprimé.",
              icon: 'pi pi-exclamation-triangle',
              acceptIcon: 'null',
              rejectIcon: 'null',
              acceptLabel: "J’ai compris",
              rejectVisible: false,
              key:'deleteTech',
              accept: () => {
                this.router.navigate(['/phoenix/technician']);
              },
            });
          }
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      }
    );
  }
}
