import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Work } from '@data/schema/Work';
import { CurrencyService } from '@data/service/currency.service';

@Component({
  selector: 'app-work-detail-cost',
  templateUrl: './work-detail-cost.component.html',
  styleUrls: ['./work-detail-cost.component.css']
})
export class WorkDetailCostComponent implements OnInit {

  @Input() work: Work;
  @Output() workChange = new EventEmitter();
  change() {
    this.workChange.emit(this.work);
  }

  listCurrency = {};

  currency = [];

  errorCurrency : boolean = false;
  errorCost : boolean = false;

  constructor(public currencyService: CurrencyService) { }

  ngOnInit(): void {

    this.listCurrency = this.currencyService.getListCurrency();

    for (const property in this.listCurrency) {
      let currency = {
        code : property,
        name : this.listCurrency[property]
      }
      this.currency.push(currency)
    }
  }



}
