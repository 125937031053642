import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {WorkOrder, WorkOrderHistoryProgress, WorkOrderProjectWork} from '@data/schema/WorkOrder';
import {ProjectWork} from "@data/schema/Project";
import {MaterialWork} from "@data/schema/Work";
import {EnvService} from "@core/service/env.service";

@Injectable({
  providedIn: 'root',
})
export class WorkOrderService {
  private apiURL = this.environment?.readConfig()?.urlApiGateway + 'bl/';

  constructor(private http: HttpClient, private environment: EnvService) {
  }

  // GET LIST OF ALL Works order
  public getAllWorkOrders(): Observable<any> {
    return this.http
      .get(this.apiURL + 'getAllWorkOrders', {observe: 'response' as 'body'})
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  public getAllWorkOrdersById(idPersona: number): Observable<any> {
    return this.http
      .get(this.apiURL + 'getAllWorkOrders/' + idPersona, {observe: 'response' as 'body'})
      .pipe(
        map((res) => {
          return res;
        })
      );
  }


  // Create Work order
  public createWorkOrder(workOrder: WorkOrder): Observable<any> {
    return this.http.post(this.apiURL + `createWorkOrder`, workOrder, {
      observe: 'response' as 'body',
    });
  }

  public getWorkOrderById(idWorkOrder: number): Observable<any> {
    return this.http
      .get(this.apiURL + 'getWorkOrderById/' + idWorkOrder, {observe: 'response' as 'body'})
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // UPDATE A Work WITH NEW VALUES
  public updateWorkOrder(workOrder: WorkOrder): Observable<any> {
    return this.http.put(this.apiURL + `updateWorkOrder`, workOrder, {
      observe: 'response' as 'body',
    });
  }

  // DELETE A work order
  public deleteWorkOrder(id: number): Observable<any> {
    return this.http.delete(this.apiURL + `deleteWorkOrder/` + id, {
      observe: 'response' as 'body',
    });
  }

  // Create a voucher
  public createVoucher(formG: any): Observable<any> {
    return this.http.post(this.apiURL + `voucher/save`, formG, {
      observe: 'response' as 'body',
    });
  }

  // List of all vouchers
  public listVouchersForWorkOrder(id: number): Observable<any> {
    return this.http.get(this.apiURL + `voucher/all/` + id, {
      observe: 'response' as 'body',
    });
  }

  // Create history
  public createWorkOrderHistory(workOrderAdvance: WorkOrderHistoryProgress[]): Observable<any> {
    return this.http.post(this.apiURL + `createWorkOrderHistory`, workOrderAdvance, {
      observe: 'response' as 'body',
    });
  }

  //get list company with list works
  public getListCompanyByWork(works: any[]): Observable<any> {
    return this.http.post(this.apiURL + 'ot/companies-by-works', works, {
      observe: 'response' as 'body',
    });
  }

  public projectWorksByCompany(idProject:number, idCompany:number):Observable<any>{
    return this.http.get(this.apiURL+ 'ot/project/'+idProject+'/works-by-company/'+idCompany, {
      observe: 'response' as 'body',
    });
  }

  public getHistoryOtByIdWorkOrder(idWorkOrder: number): Observable<any> {
    return this.http
      .get(this.apiURL + 'getHistoryOtByIdWorkOrder/' + idWorkOrder, {observe: 'response' as 'body'})
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  public isStarted(workOrder: WorkOrder) {
    for (const workOrderProjectWork of workOrder.listWorkOrderProjectWork) {
      if (workOrderProjectWork.quantityDone > 0) {
        return true;
      }
    }
    return false;
  }

  // calculate quantity based on list passed on parameters
  public calculateQuantity(listWorkMaterialConsumable, listWorkMaterialTool, listProjectWork?: ProjectWork[], listWorkOrderProjectWork?: WorkOrderProjectWork[]) {
    // if it's list of project work calculate quantity
    if (listProjectWork) {
      for (const projectWork of listProjectWork) {
        let quantityWork = projectWork.quantityTotal
        for (const materialWork of projectWork.work.materialsList) {
          // add material work with calculate for each work based on quantity
          this.addMaterialWork(listWorkMaterialConsumable, listWorkMaterialTool, materialWork, quantityWork)
        }
      }
    }
    if (listWorkOrderProjectWork) {
      //console.log(listWorkOrderProjectWork)
      for (const workOrderProjectWork of listWorkOrderProjectWork) {
        let quantityWork = workOrderProjectWork.quantityTotal
        let quantityDone = workOrderProjectWork.quantityDone
        for (const materialWork of workOrderProjectWork.projectWork.work.materialsList) {
          this.addMaterialWork(listWorkMaterialConsumable, listWorkMaterialTool, materialWork, quantityWork, quantityDone)
        }
      }
    }
  }

  //check if consumable or tool to calculate
  addMaterialWork(listWorkMaterialConsumable, listWorkMaterialTool, materialWork: MaterialWork, quantity: number, quantityDone?: number) {
    if (materialWork.materialDto.categorie.code === "SER" || materialWork.materialDto.categorie.code === "CSM") {
      // add list consummable
      this.add(listWorkMaterialConsumable, listWorkMaterialTool, "CONSUMABLE", materialWork, quantity, quantityDone)
    } else if (materialWork.materialDto.categorie.code === "OUT" || materialWork.materialDto.categorie.code === "POO") {
      // add list tools
      this.add(listWorkMaterialConsumable, listWorkMaterialTool, "TOOL", materialWork, quantity)
    }
  }

  // add material work to list consumable or list tools based on type
  add(listWorkMaterialConsumable, listWorkMaterialTool, type: string, materialWork: MaterialWork, quantity: number, quantityDone?: number) {
    //console.log(materialWork) ;

    if (type === "CONSUMABLE") {
      // check if material is already in the list
      for (const material of listWorkMaterialConsumable) {
        // already exist material in list, so add quantity to the existant
        if (materialWork.materialDto.id === material.materialDto.id) {
          material.quantityMaterial = material.quantityMaterial + (quantity * materialWork.quantityMaterial)
          material.totalIssuedQuantity = materialWork.totalIssuedQuantity;

          if (quantityDone) {
            material.quantityMaterialUsed = material.quantityMaterialUsed + (quantityDone * materialWork.quantityMaterial)
          }
          return {
            listWorkMaterialConsumable: listWorkMaterialConsumable,
            listWorkMaterialTool: listWorkMaterialTool,
            ret: true
          };
        }
      }
      // doesn't exist material in list so push with work quantity
      let materialWorkWithQuantity = JSON.parse(JSON.stringify(materialWork))
      //console.log(materialWorkWithQuantity)
      materialWorkWithQuantity.quantityMaterial = quantity * materialWorkWithQuantity.quantityMaterial

      if (quantityDone) {
        // PRODUIT EN CROIX
        // FAIT - TOTAL
        // qtyDone -
        materialWorkWithQuantity.quantityMaterialUsed = quantityDone * materialWorkWithQuantity.quantityMaterial / quantity
      }
      listWorkMaterialConsumable.push(materialWorkWithQuantity);
      return {
        listWorkMaterialConsumable: listWorkMaterialConsumable,
        listWorkMaterialTool: listWorkMaterialTool,
        ret: false
      };
    }

    if (type === "TOOL") {
      // check if material is already in the list
      for (const material of listWorkMaterialTool) {
        // already exist material in list, so add quantity to the existant
        if (materialWork.materialDto.id === material.materialDto.id) {
          // tool quantity is only the maximum
          if (materialWork.quantityMaterial > material.quantityMaterial) {
            material.quantityMaterial = materialWork.quantityMaterial;
          }
          return {
            listWorkMaterialConsumable: listWorkMaterialConsumable,
            listWorkMaterialTool: listWorkMaterialTool,
            ret: true
          };
        }
      }
      // doesn't exist material in list so push with work quantity
      let materialWorkWithQuantity = JSON.parse(JSON.stringify(materialWork))
      listWorkMaterialTool.push(materialWorkWithQuantity);
      return {
        listWorkMaterialConsumable: listWorkMaterialConsumable,
        listWorkMaterialTool: listWorkMaterialTool,
        ret: false
      };
    }
  }
}
