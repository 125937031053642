<app-kober-menu module="kober-skill"></app-kober-menu>

<div class="p-d-flex p-jc-center p-m-2 p-p-2 main-page">

    <div
      class="p-col-6 p-nogutter p-jc-center p-m-3 p-p-3 main-content p-shadow-4"
    >
      <div class="p-grid p-fluid">
        <div class="p-col-4">
          <h2>  {{ 'COMPETENCES'| translate }} </h2>
        </div>
      </div>
      <div class="p-grid p-fluid">
        <div class="p-col">
          <app-filtre-tag (newArrayFilter)="filterArrayByTag($event)" [typeOfTag]="typeOfTag"></app-filtre-tag>
        </div>
      </div>


      <div>
        <div>
          <!----
        PANEL THAT DISPLAY BOTH TABLES AND SELECTED RESULTS
        ---->
          <p-panel [showHeader]="false">
            <!----
        TABVIEW THAT SHOW : ALL/HABILITATION/SAVOIR-ETRE/SAVOIR FAIRE
        ---->
            <p-tabView>
              <!----
          SHOW ALL SKILLS
          ---->

              <ng-container>
                <p-table
                  #dt1
                  (sortFunction)="naturalSort($event)"
                  [customSort]="true"
                  sortField="name"
                  [sortOrder]='1'
                  dataKey="name"
                  [(selection)]="skillSelected"
                  selectionMode="single"
                  (onRowSelect)="onRowSelectSkill($event)"
                  [value]="listSkillWithCount"
                  class="table"
                  [scrollable]="true"
                  scrollHeight="60vh"
                  styleClass="p-datatable-sm p-datatable-striped"
                  [globalFilterFields]="['name', 'type', 'personas']"
                >
                  <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                      <div class="p-field-checkbox">
                        <p-checkbox
                          class="p-mr-2 p-mb-2"
                          [(ngModel)]="hab"
                          label="{{ 'HABILITATION' | translate }}"
                          binary="true"
                          inputId="binary"
                          (onChange)="
                            showListSkillbyType($event, 'HABILITATION')
                          "
                        ></p-checkbox>

                        <p-checkbox
                          class="p-mr-2 p-mb-2"
                          [(ngModel)]="sf"
                          label="{{ 'SAVOIR_FAIRE' | translate }}"
                          binary="true"
                          inputId="binary"
                          (onChange)="
                            showListSkillbyType($event, 'SAVOIR_FAIRE')
                          "
                        ></p-checkbox>

                        <p-checkbox
                          class="p-mr-2 p-mb-2"
                          [(ngModel)]="se"
                          label="{{ 'SAVOIR_ETRE' | translate }}"
                          binary="true"
                          inputId="binary"
                          (onChange)="
                            showListSkillbyType($event, 'SAVOIR_ETRE')
                          "
                        ></p-checkbox>
                      </div>

                      <span class="p-input-icon-left p-ml-auto">
                        <i class="pi pi-search"> </i>
                        <input
                          p
                          pInputText
                          type="text"
                          (input)="
                            dt1.filterGlobal($event.target.value, 'contains')
                          "
                          placeholder="{{ 'recherche' | translate }}"
                        />
                      </span>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="table-5"></th>
                      <th id="name" pSortableColumn="name">
                        {{ 'NOM' | translate }}
                        <p-sortIcon field="name"></p-sortIcon>
                      </th>
                      <th id="type" pSortableColumn="type">
                        Type
                        <p-sortIcon field="type"></p-sortIcon>
                      </th>
                      <th id="personas">
                        Personas
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template
                    pTemplate="body"
                    let-listSkillWithCount
                    let-rowIndex="rowIndex"
                  >
                    <tr
                      [ngClass]="{
                        'hightlight-desespoir':
                          selectedPersonaHasSkill(listSkillWithCount)
                      }"
                      [pSelectableRow]="listSkillWithCount"
                      [pSelectableRowIndex]="rowIndex"
                      pDraggable=""
                    >
                      <td class="table-5">
                        <p-checkbox
                          [(ngModel)]="selectedSkillToAssign"
                          onclick="event.stopPropagation();"
                          name="groupname"

                          [value]="listSkillWithCount"
                        ></p-checkbox>
                      </td>
                      <td>
                        {{ listSkillWithCount.name }}
                      </td>
                      <td *ngIf="listSkillWithCount.type === 'HABILITATION'">
                        {{ 'HABILITATION' | translate }}
                      </td>
                      <td *ngIf="listSkillWithCount.type === 'SAVOIR_ETRE'">
                        Savoir être
                      </td>
                      <td *ngIf="listSkillWithCount.type === 'SAVOIR_FAIRE'">
                        Savoir faire
                      </td>
                      <td>
                        <span class="span-style"
                        ><p-button
                          (click)="showSkill(listSkillWithCount)"
                          onclick="event.stopPropagation();"
                          styleClass="p-button-link"
                        >{{
                          listSkillWithCount.totalPersona
                          }}
                          Personas</p-button
                        ></span
                        >
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
                <button
                  pButton
                  label="Décocher"
                  class="p-button-outlined filter-clear"
                  icon="pi pi-filter-slash"
                  (click)="clear()"
                ></button>
              </ng-container>
            </p-tabView>

            <!----
        DISPLAY SELECTED SKILLS FROM TAB
        ---->
          </p-panel>
        </div>
      </div>
    </div>

    <div class="p-col-5 p-jc-center p-m-3 p-p-3 main-content p-shadow-4">
      <h2>Personas</h2>

      <p-panel [showHeader]="false" id="tablePersona">
        <p-tabView>
          <ng-container>
            <p-table
              #dt2
              [rowHover]="true"
              [filterDelay]="0"
              selectionMode="single"
              (sortFunction)="naturalSort($event)"
              [customSort]="true"
              sortField="lastName"
              [sortOrder]='1'
              dataKey="lastName"
              (onRowSelect)="onRowSelectPersona($event)"
              [(selection)]="personaSelected"
              [value]="listPersonasWithCountSkills"
              class="table"
              [scrollable]="true"
              scrollHeight="60vh"
              styleClass="p-datatable-sm p-datatable-striped"
              [globalFilterFields]="[
                'firstName',
                'lastName',
                'totalHab',
                'totalSf',
                'totalSe'
              ]"
            >
              <ng-template pTemplate="caption">
                <div class="p-d-flex">
                  <div class="p-field-checkbox">
                    <p-checkbox
                      class="p-mr-2 p-mb-2"
                      [(ngModel)]="displayTechnician"
                      label="{{ 'INTERVENANTS'| translate }}"
                      binary="true"
                      inputId="binary"
                      (onChange)="selectTechnician($event)"
                    ></p-checkbox>
                  </div>

                  <span class="p-input-icon-left p-ml-auto">
                    <i class="pi pi-search"> </i>
                    <input
                      p
                      pInputText
                      type="text"
                      (input)="
                        dt2.filterGlobal($event.target.value, 'contains')
                      "
                      placeholder="Rechercher"
                    />
                  </span>
                </div>
              </ng-template>
              <ng-template pTemplate="header">
                <tr>
                  <th class="table-15" id=""></th>
                  <th id="lastName" pSortableColumn="lastName">
                    {{ 'NOM' | translate }}
                    <p-sortIcon field="lastName"></p-sortIcon>
                  </th>
                  <th class="table-10" id="h">H</th>
                  <th class="table-10" id="s-f">S-F</th>
                  <th class="table-10" id="s-e">S-E</th>
                </tr>
              </ng-template>
              <ng-template
                pTemplate="body"
                let-listPersonasWithCountSkills
                let-rowIndex="rowIndex"
              >
                <tr
                  [ngClass]="{
                    'hightlight-desespoir': hasSelectedSkill(
                      listPersonasWithCountSkills
                    )
                  }"
                  pDroppable=""
                  [pSelectableRow]="listPersonasWithCountSkills"
                  (onDrop)="drop($event, listPersonasWithCountSkills)"
                >
                  <td class="table-15">
                    <p-avatar styleClass="p-mr-2" size="xlarge" shape="circle"
                    ><img
                      style="object-fit: cover"
                      src="{{ listPersonasWithCountSkills?.photo }}"
                      alt=""
                    /></p-avatar>
                  </td>
                  <td>
                    <span
                    >{{ listPersonasWithCountSkills.firstName }}
                      {{ listPersonasWithCountSkills.lastName }}</span
                    >
                  </td>
                  <td class="table-10">
                    <span class="span-style">
                      <p-button
                        (click)="showPersona(listPersonasWithCountSkills)"
                        onclick="event.stopPropagation();"
                        styleClass="p-button-link"
                      >{{ listPersonasWithCountSkills.totalHab }}</p-button
                      >
                    </span>
                  </td>
                  <td class="table-10">
                    <span class="span-style">
                      <p-button
                        (click)="showPersona(listPersonasWithCountSkills)"
                        onclick="event.stopPropagation();"
                        styleClass="p-button-link"
                      >{{ listPersonasWithCountSkills.totalSf }}</p-button
                      ></span
                    >
                  </td>
                  <td class="table-10">
                    <span class="span-style"
                    ><p-button
                      (click)="showPersona(listPersonasWithCountSkills)"
                      onclick="event.stopPropagation();"
                      styleClass="p-button-link"
                    >{{ listPersonasWithCountSkills.totalSe }}</p-button
                    ></span
                    >
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </ng-container>
        </p-tabView>
      </p-panel>
  </div>
</div>
<p-confirmDialog
  baseZIndex="5"
  styleClass="main-page"
  [style]="{ width: '60%' }"
></p-confirmDialog>
