import { Pipe, PipeTransform } from '@angular/core';
import { ControlsType, RadioButton } from '@data/schema/intervention/Form';

@Pipe({
  name: 'isRadioButton'
})
export class IsRadioButtonPipe implements PipeTransform {

  transform(controlsType : ControlsType): controlsType is RadioButton {
    return controlsType?.code === "radioButton";
  }

}
