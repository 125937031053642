// Angular core
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';

import {AppComponent} from './app.component';
import { AppRoutingModule } from './app-routing.module';

// to rework
import { DatePipe, registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';

registerLocaleData(localeFr);
// Important to keep this here
import {TranslateStore} from '@ngx-translate/core';

// Angularx
import {QRCodeModule} from 'angularx-qrcode';




// Directives
import {RegexDirective} from './directives/regex.directive';
import { DuplicationDirective } from './directives/duplication.directive';
import { DuplicationNameDirective } from './directives/duplication-name.directive';
import { CheckStoreHoursDirective } from './directives/check-store-hours.directive';

// Component to move
import {PersonaComponent} from './kober-skill/persona/persona.component';
import {NewPersonaComponent} from './kober-skill/persona/new-persona/new-persona.component';
import {ShowPersonaComponent} from './kober-skill/persona/show-persona/show-persona.component';
import {TechnicianComponent} from './kober-skill/technician/technician.component';
import {SkillComponent} from './kober-skill/skill/skill.component';
import {NewSkillComponent} from './kober-skill/skill/new-skill/new-skill.component';
import {NewTechnicianComponent} from './kober-skill/technician/new-technician/new-technician.component';
import {ShowSkillsComponent} from './kober-skill/skill/show-skills/show-skills.component';
import {ShowTechnicianComponent} from './kober-skill/technician/show-technician/show-technician.component';
import {NewPersonaStepsComponent} from './kober-skill/persona/new-persona/new-persona-steps.component';
import {AssignSkillComponent} from './kober-skill/persona/new-persona/assign-skill.component';
import {AddSkillComponent} from './kober-skill/persona/show-persona/add-skill.component';
import {CompanyComponent} from './kober-skill/company/company.component';
import {NewCompanyComponent} from './kober-skill/company/new-company/new-company.component';
import {UploadFileMaterialComponent} from './kober-material/material/upload-file-material/upload-file-material.component';
import {ShowCompanyComponent} from './kober-skill/company/show-company/show-company.component';
import {SkillsOfPersonasComponent} from './kober-skill/select-persona-skill/skills-of-personas/skills-of-personas.component';

import {AssignSkillsToPersonasComponent} from './kober-skill/select-persona-skill/assign-skills-to-personas/assign-skills-to-personas.component';

import {SkillsOfPersonaDetailPersonaComponent} from './kober-skill/select-persona-skill/skills-of-personas/detail-persona.component';
import {AssignSkillsToPersonasDetailComponent} from './kober-skill/select-persona-skill/assign-skills-to-personas/assign-skills-to-personas-detail.component';
import {PersonasOfSkillComponent} from './kober-skill/select-persona-skill/personas-of-skill/personas-of-skill.component';
import {PersonasOfSkillDetailComponent} from './kober-skill/select-persona-skill/assign-skills-to-personas/personas-of-skill-detail.component';
import {WorkComponent} from './kober-services/work/work.component';
import {ShowWorkComponent} from './kober-services/work/show-work/show-work.component';
import {NewWorkComponent} from './kober-services/work/new-work/new-work.component';

import {ProjectShowGeneralComponent} from './kober-services/components/project/project-show-general/project-show-general.component';


import {ProjectComponent} from './kober-services/project/project.component';
import {NewProjectComponent} from './kober-services/project/new-project/new-project.component';
import {WorkOrderComponent} from './kober-services/work-order/work-order.component';
import {NewWorkOrderComponent} from './kober-services/work-order/new-work-order/new-work-order.component';
import {ShowProjectComponent} from './kober-services/project/show-project/show-project.component';
import {ContractComponent} from './kober-services/contract/contract.component';
import {ContractFormCompanyComponent} from './kober-services/contract/new-contract/contract-form-company/contract-form-company.component';
import {ContractFormSelectPrestationComponent} from './kober-services/contract/new-contract/contract-form-select-prestation/contract-form-select-prestation.component';
import {ContractFormGeneralComponent} from './kober-services/contract/new-contract/contract-form-general/contract-form-general.component';
import {NewContractComponent} from './kober-services/contract/new-contract/new-contract.component';
import {ShowContractComponent} from './kober-services/contract/show-contract/show-contract.component';
import { WorkDetailSkillComponent } from './kober-services/components/work/work-detail-skill/work-detail-skill.component';
import { WorkDetailGeneralComponent } from './kober-services/components/work/work-detail-general/work-detail-general.component';
import { WorkDetailConsumableComponent } from './kober-services/components/work/work-detail-consumable/work-detail-consumable.component';
import { WorkDetailToolsComponent } from './kober-services/components/work/work-detail-tools/work-detail-tools.component';
import { WorkDetailCostComponent } from './kober-services/components/work/work-detail-cost/work-detail-cost.component';

import { WorkShowConsumableComponent } from './kober-services/components/work/work-show-consumable/work-show-consumable.component';
import { WorkShowToolsComponent } from './kober-services/components/work/work-show-tools/work-show-tools.component';
import { WorkShowCostComponent } from './kober-services/components/work/work-show-cost/work-show-cost.component';
import { WorkShowGeneralComponent } from './kober-services/components/work/work-show-general/work-show-general.component';
import { WorkShowSkillComponent } from './kober-services/components/work/work-show-skill/work-show-skill.component';
import { ProjectFormGeneralComponent } from './kober-services/components/project/project-form-general/project-form-general.component';
import { ProjectFormDetailComponent } from './kober-services/components/project/project-form-detail/project-form-detail.component';
import { WorkFormListWorksComponent } from './kober-services/components/project/project-form-detail/work-form-list-works/work-form-list-works.component';
import { WorkFormSelectedWorksComponent } from './kober-services/components/project/project-form-detail/work-form-selected-works/work-form-selected-works.component';
import { WorkFormListMaterialsComponent } from './kober-services/components/project/project-form-detail/work-form-list-materials/work-form-list-materials.component';
import { WorkFormListCostComponent } from './kober-services/components/project/project-form-detail/work-form-list-cost/work-form-list-cost.component';
import { WorkOrderFormGeneralComponent } from './kober-services/components/work-order/work-order-form-general/work-order-form-general.component';
import { WorkOrderFormDetailComponent } from './kober-services/components/work-order/work-order-form-detail/work-order-form-detail.component';

import { WorkOrderFormListWorksComponent } from './kober-services/components/work-order/work-order-form-detail/work-order-form-list-works/work-order-form-list-works.component';
import { WorkOrderFormListSelectedWorksComponent } from './kober-services/components/work-order/work-order-form-detail/work-order-form-list-selected-works/work-order-form-list-selected-works.component';
import { ShowWorkOrderComponent } from './kober-services/work-order/show-work-order/show-work-order.component';
import { WorkOrderShowGeneralComponent } from './kober-services/components/work-order/work-order-show-general/work-order-show-general.component';
import { WorkNewVoucherAddMaterialsComponent } from './kober-services/components/project/project-form-detail/work-form-list-materials/work-new-voucher-add-materials/work-new-voucher-add-materials.component';
import { WorkNewVoucherListMaterialsComponent } from './kober-services/components/project/project-form-detail/work-form-list-materials/work-new-voucher-list-materials/work-new-voucher-list-materials.component';
import { WorkFormHistoryComponent } from './kober-services/components/work-order/work-order-form-detail/work-form-history/work-form-history.component';

import { WorkOrderFormReportAdvanceComponent } from './kober-services/components/work-order/work-order-form-detail/work-order-form-report-advance/work-order-form-report-advance.component';
import { WorkOrderShowAdvancementComponent } from './kober-services/components/work-order/work-order-show-advancement/work-order-show-advancement.component';
import { ProgressBarComponent } from './kober-services/components/progress-bar/progress-bar.component';
import { TagComponent } from './kober-setting/tag/tag.component';
import { NewTagComponent } from './kober-setting/tag/new-tag/new-tag.component';
import { SettingTagComponent } from './kober-setting/components/tag/setting-tag/setting-tag.component';
import { DetailTagComponent } from './kober-setting/components/tag/detail-tag/detail-tag.component';
import { ListTagComponent } from './kober-setting/components/tag/list-tag/list-tag.component';
import { VoucherComponent } from './kober-services/components/project/project-form-detail/voucher/voucher.component';
import {ShowTagComponent} from "./kober-setting/tag/show-tag/show-tag.component";
import { ConfigureCurrencyComponent } from './kober-setting/currency/configure-currency/configure-currency.component';
import { ShowCurrencyComponent } from './kober-setting/currency/show-currency/show-currency.component';
import { WorkFromListWorkOrderComponent } from './kober-services/components/project/project-form-detail/work-from-list-work-order/work-from-list-work-order.component';
import { SettingCurrencyComponent } from './kober-setting/components/currency/setting-currency/setting-currency.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {EnvService} from "@core/service/env.service";
import { NgImageSliderModule } from 'ng-image-slider';
import { ExpiringSkillsComponent } from './kober-skill/select-persona-skill/expiring-skills/expiring-skills.component';
import { ShowSkillExpiringComponent } from './kober-skill/select-persona-skill/show-skill-expiring/show-skill-expiring.component';
import { ShowPersonaWithSkillsExpiredComponent } from './kober-skill/select-persona-skill/show-persona-with-skills-expired/show-persona-with-skills-expired.component';
import { WorkOrderCreationComponent } from './modules/kober-intervention/page/work-order-creation/work-order-creation.component';
import { UpdateTechDialogComponent } from './modules/kober-intervention/page/component/update-tech-dialog/update-tech-dialog.component';


const appInitializerFn = (configService: EnvService) => {
  return () => {
    return configService.setConfig();
  };
}
@NgModule({

  declarations: [
    AppComponent,

    // to move
    PersonaComponent,
    TechnicianComponent,
    ShowTechnicianComponent,
    NewPersonaComponent,
    ShowPersonaComponent,
    SkillComponent,
    ShowSkillsComponent,
    NewSkillComponent,
    NewTechnicianComponent,
    NewPersonaStepsComponent,
    AssignSkillComponent,
    AddSkillComponent,
    CompanyComponent,
    NewCompanyComponent,
    AssignSkillsToPersonasComponent,
    UploadFileMaterialComponent,
    ShowCompanyComponent,
    SkillsOfPersonasComponent,
    SkillsOfPersonaDetailPersonaComponent,
    PersonasOfSkillComponent,
    WorkComponent,
    ShowWorkComponent,
    NewWorkComponent,
    WorkDetailSkillComponent,
    WorkDetailGeneralComponent,
    WorkDetailConsumableComponent,
    WorkDetailToolsComponent,
    WorkDetailCostComponent,
    WorkShowConsumableComponent,
    WorkShowToolsComponent,
    WorkShowCostComponent,
    WorkShowGeneralComponent,
    WorkShowSkillComponent,
    AssignSkillsToPersonasDetailComponent,
    PersonasOfSkillDetailComponent,
    ProjectComponent,
    NewProjectComponent,
    ProjectFormGeneralComponent,
    ProjectFormDetailComponent,
    WorkFormListWorksComponent,
    WorkFormSelectedWorksComponent,
    WorkFormListMaterialsComponent,
    WorkFormListCostComponent,
    WorkOrderComponent,
    NewWorkOrderComponent,
    WorkOrderFormGeneralComponent,
    ShowProjectComponent,
    WorkOrderFormDetailComponent,
    ContractComponent,
    ContractFormCompanyComponent,
    ContractFormSelectPrestationComponent,
    ContractFormGeneralComponent,
    NewContractComponent,
    ShowContractComponent,
    ShowProjectComponent,
    ProjectShowGeneralComponent,
    WorkOrderFormListWorksComponent,
    WorkOrderFormListSelectedWorksComponent,
    ShowWorkOrderComponent,
    WorkOrderShowGeneralComponent,
    WorkNewVoucherAddMaterialsComponent,
    WorkNewVoucherListMaterialsComponent,
    WorkOrderFormReportAdvanceComponent,
    WorkFormHistoryComponent,
    WorkOrderShowAdvancementComponent,
    ProgressBarComponent,
    TagComponent,
    NewTagComponent,
    ShowTagComponent,
    SettingTagComponent,
    DetailTagComponent,
    ListTagComponent,
    VoucherComponent,
    ConfigureCurrencyComponent,
    ShowCurrencyComponent,
    WorkFromListWorkOrderComponent,
    SettingCurrencyComponent,
    ExpiringSkillsComponent,
    ShowSkillExpiringComponent,
    ShowPersonaWithSkillsExpiredComponent,
    // DIRECTIVE
    CheckStoreHoursDirective,
    DuplicationDirective,
    DuplicationNameDirective,
    RegexDirective,
    UpdateTechDialogComponent,

    //WorkOrderCreationComponent,
    // PermissionDirective,
    // IfRolesDirective
  ],

  // to move to module skill
  entryComponents: [
    AddSkillComponent,
    SkillsOfPersonaDetailPersonaComponent,
    ShowSkillExpiringComponent,
  ],

  providers: [ TranslateStore,
    EnvService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [EnvService]
    },
    DatePipe

  ],

  imports: [
    // Angular
    BrowserModule,


    // Core & Shared
    CoreModule,
    SharedModule,

    // App
    AppRoutingModule,

    // to move

    //RouterModule.forRoot(routes),
    QRCodeModule,
    ImageCropperModule,
    NgImageSliderModule,
    BrowserAnimationsModule,

  ],
  // to move
  exports: [
    RegexDirective,
  ],

  bootstrap: [AppComponent]
})
export class AppModule {
}
