import { Component, OnInit, HostListener } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ContractService } from "@data/service/contract.service";
import { ConfirmationService, MenuItem, MessageService } from "primeng/api";
import { Router } from "@angular/router";
import { CurrencyService } from "@data/service/currency.service";
import { Currency } from "@data/schema/Currency";
import { Observable, BehaviorSubject } from 'rxjs';
import { LocalStorageService } from '@shared/service/local-storage.service';
import { ComponentCanDeactivate } from '@core/guard/component-can-deactivate.guard';
import { ContractCustomResponse } from '@data/schema/ContractCustomResponse';

@Component({
  selector: 'app-new-contract',
  templateUrl: './new-contract.component.html',
  styleUrls: ['./new-contract.component.css']
})
export class NewContractComponent implements OnInit, ComponentCanDeactivate {

  public submited: BehaviorSubject<boolean>;

  //@HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return (this.contractForm.pristine || this.submited.value) ? true : false
  }

  contractForm: FormGroup;
  mode: String = "create";
  isSubmit: boolean = false;
  items: MenuItem[];
  invalidDatesOnCompanyChanged: boolean = false;
  currency: Currency;

  constructor(private contractService: ContractService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private currencyService: CurrencyService,
    private localStorage: LocalStorageService) {

      this.submited = new BehaviorSubject(false);
      this.currency = new Currency(JSON.parse(this.localStorage.getLocalStorageItem("currency")));

  }

  ngOnInit(): void {
    this.items = [
      { label: 'Contrats', routerLink: '/phoenix/contract' },
      { label: "Création d'un contrat", styleClass: 'ariane-current' }
    ];
    this.contractForm = new FormGroup({
      contractName: new FormControl(null, Validators.required),
      description: new FormControl(null),
      creationDate: new FormControl(new Date()),
      effectiveDate: new FormControl(null, Validators.required),
      expiryDate: new FormControl(null, Validators.required),
      validate: new FormControl(false),
      currency: new FormControl(null, Validators.required),
      company: new FormControl(null, Validators.required),
      contractWorks: new FormArray([
      ]),
    })
    this.isCurrencyAlreadyConfigured();

  }

  isCurrencyAlreadyConfigured() {
    if (!this.currency?.currencyCode) {
      this.confirmationService.confirm({
        header: 'Action impossible',
        message:
          "La devise n’est pas paramétrée.",
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        acceptLabel: "J'ai compris",
        rejectVisible: false,
        closeOnEscape: false,
        dismissableMask: false,
        accept: () => {
          this.submited.next(true)
          this.redirectTo('/phoenix/contract');
        },
        reject: () => {
          this.submited.next(true)
          this.redirectTo('/phoenix/contract');
        },

      });
    } else {
      this.contractForm.patchValue({ currency: this.currency?.currencyCode });

    }
  }

  checkContractWorks() {
    let myArray = this.contractForm.value.contractWorks.map(x => x.workId);
    if (myArray.length > 0) {
      return myArray.length === new Set(myArray).size;
    } else {
      return false;
    }
  }

  back() {

   if (this.contractForm.pristine || this.submited.value){

        this.redirectTo('/phoenix/contract')

   }
   else{

      this.confirmationService.confirm({
        header: 'Confirmation',
        message:
          'Vous allez quitter la page, les données seront perdues.' +
          '<br>' +
          'Voulez vous continuer ?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Quitter',
        rejectLabel: 'Annuler',
        accept: () => {

          this.redirectTo('/phoenix/contract')
        },
      });
    }
  }

  sendContract() {

    if (this.contractForm.invalid || this.invalidDatesOnCompanyChanged) {
      this.isSubmit = true;
    }
    else {
      if (this.checkContractWorks() === true) {
        this.contractService.SaveContract(this.contractForm).subscribe(
          res => {
            if (res.body.code === 200) {
              this.messageService.add({ severity: 'success', summary: 'Opération réussie', detail: res.message });
              this.submited.next(true)
              this.redirectTo('/phoenix/contract');
            }else if(res.body.code === 607){
                this.confirmationService.confirm({
                  header: 'Action impossible',
                  message:
                    'Un contrat contenant les mêmes prestations existe déjà pour cette entreprise' 
                    ,
                  icon: 'pi pi-exclamation-triangle',
                  acceptIcon: 'null',
                  rejectIcon: 'null',
                  acceptLabel: "J'ai compris",
                  rejectVisible: false,
                });
            }
          }, error => {
              this.messageService.add({
                severity: 'error',
                summary: 'Erreur',
                detail: 'Échec de l’opération : veuillez réessayer'
              });
          }, () => {

          }
        )
      }
    }
  }

  redirectTo(uri: string) {
    this.submited.next(true)
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }


  invalidDatesOnChangeCompany(event) {
    console.log(event);
    this.invalidDatesOnCompanyChanged = event;
  }
}
