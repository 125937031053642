import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EnvService } from '@core/service/env.service';
import { TokenStorageService } from '@core/service/token-storage.service';
import { PersonaService } from '@data/service/persona.service';
import { SellerbotService } from '@data/service/sellerbot.service';

@Component({
  selector: 'app-update-tech-dialog',
  templateUrl: './update-tech-dialog.component.html',
  styleUrls: ['./update-tech-dialog.component.scss']
})
export class UpdateTechDialogComponent implements OnInit {
  iframeUrl : SafeResourceUrl;
  baseUrl: string;
  uri: string;
  SOURCE_APP_KEY: string = 'source_app';
  SOURCE_APP_VALUE: string = 'kober';
  id: string;
  tempId: string;
  isError: boolean = false;
  loading: boolean = true;

  constructor(private sanitizer: DomSanitizer,
    private tokenStorageService: TokenStorageService,
    private environment: EnvService,
    public dialogRef: MatDialogRef<UpdateTechDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private personaService: PersonaService,
    private sellerBotService: SellerbotService) {
     }

  ngOnInit(): void {
      this.personaService.getTechnicianByPartyId(this.data.technician.idParty).subscribe((res) => {
        this.tempId = res.body.idKeycloak;
        if(this.tempId == null){
          this.isError = true;
          this.loading = false;
        }
        this.sellerBotService.getIdByidKeyCloack(this.tempId).subscribe((res) => {
          if(res == null){
            this.isError = true;
            this.loading = false;
          }else{
            if(this.data.action == 'update'){
              this.uri = 'technician_instance/update/'+res.id;
            }else{
              this.uri = 'technician_instance/info/'+res.id;
            }
            this.baseUrl = this.environment?.readConfig()?.baseUrlSbFront;
            const token = this.tokenStorageService.getToken();
            const refreshToken = this.tokenStorageService.getRefreshToken();
            this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.baseUrl}/${this.uri}?${this.SOURCE_APP_KEY}=${this.SOURCE_APP_VALUE}&token=${token}}&refresh_token=${refreshToken}`);
          }

        },
        (error) => {
          console.log(error);
          this.isError = true;
          this.loading = false;
        });
      },
      (error) => {
        console.log(error);
        this.isError = true;
        this.loading = false;
      });
  }

  onLoadIFrame(){
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  }

}
