import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { PersonaService } from '@data/service/persona.service';
import { Persona } from '@data/schema/Persona';
import { Router, NavigationExtras } from '@angular/router';
import { ConfirmationService,SortEvent } from 'primeng/api';
import {CustomSortService} from "@shared/service/custom-sort.service";
import { TranslateService } from '@ngx-translate/core';
import { OrganizationService } from '@data/service/organization.service';
import { CreationTeamService } from '@data/service/intervention/save-team.service';
import { LocalStorageService } from '@shared/service/local-storage.service';
import { PermissionsList } from '@shared/enums/permissionsList';
import { ExportDataService } from '@shared/service/export-data.service';
import { EnvService } from '@core/service/env.service';
import {Table} from "primeng/table";
import {Pageable} from "@data/schema/Pageable";
@Component({
  selector: 'app-persona',
  templateUrl: './persona.component.html',
  styleUrls: ['./persona.component.css'],
})
export class PersonaComponent implements OnInit, AfterViewInit {
  id: number;
  persona: Persona[] = [];
  enabled: boolean = true;
  display: string;
  personaOnToggle: Persona;
  isPartOfATeam: boolean = false;
  listPosts: any[] = [];
  personasRole: any[] = [];
  userPools: number[] = [];
  loading: boolean;
  lazyLoadEvent: LazyLoadEvent;
  totalRecords: number = 20;
  indexTab: number = 0;
  pageSize = this.environment?.readConfig()?.personaExportSize;

  pageable: Pageable = {};

  //default sort order
  sortBy: string = 'lastName';
  sortOrder: string = 'asc';
  @ViewChild('dt1') dt1: Table;

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private personaService: PersonaService,
    private router: Router,
    private creationTeamService :CreationTeamService,
    private organizationService: OrganizationService,
    private customSortService: CustomSortService,
    private localStorageService : LocalStorageService,
    private translate: TranslateService,
    private exportDataService: ExportDataService,
    private environment: EnvService,
  ) {}

  onPress() {
    this.enabled = !this.enabled;
    this.getPersonaList(this.enabled, "");
    if (!this.enabled) {
      this.display =  this.translate.instant('MONTRER_ACTIF');
    } else {
      this.display =  this.translate.instant('MONTRER_INACTIFS');
    }
  }

  ngOnInit() {
    this.getAllProfiles();
    this.display = this.translate.instant('MONTRER_INACTIFS');
    this.getUserPools();
  }
    
  ngAfterViewInit() {
    this.getPersonaList(this.enabled, "");
  }
  
  getUserPools() {
    let hasPermissionToViewAll = this.localStorageService.hasPermission(PermissionsList.Kober_viewAll);
    if(!hasPermissionToViewAll){
      this.userPools = JSON.parse(sessionStorage.getItem("user-pools")).map(pool => pool.id)
    }
  }


  public onSearchChangeReloadData(searchValue : string){
    this.getPersonaList(this.enabled, searchValue);
  }
  public onPageChangeReloadData(){
    this.getPersonaList(this.enabled, "");
  }

  private getPersonaList(enabled: boolean, searchText: string) {
    var sortOrder = this.dt1.sortOrder == 1 ? "asc": "desc";
    this.personaService.getNewListPersonaByEnabled(
      enabled, this.userPools.toString(), this.pageable.page.toString(),
      this.dt1.rows.toString(), this.dt1.sortField, sortOrder, searchText)
      .subscribe(
      (res) => {
        if (res.status === 200) {
          this.persona = res.body.content;
          this.loading = false;
          this.totalRecords = res.body.totalElements;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => { }
    );
  }

getAllProfiles(){
  // Get list of posts
  this.organizationService.getAllProfilesPersona().subscribe({
    next: (res) => {
      this.listPosts = res;
    },
  });
}

  getRowPersona(persona: Persona) {
    this.personaOnToggle = persona;
  }

  update() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        editMode: true,
      },
    };
    this.router.navigate(
      ['/phoenix/show-persona/', this.personaOnToggle.id],
      navigationExtras
    );
  }

  changePersonaStatus() {
    if (this.enabled && !this.isPartOfATeam) {
      this.confirmationService.confirm({
        header: this.translate.instant('titre_message_exception_CONFIRMATION'),
        message: this.translate.instant('texte_message_exception_DESACTIVER_PERSONA'),
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Désactiver',
        rejectLabel: 'Annuler',
        accept: () => {
          this.disable(this.personaOnToggle);
          this.router.navigate(['/phoenix/persona']);
          this.messageService.add({
            severity: 'success',
            summary:  this.translate.instant('toast_OPERATION_REUSSI'),
          });
        },
      });
    } else if(this.enabled && this.isPartOfATeam){
      this.confirmationService.confirm({
        header: this.translate.instant('titre_message_exception_SUPPRESSION_IMPOSSIBLE'),
        message: this.translate.instant('texte_message_exception_DESACTIVER_PERSONA_AVEC_EQUIPE'),
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: this.translate.instant('bouton_message_exception_COMPRIS'),
        rejectVisible: false
      });
    }
    else if (!this.enabled) {
      this.confirmationService.confirm({
        header: 'Confirmation',
        message:
          'Vous allez activer le persona.' + '<br>' + 'Voulez vous continuer ?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Activer',
        rejectLabel: 'Annuler',
        accept: () => {
          this.activate(this.personaOnToggle);
          this.router.navigate(['/phoenix/persona']);
          this.messageService.add({
            severity: 'success',
            summary: 'Opération réussie',
          });
        },
      });
    }
  }

  disable(personaToDelete: Persona) {
    this.persona = this.persona.filter(
      (persona) => persona.id !== personaToDelete.id
    );
    // Call API in the service to soft delete Persona
    this.personaService
      .deletePersona(personaToDelete.id, personaToDelete)
      .subscribe(
        (res) => {
          // Deleted
          if (res.status === 200) {
          }
        },
        (error) => {
          if (error) {
            this.messageService.add({
              severity: 'error',
              summary: 'Échec de l’opération : veuillez réessayer',
            });
          }
        },
        () => {}
      );
  }

  activate(personaToDelete: Persona) {
    this.persona = this.persona.filter(
      (persona) => persona.id !== personaToDelete.id
    );
    // Call API in the service to soft delete Persona
    this.personaService
      .activatePersona(personaToDelete.id, personaToDelete)
      .subscribe(
        (res) => {
          // Deleted
          if (res.status === 200) {
          }
        },
        (error) => {
          if (error) {
            this.messageService.add({
              severity: 'error',
              summary: 'Échec de l’opération : veuillez réessayer',
            });
          }
        },
        () => {}
      );
  }
  isTechPartOfTeam(partyID : any){
    this.creationTeamService.isTechPartOfATeam(partyID).subscribe(
      async res => {
        if(res.status === 200){
          this.isPartOfATeam = res.body;
        }
      }
    )
  }
  naturalSortPersona(event: SortEvent) {
    this.customSortService.naturalSort(event);
  }

   userSync(){
    this.personaService.userSync().subscribe(
      (res)=>{ window.location.reload(); },
    );

   }

   loadPersona(event: LazyLoadEvent){
    console.log(event)
    let currentPage: number;
    currentPage = event.first / event.rows ;
     this.pageable.page= event.first / event.rows;
    console.log(currentPage)
    this.loading = true;
    this.lazyLoadEvent = event;
    this.lazyLoadEvent['page'] = currentPage;
   }



   exportToExcel(pageSize: number = this.pageSize) {
    this.loading = true;
    let allData: any[] = [];
    let currentPage = 0;
    let totalElements = 0;
    pageSize = pageSize ?? 50;
    const getData = (): void => {
      const lazyLoadEvent = {
        first: currentPage * pageSize,
        rows: pageSize,
        sortField: this.lazyLoadEvent.sortField,
        sortOrder: this.lazyLoadEvent.sortOrder,
        globalFilter: this.lazyLoadEvent.globalFilter,
        page: currentPage
      };

      this.personaService.getListPersonaByEnabled(
        this.enabled,
        this.userPools.toString(),
        lazyLoadEvent
      ).subscribe(
        (res) => {
          const data = this.mapAndFormatData(res.body.content);
          allData.push(...data);
          if (totalElements === 0) {
            totalElements = res.body.totalElements;
          }
          if (currentPage < Math.ceil(totalElements / pageSize) - 1) {
            currentPage++;
            getData();
          } else {
            this.loading = false;
            const filename = this.translate.instant('etape_PERSONAS');
            this.exportDataService.exportAsExcelFile(allData, filename);
            this.messageService.add({ severity: 'success', summary: 'Opération réussie' });
          }
        }, error => {
          console.log("Error when export the Persona Excel file", error);
          this.loading = false;
        }
      );
    };

    getData();
  }

  private mapAndFormatData(personnas: Persona[]): any[] {
    return personnas.map(persona => {
          const baseData = {
            [this.translate.instant('PRENOM')]: persona.firstName,
            [this.translate.instant('NOM')]: persona.lastName,
            [this.translate.instant('NOM_PUBLIC')]: persona.publicName,
            [this.translate.instant('PROFILS')]:persona.listProfil[0]?.nameFR,
            [this.translate.instant('ENTREPRISE')]:persona?.company.name,
            [this.translate.instant('EMAIL')]:persona.mail,
            [this.translate.instant('NUM_PERSONEL')]:persona.personalNumber
          };

          return baseData;
        });


  }

}
