<h2 xmlns="http://www.w3.org/1999/html">  {{ 'CREER_PERSONA' | translate }}   </h2>
<form #myForm="ngForm" style="position: relative">
  <div class="p-grid p-col-12 p-d-flex p-jc-center">
    <div class="p-col-6">
      <div class="p-fluid">
        <div class="p-field p-grid">
          <label for="firstName" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
            {{ 'PRENOM' | translate }}   *
          </label>
          <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
            <input
              required
              [(ngModel)]="persona.firstName"
              id="firstName"
              name="firstName"
              type="text"
              class="form-control"
              pInputText
              [minLength]="1"
             
            />
             <!-- pattern="[a-zA-Z\s-éçèà]+" -->
            <!-- validation error message -->
            <div
              *ngIf="
                myForm.controls['firstName']?.invalid &&
                myForm.controls['firstName'].touched
              "
            >
              <p-message severity="error" text="Champ obligatoire."></p-message>
            </div>
            <p-message
              *ngIf="myForm.controls['firstName']?.errors?.pattern"
              severity="error"
              text="Format non valide (caractères acceptés : alphabétiques et - uniquement)."
            ></p-message>
          </div>
        </div>
        <div class="p-field p-grid">
          <label for="lastName" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
            {{ 'NOM_FAMILLE' | translate }}   *
          </label>
          <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
            <input
              required
              [(ngModel)]="persona.lastName"
              id="lastName"
              name="lastName"
              type="text"
              class="form-control"
              pInputText
              [minLength]="1"
              
            />
            <!-- pattern="[a-zA-Z\s-éçèà]+" -->
            <div
              *ngIf="
                myForm.controls['lastName']?.invalid &&
                myForm.controls['lastName']?.touched
              "
            >
              <p-message severity="error" text="Champ obligatoire.">
              </p-message>
            </div>
            <div *ngIf="myForm.controls['lastName']?.errors?.pattern">
              <p-message
                severity="error"
                text="Format non valide (caractères acceptés : alphabétiques et - uniquement)."
              >
              </p-message>
            </div>
          </div>
        </div>
        <div class="p-field p-grid">
          <label for="middleName" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
            {{ 'DEUXIEME_NOM' | translate }}
          </label>
          <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
            <input
              type="text"
              class="form-control"
              pInputText
              id="middleName"
              [(ngModel)]="persona.middleName"
              name="middleName"
              value=""
            />
          </div>
        </div>
        <div class="p-field p-grid">
          <label for="publicName" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
             {{ 'NOM_PUBLIC' | translate }}
          </label>
          <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
            <input
              type="text"
              class="form-control"
              pInputText
              id="publicName"
              [(ngModel)]="persona.publicName"
              name="publicName"
            />
          </div>
        </div>
        <div class="p-field p-grid">
          <label for="nationality" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
           {{ 'NATIONALITE' | translate }}
          </label>
          <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
            <input
              type="text"
              class="form-control"
              pInputText
              id="nationality"
              [(ngModel)]="persona.nationality"
              name="nationality"
            />
          </div>
        </div>
        <div class="p-field p-grid">
          <label for="birthDate" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
            {{ 'DATE_DE_NAISSANCE' | translate }}
          </label>
          <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
            <p-calendar
              dateFormat="dd/mm/yy"
              [locale]="calendar_fr"
              id="birthDate"
              [(ngModel)]="persona.birthDate"
              name="birthDate"
              [monthNavigator]="true"
              [yearNavigator]="true"
              yearRange="1960:2050"
              inputId="navigators"
            ></p-calendar>
          </div>
        </div>
        <div
          class="p-field p-grid"
          [class.has-error]="email.invalid && email.touched"
        >
          <label
            for="email"
            class="p-col-12 p-mb-2 p-md-2 p-mb-md-0 control-label"
          >
             {{ 'EMAIL' | translate }} *
          </label>
          <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
            <input
              id="email"
              required
              email
              name="email"
              pInputText
              #email="ngModel"
              [(ngModel)]="persona.mail"
              type="text"
            />
            <p-message
              *ngIf="email.errors?.required && email.touched"
              severity="error"
              text="Champ obligatoire."
            ></p-message>
            <p-message
              *ngIf="email.errors?.email && email.touched"
              severity="error"
              text="Format non valide (exemple@domaine.com)."
            ></p-message>
          </div>
        </div>
        <div class="p-field p-grid">
          <label for="gender" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
             {{ 'GENRE' | translate }} *
          </label>
          <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
            <p-dropdown
              id="type"
              [options]="gender"
              placeholder="Genre"
              [(ngModel)]="persona.gender"
              name="gender"
              optionLabel="name"
              optionValue="value"
              required
            ></p-dropdown>
            <div
              *ngIf="
                myForm.controls['gender']?.invalid &&
                myForm.controls['gender'].touched
              "
            >
              <p-message severity="error" text="Champ obligatoire."></p-message>
            </div>
          </div>
        </div>
        <div class="p-field p-grid">
          <label for="personalNumber" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
             {{ 'NUM_PERSONEL' | translate }}
          </label>
          <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
            <input
              *ngIf="profile == 'staging'"
              type="text"
              pattern="[0-9]{5}"
              pInputText
              class="ng-dirty"
              required
              [(ngModel)]="persona.personalNumber"
              name="personalNumber"
            />
            <div *ngIf="myForm.controls['personalNumber']?.errors?.pattern">
              <p-message
                severity="error"
                text="Format non valide (cinq caractères acceptés : chiffres uniquement, ex: 00012)."
              >
              </p-message>
            </div>
            <input
              *ngIf="profile != 'staging'"
              type="text"
              pInputText
              class="ng-dirty"
              required
              [(ngModel)]="persona.personalNumber"
              name="personalNumber"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="p-col-6">
      <div class="p-fluid">
        <div class="p-field p-grid">
          <label for="phone" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
             {{ 'TELEPHONE' | translate }}
          </label>
          <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
            <input
              type="tel"
              name="phone"
              pInputText
              [(ngModel)]="persona.phone"
              (input)="validPhoneFormat($event)"
            />
            <div *ngIf="errorPhone">
              <p-message
                severity="error"
                text="Format non valide (caractères acceptés : numériques et +
                    uniquement)."
              ></p-message>
            </div>
          </div>
        </div>

        <div class="p-field p-grid">
          <label for="address" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
             {{ 'ADRESSE' | translate }}
          </label>
          <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
            <p-autoComplete
              [(ngModel)]="persona.address"
              class="form-control"
              id="address"
              name="address"
              [minLength]="1"
            >
            </p-autoComplete>
          </div>
        </div>
        <div class="p-field p-grid">
          <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ 'ENTREPRISE' | translate }} *  </label>
          <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
            <p-autoComplete
              [(ngModel)]="persona.company"
              [suggestions]="filteredCompanies"
              (completeMethod)="filterCompany($event)"
              field="name"
              [dropdown]="true"
              name="company"
              required="true"
            >
            </p-autoComplete>
            <!-- validation error message -->
            <div
              *ngIf="
                myForm.controls['company']?.invalid &&
                myForm.controls['company'].touched
              "
            >
              <p-message severity="error" text="Champ obligatoire."></p-message>
            </div>
          </div>
        </div>
        <div class="p-field p-grid">
          <label for="manager" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
             {{ 'MANAGER' | translate }}
          </label>
          <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
            <input
              type="text"
              class="form-control"
              pInputText
              id="manager"
              [(ngModel)]="persona.manager"
              name="manager"
              value=""
            />
          </div>
        </div>
        <div class="p-field p-grid">
          <label for="listPosts" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
            {{ 'PROFILS' | translate }} *
          </label>
          <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
            <p-multiSelect
              #select="ngModel"
              [options]="listPosts"
              defaultLabel="Séléctionner un poste"
              optionLabel="nameFR"
              [(ngModel)]="selectedPosts"
              [ngModelOptions]="{ standalone: true }"
              (onChange)="changePost($event)"
              required
              (onPanelHide)="panelClosed = true"
              display="chip"
            ></p-multiSelect>
            <!-- validation error message -->
            <div *ngIf="select.invalid && (select.dirty || panelClosed)">
              <p-message severity="error" text="Champ obligatoire."></p-message>
            </div>
          </div>
        </div>
        <div class="p-field p-grid">
          <label for="subscription" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
              {{ 'ABONNEMENT' | translate }}
          </label>
          <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
            <input
              type="text"
              class="form-control"
              pInputText
              id="subscription"
              [(ngModel)]="persona.subscription"
              name="subscription"
              value=""
            />
          </div>
        </div>
        <div class="p-field p-grid">
          <label for="globalRating" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
            {{ 'NOTE_GLOBALE' | translate }}
          </label>

          <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
            <p-rating
              [(ngModel)]="persona.globalRating"
              [cancel]="false"
              name="globalRating"
            ></p-rating>
          </div>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="photo" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
            {{ 'PHOTO' | translate }}
        </label>
        <div class="profilepic p-ml-4">
          <img
            *ngIf="persona?.photo"
            class="profilepic__image"
            src="{{ persona?.photo }}"
            width="150"
            height="150"
            alt="Profibild"
          />
          <img
            *ngIf="!persona?.photo"
            class="profilepic__image"
            width="150"
            height="150"
            src="assets/img/default-avatar.png"
            alt="Profibild"
          />
          <div class="profilepic__content">
            <!-- <span class="profilepic__text">Modifier la photo</span><br> -->
            <p-fileUpload
              mode="basic"
              (onSelect)="onSelection($event, ftl)"
              #ftl
              multiple="false"
              fileLimit="1"
              accept="image/*"
              invalidFileSizeMessageSummary="Impossible de charger cette photo (taille max autorisée 1MB)."
              invalidFileSizeMessageDetail="L'image ne sera pas enregistrée."
              [showUploadButton]="false"
              cancelLabel="{{ 'formulaire_ANNULER' | translate }}"
              class="p-pb-1"
              title="Modifier la photo"
            >
            </p-fileUpload>
            <div
              *ngIf="this.persona.photo"
              class="suppr"
              (click)="deletePhoto()"
              title="Supprimer la photo"
            >
              <i class="pi pi-times p-mt-1"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p-confirmDialog
      baseZIndex="5"
      styleClass="main-page"
      [style]="{ width: '60%' }"
    ></p-confirmDialog>
    <div class="p-col-8"></div>
    <p-sidebar
      class="p-text-right"
      [style]="{ height: '13.5vh' }"
      [visible]="true"
      [blockScroll]="true"
      [dismissible]="false"
      [showCloseIcon]="false"
      [modal]="false"
      position="bottom"
    >
      <button
        class="p-mr-2 p-mb-2 p-button-outlined"
        pButton
        type="button"
        label="{{ 'formulaire_ANNULER' | translate }}"
        (click)="confirm()"
      ></button>

      <button
        class="p-mr-2 p-mb-2"
        pButton
        type="button"
        label="Suivant"
        routerLink="/phoenix/persona"
        [disabled]="!myForm.form.valid || select.invalid"
        (click)="nextPage()"
      ></button>

      <button
        class="p-mr-2 p-mb-2"
        pButton
        type="button"
        label="{{ 'formulaire_VALIDER' | translate }}"
        [disabled]="!myForm.form.valid  || select.invalid"
        (click)="save()"
      ></button>
    </p-sidebar>
    <p-dialog [style]="{width: '32vw'}"
      header="Intervenant"
      [draggable]="false"
      [closable]="true"
      [(visible)]="displayTech"
    >
      <div class="p-grid p-field">
        <div class="p-col-4">
          <h3 class="p-d-inline">  {{ 'Adresse_DEPART' | translate }} : </h3>
        </div>
        <div class="p-col-2">
        </div>
        <div class="p-col-4">
          <input
            type="text"
            name="address"
            [(ngModel)]="technicianAddress"
            class="form-control"
            pInputText
            id="address"
            name="address"
          />
        </div>
      </div>

      <!-- spacing pour button -->
      <div class="p-text-right">
        <button
          pButton
          type="button"
          (click)="displayTech = false"
          label="{{ 'formulaire_ANNULER' | translate }}"
          class="p-button-outlined p-mr-2"
        ></button>
        <p-button
          (onClick)="createTechnicien()"
          styleClass="p-mr-2"
          label="{{ 'formulaire_VALIDER' | translate }}"
        ></p-button>
      </div>
    </p-dialog>
  </div>
</form>

<p-dialog [(visible)]="display" [draggable]="false" styleClass="main-page">
  <image-cropper
    [imageBase64]="imageBase64"
    [maintainAspectRatio]="true"
    [containWithinAspectRatio]="containWithinAspectRatio"
    [aspectRatio]="1 / 1"
    [resizeToWidth]="150"
    [cropperMinWidth]="120"
    [onlyScaleDown]="true"
    [roundCropper]="true"
    [canvasRotation]="canvasRotation"
    [transform]="transform"
    [alignImage]="'center'"
    [style.display]="showCropper ? null : 'none'"
    format="png"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady($event)"
    (loadImageFailed)="loadImageFailed()"
  ></image-cropper>
  <ng-template pTemplate="footer">
    <p-button (click)="onUpload(ftl)">{{ 'formulaire_VALIDER' | translate }}</p-button>
  </ng-template>
</p-dialog>
