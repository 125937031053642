import { Component, OnInit, Input } from '@angular/core';
import {  MenuItem, MessageService } from 'primeng/api';
import { LoginService } from '@core/service/login.service';
import { TokenStorageService } from '@core/service/token-storage.service';
import { Router } from '@angular/router';
import { PersonaService } from '@data/service/persona.service';
import { UserService } from '@shared/service/user.service';
import { Persona } from '@data/schema/Persona';
import { CommonModule } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-kober-menu',
  templateUrl: './kober-menu.component.html',
  styleUrls: ['./kober-menu.component.css'],
})

export class KoberMenuComponent implements OnInit {
  @Input() module: string;

  currentId: string;

  reducedMenu: boolean;

  urlProfile: string;
  mainMenu: MenuItem[];
  bottomMenu: MenuItem[];
  idKeycloak: string;
  persona: Persona;

  menuSettingTag: boolean = false;

  role: string;
  origin : string;

  permission:  String;


  constructor(
    private messageService: MessageService,
    private loginService: LoginService,
    private tokenStorageService: TokenStorageService,
    private router: Router,
    private personaService: PersonaService,
    private userService: UserService,
    private translate : TranslateService,
    private translateService: TranslateService
  ) {}
  ngOnInit(): void {
    this.permission = (sessionStorage.getItem("auth-scope"))
    this.currentId = '';
    this.reducedMenu = this.initializeCollapseMenu();
    this.persona = this.userService.user;
    //console.log(this.userService.user);
    if (!this.persona) {
      let token: any;
      token = this.tokenStorageService.decodeToken();
      this.idKeycloak = token.sub;
      console.log(this.idKeycloak);
      this.personaService.getPersonaByKeycloak(this.idKeycloak).subscribe(
        (res) => {
          if (res.status === 200) {
            this.persona = res.body;
            this.userService.user = this.persona;
          }
        },
        (error) => {
          if (error) {
            console.log('didnt work');
          }
        },
        () => {}
      );
    }

    this.role = this.tokenStorageService.decodeToken().realm_access.roles[0];

    if (this.module === 'kober-skill') {
      this.origin = "skill";
      this.mainMenu = [
        {
          label: this.translate.instant('menu_PERSONAS'),
          id: 'personaId',
          icon: 'person-icon',
          routerLink: ['/phoenix/persona'],
          command: (event) => {
            localStorage.setItem("currentId", event.item.id);
          },
          expanded: this.compareWithLastClickedItem('personaId'),
          items: [
            {
              label: this.translate.instant('menu_PERSONAS'),
              routerLink: ['/phoenix/persona'],
              styleClass:'skillSubMenuStyle',
              routerLinkActiveOptions: { exact: true },
            },
            {
              label: this.translate.instant('menu_INTERVENANTS') ,
              routerLink: ['/phoenix/technician'],
              styleClass:'skillSubMenuStyle',
              routerLinkActiveOptions: { exact: true },
            },
          ],
        },
        {
          label: this.translate.instant('menu_COMPETENCES'),
          icon: 'skill-icon',
          routerLink: ['/phoenix/skill'],
          id: 'skillId',
          command: (event) => {
            localStorage.setItem("currentId", event.item.id);
          },
          expanded: this.compareWithLastClickedItem('skillId'),
          items: [
            {
              label: this.translate.instant('menu_COMPETENCES'),
              routerLink: ['/phoenix/skill'],
              routerLinkActiveOptions: { exact: true },
            },
            {
              label:  this.translate.instant('menu_PERSONAS_COMPETENCES'),
              styleClass:'skillSubMenuStyle',
              routerLink: ['/phoenix/skills-of-personas'],
              routerLinkActiveOptions: { exact: true },
              visible: this.hasAuthorization([
                'administrateur',
                'admin_metier',
                'admin_responsable_DPO',
                'manager_operationnel',
                'manager_CA',
              ]),
            },
            {
              label: this.translate.instant('menu_COMPETENCES_PERSONAS'),
              routerLink: ['/phoenix/personas-of-skills'],
              styleClass:'skillSubMenuStyle',
              routerLinkActiveOptions: { exact: true },
              visible: this.hasAuthorization([
                'administrateur',
                'admin_metier',
                'admin_responsable_DPO',
                'manager_operationnel',
                'manager_CA',
              ]),
            },
            {
              label: this.translate.instant('menu_ASSIGNER_COMPETENCES'),
              styleClass:'skillSubMenuStyle',
              routerLink: ['/phoenix/assign-skills-to-personas'],
              routerLinkActiveOptions: { exact: true },
              visible: this.hasAuthorization([
                'administrateur',
                'admin_metier',
                'admin_responsable_DPO',
                'manager_operationnel',
                'manager_CA',
              ]),
            },
            {
              label: this.translate.instant('menu_COMPETENCES_EXPIREES'),
              styleClass:'skillSubMenuStyle',
              routerLink: ['/phoenix/expiring-skills'],
              routerLinkActiveOptions: { exact: true },
              visible: this.hasAuthorization([
                'administrateur',
                'admin_metier',
                'admin_responsable_DPO',
                'manager_operationnel',
                'manager_CA',
              ]),
            },
          ],
        },
        {
          label:  this.translate.instant('menu_ENTREPRISES'),
          icon: 'company-icon',
          routerLink: ['/phoenix/company'],
          styleClass:'skillMenuStyle',
          routerLinkActiveOptions: { exact: true },
        },
      ];

      this.bottomMenu = this.initializeBottomMenu();

    } else if (this.module === 'kober-material') {
      this.origin = "material";
      this.mainMenu = [
        {
          label: this.translateService.instant('MATERIELS'),
          icon: 'material-icon',
          id: 'materialsId',
          routerLink: ['/phoenix/logistic/material'],
          visible: this.hasAuthorization([
            'administrateur',
            'admin_metier',
            'admin_responsable_DPO',
            'magasinier',
            'manager_operationnel',
            'manager_operationnel_prestataire',
            'charge_de_pilotage',
            'ca_prestataire',
            'manager_CA'
          ]),
          command: (event) => {
            localStorage.setItem("currentId", event.item.id);
            //console.log(this.currentId);

          },
          expanded: this.compareWithLastClickedItem('materialsId'),
          items: [
            {
              label: this.translateService.instant('MATERIELS'),
              styleClass: 'subMenuStyle',
              routerLink: ['/phoenix/logistic/material'],
              routerLinkActiveOptions: { exact: true },
            },
            {
              label:  this.translateService.instant('UNITE'),
              routerLink: ['/phoenix/logistic/unit'],
              styleClass: 'subMenuStyle',
              routerLinkActiveOptions: { exact: true },
              visible: this.hasAuthorization([
                'administrateur',
                'admin_metier',
                'admin_responsable_DPO',
                'magasinier',
                'manager_operationnel',
                'manager_operationnel_prestataire',
                'charge_de_pilotage',
                'ca_prestataire',
                'manager_CA'
              ]),
            },
            {
              label: this.translateService.instant('categories'),
              styleClass: 'subMenuStyle',
              routerLink: ['/phoenix/logistic/category'],
              routerLinkActiveOptions: { exact: true },
              visible: this.hasAuthorization([
                'administrateur',
                'admin_metier',
                'admin_responsable_DPO',
                'magasinier',
                'manager_operationnel',
                'manager_operationnel_prestataire',
                'charge_de_pilotage',
                'ca_prestataire',
                'manager_CA'
              ]),
            },
          ],
        },
        {
          label: this.translateService.instant('MAGASINS'),
          styleClass:'menuStyle',
          routerLink: ['/phoenix/logistic/store'],
          routerLinkActiveOptions: { exact: true },
          id:'storeId',
          icon: 'store-icon',
          visible: this.hasAuthorization([
            'administrateur',
            'admin_metier',
            'admin_responsable_DPO',
            'magasinier',
            'manager_operationnel',
            'manager_operationnel_prestataire',
            'charge_de_pilotage',
            'ca_prestataire',
            'manager_CA'
          ]),
        },
        {
          label:  this.translateService.instant('MAGASINS'),
          icon: 'stock-icon',
          styleClass:'menuStyle',
          routerLink: ['/phoenix/logistic/contributor'],
          id:'stockId',
          visible: this.hasAuthorization([
            'ca_prestataire',
            'charge_de_pilotage'
          ]),
          command: (event) => {
            this.currentId = "stockId";
            localStorage.setItem("currentId", event.item.id);
            //console.log(this.currentId);
          },
          expanded: this.compareWithLastClickedItem('stockId'),
          items: [
            {
              label: this.translateService.instant('stocks_intervenants'),
              id: 'stockKeeperId',
              styleClass:'subMenuStyle',
              routerLink: ['/phoenix/logistic/contributor'],
              routerLinkActiveOptions: { exact: true },
              visible: this.hasAuthorization([
                'charge_de_pilotage',
                'ca_prestataire'
              ]),
            },
          ],
        },
        {
          label:  this.translateService.instant('STOCKS'),
          icon: 'stock-icon',
          styleClass:'menuStyle',
          routerLink: ['/phoenix/logistic/global-stock'],
          id:'stockId',
          visible: this.hasAuthorization([
            'administrateur',
            'admin_metier',
            'admin_responsable_DPO',
            'manager_operationnel',
            'manager_CA',
            'manager_operationnel_prestataire',
            'magasinier',
          ]),
          command: (event) => {
            this.currentId = "stockId";
            localStorage.setItem("currentId", event.item.id);
            //console.log(this.currentId);
          },
          expanded: this.compareWithLastClickedItem('stockId'),
          items: [
            {
              label: this.translateService.instant('stocks_global'),
              id: 'stockGlobalId',
              styleClass:'subMenuStyle',
              routerLink: ['/phoenix/logistic/global-stock'],
              routerLinkActiveOptions: { exact: true },
              visible: this.hasAuthorization([
                'administrateur',
                'admin_metier',
                'admin_responsable_DPO',
                'manager_operationnel',
                'manager_operationnel_prestataire',
                'magasinier',
                'manager_CA',
              ]),
            },
            {
              label: this.translateService.instant('stocks_magasins'),
              id: 'stockOfStoreId',
              styleClass:'subMenuStyle',
              routerLink: ['/phoenix/logistic/store'],
              routerLinkActiveOptions: { exact: true },
              visible: this.hasAuthorization([
                'administrateur',
                'admin_metier',
                'admin_responsable_DPO',
                'manager_operationnel',
                'manager_operationnel_prestataire',
                'manager_CA',
                'magasinier',
              ]),
              state: {source:'stock'}
            },
            {
              label: this.translateService.instant('stocks_intervenants'),
              id: 'stockKeeperId',
              styleClass:'subMenuStyle',
              routerLink: ['/phoenix/logistic/contributor'],
              routerLinkActiveOptions: { exact: true },
              visible: this.hasAuthorization([
                'administrateur',
                'admin_metier',
                'admin_responsable_DPO',
                'manager_operationnel',
                'manager_operationnel_prestataire',
                'manager_CA',
                'magasinier'
              ]),
            },
            {
              label: this.translateService.instant('mouvements'),
              id: 'mouvementId',
              styleClass:'subMenuStyle',
              routerLink: ['/phoenix/logistic/movements'],
              routerLinkActiveOptions: { exact: true },
              visible: this.hasAuthorization([
                'administrateur',
                'admin_metier',
                'admin_responsable_DPO',
                'magasinier',
              ]),
            },
          ],
        },
        {
          label: this.translateService.instant('COMMANDES'),
          routerLink: ['/phoenix/logistic/order'],
          id :'orderId',
          icon: 'order-icon',
          styleClass:'menuStyle',
          command: (event) => {
            localStorage.setItem("currentId", event.item.id);
          },
        },
        {
          label: this.translateService.instant('recherche'),
          routerLink: ['/phoenix/logistic/search-item'],
          icon: 'search-icon',
          styleClass:'menuStyle',

          id: 'searchId',
          command: (event) => {
            localStorage.setItem("currentId", event.item.id);
           // console.log(this.currentId);
          },
        },
      ];
      this.bottomMenu = this.initializeBottomMenu();

    } else if (this.module === 'kober-services') {
      this.origin="services"

      this.mainMenu = [
        {
          label: this.translateService.instant("ORDRES_DE_TRAVAUX"),
          icon: 'orders-icon',
          styleClass: 'serviceMenuStyle',
          visible: this.hasAuthorization([
            'administrateur',
            'admin_metier',
            'admin_responsable_DPO',
            'manager_operationnel',
            'manager_CA',
            'manager_operationnel_prestataire',
            'ca_prestataire',
            'charge_de_pilotage',
            'magasinier'
          ]),
          routerLink: ['/phoenix/work-order'],
          routerLinkActiveOptions: { exact: true },
        },
        {
          label: this.translateService.instant("menu_PROJETS"),
          icon: 'project-icon',
          styleClass: 'serviceMenuStyle',
          visible: this.hasAuthorization([
            'administrateur',
            'admin_metier',
            'admin_responsable_DPO',
            'manager_operationnel',
            'manager_CA'
          ]),
          routerLink: ['/phoenix/project'],
          routerLinkActiveOptions: { exact: true },
        },
        {
          label: this.translateService.instant("menu_CONTRATS"),
          styleClass: 'serviceMenuStyle',
          visible: this.hasAuthorization([
            'administrateur',
            'admin_metier',
            'admin_responsable_DPO',
            'manager_operationnel',
            'manager_operationnel_prestataire'
          ]),
          icon: 'contrat-icon',
          routerLink: ['/phoenix/contract'],
          routerLinkActiveOptions: { exact: true },
        },
        {
          label: this.translateService.instant("menu_PRESTATIONS"),
          visible: this.hasAuthorization([
            'administrateur',
            'admin_metier',
            'admin_responsable_DPO',
            'manager_operationnel',
            'manager_operationnel_prestataire',
            'manager_CA',
            'charge_de_pilotage',
            'ca_prestataire'
          ]),
          icon: 'prestation-icon',
          routerLink: ['/phoenix/work'],
          routerLinkActiveOptions: { exact: true },
          styleClass: 'serviceMenuStyle',
        },

      ];

      this.bottomMenu = this.initializeBottomMenu();
    }

    // menu tag
    else if (this.module === 'kober-setting') {
      this.origin="settings"
      this.mainMenu = [
        {
          label: 'Tags',
          icon: 'tags-icon',
          visible: this.hasAuthorization([
            'administrateur',
            'admin_metier',
            'admin_responsable_DPO',
            'manager_operationnel',
            'manager_operationnel_prestataire',
          ]),
          routerLink: ['/phoenix/tag'],
          routerLinkActiveOptions: { exact: true },
        },
        {
          label: 'Devise',
          icon: 'currency-icon',
          visible: this.hasAuthorization([
            'administrateur',
            'admin_metier',
            'admin_responsable_DPO',
            'manager_operationnel',
            'manager_operationnel_prestataire',
          ]),
          routerLink: ['/phoenix/show-currency'],
          routerLinkActiveOptions: { exact: true },
        },
        {
          label: 'Entreprises',
          icon: 'company-icon',
          visible: this.hasAuthorization([
            'administrateur',
            'admin_metier',
            'admin_responsable_DPO',
            'manager_operationnel'
          ]),
          routerLinkActiveOptions: { exact: true },
          items: [
            {
              label: 'Entreprises',
              styleClass: 'subMenuStyle',
              routerLink: ['/phoenix/settings/company'],
              routerLinkActiveOptions: { exact: true },
            },
            {
              label:  this.translateService.instant('relations_ENTREPRISES'),
              routerLink: ['/phoenix/settings/company-relationship'],
              styleClass: 'subMenuStyle',
              routerLinkActiveOptions: { exact: true },
              visible: this.hasAuthorization([
                'administrateur',
                'admin_metier',
                'admin_responsable_DPO',
                'magasinier',
              ]),
            }
          ],
        },
        {
          label: 'Organisations hiérarchiques',
          icon: 'organisation-icon',
          visible: this.hasAuthorization([
            'administrateur',
            'admin_metier',
            'admin_responsable_DPO',
            'manager_operationnel'
          ]),
          routerLinkActiveOptions: { exact: true },
          items: [
            // {
            //   label: 'Entités',
            //   styleClass: 'subMenuStyle',
            //   routerLink: ['/phoenix/material'],
            //   routerLinkActiveOptions: { exact: true },
            // },
            // {
            //   label: 'Hiérarchie',
            //   routerLink: ['/phoenix/unit'],
            //   styleClass: 'subMenuStyle',
            //   routerLinkActiveOptions: { exact: true },
            //   visible: this.hasAuthorization([
            //     'administrateur',
            //     'admin_metier',
            //     'admin_responsable_DPO',
            //     'magasinier',
            //   ]),
            // },
            // {
            //   label: 'Templates',
            //   routerLink: ['/phoenix/unit'],
            //   styleClass: 'subMenuStyle',
            //   routerLinkActiveOptions: { exact: true },
            //   visible: this.hasAuthorization([
            //     'administrateur',
            //     'admin_metier',
            //     'admin_responsable_DPO',
            //     'magasinier',
            //   ]),
            // },
            {
              label: 'Mes organisations',
              routerLink: ['/phoenix/settings/my-organizations'],
              styleClass: 'subMenuStyle',
              routerLinkActiveOptions: { exact: true },
              visible: this.hasAuthorization([
                'administrateur',
                'admin_metier',
                'admin_responsable_DPO',
                'magasinier',
              ]),
            }
          ],
        }
      ];

      this.bottomMenu = this.initiliszeBottomMenuTag();
    }
  }

  compareWithLastClickedItem(id: string){
    //console.log('compareWithLastClickedItem : ' ,id);
    if (localStorage.getItem('currentId') !== null) {
    //console.log("currentId is stored : ", localStorage.getItem('currentId'));
      if (localStorage.getItem('currentId') === id) {
      // console.log('true : ', id);
      return true;
      }
    }
    return false;

  }

  hasAuthorization(authorized: string[]) {
    if (authorized.includes(this.role)) {
      return true;
    } else {
      return false;
    }
  }

  hasPermission(authorized: string){
    if (this.permission.split(" ").includes(authorized)
      ){
        return true;
    }

    else{
      return false;
    }

  }


  initializeCollapseMenu() {
    if (localStorage.getItem('COLAPSE_MENU') !== null) {
      if (localStorage.getItem('COLAPSE_MENU') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  toggleCollapseMenu() {
    console.log("reduceMenu : "+this.reducedMenu);
    this.reducedMenu = !this.reducedMenu;
    console.log(this.reducedMenu);
    if (this.reducedMenu) {
      localStorage.setItem('COLAPSE_MENU', 'true');
    } else {
      localStorage.setItem('COLAPSE_MENU', 'false');
    }
    console.log(this.bottomMenu);
    this.bottomMenu = this.initializeBottomMenu();
  }
  menuSettingTrue(){
    console.log("menuSetting");
    this.menuSettingTag = true;
    this.router.navigate(['/phoenix/settings/company']);
  }

  menuSettingFalse(){
    this.menuSettingTag = false;
    this.router.navigate(['/phoenix/home']);
  }

  initializeBottomMenu(){
      return [
        {
          label: this.translateService.instant('deconnexion'),
          icon: 'logout-icon',
          command: (event) => {
            localStorage.clear()
            sessionStorage.clear()
            this.loginService.logout();
            this.messageService.add({
              severity: 'warn',
              summary: 'A bientôt',
              detail: '',
            });
          },
          routerLink: ['/'],
        },
        {
          label: this.translateService.instant('masquer_le_menu'),
          icon: 'sidebar-close-icon',
          visible: !this.reducedMenu,
          styleClass: 'collapse-menu',
          command: (event) => {
            //this.loginService.logout();
            this.toggleCollapseMenu();
          },
        },
        {
          label: 'Ouvrir le menu',
          icon: 'sidebar-open-icon',
          visible: this.reducedMenu,
          styleClass: 'collapse-menu',
          command: (event) => {
            //this.loginService.logout();
            this.toggleCollapseMenu();
          },
        },
        {
          label: this.translateService.instant('parametrage'),
          icon: 'settings-icon',
          visible: this.hasPermission("kober:parametrage"),
          styleClass: 'collapse-menu',
          command: (event) => {
            //this.loginService.logout();
            this.menuSettingTrue();
            this.reducedMenu = true;
            this.toggleCollapseMenu();
          },
        },
      ];
    }

    initiliszeBottomMenuTag(){
        return [
          {
            label: 'Déconnexion',
            icon: 'logout-icon',
            command: (event) => {
              localStorage.clear()
              sessionStorage.clear()
              this.loginService.logout();
              this.messageService.add({
                severity: 'warn',
                summary: 'A bientôt',
                detail: '',
              });
            },
            routerLink: ['/'],
          },
          //foot menu tag
          /**{
            label: 'Historique de navigation',
            command: (event) => {
              // add link historique
            },
          },
          {
            label: 'Base de connaissance',
            styleClass: 'collapse-menu',
            command: (event) => {
              // add link Base of knowledge
            },
          },**/
          {
            label: 'Quitter le paramétrage',
            icon: 'quit-setting-icon',
            styleClass: 'collapse-menu',
            command: (event) => {
              this.menuSettingFalse();
            },
          },
        ];
    }
}
