export class WorkOrderAnnouncer {
  constructor(expectedVersionEvolution?: number, updated?: boolean) {
    this._updated = true;
    if (expectedVersionEvolution) {
      this._expectedVersionEvolution = expectedVersionEvolution;
    }else{
      this._expectedVersionEvolution = 1;
    }
    if (updated !== undefined) {
      this._updated = updated;
    }
  }
  private _updated: boolean;
  private _expectedVersionEvolution: number;

  get updated() {
    return this._updated;
  }

  get expectedVersionEvolution() {
    return this._expectedVersionEvolution;
  }
} 