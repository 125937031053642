import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { SidenavService } from '@shared/service/sidenav.service';
import { Router } from '@angular/router';
import { LocalStorageService } from '@shared/service/local-storage.service';
import { LoginService } from '@core/service/login.service';
import { CustomIconsService } from '@shared/service/custom-icons.service';
import { Persona } from '@data/schema/Persona';
import { MatDialog } from '@angular/material/dialog';
import { HelpPersonaOrganizationsDialogComponent } from '../help-persona-organizations-dialog/help-persona-organizations-dialog.component';
import { HelpPoolOneComponent } from '../help-pool-one/help-pool-one.component';
import Package from "../../../../../../../mobile/kober-mobile/package.json";
import {EnvService} from "@core/service/env.service";
import {TokenStorageService} from "@core/service/token-storage.service";

@Component({
  selector: 'app-setting-menu-left',
  templateUrl: './setting-menu-left.component.html',
  styleUrls: ['./setting-menu-left.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [

    // animate sidenave
    trigger('onSideNavChange', [
      state('close',
        style({
          width: '60px'
        })
      ),
      state('open',
        style({
          width: '250px'
        })
      ),
      transition('close => open', animate('250ms ease-in')),
      transition('open => close', animate('250ms ease-in')),

    ]),
    trigger('animateText', [
      state('hide',
        style({
          'display': 'none',
          opacity: 0,
        })
      ),
      state('show',
        style({
          'display': 'block',
          opacity: 1,
        })
      ),
      transition('close => open', animate('350ms ease-in')),
      transition('open => close', animate('200ms ease-out')),
    ])

  ]
})
export class SettingMenuLeftComponent implements OnInit {

  /**
   * user persona logged
   */
  persona: Persona;

  /**
   * Determined if nav should be collapsed or not
   */
  sideNavState: boolean;

  /**
   * Determined if text should be displayed or not
   */
  linkText: boolean;

  /**
   * Determine which panel to open in menu
   */
  step: number = 0;
  role:  String;
  public appVersion: string = Package.version;
  /**
   * Inject service
   * @param _sidenavService
   * @param router
   * @param localStorageService
   */
  constructor(
    private _sidenavService: SidenavService,
    private router: Router,
    public dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private loginService: LoginService,
    private customIconService: CustomIconsService,
    private environment: EnvService,
    private tokenStorageService: TokenStorageService,
  ) {
    this.customIconService.customIcon();
  }

  /**
   * Check local storage to get user preference about nav
   * If get preference : sidenavstate get preference
   * If not : true by default
   *
   * check current url and determine which panel to open by default
   */
  ngOnInit() {
    this.role = (sessionStorage.getItem("auth-role"))
    if (this.localStorageService.getLocalStorageItem("SIDENAVSTATE") === null) {
      this.sideNavState = true;
    }
    else {
      this.sideNavState = JSON.parse(this.localStorageService.getLocalStorageItem("SIDENAVSTATE"))
    }
    this.linkText = this.sideNavState
    this._sidenavService.sideNavState$.next(this.sideNavState)

    if (sessionStorage.getItem("persona") !== null) {
      this.persona = JSON.parse(sessionStorage.getItem("persona"))
    }

    if (this.router.url.startsWith('/phoenix/settings/company')) {
      this.setStep(0)
    }
    else if (this.router.url.startsWith('/phoenix/settings/my-organizations') || this.router.url.startsWith('/phoenix/settings/new-organization')) {
      this.setStep(1)
    }

  }


  /**
   * Change sideNavState and store in local storage
   */
  onSinenavToggle() {
    this.sideNavState = !this.sideNavState
    this.localStorageService.setLocalStorageItem("SIDENAVSTATE", this.sideNavState)

    setTimeout(() => {
      this.linkText = this.sideNavState;
    }, 200)
    this._sidenavService.sideNavState$.next(this.sideNavState)
  }

  /**
   * logout user and redirect to login page
   */
  logout() {
    this.loginService.logout();
    this.router.navigate(['/'])
  }

  setStep(index: number) {
    this.step = index;
  }
  openDialog() {
   if(!localStorage.getItem('on-boarding-organization')){
    const dialogRef = this.dialog.open(HelpPersonaOrganizationsDialogComponent, {
      width: '25%',
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe(result => {
  /**
   *  Determine if menu should open organization on boarding popup.
   */
       localStorage.setItem('on-boarding-organization', JSON.stringify(true));
    });
   }
  }
  openHelpDialog(){
    if(!localStorage.getItem('on-boarding-pool')){
      const dialogRef = this.dialog.open(HelpPoolOneComponent, {
        width: '25%',
        panelClass: 'custom-dialog-container'
      });
      dialogRef.afterClosed().subscribe(result => {
    /**
     *  Determine if menu should open pool on boarding popup.
     */
         localStorage.setItem('on-boarding-pool', JSON.stringify(true));
      });
     }
  }

  redirectSb() {
    const tab = window.open(this.environment.env.baseUrlSbFront + '/login-external')
    tab.onload = () => {
      tab.postMessage({
        token: {
          accessToken: this.tokenStorageService.getToken(),
          refreshToken: this.tokenStorageService.getRefreshToken()
        }
      },"*");
    }

    for (var i = 0; i < 50; i++) {
      setTimeout(() =>  {
        tab.postMessage({
          token: {
            accessToken: this.tokenStorageService.getToken(),
            refreshToken: this.tokenStorageService.getRefreshToken()
          }
        },"*");
        }, i * 10)
    }
  }
}
