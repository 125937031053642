<h4>Prestations disponibles du projet</h4>
<div *ngIf="workOrder && workOrder.project" class="card">
  <div *ngIf="!checkToShow()">
    <br> <span *ngIf="!workOrder.project.id">Veuillez séléctionner un projet </span>
    <br> <span *ngIf="workOrder.project.id">Toutes les prestations du projet sont planifiées dans des OT<br></span>
  </div>


  <p-table
    [(selection)]="selectedProjectWorks"
    styleClass="p-datatable-sm p-datatable-striped"
    #dt1
    class="table"
    [paginator]="true"
    *ngIf="checkToShow()"
    (sortFunction)="naturalSortWorkOrder($event)"
    [customSort]="true"
    sortField="work.name" [sortOrder]='1'
    [rows]="10"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{{'PAGINATION'|translate}}"
    [rowsPerPageOptions]="[10, 25, 50]"
    [value]="workOrder.project.projectWorks"
    [globalFilterFields]="[
      'work.name',
      'work.description',
      'comment',
      'quantityFree',
      'quantityTotal'
    ]"
  >

    <ng-template pTemplate="caption">
      <div class="p-d-flex p-jc-between p-m-3">

        <div>
          <p-button *ngIf="selectedProjectWorks.length!=0" p-button (click)="validProjectWorks()" label="Ajouter à l'OT"></p-button>
        </div>

        <span class="p-input-icon-left p-ml-auto">
          <i class="pi pi-search" [style]="{'margin-left':'12%','margin-right':'10px'}"></i>
          <input
            [style]="{'margin-left':'12%','height': '45px'}"
            pInputText
            type="text"
            (input)="dt1.filterGlobal($event.target.value, 'contains')"
            placeholder="Rechercher"
          />
      </span>



      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 2rem; background-color: rgba(229, 229, 229, 0.35)!important">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th style="width: 4rem; background-color: rgba(229, 229, 229, 0.35)!important">
        </th>
        <th class="table-20 head" pSortableColumn="work.name">
          Prestations<p-sortIcon field="work.name"></p-sortIcon>
        </th>
        <th class="table-25 head" pSortableColumn="work.description">
          Description<p-sortIcon field="work.description"></p-sortIcon>
        </th>
        <th class="table-20 head" pSortableColumn="comment">
          Commentaire<p-sortIcon field="comment"></p-sortIcon>
        </th>
        <th class="table-15 head p-text-right" pSortableColumn="quantityTotal">
          Quantité totale<p-sortIcon field="quantityTotal"></p-sortIcon>
        </th>
        <th class="table-15 head p-text-right" pSortableColumn="quantityFree">
          Quantité restante<p-sortIcon field="quantityFree"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-projectWork>
      <!-- <tr *ngIf="projectWork.quantityFree > 0"> -->
        <tr>
        <td style="width: 2rem">
          <p-tableCheckbox [value]="projectWork" *ngIf="projectWork.quantityFree > 0"></p-tableCheckbox>

        </td>
        <td style="width: 4rem">
          <button
          *ngIf="projectWork.quantityFree > 0"
          (click)="addProjectWork(projectWork)"
          pButton
          pRipple
          type="button"
          icon="pi pi-plus"
          class="p-button-rounded p-button-text"
         ></button>
        </td>
        <td>{{ projectWork.work.name }}</td>
        <td>{{ projectWork.work.description }}</td>
        <td>{{ projectWork.comment }}</td>
        <td class="p-text-right">{{ projectWork.quantityTotal }}</td>
        <td class="p-text-right">{{ projectWork.quantityFree }}</td>
        <!-- <td class="p-text-right">{{ calculateQuantityFree(projectWork) }}</td> -->
      </tr>
    </ng-template>
  </p-table>
</div>
<p-dialog
  [draggable]="false"
  [modal]="true"
  styleClass="main-page"
  [style]="{ width: '50%' }"
  header="{{ addedProjectWork.projectWork?.work?.name }}"
  [(visible)]="displayModal"
  (onShow)="setFocus()"
>
  <div class="dialog-content p-grid p-jc-evenly p-mb-5">
    <div class="p-col-6">
      <div class="p-mb-2 head-title">Description</div>
      <div *ngIf="addedProjectWork.projectWork?.work?.description">
        {{ addedProjectWork.projectWork?.work?.description }}
      </div>
      <div *ngIf="!addedProjectWork.projectWork?.work?.description">
        Pas de description...
      </div>
    </div>
    <div class="p-col-6">
      <div class="p-mb-2 head-title">Commentaire</div>
      <div *ngIf="addedProjectWork.projectWork?.comment">
        {{ addedProjectWork.projectWork?.comment }}
      </div>
      <div *ngIf="!addedProjectWork.projectWork?.comment">
        Pas de commentaire...
      </div>
    </div>
    <div class="p-col-12 p-lg-2">
      <div class="p-text-right head-title p-mb-3">Quantité totale du projet</div>
      <!-- <div class="p-text-right">{{ calculateQuantityFree(addedProjectWork?.projectWork)  }} / {{ addedProjectWork.projectWork?.quantityTotal }}</div> -->
      <div class="p-text-right">
        {{ addedProjectWork.projectWork?.quantityTotal }}
      </div>
    </div>
    <div class="p-col-12 p-lg-2 p-offset-1">
      <div class="p-text-right head-title p-mb-3">Quantité restante du projet</div>
      <!-- <div class="p-text-right">{{ calculateQuantityFree(addedProjectWork?.projectWork)  }} / {{ addedProjectWork.projectWork?.quantityTotal }}</div> -->
      <div class="p-text-right">
        {{ addedProjectWork.projectWork?.quantityFree }}
      </div>
    </div>
    <div class="p-col-12 p-lg-4">
      <div class="head-title p-text-center">Quantité pour l'OT *</div>
      <div class="p-text-center">
        <p-inputNumber
          [(ngModel)]="addedProjectWork.quantityTotal"
          mode="decimal"
          [showButtons]="true"
          buttonLayout="horizontal"
          spinnerMode="horizontal"
          decrementButtonClass="p-button-primary"
          incrementButtonClass="p-button-primary"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
          size="4"
          #input
        >
        </p-inputNumber>
        <p-message
          *ngIf="
            addedProjectWork.quantityTotal >
            addedProjectWork.projectWork?.quantityFree
          "
          severity="error"
          text="Quantité maximum autorisée : {{
            addedProjectWork.projectWork?.quantityFree
          }} "
        ></p-message>
        <p-message
          *ngIf="
            addedProjectWork.quantityTotal < 1
          "
          severity="error"
          text="Veuillez sélectionner une quantité pour la prestation"
        ></p-message>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="p-text-right">
      <!--RETOUR A LA LISTE-->
      <p-button
        styleClass="p-button-outlined p-mr-2"
        label="Annuler"
        (click)="cancel()"
      ></p-button>

      <p-button
        styleClass="p-mr-2"
        label="Valider"
        (click)="validProjectWork()"
      ></p-button>
    </div>
  </ng-template>

</p-dialog>
