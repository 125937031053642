import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Contracts} from "@data/schema/Contracts";
import { MenuItem, MessageService, SortEvent} from "primeng/api";
import { NavigationExtras, Router} from "@angular/router";
import {ContractService} from "@data/service/contract.service";
import {StatusEnumContract} from "@data/schema/StatusEnumContract";
import {SearchItemService} from "@data/service/search-item.service";
import {CustomSortService} from "@shared/service/custom-sort.service";
import { TokenStorageService } from '@core/service/token-storage.service';
import { PersonaService } from '@data/service/persona.service';
import { Persona } from '@data/schema/Persona';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.css']
})
export class ContractComponent implements OnInit {

  contracts: Contracts[] = [];
  contractsInactif: Contracts[] = [];
  contractsEnVigueur: Contracts[] = [];
  contractsDone: Contracts[] = [];
  selectedContracts: Contracts[] = [];
  enabled: boolean = false;
  display: string;
  contractIdOnToggle: Contracts;
  private sub: any;
  id: number;
  contractAttachedToWorkOrder: boolean = false;
  statusContract = StatusEnumContract;
 


  items: MenuItem[];
  contratRecherche: string;
  valueContrats: boolean = true;

  private role: string;
  idKeycloak: string;
  persona: Persona;



  constructor(private messageService: MessageService,
              private contractService: ContractService,
              private router: Router,
              private customSortService: CustomSortService,
              private searchItemService: SearchItemService, 
              private tokenStorageService: TokenStorageService,
              private personaService: PersonaService) {
  }


  ngOnInit(): void {

    this.items = [
      {label: 'Contrats'},
    ];
    this.enabled = false;
    this.display = 'Inactif (s)';

    if (!this.persona) {
      let token: any;
      token = this.tokenStorageService.decodeToken();
      this.idKeycloak = token.sub;

    }
    this.role = this.tokenStorageService.decodeToken().realm_access.roles[0];
    if (this.hasAuthorization([
      'administrateur',
      'admin_metier',
      'admin_responsable_DPO',
      'manager_operationnel',
    ])) {

    this.contractService.ListAllContract().subscribe(
      (res) => {
        if (res.status === 200) {
          this.contracts = res.body;
          this.contractsInactif = res.body.filter(x => x.status.code === StatusEnumContract.INACTIF);
          this.contractsEnVigueur = res.body.filter(x => x.status.code === StatusEnumContract.EN_VIGUEUR);
          this.contractsDone = res.body.filter(x => x.status.code === StatusEnumContract.TERMINE);

        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l\'opération : veuillez réessayer',
          });
        }
      },
      () => {

      }
    );}else if (this.hasAuthorization([
      'manager_operationnel_prestataire'])) {

        this.personaService.getPersonaByKeycloak(this.idKeycloak).subscribe(
          (res) => {
            if (res.status === 200) {
              this.persona = res.body;
                this.contractService.ListAllContractByCompany(this.persona?.company?.companyId).subscribe(
                  (res) => {
                    if (res.status === 200) {
                      this.contracts = res.body;
                      this.contractsInactif = res.body.filter(x => x.status.code === StatusEnumContract.INACTIF);
                      this.contractsEnVigueur = res.body.filter(x => x.status.code === StatusEnumContract.EN_VIGUEUR);
                      this.contractsDone = res.body.filter(x => x.status.code === StatusEnumContract.TERMINE);
            
                    }
                  },
                  (error) => {
                    if (error) {
                      this.messageService.add({
                        severity: 'error',
                        summary: 'Échec de l\'opération : veuillez réessayer',
                      });
                    }
                  },
                  () => {
            
                  }
                );
            }}
        );
    }

  }

  hasAuthorization(authorized: String[]) {
    if (authorized.includes(this.role)) {
      return true;
    } else {
      return false;
    }
  }

  getRowIndex(id: any) {
    this.contractIdOnToggle = id;

  }

  getCompany(id: any) {
    this.contractService.testContract(id);
  }

  // enable() {
  //   if (this.enabled === false) {
  //     this.contractService.ListContract(false).subscribe(
  //       (res) => {
  //         if (res.status === 200) {
  //           this.contracts = res.body;
  //         }
  //       },
  //       (error) => {
  //         if (error) {
  //           this.messageService.add({
  //             severity: 'error',
  //             summary: 'Échec de l’opération : veuillez réessayer',
  //           });
  //         }
  //       },
  //       () => {
  //       }
  //     );
  //     this.enabled = true;
  //     this.display = 'Actif (s)';
  //   } else {
  //     this.contractService.ListContract(true).subscribe(
  //       (res) => {
  //         if (res.status === 200) {
  //           this.contracts = res.body;

  //         }
  //       },
  //       (error) => {
  //         if (error) {
  //           this.messageService.add({
  //             severity: 'error',
  //             summary: 'Échec de l’opération : veuillez réessayer',
  //           });
  //         }
  //       },
  //       () => {
  //       }
  //     );
  //     this.enabled = false;
  //     this.display = 'Inactif (s)';
  //   }
  // }



  update() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        mode: 'update'
      }


    }
    this.router.navigate(['phoenix/show-contract/', this.contractIdOnToggle.contractId], navigationExtras);

  }
  filterTableInputChangeTabContract(event, str, dt1,dt2,dt3,dt4) {
    this.contratRecherche = event;
    this.searchItemService.filterTableInputChange(this.contratRecherche, str, dt1,dt2,dt3,dt4);
  }

  handleChangeTabContract(event,str, dt1,dt2,dt3,dt4){
    this.searchItemService.handleChange(event,this.contratRecherche, dt1,dt2,dt3,dt4);
  }

  naturalSortContract(event: SortEvent) {
    this.customSortService.naturalSort(event);
  }

}
