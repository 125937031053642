
import { Input, OnInit, Directive, ViewContainerRef, TemplateRef, OnDestroy } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {TokenStorageService} from "@core/service/token-storage.service";

@Directive({
  selector: '[ifRoles]'
})
export class IfRolesDirective implements OnInit, OnDestroy {
  private subscription: Subscription[] = [];
  // the role the user must have
  @Input() public ifRoles: Array<string>;

  roleUser : string
  roles : string[] = [];
  stop$ = new Subject();

  isVisible = false;

constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private tokenStorageService: TokenStorageService
  ) {}

  ngOnInit() {
    //  We subscribe to the roles$ to know the roles the user has
      this.roleUser = this.tokenStorageService.getRole();
      this.roles.push(this.roleUser)


      // If he doesn't have any roles, we clear the viewContainerRef
      if (!this.roles) {
        this.viewContainerRef.clear();
      }
      // If the user has the role needed to
      // render this component we can add it

      const idx = this.roles.findIndex((element) => this.ifRoles.indexOf(element) !== -1);
        if (idx < 0) {
          this.isVisible = false
          this.viewContainerRef.clear();
        } else {
          this.isVisible = true
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        }

  }

  // Clear the subscription on destroy
  ngOnDestroy() {
    this.stop$.next();
  }



}
