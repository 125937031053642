import { Pipe, PipeTransform } from '@angular/core';
import { DateTime, InputNumber, InputText, Select, SelectWithQuantity, Signature } from '@data/schema/intervention/Form';

@Pipe({
  name: 'isSignature'
})
export class IsSignaturePipe implements PipeTransform {

  transform(controlsType : Select | SelectWithQuantity | InputNumber | InputText | DateTime |Signature): controlsType is Signature {
    return controlsType?.code === "signature";
  }

}
