<div class="fixActionRow">
  <div class="dialogHeader">
    <h1 mat-dialog-title>
      {{ "attribuer_ot_SELECTIONNER_EQUIPE" | translate }}
      <button
        mat-icon-button
        aria-label="close dialog"
        mat-dialog-close
        class="close-button"
      >
        <mat-icon>close</mat-icon>
      </button>
    </h1>
  </div>
  <div fxLayoutAlign="end">
    <ng-template #showMyTeams>
    <section fxLayoutAlign="start" class="view-all" *ifPermission='["teams:userteams"]'>
      <mat-checkbox
      (click)="$event.stopPropagation()"
                      (change)="$event ? getMyTeams() : null"
                      [checked]="selection"
                    >
                    Afficher toutes mes équipes
     </mat-checkbox>
    </section >
    </ng-template>
    <section  fxLayoutAlign="start" class="view-all" *ifPermission="['teams:viewall']; else showMyTeams">

      <mat-checkbox

      (click)="$event.stopPropagation()"
                      (change)="$event ? getTeams() : null"
                      [checked]="selection"
                    >
                    Afficher toutes les équipes
     </mat-checkbox>

    </section >

    <mat-form-field class="searchDialog dialog" appearance="outline">
      <mat-icon
        class="customSearch"
        [inline]="true"
        svgIcon="customSearch"
      ></mat-icon>
      <input
        type="search"
        class="inputSearch"
        fxFlex="80"
        matInput
        [(ngModel)]="inputSearch"
        (input)="searchMode ? searchByTeamName() : searchByTechFullame()"
        placeholder="{{ 'RECHERCHER_PAR' | translate }}"
        #searchTabIntervention
      />
      <div fxFlex="10" class="icons">
        <mat-icon
          class="person"
          (click)="switchSearchMode('tech')"
          [ngClass]="{ disabled: searchMode === true }"
          [inline]="true"
          svgIcon="personSearch"
        ></mat-icon>
      </div>
      <div fxFlex="10" class="iconsPeople">
        <mat-icon
          class="people"
          (click)="switchSearchMode('team')"
          [ngClass]="{ disabled: searchMode !== true }"
          [inline]="true"
          svgIcon="peopleSearch"
        ></mat-icon>
      </div>
    </mat-form-field>
  </div>
  <div mat-dialog-content class="teams">
    <div fxLayoutAlign="end" class="expansion">
      <button
        mat-button
        color="primary"
        (click)="toggleAll(); $event.stopPropagation()"
        class="collapse"
      >
        {{
          allExpandState
            ? ("attribuer_ot_REDUIRE_TOUT" | translate)
            : ("attribuer_ot_DEVELOPPER_TOUT" | translate)
        }}
      </button>
    </div>
    <div *ngIf="loading" fxLayoutAlign="center">
      <img src="assets/GIF/Loader.gif"  width="120px" height="120px"/>
    </div>
    <div class="panel-container-dialog">
      <div class="panel-column">
        <mat-accordion
          class="mat-accordion"
          fxLayout="row wrap"
          fxLayoutGap="10px"
          fxLayoutAlign="start"
          fxFlex="100"
          multi
        >
          <mat-expansion-panel
            class="mat-expansion-panel dialog"
            [expanded]="allExpandState"
            fxFlex="48"
            hideToggle="true"
            *ngFor="let team of teams; let index = index"
            (click)="selectTeam(index)"
            [ngStyle]="{
              'background-color': indexOfTeam === index ? '#0278BC' : '#f4f5f7'
            }"
            (opened)="team.expanded = true"
            (closed)="team.expanded = false"
          >
            <mat-expansion-panel-header class="panelHeder"

              #panelHeader
              (click)="panelHeader._toggle(index)"
            >
              <mat-panel-title fxFlex="61">
                <span
                  class="team"
                  [ngStyle]="{
                    color: indexOfTeam === index ? '#ffffff' : '#6A707E'
                  }"
                  >{{ team.name }}</span
                >
              </mat-panel-title>
              <mat-panel-description fxFlex="15" >
                <div class="icon-one" >
                  <mat-icon
                    [inline]="true"
                    [ngStyle]="{
                      color: indexOfTeam === index ? '#ffffff' : '#6A707E'
                    }"
                    >person</mat-icon >
                </div>
                <span
                  class="number-in-teams"
                  [ngStyle]="{
                    color: indexOfTeam === index ? '#ffffff' : '#6A707E'
                  }"
                >
                  {{ team.technicians.length }}
                </span>
                <div class="icon-three" (click)="panelHeader._toggle(index)">
                  <mat-icon
                    [inline]="true"
                    svgIcon="caret-right-fill"
                    *ngIf="!team.expanded && indexOfTeam != index"
                  ></mat-icon>
                  <mat-icon
                    [inline]="true"
                    svgIcon="caret-down-fill"
                    *ngIf="team.expanded && indexOfTeam != index"
                  ></mat-icon>
                  <mat-icon
                    [inline]="true"
                    svgIcon="white-caret-right-fill"
                    *ngIf="!team.expanded && indexOfTeam === index"
                  ></mat-icon>
                  <mat-icon
                    [inline]="true"
                    svgIcon="white-caret-down-fill"
                    *ngIf="team.expanded && indexOfTeam === index"
                  ></mat-icon >
                </div>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div
              *ngFor="let technician of team.technicians"
              class="number-of-teams"
              fxLayoutAlign="space-between center"
            >
              <app-persona-card
                [persona]="technician"
                *ngIf="technician?.responsible"
                [responsibleTechnician]="technician.idParty"
                [teamScope]="true"
                [teamDisplay]="true"
                [displayTeams]="true"
                class="card persona-card"
              >
              </app-persona-card>
              <app-persona-card
                [persona]="technician"
                *ngIf="!technician?.responsible"
                [teamScope]="true"
                [displayTeams]="true"
                class="card persona-card"
              >
              </app-persona-card>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
  <div class="spacer"></div>

  <div mat-dialog-actions align="end">
    <button
      mat-button
      color="primary"
      mat-stroked-button
      [mat-dialog-close]="false"
    >
      {{ "attribuer_ot_ANNULER" | translate }}
    </button>
    <button
      mat-button
      color="primary"
      mat-raised-button
      (click)="validateWorkOrderAssignement()"
      [disabled]="!selected"
      cdkFocusInitial
    >
      {{ "attribuer_ot_VALIDER" | translate }}
    </button>
  </div>
</div>

