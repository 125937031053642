<app-kober-menu module="kober-skill"></app-kober-menu>

  


      <div class="p-d-flex p-jc-center p-m-2 p-p-2 main-page">
        <div class="p-grid p-ai-center vertical-container p-jc-center">
            <div class="p-col-12 p-md-11 p-jc-center p-m-3 p-p-3 main-content p-shadow-4">
              <div class="p-grid p-jc-between p-p-3"> 
                <h2>{{ 'titre_COMPETENCES_EXPIREES' | translate }}</h2>
                <!-- <div>       
                  <button  *ifRoles='["administrateur",  "admin_metier","admin_responsable_DPO"]' class="p-mr-5 p-mt-5" pButton  (click)="userSync()" pTooltip="Synchroniser les personas"><span style="font-size: 1.2rem" class="pi pi-replay"></span></button>
                </div> -->
              </div>
              <mat-tab-group>

                <mat-tab label="{{ 'onglet_PAR_COMPETENCE' | translate}}">
                  <p-table
                    #dt1
                    (sortFunction)="naturalSort($event)"
                    [customSort]="true"
                    sortField="skillName"
                    [sortOrder]='1'
                    dataKey="skillName"
                    [value]="listSkill"
                    class="table"
                    [scrollable]="true"
                    scrollHeight="40vh"
                    [paginator]="true"
                    [rows]="10"
                    [showCurrentPageReport]="true"
                    currentPageReportTemplate="{{'PAGINATION'|translate}}"
                    styleClass="p-datatable-sm p-datatable-striped"
                    [rowsPerPageOptions]="[10, 25, 50]"
                    [globalFilterFields]="['skillName']"
                  >
                    <ng-template pTemplate="caption">
                      <div class="p-d-flex">
                        <span class="p-input-icon-left p-ml-auto">
                          <span class="p-input-icon-left p-ml-auto">
                            <i class="pi pi-search"></i>
                            <input
                              pInputText
                              type="text"
                              [value]="searchedSkill!== undefined?searchedSkill:''"
                              (input)="dt1.filterGlobal($event.target.value, 'contains')"
                              placeholder="{{ 'recherche' | translate }}"
                            />
                          </span>
                        </span>
                      </div>
                    </ng-template>

                    <ng-template pTemplate="header">
                      <tr>
                        <th id="skillName" pSortableColumn="skillName">
                          <div class="p-d-flex p-jc-between p-ai-center" style="justify-content: left;">
                            {{ 'HABILITATION' | translate }}
                            <div class="p-d-flex p-flex-column p-ai-center">
                              <p-sortIcon field="skillName"></p-sortIcon>
                            </div>
                          </div>
                        </th>
                        <th id="skillDescription" pSortableColumn="skillDescription">
                          <div class="p-d-flex p-jc-between p-ai-center" style="justify-content: left;">
                            {{ 'DESCRIPTION'|translate}}
                            <div class="p-d-flex p-flex-column p-ai-center">
                              <p-sortIcon field="skillDescription"></p-sortIcon>
                            </div>
                          </div>
                        </th>
                        <th id="nbPersonasWillExpired" pSortableColumn="nbPersonasWillExpired">
                          <div class="p-d-flex p-jc-between p-ai-center" style="justify-content: end;">
                            {{ 'va_expirer'|translate}}
                            <div class="p-d-flex p-flex-column p-ai-center">
                              <p-sortIcon field="nbPersonasWillExpired"></p-sortIcon>
                            </div>
                          </div>
                        </th>
                        <th id="nbPersonasExpired" pSortableColumn="nbPersonasExpired">
                          <div class="p-d-flex p-jc-between p-ai-center" style="justify-content: end;">
                            {{ 'date_echue'|translate}}
                            <div class="p-d-flex p-flex-column p-ai-center">
                              <p-sortIcon field="nbPersonasExpired"></p-sortIcon>
                            </div>
                          </div>
                        </th>
                        <th style="width: 6%;">

                        </th>
                      </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-skill>
                      <tr>
                        <td>
                          {{ skill.skillName }}
                        </td>
                        <td class="text-container" 
                            (mouseenter)="onMouseEnter(skill)" 
                            (mouseleave)="onMouseLeave(skill)">
                          <div class="text" (mouseover)="showFullDescription(skill.skillDescription)">
                            {{ skill.skillDescription }}
                          </div>
                          
                          <div *ngIf="skill.isHovered && fullDescription  && isDescriptionLongEnough(skill.skillDescription)" class="tooltip-text" (mouseover)="showFullDescription(skill.skillDescription)">
                            {{ fullDescription }}
                          </div>
                        </td>
                        <td style="text-align: end;">
                          {{ skill.nbPersonasWillExpired }}
                        </td>
                        <td style="text-align: end;">
                          {{ skill.nbPersonasExpired }}
                        </td>
                        <!-- <td><button routerLink="/phoenix/show-skill-expired/{{ skill.id }}" style="background-color: #FFFFFF; border: none; font-weight: bold; border-radius: 5px; padding: 10px;">
                          <i class="bi bi-arrows-angle-expand"></i>
                            </button>
                        </td> -->
                        <td style="width: 6%;"><button (click)="openDialog(skill.id)" pButton type="button" icon="pi pi-search" class="p-button-rounded p-button-text p-button-plain">
                        </button>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </mat-tab>

                <mat-tab label="{{ 'onglet_PAR_PERSONA' | translate}}">
                  <p-table
                   #dt2
                   [value]="persona"
                   class="table"
                   [scrollable]="true"
                   scrollHeight="40vh"
                   [paginator]="true"
                   [rows]="10"
                   (sortFunction)="naturalSort($event)"
                   [customSort]="true"
                   sortField="lastName"
                   [sortOrder]='1'
                   dataKey="lastName"
                   [totalRecords]="totalRecords"
                   currentPageReportTemplate="{{'PAGINATION'|translate}}"
                   styleClass="p-datatable-sm p-datatable-striped"
                   [rowsPerPageOptions]="[10, 25, 50]"
                   [showCurrentPageReport]="true"
                   [globalFilterFields]="[
                  'firstName',
                  'lastName',
                  'publicName',
                ]"
        >
                <ng-template pTemplate="caption">
                  <div class="p-d-flex">
                    <span class="p-input-icon-left p-ml-auto">
                      <i class="pi pi-search"></i>
                      <input
                        p
                        pInputText
                        type="text"
                        (input)="dt2.filterGlobal($event.target.value, 'contains')"
                        placeholder="{{ 'recherche' | translate }}"
                      />
                    </span>
                  </div>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th id="photo"></th>
                    <th id="lastName" pSortableColumn="lastName">
                      {{ "NOM" | translate }}
                      <p-sortIcon field="lastName"></p-sortIcon>
                    </th>
                    <th id="firstName" pSortableColumn="firstName">
                      {{ "PRENOM" | translate }}
                      <p-sortIcon field="firstName"></p-sortIcon>
                    </th>
                    <th id="publicName" pSortableColumn="publicName">
                      {{ "DEUXIEME_NOM" | translate }}
                      <p-sortIcon field="publicName"></p-sortIcon>
                    </th>
                    <th id="publicName" pSortableColumn="publicName">
                      {{ "ENTREPRISE" | translate }}
                      <p-sortIcon field="publicName"></p-sortIcon>
                    </th>
                    <th id="publicName" pSortableColumn="publicName">
                      {{ "va_expirer" | translate }}
                      <p-sortIcon field="publicName"></p-sortIcon>
                    </th>
                    <th id="publicName" pSortableColumn="publicName">
                      {{ "EXPIREE" | translate }}
                      <p-sortIcon field="publicName"></p-sortIcon>
                    </th>
                    <th id="actions" colspan="2" class="p-text-center table-10"></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="colgroup">
                  <colgroup>
                    <col style="width: 5%" />
                    <col style="width: 14%" />
                    <col style="width: 14%" />
                    <col style="width: 14%" />
                    <col style="width: 14%" />
                    <col style="width: 16%" />
                    <col style="width: 14%" />
                    <col style="width: 0.5%" />
                  </colgroup>
                </ng-template>
                <ng-template pTemplate="body" let-persona let-ri="rowIndex" let-columns>
                  <tr>
                    <td style="border: 0;">
                      <img
                        *ngIf="persona?.photo"
                        src="{{ persona?.photo }}"
                        class="header-image"
                      />
                      <img
                        *ngIf="!persona?.photo"
                        class="header-image"
                        src="assets/img/default-avatar.png"
                        alt="Profibild"
                      />
                    </td>
                    <td style="border: 0;">{{ persona.lastName }}</td>
                    <td style="border: 0;">{{ persona.firstName }}</td>
                    <td style="border: 0;">{{ persona.publicName }}</td>
                    <!-- <td><div *ngFor="let skill of persona.listSkill"> <p *ngIf="skill.skill.endDate > findDate()">{{skill.skill.name}}</p> </div>  </td> -->
                    <td style="border: 0;">{{ persona.company }}</td>
                    <td style="border: 0;">
                      <div *ngFor="let skill of persona.listSkill; let i = index" (click)="toggleShowAll()" style="cursor: pointer;">
                        <div *ngIf="i < 2 || showAll">
                          <p *ngIf="skill.skill.endDate > findDate()">{{skill.skill.name}}</p>
                        </div>
                      </div>
                      
                    </td>
                    <td style="display: block; border: 0;">
                      <div *ngFor="let skill of persona.listSkill; let i = index">
                          <p *ngIf="skill.skill.endDate < findDate()">{{skill.skill.name}}</p>
                      </div>
                      
                    </td>
                    
                    <td class="p-text-center table-5" style="border: 0;">
                      <button
                        pButton
                        type="button"
                        icon="pi pi-search"
                        class="p-button-rounded p-button-text p-button-plain"
                        (click)="openDialogPersona(persona.id)"
                      ></button>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
        
              <p-overlayPanel #op appendTo="body" baseZIndex="2">
                <ng-template pTemplate>
                  <div #actualTarget>
                    <button
                      pButton
                      pRipple
                      type="button"
                      icon="pi pi-pencil"
                      class="p-button-rounded p-button-text"
                      [style]="{ 'margin-right': '5px' }"
                      label="Modifier"
                      (click)="update()"
                    ></button
                    ><br />
                    <ng-contaire
                      *ifRoles="[
                        'administrateur',
                        'admin_metier',
                        'admin_responsable_DPO'
                      ]"
                    >
                      <button
                        *ngIf="enabled"
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-trash"
                        (click)="changePersonaStatus()"
                        class="p-button-rounded p-button-text"
                        label="Supprimer"
                      ></button>
                    </ng-contaire>
                    <ng-contaire
                      *ifRoles="[
                        'administrateur',
                        'admin_metier',
                        'admin_responsable_DPO'
                      ]"
                    >
                      <button
                        *ngIf="!enabled"
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-undo"
                        (click)="changePersonaStatus()"
                        class="p-button-rounded p-button-text"
                        label="Activer"
                      ></button>
                    </ng-contaire>
                  </div>
                </ng-template>
              </p-overlayPanel>
              <p-confirmDialog
                baseZIndex="5"
                styleClass="main-page"
                [style]="{ width: '60%' }"
              ></p-confirmDialog>
              </mat-tab>


              </mat-tab-group>
              
              
          </div>
        </div>
      </div>