import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Category } from '@data/schema/Category';
import { Materiel } from '@data/schema/Materiel';
import { Unit } from '@data/schema/Unit';
import { MaterialWork } from '@data/schema/Work';
@Component({
  selector: 'app-work-show-tools',
  templateUrl: './work-show-tools.component.html',
  styleUrls: ['./work-show-tools.component.css']
})
export class WorkShowToolsComponent implements OnInit {
  @Input() listTools: Materiel[];
  @Input() listToolsSelected: MaterialWork[];
  @Output() listToolsSelectedChange = new EventEmitter();
  change() {
    this.listToolsSelectedChange.emit(this.listToolsSelected);
  }

  errorNotFilled = false;
  errorDuplicate = false;
  constructor() { }

  ngOnInit(): void {
  }

}
