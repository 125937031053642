import { Component, OnInit, Input, Output } from '@angular/core';
import { Project, ProjectWorkOrder} from '@data/schema/Project';
import {CustomSortService} from "@shared/service/custom-sort.service";

import { Router } from '@angular/router';
import {TreeNode,SortEvent} from 'primeng/api';

@Component({
  selector: 'app-work-from-list-work-order',
  templateUrl: './work-from-list-work-order.component.html',
  styleUrls: ['./work-from-list-work-order.component.css']
})
export class WorkFromListWorkOrderComponent implements OnInit {
 @Input() list?:ProjectWorkOrder[];
 @Input() listProjectWorkOder?:  ProjectWorkOrder[];
 @Input() project : Project;

 // @Input() list:ProjectWorkOrder;
 // listWorkOrder : any[];
  constructor( private router: Router,
               private customSortService: CustomSortService) {
    
   }

  ngOnInit(): void {
  }

      RedirectToCreateOT(){

        this.router.navigateByUrl('/phoenix/new-work-order');
        localStorage.projectName = this.project.name;
        localStorage.projectId = this.project.id;
        localStorage.isRedirect = true;

      }


     getProgress(workOrder: any){
      let done = 0;
      let total =0;

      for (const pwo of workOrder.listWorkOrderProjectWork){
          done += pwo.quantityDone
           total += pwo.quantityTotal
      }
      return Math.trunc((done / total) * 100);
    }
    getQantityToDo(quantityTotal: number, quantityDone:number){
     let quantityToDo = quantityTotal - quantityDone;
     return quantityToDo
    }

    naturalSortWork(event:any) {
    
      this.customSortService.naturalSort(event);

    }

}
