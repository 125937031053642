<div [formGroup]="formG" class="p-grid p-fluid">
  <div class="p-col-12 p-md-6 p-lg-6" style="margin-top: 10px">
    <div class="p-grid">
      <div class="p-col-12 p-md-8 p-lg-8">
        <div class="p-fluid">
          <div class="p-grid p-nogutter">
            <div class="p-col-12">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon custom-group"><i class="pi pi-calendar p-mr-2"></i> Date</span>
                <p-calendar formControlName="voucherDate" appendTo="body" [showTime]="true" inputId="time"></p-calendar>
              </div>
              <p-message
                *ngIf="formG?.controls?.voucherDate?.invalid && (formG?.controls?.voucherDate?.dirty || formG?.controls?.voucherDate?.touched)"
                class="alert" severity="error" text="Champ obligatoire.">
              </p-message>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-col-12 p-md-6 p-lg-6" style="margin-top: 10px">
    <div class="p-grid">
      <div class="p-col-12 p-md-12 p-lg-12">
    <span class="p-float-label">
      <input id="float-input" type="text" pInputText formControlName="voucherRef">
      <label for="float-input">Référence</label>
    </span>
      </div>
    </div>
  </div>
  <div class="p-col-12 p-md-12 p-lg-12">
    <p-divider></p-divider>
  </div>
  <div class="p-col-12 p-md-6 p-lg-3">
    <label class="custom-label-form">Consommable</label>
    <div *ngIf="editItem.edit === true; else elseBlockEdit">
      <p>{{formG?.value?.conso?.materialDto?.label}}</p>
    </div>
    <ng-template #elseBlockEdit>
      <p-dropdown [filter]="true" [options]="listWorkMaterialConsumable" formControlName="conso" (onChange)="change($event)"
                  placeholder="Sélectionner" appendTo="body" optionLabel="materialDto.label" [showClear]="true">
      </p-dropdown>
      <p-message
        *ngIf="(formG?.controls?.conso?.invalid) && (formG?.controls?.conso?.dirty || formG?.controls?.conso?.touched)"
        class="alert" severity="error" text="Champ obligatoire.">
      </p-message>
    </ng-template>
  </div>
  <div class="p-col-12 p-md-6 p-lg-3">
    <label class="custom-label-form">Unité</label>
    <p>{{formG?.value?.conso?.materialDto?.unit?.name}}</p>
  </div>
  <div *ngFor="let voucher of voucherControls.controls; let index = index" class="p-col-12 p-md-6 p-lg-3">
    <div [formGroup]="voucher">
      <label class="custom-label-form">Quantité</label>
      <p-inputNumber
        mode="decimal"
        [showButtons]="true"
        formControlName="quantity"
        buttonLayout="horizontal"
        spinnerMode="horizontal"
        decrementButtonClass="p-button-primary"
        incrementButtonClass="p-button-primary"
        incrementButtonIcon="pi pi-plus"
        decrementButtonIcon="pi pi-minus"
        [min]="1"
      >
      </p-inputNumber>
      <div
        *ngIf="(voucher?.controls?.quantity?.invalid) && (voucher?.controls?.quantity?.dirty || voucher?.controls?.quantity?.touched)">
        <p-message *ngIf="(voucher?.controls?.quantity?.required)"
                   class="alert" severity="error" text="Champ obligatoire.">
        </p-message>
        <!--
        <p-message *ngIf="(voucher?.controls?.quantity?.errors?.max)"
                   class="alert" severity="error" text="Quantité maximum autorisée : {{maxInput}}">
        </p-message>
        -->
      </div>
    </div>
  </div>
  <div class="p-col-12 p-md-6 p-lg-3" style="margin: auto">
    <div class="p-grid">
      <div class="p-col-12 p-md-10 p-lg-9" style="margin: auto">
        <div>
          <br>
          <div class="box" *ngIf="editItem.hasOwnProperty('edit') === false; else elseBlockAdd">
            <button pButton pRipple type="button" label="Ajouter" class="p-button-rounded" [disabled]="formG.invalid"
                    (click)="addVoucher()">
            </button>
          </div>
          <ng-template #elseBlockAdd>
            <button pButton pRipple type="button" label="Modifier" class="p-button-rounded" [disabled]="formG.invalid"
                    (click)="editVoucher()">
            </button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>


