import { Materiel } from "./Materiel";
import { Skill } from "./Skill";

export class Work {
  idWork: number;
  name: string;
  description: string;
  cost: number;
  currency: string;
  listSkillDto: SkillWork[];
  materialsList: MaterialWork[];
  hasSkill : boolean;
  hasMaterial : boolean;
  index : number;


  constructor(work?: Partial<Work>) {
    if (work){

      Object.assign(this, work)

    }
    else{
        this.name = '';
        this.description = '';
        this.cost = null;
        this.currency = '';
        this.listSkillDto = [];
        this.materialsList = [];
        this.hasSkill = false;
        this.hasMaterial = false;
        this.index = 0;
    }

  }

  public isDefined(){

    return (
      this.name ||
      this.description ||
      this.cost

    )
  }
}

export class SkillWork {
  id: number;
  work: Work;
  idSkill: number;
  skillDto: Skill;

  constructor() {
    this.work = null;
    this.idSkill = null;
    this.skillDto = null;
  }
}

export class MaterialWork {
  id: number;
  work: Work;
  idMateriel: number;
  materialDto: Materiel;
  quantityMaterial: number;
  quantityMaterialUsed?: number;
  totalIssuedQuantity?: number;

  constructor() {
    this.work = null;
    this.idMateriel = null;
    this.materialDto = null;
    this.quantityMaterial = 1;
    this.quantityMaterialUsed = 1;
    this.totalIssuedQuantity = 1;
  }
}

