<div class="p-grid p-ai-center vertical-container p-jc-center">
<div class="p-col-12 p-md-11 p-jc-center p-m-3 p-p-3 main-content p-shadow-4">
  <div class="header-container">
    <h2 style="width: 50%;">{{'popUp_COMPETENCES_EXPIREES_PERSONA'|translate}}</h2>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
<div class="header">
    <div><img
        *ngIf="persona?.photo"
        src="{{ persona?.photo }}"
        class="header-image"
      />
      <img
        *ngIf="!persona?.photo"
        class="header-image"
        src="assets/img/default-avatar.png"
        alt="Profibild"
      /></div>
    <div class="sm-header">
        <div class="headTitle">{{'PRENOM'|translate}}</div>
        <div class="headBody">{{persona.firstName}}</div>
    </div>
    <div class="sm-header">
        <div class="headTitle">{{'NOM_FAMILLE'|translate}}</div>
        <div class="headBody">{{persona.lastName}}</div>
    </div>
    <div class="sm-header">
        <div class="headTitle">{{'DEUXIEME_NOM'|translate}}</div>
        <div class="headBody">{{persona.middleName}}</div>
    </div>
    <div class="sm-header">
        <div class="headTitle">{{'ENTREPRISE'|translate}}</div>
        <div class="headBody">{{persona.company.name}}</div>
    </div>
    <div class="sm-header">
        <div class="headTitle">{{'POSTE'|translate}}</div>
        <div class="headBody">{{persona.postPersona.nameFR}}</div>
    </div>
</div>
<div>
    <p-table
                   #dt1
                   #dt2
                   [value]="persona.listSkill"
                   class="table"
                   [scrollable]="true"
                   scrollHeight="40vh"
                   [paginator]="true"
                   [rows]="10"
                   (sortFunction)="naturalSortPersona($event)"
                   [customSort]="true"
                   sortField="lastName"
                   [sortOrder]="1"
                   dataKey="lastName"
                   styleClass="p-datatable-sm p-datatable-striped"
                   [showCurrentPageReport]="true"
                   currentPageReportTemplate="{{'PAGINATION'|translate}}"
                   styleClass="p-datatable-sm p-datatable-striped"
                   [rowsPerPageOptions]="[10, 25, 50]"
                   [globalFilterFields]="[
                  'firstName',
                  'lastName',
                  'publicName',
                ]"
        >
                <ng-template pTemplate="caption">
                  <div class="p-d-flex">
                    <span>
                      {{'COMPETENCES'|translate}} <i class="bi bi-question-circle" pTooltip="{{'helper_COMPETENCES_EXPIREES_PERSONA'|translate}}"></i>
                    </span>
                    <span class="p-ml-auto radioBtnSpan">
                      <p-radioButton class="radioBtn" name="filter" value="all" [(ngModel)]="selectedValue" inputId="all"></p-radioButton>
                      <label for="all" class="radioBtnSpan-label">{{'TOUS'|translate}}</label>
                      <p-radioButton class="radioBtn" name="filter" value="willExpire" [(ngModel)]="selectedValue" inputId="willExpire"></p-radioButton>
                      <label for="willExpire" class="radioBtnSpan-label">{{'filtre_EXPIRENT_BIENTOT'|translate}}</label>
                      <p-radioButton class="radioBtn" name="filter" value="expired" [(ngModel)]="selectedValue" inputId="expired"></p-radioButton>
                      <label for="expired" class="radioBtnSpan-label">{{'filtre_EXPIREES'|translate}}</label>
                    </span>
                    <span class="p-input-icon-left p-ml-5">
                      <i class="pi pi-search"></i>
                      <input
                        p
                        pInputText
                        type="text"
                        (input)="dt1.filterGlobal($event.target.value, 'contains')"
                        placeholder="{{ 'recherche' | translate }}"
                      />
                    </span>
                  </div>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th id="lastName" pSortableColumn="lastName">
                      {{ "HABILITATION" | translate }}
                      <p-sortIcon field="lastName"></p-sortIcon>
                    </th>
                    <th id="firstName" pSortableColumn="firstName">
                      {{ "DESCRIPTION" | translate }}
                      <p-sortIcon field="firstName"></p-sortIcon>
                    </th>
                    <th id="ExpDate">
                        {{ "DATE_EXPIRATION" | translate }}
                    </th>
                    <th id="TimeLeft">
                        {{ "DELAI_RESTANT_JOURS" | translate }}
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="colgroup">
                  <colgroup>
                    <col style="width: 15%" />
                    <col style="width: 45%" />
                    <col style="width: 20%" />
                    <col style="width: 20%" />
                  </colgroup>
                </ng-template>
                <ng-template pTemplate="body" let-skill let-ri="rowIndex" let-columns>
                  <tr *ngIf="persona.daysRemaining <= 0" [hidden]="selectedValue!='expired'">
                    <td>{{skill.skill.name}}</td>
                    <!-- <td>{{skill.skill.description}}</td> -->
                    <td class="text-container" 
                        (mouseenter)="onMouseEnter(skill)" 
                        (mouseleave)="onMouseLeave(skill)">
                      <div class="text" (mouseover)="showFullDescription(skill.skill.description)">
                        {{ skill.skill.description }}
                      </div>
                      
                      <div *ngIf="skill.isHovered && fullDescription  && isDescriptionLongEnough(skill.skill.description)" class="tooltip-text" (mouseover)="showFullDescription(skill.skill.description)">
                        {{ fullDescription }}
                      </div>
                    </td>
                    <td class="TARight" style="text-align: left !important;">{{skill.endDate | date:'d/M/yyyy'}}</td>
                    <td class="TARight" *ngIf="skill.dayRemaining > 0" style="text-align: left !important;">{{ skill.dayRemaining }}</td>
                    <td class="TARight" *ngIf="skill.dayRemaining <= 0" style="text-align: left !important;"><div class="expired">EXPIRÉE</div></td>
                  </tr>
                  <tr *ngIf="persona.daysRemaining > 0" [hidden]="selectedValue!='willExpire'">
                    <td>{{skill.skill.name}}</td>
                    <!-- <td>{{skill.skill.description}}</td> -->
                    <td class="text-container" 
                        (mouseenter)="onMouseEnter(skill)" 
                        (mouseleave)="onMouseLeave(skill)">
                      <div class="text" (mouseover)="showFullDescription(skill.skill.description)">
                        {{ skill.skill.description }}
                      </div>
                      
                      <div *ngIf="skill.isHovered && fullDescription  && isDescriptionLongEnough(skill.skill.description)" class="tooltip-text" (mouseover)="showFullDescription(skill.skill.description)">
                        {{ fullDescription }}
                      </div>
                    </td>
                    <td class="TARight" style="text-align: left !important;">{{skill.endDate | date:'d/M/yyyy'}}</td>
                    <td class="TARight" *ngIf="skill.dayRemaining > 0" style="text-align: left !important;">{{ skill.dayRemaining }}</td>
                    <td class="TARight" *ngIf="skill.dayRemaining <= 0" style="text-align: left !important;"><div class="expired">EXPIRÉE</div></td>
                  </tr>
                  <tr [hidden]="selectedValue!='all'">
                    <td>{{skill.skill.name}}</td>
                    <!-- <td>{{skill.skill.description}}</td> -->
                    <td class="text-container" 
                        (mouseenter)="onMouseEnter(skill)" 
                        (mouseleave)="onMouseLeave(skill)">
                      <div class="text" (mouseover)="showFullDescription(skill.skill.description)">
                        {{ skill.skill.description }}
                      </div>
                      
                      <div *ngIf="skill.isHovered && fullDescription  && isDescriptionLongEnough(skill.skill.description)" class="tooltip-text" (mouseover)="showFullDescription(skill.skill.description)">
                        {{ fullDescription }}
                      </div>
                    </td>
                    <td class="TARight" style="text-align: left !important;">{{skill.endDate | date:'d/M/yyyy'}}</td>
                    <td class="TARight" *ngIf="skill.dayRemaining > 0" style="text-align: left !important;">{{ skill.dayRemaining }}</td>
                    <td class="TARight" *ngIf="skill.dayRemaining <= 0" style="text-align: left !important;"><div class="expired">EXPIRÉE</div></td>
                  </tr>
                </ng-template>
              </p-table>
              <mat-dialog-actions class="returnBtnDiv">
                <button class="returnBtn" mat-button mat-dialog-close>{{'formulaire_RETOUR'|translate}}</button>
              </mat-dialog-actions>
  </div>

</div>
</div>
