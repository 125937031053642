import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrowserStorageService {

  constructor() { }

  copySessionStorage(){
    const items = { ...sessionStorage };
    for (const property in items){
      localStorage.setItem(property, items[property])
    }
  }

  retrieveLocalStorage(){
    const items = { ...localStorage };
    for (const property in items){
      sessionStorage.setItem(property, items[property])
    }
    this.clear();
  }

  clear(){
    localStorage.removeItem('auth-role');
    localStorage.removeItem('auth-token');
    localStorage.removeItem('refresh-token');
  }
  
}
