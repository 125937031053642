<h3>{{'CONSOMMABLES_REQUIS'|translate}}</h3>
<div class="p-grid p-mb-2">
  <div class="p-col-4">
    {{'NOM'|translate}}
  </div>
  <div class="p-col-4">
    {{'UNITE'|translate}}
  </div>
  <div class="p-col-2 p-text-right">
    {{'QUANTITE'|translate}}
  </div>
</div>
<div *ngFor="let workMateriel of listConsumableSelected" class="p-grid">
  <div class="p-col-4">
    {{workMateriel.materialDto.label}}
  </div>
  <div class="p-col-4">
    {{workMateriel.materialDto.unit.name}}
  </div>
  <div class="p-col-2 p-text-right">
    {{workMateriel.quantityMaterial | number:'1.0-0':'fr'}}
  </div>
</div>
<!-- <td class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"></td>
  <td class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Unité</td>
  <td class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Quantité</td>
</tr>
  <tr *ngFor="let workMateriel of listConsumableSelected" >
    <td class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{workMateriel.materialDto.label}}</td>
    <td class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{workMateriel.materialDto.unit.name}}</td>
    <td class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{workMateriel.quantityMaterial}}</td>
  </tr> -->







