<div *ngIf="!(workOrder.status === 'DONE')" class="p-text-right">
  
  <p-button
  *ifRoles='["administrateur",  "admin_metier","admin_responsable_DPO", "manager_operationnel" , "manager_operationnel_prestataire", "manager_CA", "ca_prestataire", "charge_de_pilotage"]'
    (click)="report()"
    styleClass="advance"
    label="Avancement"
  ></p-button>
</div>

<p-dialog
  [draggable]="false"
  [modal]="true"
  [contentStyle]="{ overflow: 'visible' }"
  styleClass="main-page"
  [style]="{ width: '70vw' }"
  header="Avancement"
  [(visible)]="displayModal"
>
  <div class="p-grid p-d-flex p-jc-between">
    <div class="p-col-6 p-as-end">
      <div class="p-fluid">
        <div class="p-grid p-nogutter">
          <div class="p-col-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon custom-group"
                ><i class="pi pi-calendar p-mr-2"></i> Date</span
              >
              <p-calendar
                [(ngModel)]="date"
                [showTime]="true"
                inputId="time"
              ></p-calendar>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-col-6 p-as-end">
      <div class="p-fluid">
        <div class="p-grid p-nogutter">
          <div class="p-col-12 p-nogutter">
            <label for="ref" class="label"> Référence </label>
          </div>
          <div class="p-col-12">
            <input type="text" [(ngModel)]="referenceNumber" pInputText />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-content">
    <p-table
    [scrollable]="true"
    scrollHeight="30vh"
      #dt1
      [value]="listWorkOrderAdvance"
      styleClass="p-datatable-sm p-datatable-striped"
      [globalFilterFields]="[
        'workOrderProjectWork.projectWork.work.name',
        'quantityDone',
        'quantityTotal',
        'workOrderProjectWork.quantityTotal - workOrderProjectWork.quantityDone'
      ]"
    >
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <span class="p-input-icon-left p-ml-auto">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              (input)="dt1.filterGlobal($event.target.value, 'contains')"
              placeholder="Rechercher"
            />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>Prestations</th>
          <th class="p-text-right">Quantité totale</th>
          <th class="p-text-right">A réaliser</th>
          <th class="p-text-right">Réalisée</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-historyLine>
        <tr
          [ngClass]="{
            hightlight:
              historyLine.quantityDone > 0 &&
              historyLine.quantityDone <= historyLine.quantityAvailable,
            error:
              historyLine.quantityDone > historyLine.quantityAvailable
          }"
        >
          <td>
            {{ historyLine.workOrderProjectWork?.projectWork?.work?.name }}
          </td>
          <td class="p-text-right">
            {{ historyLine.workOrderProjectWork?.quantityTotal }}
          </td>
          <td class="p-text-right">
            {{
              historyLine.workOrderProjectWork?.quantityTotal -
                historyLine.workOrderProjectWork?.quantityDone
            }}
          </td>
          <td pEditableColumn class="p-text-right">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-inputNumber
                  pTooltip="Quantité maximum autorisée : {{
                    historyLine.quantityAvailable
                  }}"
                  [ngClass]="{
                    'ng-invalid ng-dirty':
                      historyLine.quantityDone > historyLine.quantityAvailable
                  }"
                  [(ngModel)]="historyLine.quantityDone"
                  mode="decimal"
                  min="0"
                  size="7"
                  [style]="{ width: '100%' }"
                >
                </p-inputNumber>
              </ng-template>
              <ng-template pTemplate="output">
                <p-inputNumber
                  pTooltip="Quantité maximum autorisée : {{
                    historyLine.quantityAvailable
                  }}"
                  [ngClass]="{
                    'ng-invalid ng-dirty':
                      historyLine.quantityDone > historyLine.quantityAvailable
                  }"
                  [(ngModel)]="historyLine.quantityDone"
                  mode="decimal"
                  min="0"
                  size="7"
                  [style]="{ width: '100%' }"
                >
                </p-inputNumber>
              </ng-template>
            </p-cellEditor>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <ng-template pTemplate="footer">
    <p-button
      styleClass="p-button-outlined p-mr-2"
      label="Annuler"
      (click)="cancel()"
    ></p-button>

    <p-button styleClass="p-mr-2" label="Valider" (click)="submit()"></p-button>
  </ng-template>
</p-dialog>