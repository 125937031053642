import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-elements-count',
  templateUrl: './elements-count.component.html',
  styleUrls: ['./elements-count.component.scss']
})
export class ElementsCountComponent implements OnInit {

  @Input() totalCount: number = 0;
  @Input() displayedCount: number = 0;


  constructor() { }

  ngOnInit(): void {
  }

}
