import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor() { }
//trouver le moyen de remplacer la liste en dure par les noms des parametre pour permettre l'utilisation de la method pour tous les objects
  downloadFileCsv(data, filename='data') {
         
      let csvData = this.convertToCSVorExcel(data, ['id','type','name', 'description','validity','month_duration','year_duration','day_duration']);
      let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
      let dwldLink = document.createElement("a");
      let url = URL.createObjectURL(blob);
      let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
      if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
          dwldLink.setAttribute("target", "_blank");
      }
      dwldLink.setAttribute("href", url);
      dwldLink.setAttribute("download", filename + ".csv");
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
  }

  downloadFileExcel(data, filename='data') {
         
    let ExcelData = this.convertToCSVorExcel(data, ['id','type','name', 'description','validity','month_duration','year_duration','day_duration']);
    let blob = new Blob(['\ufeff' + ExcelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".xls");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  convertToCSVorExcel(objArray, headerList) {

        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        let row = '';
      
        for (let index in headerList) {
            row += headerList[index] + ';';

        }
       
        str += row + '\r\n';
        
        for (let i = 0; i < array.length; i++) {
        

        array.forEach(function(item, i) 
        { 
        
        if (array[i].validity == true) 
        
        {
            
            array[i].validity = "Oui";
        } 

        else  
        {
            array[i].validity = "Non";
        }

        if (array[i].description == null || array[i].description == "null") {
          
          array[i].description = "";

        }


        if (array[i].nom == null || array[i].nom == "null") {
          
          array[i].nom = "";

        }


      }
        
        );
      
        let line = '';
          
        
        for (let index in  headerList) 
        
        {
          
          let head = headerList[index];

          line +=  array[i][head] + ';' ;
          
        }
        str += line + '\r\n';

        }

        let str_ = '';
        
        str_ = str.replace("id;type;name;description;validity;month_duration;year_duration;day_duration","Id;Type;Nom;Description;Durée de validité;Durée jour(s);Durée mois;Durée année(s)");
        
        return str_;
    }

   

}
