import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GuardianGuard } from '@core/guard/guardian.guard';
import { RoleGuard } from '@core/guard/role.guard';
import { PermissionGuard } from '@core/guard/permission.guard';
import { ComponentCanDeactivateGuard } from '@core/guard/component-can-deactivate.guard';

// component to move
import { ContractComponent } from './kober-services/contract/contract.component';
import { NewContractComponent } from './kober-services/contract/new-contract/new-contract.component';
import { ShowContractComponent } from './kober-services/contract/show-contract/show-contract.component';
import { NewProjectComponent } from './kober-services/project/new-project/new-project.component';
import { ProjectComponent } from './kober-services/project/project.component';
import { ShowProjectComponent } from './kober-services/project/show-project/show-project.component';
import { NewWorkOrderComponent } from './kober-services/work-order/new-work-order/new-work-order.component';
import { ShowWorkOrderComponent } from './kober-services/work-order/show-work-order/show-work-order.component';
import { WorkOrderComponent } from './kober-services/work-order/work-order.component';
import { NewWorkComponent } from './kober-services/work/new-work/new-work.component';
import { ShowWorkComponent } from './kober-services/work/show-work/show-work.component';
import { WorkComponent } from './kober-services/work/work.component';
import { ConfigureCurrencyComponent } from './kober-setting/currency/configure-currency/configure-currency.component';
import { ShowCurrencyComponent } from './kober-setting/currency/show-currency/show-currency.component';
import { NewTagComponent } from './kober-setting/tag/new-tag/new-tag.component';
import { ShowTagComponent } from './kober-setting/tag/show-tag/show-tag.component';
import { TagComponent } from './kober-setting/tag/tag.component';
import { CompanyComponent } from './kober-skill/company/company.component';
import { NewCompanyComponent } from './kober-skill/company/new-company/new-company.component';
import { ShowCompanyComponent } from './kober-skill/company/show-company/show-company.component';
import { AssignSkillComponent } from './kober-skill/persona/new-persona/assign-skill.component';
import { NewPersonaStepsComponent } from './kober-skill/persona/new-persona/new-persona-steps.component';
import { NewPersonaComponent } from './kober-skill/persona/new-persona/new-persona.component';
import { PersonaComponent } from './kober-skill/persona/persona.component';
import { AddSkillComponent } from './kober-skill/persona/show-persona/add-skill.component';
import { ShowPersonaComponent } from './kober-skill/persona/show-persona/show-persona.component';
import { AssignSkillsToPersonasComponent } from './kober-skill/select-persona-skill/assign-skills-to-personas/assign-skills-to-personas.component';
import { PersonasOfSkillComponent } from './kober-skill/select-persona-skill/personas-of-skill/personas-of-skill.component';
import { SkillsOfPersonasComponent } from './kober-skill/select-persona-skill/skills-of-personas/skills-of-personas.component';
import { NewSkillComponent } from './kober-skill/skill/new-skill/new-skill.component';
import { ShowSkillsComponent } from './kober-skill/skill/show-skills/show-skills.component';
import { SkillComponent } from './kober-skill/skill/skill.component';
import { NewTechnicianComponent } from './kober-skill/technician/new-technician/new-technician.component';
import { ShowTechnicianComponent } from './kober-skill/technician/show-technician/show-technician.component';
import { TechnicianComponent } from './kober-skill/technician/technician.component';
import { ExpiringSkillsComponent } from './kober-skill/select-persona-skill/expiring-skills/expiring-skills.component';
import { ShowSkillExpiringComponent } from './kober-skill/select-persona-skill/show-skill-expiring/show-skill-expiring.component';



const routes: Routes = [
    {
        path: '',
        loadChildren: () =>
            import('@modules/login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'phoenix/home',
        canActivate: [GuardianGuard],
        loadChildren: () =>
            import('@modules/home/home.module').then(m => m.HomeModule)
    },

    {
        path: 'phoenix/profile',
        canActivate: [PermissionGuard],
        data: { allowedPermissions: ['profile'] },
        loadChildren: () =>
            import('@modules/profile/profile.module').then(m => m.ProfileModule)
    },

    {
        path: 'phoenix/intervention',
        canActivate: [PermissionGuard],
        data: { allowedPermissions: ['kober:interventions'] },
        loadChildren: () =>
            import('@modules/kober-intervention/kober-intervention.module').then(m => m.KoberInterventionModule)
    },
    {
        path: 'phoenix/settings',
        canActivate: [PermissionGuard],
        data: { allowedPermissions: ['kober:parametrage'] },
        loadChildren: () =>
            import('@modules/kober-setting/kober-setting.module').then(m => m.KoberSettingModule)
    },
    {
        path: 'phoenix/logistic',
        canActivate: [PermissionGuard],
        data: { allowedPermissions: ['kober:logistique']},
        loadChildren: () => import ('@modules/kober-material/kober-material.module').then(m => m.KoberMaterialModule)
    },

    /** KOBER COMPETENCE **/
    {
        path: 'phoenix/persona',
        canActivate: [RoleGuard],
        component: PersonaComponent,
        data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO', 'charge_de_pilotage', 'manager_operationnel', 'manager_CA'] }
    },
    {
        path: 'phoenix/new-persona',
        canActivate: [RoleGuard],
        component: NewPersonaStepsComponent,
        data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO'] },
        children: [
            { path: '', redirectTo: 'persona', pathMatch: 'full' },
            {
                path: 'persona',
                canActivate: [RoleGuard],
                canDeactivate: [ComponentCanDeactivateGuard],
                component: NewPersonaComponent,
                data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO'] }
            },
            {
                path: 'assign-skill',
                canActivate: [RoleGuard],
                canDeactivate: [ComponentCanDeactivateGuard],
                component: AssignSkillComponent,
                data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO', 'manager_operationnel'] }
            },
            {
                path: 'add-skill',
                canActivate: [RoleGuard],
                component: AddSkillComponent,
                data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO'] }
            }
        ]
    },
    {
        path: 'phoenix/show-persona/:id',
        canActivate: [RoleGuard],
        canDeactivate: [ComponentCanDeactivateGuard],
        component: ShowPersonaComponent,
        data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO', 'manager_CA', 'charge_de_pilotage', 'manager_operationnel'] }
    },
    {
        path: 'phoenix/show-technician/:id',
        canActivate: [RoleGuard],
        component: ShowTechnicianComponent,
        canDeactivate: [ComponentCanDeactivateGuard],
        data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO', 'manager_CA', 'charge_de_pilotage'] }
    },
    {
        path: 'phoenix/new-skill',
        canActivate: [RoleGuard],
        canDeactivate: [ComponentCanDeactivateGuard],
        component: NewSkillComponent,
        data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO'] }
    },
    {
        path: 'phoenix/new-technician',
        canActivate: [RoleGuard],
        component: NewTechnicianComponent,
        canDeactivate: [ComponentCanDeactivateGuard],
        data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO', 'manager_CA', 'manager_operationnel', 'charge_de_pilotage'] }
    },
    {
        path: 'phoenix/skill',
        canActivate: [GuardianGuard],
        component: SkillComponent,
        data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO', 'charge_de_pilotage', 'manager_operationnel', 'manager_CA'] }
    },
    {
        path: 'phoenix/show-skills/:id',
        canActivate: [RoleGuard],
        canDeactivate: [ComponentCanDeactivateGuard],
        component: ShowSkillsComponent,
        data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO', 'manager_CA', 'charge_de_pilotage', 'manager_operationnel'] }
    },
    {
        path: 'phoenix/expiring-skills',
        canActivate: [RoleGuard],
        component: ExpiringSkillsComponent,
        data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO', 'manager_CA', 'charge_de_pilotage', 'manager_operationnel'] }
    },
    {
        path: 'phoenix/show-skill-expired/:id',
        canActivate: [RoleGuard],
        component: ShowSkillExpiringComponent,
        data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO', 'manager_CA', 'charge_de_pilotage', 'manager_operationnel'] }
    },
    {
        path: 'phoenix/technician',
        canActivate: [RoleGuard],
        component: TechnicianComponent,
        data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO', 'charge_de_pilotage', 'manager_operationnel', 'manager_CA'] }
    },
    {
        path: 'phoenix/company',
        canActivate: [RoleGuard],
        component: CompanyComponent,
        data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO', 'charge_de_pilotage', 'manager_operationnel', 'manager_CA'] }
    },
    {
        path: 'phoenix/new-company',
        canActivate: [RoleGuard],
        component: NewCompanyComponent,
        canDeactivate: [ComponentCanDeactivateGuard],
        data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO'] }
    },
    {
        path: 'phoenix/show-company/:id',
        canActivate: [RoleGuard],
        canDeactivate: [ComponentCanDeactivateGuard],
        component: ShowCompanyComponent,
        data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO', 'manager_CA', 'charge_de_pilotage', 'manager_operationnel'] }
    },
    {
        path: 'phoenix/skills-of-personas',
        canActivate: [RoleGuard],
        component: SkillsOfPersonasComponent,
        data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO', 'manager_operationnel', 'manager_CA'] }
    },
    {
        path: 'phoenix/personas-of-skills',
        canActivate: [RoleGuard],
        component: PersonasOfSkillComponent,
        data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO', 'manager_operationnel', 'manager_CA'] }
    },
    {
        path: 'phoenix/assign-skills-to-personas',
        canActivate: [RoleGuard],
        component: AssignSkillsToPersonasComponent,
        data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO', 'manager_operationnel', 'manager_CA'] }
    },

    /** KOBER SERVICES **/
    {
        path: 'phoenix/work',
        canActivate: [RoleGuard],
        component: WorkComponent,
        data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO', 'manager_operationnel','manager_operationnel_prestataire', 'manager_CA','ca_prestataire', "charge_de_pilotage"] }
    },
    {
        path: 'phoenix/new-work',
        canActivate: [RoleGuard],
        component: NewWorkComponent,
        canDeactivate: [ComponentCanDeactivateGuard],
        data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO', "manager_operationnel" , "manager_operationnel_prestataire"] }
    },
    {
        path: 'phoenix/show-work',
        canActivate: [RoleGuard],
        component: ShowWorkComponent,
        canDeactivate: [ComponentCanDeactivateGuard],
        data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO', 'manager_operationnel','manager_operationnel_prestataire', 'manager_CA','ca_prestataire', "charge_de_pilotage"] }
    },
    {
        path: 'phoenix/show-work/:id',
        canActivate: [RoleGuard],
        component: ShowWorkComponent,
        canDeactivate: [ComponentCanDeactivateGuard],
        data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO', 'manager_CA','ca_prestataire', 'manager_operationnel','manager_operationnel_prestataire', "charge_de_pilotage"] }
    },
    {
        path: 'phoenix/project',
        canActivate: [RoleGuard],
        component: ProjectComponent,
        data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO', 'manager_operationnel', 'manager_CA'] }
    },
    {
        path: 'phoenix/new-project',
        canActivate: [RoleGuard],
        component: NewProjectComponent,
        canDeactivate: [ComponentCanDeactivateGuard],
        data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO', 'manager_operationnel','manager_CA'] }
    },
    {
        path: 'phoenix/work-order',
        canActivate: [RoleGuard],
        component: WorkOrderComponent,
        data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO', 'manager_operationnel','manager_operationnel_prestataire', 'manager_CA','ca_prestataire', "charge_de_pilotage", "magasinier"] }
    },
    {
        path: 'phoenix/new-work-order',
        canActivate: [RoleGuard],
        component: NewWorkOrderComponent,
        canDeactivate: [ComponentCanDeactivateGuard],
        data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO', 'manager_operationnel','manager_operationnel_prestataire', 'manager_CA','ca_prestataire', "charge_de_pilotage"] }
    },
    {
        path: 'phoenix/show-work-order/:id',
        canActivate: [RoleGuard],
        component: ShowWorkOrderComponent,
        canDeactivate: [ComponentCanDeactivateGuard],
        data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO', 'manager_operationnel','manager_operationnel_prestataire', 'manager_CA', 'ca_prestataire',"charge_de_pilotage", "magasinier"] }
    },
    {
        path: 'phoenix/show-project/:id',
        canActivate: [RoleGuard],
        component: ShowProjectComponent,
        canDeactivate: [ComponentCanDeactivateGuard],
        data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO', 'manager_operationnel', 'manager_CA'] }
    },
    {
        path: 'phoenix/contract',
        canActivate: [RoleGuard],
        component: ContractComponent,
        data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO', 'manager_operationnel','manager_operationnel_prestataire'] }
    },
    {
        path: 'phoenix/new-contract',
        canActivate: [RoleGuard],
        component: NewContractComponent,
        canDeactivate: [ComponentCanDeactivateGuard],
        data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO'] }
    },
    {
        path: 'phoenix/show-contract/:id',
        canActivate: [RoleGuard],
        component: ShowContractComponent,
        canDeactivate: [ComponentCanDeactivateGuard],
        data: { allowedRoles: ['administrateur', 'admin_metier', 'admin_responsable_DPO', 'manager_operationnel','manager_operationnel_prestataire'] }
    },

    /** KOBER SETTING **/
    {
        path: 'phoenix/tag',
        canActivate: [PermissionGuard],
        data: { allowedPermissions: ['kober:parametrage'] },
        component: TagComponent,
    },
    {
        path: 'phoenix/new-tag',
        canActivate: [PermissionGuard],
        data: { allowedPermissions: ['kober:parametrage'] },
        component: NewTagComponent,
    },
    {
        path: 'phoenix/show-tag/:id',
        canActivate: [PermissionGuard],
        data: { allowedPermissions: ['kober:parametrage'] },
        component: ShowTagComponent,
    },

    {
        path: 'phoenix/configure-currency',
        canActivate: [PermissionGuard],
        data: { allowedPermissions: ['kober:parametrage'] },
        component: ConfigureCurrencyComponent,
    },
    {
        path: 'phoenix/show-currency',
        canActivate: [PermissionGuard],
        data: { allowedPermissions: ['kober:parametrage'] },
        component: ShowCurrencyComponent,
    },

    {
        path: 'phoenix/not-found',
        loadChildren: () =>
            import('@modules/not-found/not-found.module').then(m => m.NotFoundModule)
    },
    {
        path: '**',
        redirectTo: '/phoenix/not-found'
    },
    //   {
    //     path: '',
    //     redirectTo: '/auth/login',
    //     pathMatch: 'full'
    //   },
    //   {
    //     path: '',
    //     canActivate: [NoAuthGuard], // Should be replaced with actual auth guard
    //     children: [
    //       {
    //         path: 'dashboard',
    //         loadChildren: () =>
    //           import('@modules/home/home.module').then(m => m.HomeModule)
    //       },
    //       {
    //         path: 'about',
    //         loadChildren: () =>
    //           import('@modules/about/about.module').then(m => m.AboutModule)
    //       },
    //       {
    //         path: 'contact',
    //         loadChildren: () =>
    //           import('@modules/contact/contact.module').then(m => m.ContactModule)
    //       }
    //     ]
    //   },
    //   {
    //     path: 'auth',
    //     component: AuthLayoutComponent,
    //     loadChildren: () =>
    //       import('@modules/auth/auth.module').then(m => m.AuthModule)
    //   },
    // Fallback when no prior routes is matched
    //   { path: '**', redirectTo: '/auth/login', pathMatch: 'full' }
    // {
    //     path: 'phoenix/home', canActivate: [GuardianGuard], component: HomeComponent
    // },

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [RouterModule],
    providers: [],
})
export class AppRoutingModule { }
