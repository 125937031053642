<app-kober-menu
module="kober-skill"
></app-kober-menu>
<div class="p-d-flex p-jc-center p-m-2 p-p-2 main-page">
  <div class="p-col-12 p-md-11 p-jc-center p-m-3 p-p-3 main-content p-shadow-4">
    <div class="p-grid p-col-12">
      <div class="p-col-12 p-d-flex p-jc-between">

        <div class="p-d-flex">
          <h2 class="p-as-center">Fiche Compétence</h2>
        </div>


        <div class="p-col-6 p-d-flex p-jc-end">
          <qrcode *ngIf="qrData" [qrdata]="qrData" [width]="150" [errorCorrectionLevel]="'M'"></qrcode>
        </div>
      </div>
      <ng-container   *ngFor="let item of skill.tags">

        <p-chip *ngIf="!updateGlobal" [pTooltip]="item?.description" tooltipPosition="top"  [label]="item.label"  styleClass="custom-chip" [style]="{'background': item.colorCode,'color':'#ffffff','border-radius':'6px','height':'35px','margin-right': '8px','margin-top': '8px'}" >

        </p-chip>

        <p-chip *ngIf="updateGlobal" [pTooltip]="item?.description" tooltipPosition="top"  (onRemove)="removeTag($event,item)" [removable]="true"  [label]="item.label"  styleClass="custom-chip" [style]="{'color':'#ffffff','background': item.colorCode,'border-radius':'6px','height':'35px','margin-right': '8px','margin-top': '8px'}" >

        </p-chip>

      </ng-container>
    </div>

    <div class="p-grid p-col-12 p-d-flex p-jc-center">
      <div class="p-col-12">
        <div class="p-fluid">

          <div class="p-field p-grid">


            <label for="nom" class="p-col-6 p-md-2 p-mb-md-0"> {{ 'NOM' | translate }} * : </label>
            <span class="p-col-12 p-md-10" *ngIf="!updateGlobal">{{
              skill?.name
            }}</span>

            <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
              <input
               (ngModelChange)=onChange($event)
                type="text"
                value="{{ skill?.name }}"
                *ngIf="updateGlobal"
                pInputText
                id="name"
                [ngClass]="{ 'ng-invalid ng-dirty': errorNameRequired }"
                type="text"
                pInputText
                [(ngModel)]="skill.name"
                (blur)="nameFieldValidator($event)"
                (input)="nameFieldValidator($event)"
                required
                [(ngModel)]="skill.name"
                name="name"
              />
              <p-message
                *ngIf="errorNameRequired"
                severity="error"
                text="Champ obligatoire."
              ></p-message>
            </div>

          </div>

          <div class="p-field p-grid">

            <label for="type" class="p-col-6 p-md-2 p-mb-md-0"> Type de compétence*: </label>
            <span class="p-col-12 p-md-10" *ngIf="!updateGlobal">{{
              skill?.type
            }}
            </span>

            <div *ngIf="!updateGlobal" class="p-col-12 p-md-10 p-pl-5 p-pr-5">
              <span *ngIf="skill.type === HABILIATION">{{ 'HABILITATION' | translate }}</span>
              <span *ngIf="skill.type === SAVOIR_FAIRE">Savoir faire</span>
              <span *ngIf="skill.type === SAVOIR_ETRE">Savoir être</span>
            </div>


            <div *ngIf="updateGlobal" class="p-col-12 p-md-10 p-pl-5 p-pr-5">

                  <p-dropdown
                  (ngModelChange)=onChange($event)
                  id="type"
                  [options]="typeOptions"
                  placeholder="Type de compétence"
                  [(ngModel)]="skill.type"
                  optionLabel="name"
                  optionValue="value"
                  [ngClass]="{'ng-invalid ng-dirty': errorTypeRequired}"

                ></p-dropdown>
                <p-message
                  *ngIf="errorTypeRequired"
                  severity="error"
                  text="Champ obligatoire."
                ></p-message>

            </div>


          </div>

          <div class="p-field p-grid">
            <label for="description" class="p-col-6 p-md-2 p-mb-md-0">
              Description:
            </label>
            <span class="p-col-12 p-md-10" *ngIf="!updateGlobal">{{
              skill?.description
            }}</span>
            <div
              class="p-col-12 p-md-10 p-pl-5 p-pr-5"
              style="word-break: break-word"
            >
              <textarea
              (ngModelChange)=onChange($event)
                class="desc"
                type="text"
                value="{{ skill?.description }}"
                *ngIf="updateGlobal"
                pInputText
                id="description"
                required
                [(ngModel)]="skill.description"
                name="description"
              >
              </textarea>
            </div>
          </div>

          <div class="p-field p-grid" *ngIf="updateGlobal">
            <label for="name" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
              Tags :
            </label>
            <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">

              <p-multiSelect id="multiselectTag" #ms [options]="tags" [group]="true" defaultLabel="{{ 'FILTRER_PAR_TAG' | translate }}"
              [(ngModel)]="selectedTags"
              (ngModelChange)=onChange($event)
              optionLabel="label" (onClick)="onClickElement($event)"
              (onChange)="changeTag($event)"
              [dropdownIcon]="iconChange" >
              <ng-template let-value pTemplate="selectedItems">
                <div class="p-grid" style="margin:auto">
                  <p-chip [label]="option.label" [style]="{'background': option.colorCode, 'color':'#ffffff', 'margin':'auto 0','border-radius':'6px'}"
                          (onRemove)="chipRemove(option)" [pTooltip]="option?.description" tooltipPosition="top" tooltipZIndex="100000000000"
                          [removable]="true" *ngFor="let option of selectedTags">
                  </p-chip>
                  <div *ngIf="selectedTags.length === 0" class="tag-placeholder">
                    {{ 'AJOUTER_TAGS' | translate }}
                  </div>
                </div>
              </ng-template>

              <ng-template let-item pTemplate="item">
                <div [ngStyle]="{'background-color': item.colorCode,'width':'10px','height':'10px', 'border-radius':'2px','margin-right':'5%'}"></div>
                <div class="tag-item" [pTooltip]="item?.description" tooltipPosition="right" tooltipZIndex="100000000000">
                  {{item.label}}
                </div>
              </ng-template>

              </p-multiSelect>


            </div>
          </div>

        </div>
      </div>

      <div class="p-col-12">

        <div class="p-fluid">
          <div class="p-field p-grid">
            <label *ngIf="hab" class="p-col-3 p-md-2 p-mb-md-0">
              Durée de validité :
            </label>
            <div *ngIf="hab && !updateGlobal" class="p-col-6 p-pl-5 p-pr-5">
              <p-inputSwitch  disabled
                (onChange)="checkExpi($event)"
                [(ngModel)]="skill.validity"
              ></p-inputSwitch>
            </div>
            <div *ngIf="hab && updateGlobal" class="p-col-6 p-pl-5 p-pr-5">
              <p-inputSwitch
              (onChange)="checkExpi($event)"
              (ngModelChange)=onChange($event)
              [(ngModel)]="skill.validity"
            ></p-inputSwitch>
            </div>
          </div>


          <div class="p-field p-grid">
            <label *ngIf="hab && expi" class="p-col-6 p-md-2 p-mb-md-0">
              {{'ANNEE_S_MAJ'|translate}}:
            </label>
            <span *ngIf="!updateGlobal && hab && expi">
              {{ skill?.year_duration }}</span
            >
            <div class="p-col-12 p-md-10 p-pl-5">
              <input
                type="number"
                min="0"
                value="{{ skill?.year_duration }}"
                pInputText
                *ngIf="updateGlobal && hab && expi"
                (ngModelChange)=onChange($event)
                (input)="dateYearFieldValidator($event)"
                id="month_duration"
                [(ngModel)]="skill.year_duration"
                name="year_duration"
              />
            </div>
          </div>


          <div class="p-field p-grid">
            <label *ngIf="hab && expi" class="p-col-6 p-md-2 p-mb-md-0">
              {{'MOIS_S_MAJ'|translate}}:
            </label>
            <span *ngIf="!updateGlobal && hab && expi">
              {{ skill?.month_duration }}</span
            >
            <div class="p-col-12 p-md-10 p-pl-5">
              <input
                type="number"
                min="0"
                value="{{ skill?.month_duration }}"
                pInputText
                *ngIf="updateGlobal && hab && expi"
                (ngModelChange)=onChange($event)
                (input)="dateMonthFieldValidator($event)"
                id="month_duration"
                [(ngModel)]="skill.month_duration"
                name="month_duration"
              />
            </div>
          </div>

          <div class="p-field p-grid">
            <label *ngIf="hab && expi" class="p-col-6 p-md-2 p-mb-md-0">
              {{'JOUR_S_MAJ'|translate}}:
            </label>
            <span *ngIf="!updateGlobal && hab && expi">
              {{ skill?.day_duration }}</span
            >
            <div class="p-col-12 p-md-10 p-pl-5">
              <input
                type="number"
                min="0"
                value="{{ skill?.day_duration }}"
                pInputText
                *ngIf="updateGlobal && hab && expi"
                (ngModelChange)=onChange($event)
                (input)="dateDayFieldValidator($event)"
                id="month_duration"
                [(ngModel)]="skill.day_duration"
                name="day_duration"
              />
            </div>
          </div>




          <p-message
            *ngIf="errorDateRequired"
            severity="error"
            text="Champ obligatoire."
          ></p-message>


          <p-message
            *ngIf="errorInvalidValue"
            severity="error"
            text="Format non valide (caractères acceptés : numériques positifs uniquement)"
          ></p-message>







        </div>
      </div>



      <p-confirmDialog
        baseZIndex="5"
        styleClass="main-page"
        [style]="{ width: '60%' }"
      ></p-confirmDialog>
      <!-- update skill router link -->
      <p-sidebar
        class="p-text-right"
        [style]="{ height: '13vh' }"
        [visible]="true"
        [blockScroll]="true"
        [dismissible]="false"
        [showCloseIcon]="false"
        [modal]="false"
        position="bottom"
      >
        <p-button
          styleClass="p-button-outlined p-mr-2"
          *ngIf="!updateGlobal"
          label="Retour"
          routerLink="/phoenix/skill"
        ></p-button>
        <p-button
          *ngIf="updateGlobal"
          styleClass="p-button-outlined p-mr-2"
          label="Retour"
          (click)="confirm()"
        ></p-button>
        <p-button
          *ifRoles="['administrateur', 'admin_metier', 'admin_responsable_DPO']"
          class="p-mr-2 p-mb-2"
          (click)="confirme()"
          label="Supprimer"
        ></p-button>
        <p-button
          *ifRoles="['administrateur', 'admin_metier', 'admin_responsable_DPO']"
          class="p-mr-2 p-mb-2"
          (click)="updateSkills()"
          label="{{ label }}"
        ></p-button>
      </p-sidebar>
    </div>
  </div>
</div>
