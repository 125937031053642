import { Component, OnInit, HostListener } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Company } from '@data/schema/Company';
import { CompanyService } from '@data/service/company.service';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { PersonaService } from '@data/service/persona.service';
import { FileUpload } from 'primeng/fileupload';
import { ComponentCanDeactivate } from '@core/guard/component-can-deactivate.guard';
import { Observable, BehaviorSubject } from 'rxjs';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'app-show-company',
  templateUrl: './show-company.component.html',
  styleUrls: ['./show-company.component.css'],
})
export class ShowCompanyComponent implements OnInit,ComponentCanDeactivate {

  public submited: BehaviorSubject<boolean>;


    //@HostListener allows us to also guard against browser refresh, close, etc.
    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
      return !this.changed || this.submited.value ? true : false;
    }

  company: Company = new Company();
  filterCompany: string[];
  id: number;
  updateGlobal: boolean = false;
  editMode: boolean;
  errorNameRequired: boolean = false;
  errorTypeRequired: boolean = false;
  type: any[];
  label: string = 'Modifier';
  changed: boolean;
  deletable: boolean = false;

  listLinkedCompany: number[];

  private sub: any;
  companyUpdate: Company = new Company();

  display: boolean = false;
  imageBase64: any = '';
  croppedImage: string;
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = true;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  errorUpload: boolean = false;


  constructor(
    private route: ActivatedRoute,
    private messageService: MessageService,
    private companyService: CompanyService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private _location: Location,
    private personaService: PersonaService
  ) {
    this.submited = new BehaviorSubject(false)
    this.route.queryParams.subscribe((params) => {
      if (params.editMode) {
        this.updateGlobal = true;
        this.label = 'Valider';
      }
    });
  }
  ngOnInit(): void {
    this.type = [
      { name: 'Autre', value: 'Autre' },
      { name: 'Filliale', value: 'Filliale' },
      { name: 'Fournisseur', value: 'Fournisseur' },
      { name: 'Kyntus', value: 'Kyntus' },
      { name: 'Maison mère', value: 'Maison mère' },
      { name: 'Partenaire', value: 'Partenaire' },
      { name: 'Sous-traitant', value: 'Sous-traitant' },
    ];

    this.sub = this.route.params.subscribe((params) => {
      this.id = +params['id'];
    });

    this.changed = false;

    this.personaService.getListCompanyIdOfPersona().subscribe(
      (res) => {
        console.log(res);
        if (res.status === 200) {
          this.listLinkedCompany = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur',
            detail: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {}
    );

    this.companyService.getCompanyById(this.id).subscribe(
      (res) => {
        if (res.status === 200) {
          this.company = res.body;
          if (this.company.enabled === false) {
            this.deletable = true;
            console.log('salut');
            console.log(this.deletable);



          }
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      }
    );



  }



  clearUpload(flt : FileUpload){

    flt.clear();
  }




  switchEditCompanyMode(value: boolean) {
    this.updateGlobal = value;
  }

  onSelection(event, fileUpload: FileUpload) {
    this.display = true;
    let fileReader = new FileReader();

    let file = event.files[0];

    fileReader.readAsDataURL(file);
    fileReader.onload = (e) => {
      // Store the base64 string of the image file
      this.imageBase64 = fileReader.result.toString();
    };
  }

  // Used to upload an image file that catch the event
  onUpload(fileUpload: FileUpload) {
    this.company.logo = this.croppedImage;
    this.display = false;
    fileUpload.clear();
  }

  clear(fileUpload: FileUpload) {
    fileUpload.clear();
  }


  confirmUpdateCompany() {
    if (this.company?.name === '') {
      this.errorNameRequired = true;
    }
    if (this.updateGlobal === false) {
      this.label = 'Valider';
      this.updateGlobal = true;
    } else if (this.updateGlobal == true) {
      this.updateCompany();
    }
  }
  updateCompany() {
    // update company function
    this.companyService.updateCompany(this.company).subscribe(
      (res) => {
        if (res.status === 200) {
          this.updateGlobal = false;
          this.label = 'Modifier';
          this.messageService.add({
            severity: 'success',
            summary: 'Opération réussie',
          });
        }
      },
      (error) => {
        if (error.status === 500) {
          this.messageService.add({severity: 'error', summary: 'Échec de l’opération : veuillez réessayer'});
       }
       if (error.status === 409) {
      this.messageService.add({severity: 'error', summary: 'Cet élément existe déjà'});
      }
    },

      () => {this.redirectTo('/phoenix/company')}
    );

  }
  companyType(event) {
    this.filterCompany = this.type
      .filter((c) => c.name.toLowerCase().startsWith(event.query.toLowerCase()))
      .map((company) => company.name);
  }
  confirmReturn() {
    this.confirmationService.confirm({
      header: 'Confirmation',
      message:
        'Vous allez quitter la modification, les données saisies seront perdues.' +
        '<br>' +
        'Voulez vous continuer ?',
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button-outlined',
      acceptIcon: 'null',
      rejectIcon: 'null',
      acceptLabel: 'Quitter',
      rejectLabel: 'Annuler',
      accept: () => {
        this.company = this.company;
        this.errorNameRequired = false;
        this.errorTypeRequired = false;
      },
    });
  }
  // delete(id: number) {
  //   // Call API in the service to soft delete Persona
  //   this.companyService.deleteCompany(id).subscribe(
  //     (res) => {
  //       // Deleted
  //       if (res.status === 200) {
  //         console.log(res.body);
  //       }
  //     },
  //     (error) => {
  //       if (error) {
  //         this.messageService.add({
  //           severity: 'error',
  //           summary: 'Échec de l’opération : veuillez réessayer',
  //         });
  //       }
  //     },
  //     () => {}
  //   );
  // }

  confirmActiveCompany(){
   this.confirmationService.confirm({
   header: 'Confirmation',
        message:
          'Vous allez réactiver cette entreprise.' + '<br>' + 'Voulez vous continuer ?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Activer',
        rejectLabel: 'Annuler',
        accept: () => {
          this.activate();
          this.redirectTo('/phoenix/company')
          this.messageService.add({
            severity: 'success',
            summary: 'Opération réussie',
        });
      },
    });
  }
  activate(){
   this.companyService.activateCompanyById(this.company.companyId).subscribe(
    (res) => {
    },
    (error) => {
      if (error) {
        this.messageService.add({
          severity: 'error',
          summary: 'Échec de l’opération : veuillez réessayer',
        });
      }
    }
  );
 }
  deleteCompany() {
    if (this.company.enabled === true) {
      this.companyService.disableCompany(this.company.companyId).subscribe(
        (res) => {
          
        },
        (error) => {
          if (error) {
            this.messageService.add({
              severity: 'error',
              summary: 'Échec de l’opération : veuillez réessayer',
            });
          }
        },
      );
    }
  }

  confirme() {
    if (this.checkCompanyAlreadyUsed(this.company.companyId)) {
      this.confirmationService.confirm({
        header: 'Désactivation impossible',
        message:
          "Cette entreprise est associée à un ou plusieurs personas : vous devez supprimer les associations pour désactiver l'entreprise.",
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        acceptLabel: "J'ai compris",
        rejectVisible: false,
      });
    } else {
      this.confirmationService.confirm({
        header: 'Confirmation',
        message:
          'Vous allez désactiver cette entreprise.' +
          '<br>' +
          'Voulez vous continuer ?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Désactiver',
        rejectLabel: 'Annuler',
        accept: () => {
          this.deleteCompany();
          this.redirectTo('/phoenix/company')
          this.messageService.add({
            severity: 'success',
            summary: 'Opération réussie',
          });
        },
      });
    }

  }

  goBack() {
    this.submited.next(true);
    this._location.back();
  }

  redirectTo(uri:string){
    this.submited.next(true);
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
 }

  confirmReturnBottom() {
    if (this.updateGlobal) {
      this.confirmationService.confirm({
        header: 'Confirmation',
        message:
          'Vous allez quitter la page, les données saisies seront perdues.' +
          '<br>' +
          'Voulez vous continuer ?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Quitter',
        rejectLabel: 'Annuler',
        accept: () => {
          this.goBack();
        },
      });
    } else {
      this.goBack();
    }
  }

   //Detect input changes
   onChange(UpdatedCompany: Company): void {
    console.log("changement")
     if (this.company != UpdatedCompany) {
       this.changed = true;
     }
   }


  checkCompanyAlreadyUsed(companyId: number) {
    if (this.listLinkedCompany.includes(companyId)) {
      return true;
    } else {
      return false;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  cropperReady() {
    // cropper ready
  }

  imageLoaded(event) {
    this.errorUpload = false;
    // show cropper
    //this.company.logo = this.croppedImage;
    this.display = true;

  }

  loadImageFailed() {
    // show message
    this.errorUpload = true;
  }

  deletePhoto(){
    this.company.logo = null;
  }

}
